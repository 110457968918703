import React from 'react';

const SvgIconConfiguration = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-configuration_svg__a"
        d="M21.195 9.398l-2.18-.363a8.209 8.209 0 0 0-.69-1.844l1.396-1.73a.962.962 0 0 0-.03-1.245l-.867-.975a.962.962 0 0 0-1.232-.176l-1.864 1.171a8.2 8.2 0 0 0-2.75-1.24L12.614.803a.961.961 0 0 0-.95-.804h-1.306a.96.96 0 0 0-.948.804l-.366 2.192a8.193 8.193 0 0 0-2.314.961L4.958 2.692a.964.964 0 0 0-1.24.103l-.923.923a.963.963 0 0 0-.101 1.24L3.96 6.734a8.217 8.217 0 0 0-.953 2.296l-2.204.368a.962.962 0 0 0-.804.95v1.305c0 .47.341.872.804.949l2.204.368c.166.675.408 1.32.73 1.919l-1.39 1.72a.962.962 0 0 0 .03 1.244l.866.975a.963.963 0 0 0 1.233.176l1.89-1.187c.8.547 1.705.949 2.677 1.187l.366 2.192a.96.96 0 0 0 .948.804h1.306c.472 0 .873-.34.95-.804l.365-2.192a8.186 8.186 0 0 0 2.286-.945l1.847 1.318a.96.96 0 0 0 1.24-.102l.922-.923a.959.959 0 0 0 .102-1.24L18.06 15.27a8.176 8.176 0 0 0 .955-2.303l2.18-.364a.96.96 0 0 0 .804-.95v-1.305a.962.962 0 0 0-.805-.949zm-10.16 5.727a4.126 4.126 0 1 1 .001-8.251 4.126 4.126 0 0 1-.001 8.251z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <mask id="icon-configuration_svg__b" fill="#fff">
        <use xlinkHref="#icon-configuration_svg__a" />
      </mask>
      <use fill="currentColor" xlinkHref="#icon-configuration_svg__a" />
      <g fill="currentColor" mask="url(#icon-configuration_svg__b)">
        <path d="M-1-1h24v24H-1z" />
      </g>
    </g>
  </svg>
);

export default SvgIconConfiguration;
