import React from 'react';

const SvgIconTruckOn = props => (
  <svg width={178} height={85} viewBox="0 0 178 85" {...props}>
    <defs>
      <linearGradient id="icon-truck-on_svg__a" x1="34.179%" x2="64.341%" y1="27.395%" y2="70.452%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__b" x1="61.585%" x2="47.713%" y1="63.045%" y2="41.936%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__c" x1="59.197%" x2="43.525%" y1="77.425%" y2="40.472%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__d" x1="83.125%" x2="50.124%" y1="66.123%" y2="34.827%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__e" x1="55.473%" x2="53.632%" y1="98.956%" y2="82.422%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__f" x1="30.2%" x2="70.584%" y1="35.688%" y2="63.973%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__g" x1="66.426%" x2="46.096%" y1="73.084%" y2="48.987%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__h" x1="6.851%" x2="66.034%" y1="9.119%" y2="99.976%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__i" x1="25.613%" x2="75.075%" y1="-1.945%" y2="75.956%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__j" x1="44.637%" x2="56.15%" y1="39.2%" y2="61.76%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__k" x1="45.485%" x2="54.789%" y1="40.57%" y2="58.8%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__l" x1="30.882%" x2="53.983%" y1="33.837%" y2="60.442%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__m" x1="47.385%" x2="52.483%" y1="45.45%" y2="55.43%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__n" x1="43.718%" x2="56.856%" y1="37.14%" y2="62.9%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__o" x1="49.47%" x2="50.689%" y1="48.88%" y2="51.28%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__p" x1="30.02%" x2="69.563%" y1="9.893%" y2="89.728%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__q" x1="43.842%" x2="56.971%" y1="37.09%" y2="62.85%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__r" x1="30.582%" x2="69.795%" y1="9.552%" y2="90.257%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__s" x1="35.343%" x2="64.99%" y1="9.906%" y2="90.691%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__t" x1="30.087%" x2="70.138%" y1="10.198%" y2="89.485%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__u" x1="36.241%" x2="66.843%" y1="39.602%" y2="59.741%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__v" x1="37.035%" x2="64.768%" y1="44.494%" y2="57.478%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__w" x1="14.315%" x2="66.853%" y1="8.108%" y2="87.548%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__x" x1=".909%" x2="65.417%" y1="3.224%" y2="117.995%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__y" x1="34.035%" x2="64.167%" y1="44.401%" y2="54.858%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__z" x1="32.186%" x2="67.859%" y1="39.769%" y2="60.241%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__A" x1="15.656%" x2="52.121%" y1="23.389%" y2="65.396%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__B" x1="26.527%" x2="74.056%" y1="27.943%" y2="72.173%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__C" x1="24.991%" x2="75.008%" y1="10.716%" y2="89.294%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__D" x1="28.692%" x2="71.308%" y1="6.695%" y2="93.323%">
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__E" x1="24.996%" x2="75.012%" y1="10.716%" y2="89.294%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__F" x1="28.697%" x2="71.314%" y1="6.695%" y2="93.323%">
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__G" x1="31.342%" x2="73.051%" y1="34.667%" y2="62.927%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__H" x1="35.641%" x2="60.56%" y1="9.778%" y2="80.651%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__I" x1="18.08%" x2="81.094%" y1="7.04%" y2="93.38%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__J" x1="43.702%" x2="55.709%" y1="8.721%" y2="90.858%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__K" x1="44.11%" x2="56.659%" y1="9.782%" y2="90.331%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__L" x1="48.104%" x2="52.033%" y1="12.121%" y2="87.548%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__M" x1="33.67%" x2="60.576%" y1="46.356%" y2="53.895%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__N" x1="-3.725%" x2="103.748%" y1="50.06%" y2="50.06%">
        <stop offset="0%" stopOpacity={0} />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__O" x1="16.38%" x2="76.344%" y1="11.285%" y2="93.89%">
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__P" x1="23.011%" x2="71.871%" y1="3.4%" y2="99.919%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-truck-on_svg__Q" x1="35.631%" x2="89.508%" y1="15.263%" y2="72.36%">
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="url(#icon-truck-on_svg__a)"
        fillRule="nonzero"
        d="M16.169 62v1.552L7 65.928v1.086h.669l.19 3.815-.859.404V72h.822L20 67.588V62z"
      />
      <path
        fill="url(#icon-truck-on_svg__b)"
        fillRule="nonzero"
        d="M9 60.664v2.191h2.685s3.956-.437 4.683.955h2.1v3.222c0 .707 1.004 1.88 1.004 1.88s-.698-.08-.698.708c0 .782-.153 2.042 1.164 2.508 1.317.472 5.534.943 8.98.863 3.445-.08 7.396-1.726 7.589-2.589.193-.862.193-2.456.193-2.456h1.163s-.658 1.283-.579 1.634c.08.35.908.333 1.924.19 0 0 3.332-4.878 3.786-9.037.454-4.16-26.853-5.707-33.994-.07z"
      />
      <path
        fill="url(#icon-truck-on_svg__c)"
        fillRule="nonzero"
        d="M41 71.94c2.492-2.166 4.797-2.038 4.797-2.038L64.063 77C66.456 75.92 68 73.446 68 73.446s.06-7.053-2.437-9.813c-2.498-2.76-14.991-5.004-19.606-2.637C41.337 63.364 41 71.94 41 71.94z"
      />
      <path
        fill="url(#icon-truck-on_svg__d)"
        fillRule="nonzero"
        d="M70.597 69s1.56-1.394 4.337-1.729c2.778-.34 5.066.295 5.066.295v-7.26L64 60s3.063 1.99 3.988 3.162C69.322 64.857 70.597 69 70.597 69z"
      />
      <path
        fill="url(#icon-truck-on_svg__e)"
        fillRule="nonzero"
        d="M121.56 3s-1.312.856-2.33 2.788l-.607 56.18L90 61.416v9.114s1.048-.102 3.543-.107V67.6c0-1.02.813-1.853 1.844-1.904 1.963-.084 5.29-.203 8.97-.203 3.68 0 7.007.119 8.97.203a1.917 1.917 0 0 1 1.844 1.904v4.027c1.236.113 2.53.237 3.88.372l1.472-49.393.521.017.206-6.905.75-2.434V4.28L121.56 3z"
      />
      <path
        fill="url(#icon-truck-on_svg__f)"
        fillRule="nonzero"
        d="M116 73.344c0 .566-.45 1.037-1.044 1.1-1.834.194-5.795.556-9.456.556-3.328 0-6.906-.393-8.906-.655-.916-.12-1.594-.855-1.594-1.73V67.96c0-.948.789-1.724 1.789-1.771a214.94 214.94 0 0 1 8.705-.189c3.573 0 6.8.11 8.706.189 1 .042 1.789.823 1.789 1.771v5.384H116z"
      />
      <path
        fill="url(#icon-truck-on_svg__g)"
        fillRule="nonzero"
        d="M169.108 74.196s-.411-.757-2.045-.898l-19.67 3.574s-5.196.486-7.002-.435c-1.807-.915-3.374-2.959-3.374-2.959s-.895-11.372 10.338-15.093c11.232-3.72 21.859.588 22.481 6.601.623 6.014-.728 9.21-.728 9.21z"
      />
      <path
        fill="url(#icon-truck-on_svg__h)"
        fillRule="nonzero"
        d="M176.818 61.957v-.922a.361.361 0 0 0-.357-.36h-.217v-.625l.385-.354a.586.586 0 0 0 .19-.433l-.006-2.785a.544.544 0 0 0-.285-.472l-.284-.152V54.75s.474-.253.284-.624c-.19-.372-10.866-9.76-17.73-11.583 0 0-12.69-14.84-15.984-17.608-3.298-2.767-8.13-2.548-21.369-2.936L120 71.768s18.076.248 19.868.326c1.791.073 3.432.906 3.432.906h2.238s.971-2.335 1.378-4.517c.413-2.183 1.496-5.834 4.89-7.6 3.398-1.767 10.19-1.204 11.719-.49 1.53.715 4.515 2.448 5.112 6.695.597 4.253.446 5.642.446 5.642s3.288-.04 3.695-.788c.413-.753.971-2.593 2.461-3.724 1.49-1.13 2.69-1.805 2.724-2.745.05-.94.24-3.42-1.145-3.516z"
      />
      <path
        fill="url(#icon-truck-on_svg__i)"
        fillRule="nonzero"
        d="M172.592 72.19c.429-.769 1.007-2.646 2.553-3.8 1.55-1.154 2.79-1.843 2.824-2.802.04-.959.232-3.49-1.198-3.588L166 62.465c1.053 1.074 1.985 2.6 2.298 4.777.619 4.34.463 5.758.463 5.758s3.403-.04 3.831-.81z"
      />
      <g fillRule="nonzero">
        <path
          fill="url(#icon-truck-on_svg__j)"
          d="M25.584 8.353a1.547 1.547 0 0 0-.06-.14c.021.053.043.094.06.14z"
          transform="translate(142 57)"
        />
        <path
          fill="url(#icon-truck-on_svg__k)"
          d="M25.314 7.726c-.017-.035-.039-.07-.055-.105.016.035.038.064.055.105z"
          transform="translate(142 57)"
        />
        <path
          fill="url(#icon-truck-on_svg__l)"
          d="M23.958 5.704l2.645-.123S24.206 1.723 19.947.873c-4.26-.85-9.323-.45-12.145 1.472-2.82 1.928-4.579 5.551-5.058 8.898-.48 3.342-.556 4.409-1.185 4.532-.628.129-1.013 0-1.013 0v.697c.358.153.578.264.578.264h2.21s.958-2.433 1.36-4.707c.408-2.275 1.477-6.079 4.828-7.92 3.35-1.84 10.061-1.254 11.571-.51.755.375 1.868 1.014 2.865 2.105z"
          transform="translate(142 57)"
        />
        <path
          fill="url(#icon-truck-on_svg__m)"
          d="M25.01 7.146c-.01-.018-.021-.035-.032-.059.01.024.022.041.033.059z"
          transform="translate(142 57)"
        />
        <path
          fill="url(#icon-truck-on_svg__n)"
          d="M25.815 9.04c-.016-.06-.038-.112-.055-.165.017.053.039.106.055.164z"
          transform="translate(142 57)"
        />
        <path
          fill="url(#icon-truck-on_svg__o)"
          d="M24.68 6.618l-.01-.011.01.011z"
          transform="translate(142 57)"
        />
        <path
          fill="url(#icon-truck-on_svg__p)"
          d="M26.41 12.832c-.022-.193-.038-.393-.066-.604.022.211.044.41.066.604z"
          transform="translate(142 57)"
        />
        <path
          fill="url(#icon-truck-on_svg__q)"
          d="M26.003 9.784c-.011-.059-.028-.117-.044-.176.016.059.033.117.044.176z"
          transform="translate(142 57)"
        />
        <path
          fill="url(#icon-truck-on_svg__r)"
          d="M26.333 12.146c-.022-.2-.05-.404-.071-.615.027.21.05.422.071.615z"
          transform="translate(142 57)"
        />
        <path
          fill="url(#icon-truck-on_svg__s)"
          d="M26.245 11.396c-.033-.258-.066-.528-.105-.815.039.287.078.557.105.815z"
          transform="translate(142 57)"
        />
        <path
          fill="url(#icon-truck-on_svg__t)"
          d="M26.41 12.856c.022.205.039.404.056.592-.017-.188-.034-.387-.056-.592z"
          transform="translate(142 57)"
        />
      </g>
      <path
        fill="url(#icon-truck-on_svg__u)"
        fillRule="nonzero"
        d="M177 55h-8.994s-.101 1.398.508 2h8.48v-2H177z"
      />
      <path
        fill="url(#icon-truck-on_svg__v)"
        fillRule="nonzero"
        d="M177 62h-5s.202-1 1.065-1h1.345l2.59.28V62z"
      />
      <path
        fill="url(#icon-truck-on_svg__w)"
        fillRule="nonzero"
        d="M127.74 53H124v-5h3.74c1.251 0 2.26 1.063 2.26 2.367v.26c0 1.316-1.015 2.373-2.26 2.373z"
      />
      <path
        fill="url(#icon-truck-on_svg__x)"
        fillRule="nonzero"
        d="M143 26s-.571-3.313-3.456-4.297c0 0-6.381-12-18.04-18.703l.44 1.256v8.888l-.74 2.4L121 22.36s10.25.15 12.988.456c2.744.306 6.263.434 9.012 3.185z"
      />
      <path
        fill="url(#icon-truck-on_svg__y)"
        fillRule="nonzero"
        d="M147 62.172L120.126 62 120 66.675l24.665.325c.005-.006.662-2.74 2.335-4.828z"
      />
      <path
        fill="url(#icon-truck-on_svg__z)"
        fillRule="nonzero"
        d="M171 53s-1.067-1.63-1.942-2H164l1.34 2H171z"
      />
      <path
        fill="url(#icon-truck-on_svg__A)"
        fillRule="nonzero"
        d="M154.502 40.291c-1.316-1.62-8.06-10.327-11.996-12.853-2.758-1.773-9.826-2.289-13.813-2.436-1.529-.057-2.775 1.184-2.689 2.685.185 3.286.514 8.764.808 12.106.121 1.365 1.229 2.441 2.625 2.543 2.493.176 6.278.493 7.859.867 2.458.584 6.941 2.459 6.941 2.459s10.847.492 11.522.289c.71-.221 1.247-.76 1.241-1.626 0-.708-1.183-2.413-2.498-4.034zm-11.275 3.937s-3.946-1.654-6.11-2.164c-1.535-.362-5.424-.662-7.553-.804a1.728 1.728 0 0 1-1.615-1.546c-.3-3.1-.652-9.212-.814-12.112-.046-.878.675-1.603 1.57-1.58 3.248.09 10.362.475 12.999 2.17 2.204 1.415 5.406 5.03 7.743 7.84l-.208 8.429c-2.815-.09-6.012-.233-6.012-.233z"
      />
      <path
        fill="url(#icon-truck-on_svg__B)"
        fillRule="nonzero"
        d="M4 59.724L119.411 62 120 1.493a.52.52 0 0 0-.516-.524L5.704 0 4 59.724z"
      />
      <ellipse
        cx={157.5}
        cy={75}
        fill="url(#icon-truck-on_svg__C)"
        fillRule="nonzero"
        rx={10.5}
        ry={10}
      />
      <ellipse
        cx={158}
        cy={74.5}
        fill="url(#icon-truck-on_svg__D)"
        fillRule="nonzero"
        rx={6}
        ry={6.5}
      />
      <ellipse
        cx={54.5}
        cy={75}
        fill="url(#icon-truck-on_svg__E)"
        fillRule="nonzero"
        rx={10.5}
        ry={10}
      />
      <ellipse
        cx={54}
        cy={74.5}
        fill="url(#icon-truck-on_svg__F)"
        fillRule="nonzero"
        rx={6}
        ry={6.5}
      />
      <path
        fill="url(#icon-truck-on_svg__G)"
        fillRule="nonzero"
        d="M40 60l1.199 1.956 8.944.302s1.513-1.154 4.836-1.132c3.323.023 5.167 1.368 5.167 1.368h2.572c1.1 0 2.084.638 2.497 1.619L68.962 73l1.682-.353L72 71.263s-1.641-6.595-3.812-8.372c-2.17-1.776-3.322-2.42-3.322-2.42L40 60z"
      />
      <path
        fill="url(#icon-truck-on_svg__H)"
        fillRule="nonzero"
        d="M46 63.13s-2.942 3.353-4.644 9.87H40s.23-5.857 2.322-10l3.678.13z"
      />
      <path fill="url(#icon-truck-on_svg__I)" fillRule="nonzero" d="M92 62.227L80 62v10h12z" />
      <path
        fill="url(#icon-truck-on_svg__J)"
        fillRule="nonzero"
        d="M113 66s.459 5.545.287 9l1.713-.157s.019-7.015-.433-8.742L113 66z"
      />
      <path
        fill="url(#icon-truck-on_svg__K)"
        fillRule="nonzero"
        d="M99 66s-.44 4.907-.142 9l-1.664-.167s-.527-4.934.156-8.783L99 66z"
      />
      <path
        fill="url(#icon-truck-on_svg__L)"
        fillRule="nonzero"
        d="M3.713 0H2.482l1.176 1.641-.165 5.31h-.687v-.437h-.643l.012 3.606h.668l.006-.396h.558L2.83 27.869h-.772v-.436h-.644v3.606h.668v-.397h.662l-.582 18.36H1.31v-.43H.668v3.651h.668v-.441h.766L1.9 58.206H0L1.844 60h1.287L5 0z"
      />
      <path
        fill="url(#icon-truck-on_svg__M)"
        fillRule="nonzero"
        d="M71 72l11-.873h-2.175V70l-8.786.68.301 1.002z"
      />
      <path
        stroke="url(#icon-truck-on_svg__N)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M80 64.31l12 .38"
        opacity={0.5}
      />
      <path
        fill="url(#icon-truck-on_svg__O)"
        fillRule="nonzero"
        d="M143.868 44.773s-3.891-1.614-6.025-2.112c-1.513-.354-5.347-.647-7.446-.785-.842-.056-1.513-.697-1.593-1.51-.296-3.025-.643-8.991-.802-11.823-.045-.857.666-1.565 1.547-1.542 3.203.088 10.217.464 12.817 2.117 2.173 1.383 5.33 4.91 7.634 7.654L149.795 45a561.621 561.621 0 0 1-5.927-.227z"
      />
      <path
        fill="url(#icon-truck-on_svg__P)"
        fillRule="nonzero"
        d="M150.011 44.998l-3.925-.236c-.47-.027-.84-.354-.853-.756L145 37.81c-.02-.445.395-.81.915-.81 1.28 0 3.462.059 3.9.51.597.6 1.242 2.67 1.18 6.716-.013.45-.458.804-.985.772z"
      />
      <path
        fill="url(#icon-truck-on_svg__Q)"
        fillRule="nonzero"
        d="M173.7 60.802l2.698.198.403-.338a.537.537 0 0 0 .199-.413l-.006-2.654a.514.514 0 0 0-.298-.45l-.298-.145H171l2.7 3.802z"
      />
      <path d="M0-9h178v94H0z" />
    </g>
  </svg>
);

export default SvgIconTruckOn;
