import React from 'react';

const SvgIconWhatsapp = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M12.002 2h-.005C6.485 2 2 6.485 2 12c0 2.188.705 4.215 1.904 5.861l-1.246 3.715L6.5 20.347A9.913 9.913 0 0 0 12.002 22C17.517 22 22 17.514 22 12S17.516 2 12.002 2zm5.82 14.121c-.242.681-1.2 1.247-1.963 1.412-.523.11-1.205.2-3.503-.753-2.938-1.218-4.831-4.204-4.978-4.398-.142-.193-1.188-1.58-1.188-3.016 0-1.435.729-2.133 1.022-2.433.242-.247.64-.36 1.023-.36.124 0 .235.007.335.012.294.013.441.03.635.494.241.581.829 2.016.899 2.163.071.148.142.348.042.542-.094.2-.176.288-.324.458-.147.17-.287.3-.434.483-.136.159-.288.329-.118.623.17.287.758 1.246 1.623 2.016 1.116.993 2.02 1.311 2.345 1.446.24.1.528.076.705-.111.223-.242.5-.642.78-1.035.2-.283.453-.318.718-.218.27.094 1.699.8 1.993.947.293.147.487.217.558.34.07.124.07.706-.17 1.388z"
    />
  </svg>
);

export default SvgIconWhatsapp;
