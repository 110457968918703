import React from 'react';

const SvgIconSales = props => (
  <svg width={114} height={111} viewBox="0 0 114 111" {...props}>
    <defs>
      <path id="icon-sales_svg__a" d="M0 .047h113.188v13.05H0z" />
      <path id="icon-sales_svg__c" d="M.072.04h6.781v3.378H.073z" />
      <linearGradient id="icon-sales_svg__e" x1="35.634%" x2="67.403%" y1="10.48%" y2="85.131%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__f" x1="38.267%" x2="55.579%" y1="9.241%" y2="94.889%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__g" x1="46.585%" x2="54.138%" y1="10.48%" y2="85.131%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__h" x1="46.515%" x2="52.34%" y1="-7.028%" y2="158.269%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__i" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__j" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__k" x1="53.395%" x2="48.324%" y1="99.442%" y2="52.716%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#E35563" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__l" x1="50%" x2="72.528%" y1="45.65%" y2="144.372%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="50.018%" stopColor="#FF8C9F" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__m" x1="26.626%" x2="78.316%" y1="20.845%" y2="75.917%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__n" x1="44.059%" x2="53.989%" y1="-7.028%" y2="158.269%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__o" x1="19.324%" x2="64.585%" y1="24.179%" y2="78.438%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__p" x1="29.5%" x2="63.762%" y1="-2.337%" y2="149.363%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__q" x1="33.321%" x2="70.205%" y1="10.48%" y2="85.131%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__r" x1="31.005%" x2="73.011%" y1="10.48%" y2="85.131%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__s" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__t" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__u" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__v" x1="31.303%" x2="58.89%" y1="9.241%" y2="94.889%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__w" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__x" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__y" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__z" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__A" x1="32.617%" x2="71.058%" y1="10.48%" y2="85.131%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__B" x1="40.844%" x2="54.353%" y1="9.241%" y2="94.889%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__C" x1="19.324%" x2="64.585%" y1="33.634%" y2="68.024%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__D" x1="19.458%" x2="64.522%" y1="9.241%" y2="94.889%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__E" x1="26.626%" x2="78.316%" y1="40.946%" y2="58.048%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__F" x1="29.5%" x2="63.762%" y1="38.793%" y2="71.276%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-sales_svg__G" x1="29.5%" x2="63.762%" y1="34.346%" y2="79.72%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(.67 78.936)">
        <mask id="icon-sales_svg__b" fill="#fff">
          <use xlinkHref="#icon-sales_svg__a" />
        </mask>
        <path
          fill="#E9F6FF"
          d="M87.618 1.808c-7.939-.06-32.54-.337-47.472-1.26C12.46-1.164-10.146 1.798 4.711 5.102c4.152.923-7.811 6.528 21.494 7.733 25.726 1.058 51.945-1.36 64.635-2.401 12.69-1.042 18.544-2.858 21.262-3.677 2.39-.72 3.268-4.74-24.484-4.949"
          mask="url(#icon-sales_svg__b)"
        />
      </g>
      <path fill="#FFC910" d="M17.769 82.371l-.083-1.98a809.636 809.636 0 0 0 .083 1.98" />
      <path
        fill="#FFAB01"
        d="M34.132 79.557l.083 2.193c.022.559-.711 1.149-2.227 1.656-3.274 1.097-8.96 1.396-12.701.67-2.009-.391-3.048-.998-3.072-1.647l-.083-2.193c.024.648 1.063 1.255 3.072 1.646 3.74.727 9.427.427 12.7-.67 1.517-.507 2.25-1.097 2.228-1.655"
      />
      <path
        fill="#FFC910"
        d="M20.221 81.541c3.099.602 7.809.354 10.52-.554 2.713-.909 2.4-2.133-.699-2.736-3.098-.602-7.808-.354-10.52.555-2.711.908-2.398 2.133.7 2.735m10.838-3.63c3.74.726 4.119 2.205.845 3.302-3.274 1.096-8.96 1.396-12.701.669-3.74-.727-4.119-2.206-.845-3.302 3.274-1.097 8.96-1.397 12.7-.67"
      />
      <path
        fill="#FFAB01"
        d="M30.042 78.251c3.098.603 3.412 1.827.7 2.736-2.712.908-7.422 1.156-10.52.554-3.098-.602-3.411-1.827-.7-2.735 2.712-.909 7.422-1.157 10.52-.555"
      />
      <path
        fill="#F99B0F"
        d="M19.522 79.079c2.712-.909 7.422-1.157 10.52-.555 1.542.3 2.394.754 2.526 1.246.155-.594-.702-1.164-2.526-1.519-3.098-.602-7.808-.354-10.52.555-1.362.456-1.96.992-1.826 1.49.108-.416.71-.843 1.826-1.217m-2.53 2.112v2.15c.514.285 1.28.537 2.295.734 1.003.195 2.147.316 3.345.368v-2.19c-1.229-.05-2.402-.172-3.428-.371-.962-.187-1.7-.424-2.212-.69"
      />
      <path
        fill="#F99B0F"
        d="M28.772 78.935l-.822.275c.328.09.483.2.463.327-.018.128-.21.26-.574.396l-.845-.165c.203-.077.292-.153.266-.226-.026-.074-.164-.135-.413-.184a3.472 3.472 0 0 0-.857-.052 3.128 3.128 0 0 0-.813.146.884.884 0 0 0-.198.088c-.044.03-.066.06-.066.091-.003.064.15.145.46.244.186.06.318.107.401.144.288.125.414.251.376.38-.037.126-.233.25-.588.368-.448.15-.962.253-1.542.306a6.985 6.985 0 0 1-1.58-.017l-.985.33-.789-.153.949-.317c-.416-.111-.616-.243-.599-.396.016-.152.25-.304.703-.457l.814.158c-.288.106-.42.202-.397.288.023.086.203.162.541.227.309.06.636.08.984.06a3.55 3.55 0 0 0 .934-.166c.098-.033.177-.065.237-.097a.328.328 0 0 0 .118-.09c.06-.092-.081-.196-.422-.31-.057-.018-.098-.033-.125-.042-.417-.145-.635-.281-.655-.409-.02-.128.157-.254.532-.38.41-.137.87-.227 1.385-.271a7.245 7.245 0 0 1 1.506.023l.813-.272.788.153z"
      />
      <path
        fill="#FFC910"
        d="M28.772 78.627l-.822.275c.328.09.483.2.463.327-.018.128-.21.26-.574.396l-.845-.165c.203-.077.292-.153.266-.226-.026-.074-.164-.135-.413-.184a3.472 3.472 0 0 0-.857-.052 3.124 3.124 0 0 0-.813.146.88.88 0 0 0-.198.088c-.044.03-.066.06-.066.091-.003.064.15.145.46.244.186.06.318.107.401.144.288.125.414.252.376.379-.037.127-.233.25-.588.37a6.84 6.84 0 0 1-1.542.305 6.99 6.99 0 0 1-1.58-.017l-.985.33-.789-.153.949-.318c-.416-.11-.616-.242-.599-.395.016-.152.25-.304.703-.458l.814.159c-.288.106-.42.202-.397.288.023.086.203.162.541.227a4 4 0 0 0 .984.061c.348-.02.659-.075.934-.167.098-.033.177-.065.237-.097a.326.326 0 0 0 .118-.09c.06-.092-.081-.196-.422-.31-.057-.018-.098-.033-.125-.042-.417-.145-.635-.281-.655-.409-.02-.127.157-.254.532-.38.41-.137.87-.227 1.385-.271a7.259 7.259 0 0 1 1.506.023l.813-.272.788.153zm-11.07.844l-.083-1.981a807.471 807.471 0 0 0 .083 1.981"
      />
      <path
        fill="#FFAB01"
        d="M34.065 76.656l.083 2.194c.022.559-.711 1.148-2.227 1.656-3.274 1.097-8.96 1.396-12.701.67-2.009-.391-3.048-.999-3.072-1.647l-.083-2.193c.024.648 1.063 1.255 3.072 1.646 3.74.727 9.427.427 12.7-.67 1.517-.507 2.25-1.097 2.228-1.656"
      />
      <path
        fill="#FFC910"
        d="M20.154 78.64c3.099.603 7.809.355 10.52-.554 2.713-.908 2.4-2.133-.699-2.735-3.098-.602-7.808-.354-10.52.554-2.711.909-2.398 2.133.7 2.736m10.838-3.63c3.74.726 4.119 2.205.845 3.301-3.274 1.097-8.96 1.397-12.701.67-3.74-.728-4.119-2.206-.845-3.303 3.274-1.096 8.96-1.396 12.7-.669"
      />
      <path
        fill="#FFAB01"
        d="M29.975 75.35c3.098.603 3.412 1.828.7 2.736-2.712.909-7.422 1.157-10.52.555-3.098-.603-3.411-1.827-.7-2.736 2.712-.908 7.422-1.156 10.52-.554"
      />
      <path
        fill="#F99B0F"
        d="M19.455 76.178c2.712-.908 7.422-1.156 10.52-.554 1.542.3 2.394.754 2.526 1.246.155-.594-.702-1.165-2.526-1.519-3.098-.602-7.808-.354-10.52.554-1.362.457-1.96.993-1.826 1.49.108-.416.71-.843 1.826-1.217m-2.53 2.113v2.15c.514.284 1.28.537 2.295.734 1.003.195 2.147.316 3.345.367v-2.19c-1.229-.05-2.402-.17-3.428-.37-.961-.187-1.7-.424-2.212-.69"
      />
      <path
        fill="#F99B0F"
        d="M28.705 76.035l-.822.275c.328.09.483.2.463.327-.018.128-.21.26-.574.395l-.845-.164c.203-.077.292-.153.266-.226-.026-.075-.164-.135-.413-.184a3.468 3.468 0 0 0-.857-.052 3.125 3.125 0 0 0-.813.145.888.888 0 0 0-.198.089c-.044.03-.066.06-.066.09-.003.064.15.146.46.245.186.06.319.107.401.143.288.126.414.252.376.38-.037.127-.233.25-.587.369a6.84 6.84 0 0 1-1.543.306 6.986 6.986 0 0 1-1.58-.017l-.985.33-.789-.153.949-.318c-.416-.11-.616-.242-.599-.395.016-.152.25-.305.703-.458l.814.158c-.288.106-.42.202-.397.288.023.087.203.163.541.228.309.06.636.08.984.06a3.55 3.55 0 0 0 .934-.166c.098-.033.177-.065.237-.097a.327.327 0 0 0 .118-.09c.06-.092-.081-.196-.422-.31l-.125-.042c-.417-.145-.635-.281-.655-.409-.02-.128.157-.255.532-.38.41-.137.87-.228 1.385-.271a7.259 7.259 0 0 1 1.506.023l.813-.272.788.153z"
      />
      <path
        fill="#FFC910"
        d="M28.705 75.727l-.822.275c.328.09.483.2.463.327-.018.128-.21.26-.574.395l-.845-.164c.203-.077.292-.153.266-.226-.026-.075-.164-.136-.413-.184a3.467 3.467 0 0 0-.857-.052 3.128 3.128 0 0 0-.813.145.884.884 0 0 0-.198.089c-.044.03-.066.06-.066.09-.003.064.15.146.46.245.186.06.319.107.401.143.288.126.414.252.376.38-.037.127-.233.25-.587.369a6.84 6.84 0 0 1-1.543.306 7.002 7.002 0 0 1-1.58-.017l-.985.33-.789-.153.949-.318c-.416-.11-.616-.242-.599-.395.016-.152.25-.305.703-.458l.814.158c-.288.106-.42.202-.397.288.023.086.203.163.541.228.309.06.636.08.984.06a3.47 3.47 0 0 0 .934-.167c.098-.032.177-.064.237-.096a.326.326 0 0 0 .118-.09c.06-.092-.081-.196-.422-.31l-.125-.042c-.417-.145-.635-.282-.655-.409-.02-.128.157-.254.532-.38.41-.137.87-.228 1.385-.271a7.259 7.259 0 0 1 1.506.023l.813-.273.788.154zm-10.685.668l-.083-1.981a812.121 812.121 0 0 0 .083 1.98"
      />
      <path
        fill="#FFAB01"
        d="M34.383 73.58l.083 2.193c.021.56-.711 1.149-2.227 1.657-3.274 1.096-8.96 1.396-12.701.669-2.009-.39-3.048-.998-3.072-1.646l-.084-2.194c.025.649 1.064 1.256 3.073 1.646 3.74.727 9.427.428 12.7-.669 1.517-.508 2.25-1.097 2.228-1.656"
      />
      <path
        fill="#FFC910"
        d="M20.472 75.564c3.099.603 7.809.354 10.52-.554 2.713-.909 2.4-2.133-.699-2.735-3.098-.603-7.808-.354-10.52.554-2.711.908-2.398 2.133.7 2.735m10.838-3.63c3.74.727 4.119 2.205.845 3.302s-8.96 1.396-12.701.67c-3.74-.728-4.119-2.206-.845-3.303s8.96-1.396 12.7-.67"
      />
      <path
        fill="#FFAB01"
        d="M30.293 72.275c3.098.602 3.412 1.826.7 2.735-2.712.908-7.422 1.157-10.52.554-3.098-.602-3.412-1.827-.7-2.735 2.712-.908 7.422-1.157 10.52-.554"
      />
      <path
        fill="#F99B0F"
        d="M19.773 73.102c2.712-.908 7.422-1.156 10.52-.554 1.542.3 2.393.753 2.526 1.246.155-.595-.703-1.165-2.526-1.52-3.098-.602-7.808-.353-10.52.555-1.362.456-1.96.993-1.826 1.49.108-.416.71-.843 1.826-1.217m-2.53 2.113v2.149c.514.285 1.28.538 2.295.735 1.003.195 2.147.315 3.345.367v-2.19c-1.229-.05-2.402-.171-3.428-.37-.962-.188-1.7-.424-2.212-.691"
      />
      <path
        fill="#F99B0F"
        d="M29.022 72.958l-.82.275c.327.091.481.2.462.328-.019.127-.21.259-.574.395l-.845-.164c.203-.078.292-.153.266-.227-.026-.074-.164-.135-.413-.183a3.463 3.463 0 0 0-.857-.053 3.12 3.12 0 0 0-.814.146.887.887 0 0 0-.197.088c-.044.03-.066.06-.066.091-.003.064.15.145.46.245.185.059.318.107.401.143.288.126.414.252.376.38-.037.127-.233.25-.588.369-.448.15-.962.252-1.542.305a6.99 6.99 0 0 1-1.58-.017l-.986.33-.788-.153.948-.317c-.415-.11-.615-.243-.598-.395.016-.153.25-.305.703-.458l.814.158c-.288.106-.42.202-.397.288.023.086.203.162.54.228.31.06.637.08.985.06a3.51 3.51 0 0 0 .934-.167c.098-.032.177-.064.237-.096a.329.329 0 0 0 .118-.09c.06-.093-.081-.196-.422-.31l-.125-.043c-.417-.145-.635-.28-.655-.408-.02-.128.157-.255.532-.38.409-.137.87-.228 1.385-.272a7.253 7.253 0 0 1 1.506.023l.813-.272.787.153z"
      />
      <path
        fill="#FFC910"
        d="M29.022 72.65l-.82.275c.327.091.481.2.462.328-.019.127-.21.259-.574.395l-.845-.164c.203-.078.292-.153.266-.227-.026-.074-.164-.135-.413-.184a3.468 3.468 0 0 0-.857-.052 3.124 3.124 0 0 0-.814.146.89.89 0 0 0-.197.088c-.044.03-.066.06-.066.091-.003.064.15.145.46.245.185.059.318.107.401.143.288.126.414.252.376.38-.037.127-.233.25-.588.368-.448.15-.962.253-1.542.306a6.99 6.99 0 0 1-1.58-.017l-.986.33-.788-.153.948-.317c-.415-.111-.615-.243-.598-.395.016-.153.25-.305.703-.458l.814.158c-.288.106-.42.202-.397.288.023.086.203.162.54.228.31.06.637.08.985.06a3.51 3.51 0 0 0 .934-.167c.098-.032.177-.064.237-.096a.326.326 0 0 0 .118-.09c.06-.093-.081-.196-.422-.31l-.125-.043c-.417-.145-.635-.281-.655-.408-.02-.128.157-.255.532-.38.409-.137.87-.228 1.385-.272a7.26 7.26 0 0 1 1.506.023l.813-.272.787.153zM17.91 73.813l-.083-1.981a865.345 865.345 0 0 0 .083 1.981"
      />
      <path
        fill="#FFAB01"
        d="M34.273 70.998l.083 2.194c.022.559-.711 1.148-2.227 1.656-3.274 1.097-8.96 1.396-12.701.67-2.009-.391-3.048-.999-3.072-1.647l-.083-2.193c.024.648 1.063 1.255 3.072 1.646 3.74.727 9.427.427 12.7-.67 1.517-.507 2.25-1.097 2.228-1.656"
      />
      <path
        fill="#FFC910"
        d="M20.363 72.983c3.098.602 7.808.354 10.52-.555 2.712-.908 2.398-2.133-.7-2.735-3.098-.602-7.808-.354-10.52.555-2.711.908-2.398 2.132.7 2.735m10.838-3.63c3.74.726 4.119 2.205.845 3.302-3.274 1.096-8.96 1.396-12.701.669-3.74-.728-4.119-2.206-.845-3.303 3.274-1.096 8.96-1.396 12.7-.669"
      />
      <path
        fill="#FFAB01"
        d="M30.183 69.693c3.098.603 3.412 1.827.7 2.735-2.712.909-7.422 1.157-10.52.555-3.098-.603-3.411-1.827-.7-2.735 2.712-.909 7.422-1.157 10.52-.555"
      />
      <path
        fill="#F99B0F"
        d="M19.663 70.52c2.712-.908 7.422-1.156 10.52-.554 1.542.3 2.393.754 2.526 1.246.155-.594-.702-1.164-2.526-1.519-3.098-.602-7.808-.354-10.52.555-1.362.456-1.96.992-1.826 1.489.108-.415.71-.843 1.826-1.217m-2.53 2.113v2.15c.514.284 1.28.537 2.295.734 1.003.195 2.147.316 3.346.367v-2.19c-1.23-.05-2.403-.17-3.43-.37-.96-.187-1.699-.424-2.211-.69"
      />
      <path
        fill="#F99B0F"
        d="M28.913 70.377l-.822.275c.328.09.483.2.463.327-.018.128-.21.26-.574.396l-.845-.165c.203-.077.292-.153.266-.226-.026-.075-.164-.135-.413-.184a3.458 3.458 0 0 0-.857-.052 3.12 3.12 0 0 0-.813.145.888.888 0 0 0-.198.089c-.044.03-.066.06-.066.09-.003.064.15.146.46.245.186.06.318.107.401.143.288.126.414.252.376.38-.037.127-.233.25-.588.369a6.84 6.84 0 0 1-1.542.306 6.99 6.99 0 0 1-1.58-.017l-.986.33-.788-.153.948-.318c-.415-.11-.615-.242-.598-.395.016-.152.25-.305.703-.458l.814.159c-.288.105-.42.201-.397.288.023.086.203.162.541.227.309.06.636.08.984.06a3.55 3.55 0 0 0 .934-.166c.098-.033.177-.065.237-.097a.328.328 0 0 0 .118-.09c.06-.092-.081-.196-.422-.31l-.125-.042c-.417-.145-.635-.281-.654-.409-.021-.128.156-.254.531-.38.41-.137.87-.227 1.385-.271a7.246 7.246 0 0 1 1.506.023l.813-.272.788.153z"
      />
      <path
        fill="#FFC910"
        d="M28.913 70.069l-.822.275c.328.09.483.2.463.327-.018.128-.21.26-.574.396l-.845-.165c.203-.077.292-.153.266-.226-.026-.075-.164-.136-.413-.184a3.458 3.458 0 0 0-.857-.052 3.116 3.116 0 0 0-.813.145.884.884 0 0 0-.198.089c-.044.03-.066.06-.066.09-.003.064.15.146.46.245.186.06.318.107.401.143.288.126.414.252.376.38-.037.127-.233.25-.588.369a6.84 6.84 0 0 1-1.542.306 6.99 6.99 0 0 1-1.58-.017l-.986.33-.788-.153.948-.318c-.415-.11-.615-.242-.598-.395.016-.152.25-.305.703-.458l.814.158c-.288.106-.42.202-.397.289.023.085.203.162.541.227.309.06.636.08.984.06.348-.018.659-.074.934-.166.098-.033.177-.065.237-.097a.326.326 0 0 0 .118-.09c.06-.092-.081-.196-.422-.31l-.125-.042c-.417-.145-.635-.282-.654-.409-.021-.128.156-.254.531-.38.41-.137.87-.227 1.385-.271a7.26 7.26 0 0 1 1.506.023l.813-.272.788.153zm-12.191.856l-.082-1.981a756.97 756.97 0 0 0 .082 1.98"
      />
      <path
        fill="#FFAB01"
        d="M33.086 68.11l.083 2.193c.021.56-.712 1.149-2.227 1.656-3.274 1.097-8.96 1.397-12.701.67-2.009-.39-3.048-.998-3.073-1.646l-.083-2.194c.025.649 1.064 1.256 3.073 1.646 3.74.727 9.427.428 12.7-.669 1.516-.508 2.25-1.097 2.228-1.656"
      />
      <path
        fill="#FFC910"
        d="M19.175 70.094c3.099.603 7.809.354 10.52-.554 2.712-.909 2.4-2.133-.7-2.735-3.097-.603-7.807-.354-10.519.554-2.712.909-2.399 2.133.7 2.735m10.837-3.63c3.741.727 4.12 2.205.846 3.302s-8.96 1.396-12.701.67c-3.74-.728-4.119-2.206-.845-3.303s8.96-1.396 12.7-.67"
      />
      <path
        fill="#FFAB01"
        d="M28.996 66.805c3.098.602 3.411 1.826.7 2.735-2.712.908-7.423 1.157-10.52.554-3.099-.602-3.412-1.826-.7-2.735 2.712-.908 7.422-1.157 10.52-.554"
      />
      <path
        fill="#F99B0F"
        d="M18.476 67.632c2.712-.908 7.422-1.156 10.52-.554 1.542.3 2.393.753 2.526 1.246.155-.595-.703-1.165-2.526-1.52-3.098-.602-7.808-.353-10.52.555-1.362.457-1.96.993-1.827 1.49.109-.416.711-.843 1.827-1.217m-2.531 2.113v2.15c.514.284 1.281.537 2.296.734 1.003.195 2.146.315 3.345.367v-2.19c-1.229-.05-2.402-.171-3.428-.37-.962-.188-1.7-.424-2.213-.691"
      />
      <path
        fill="#F99B0F"
        d="M27.725 67.488l-.82.275c.327.091.481.2.461.328-.018.127-.21.26-.573.395l-.846-.164c.204-.078.292-.153.266-.227-.025-.074-.163-.135-.412-.183a3.463 3.463 0 0 0-.857-.053 3.12 3.12 0 0 0-.814.146.887.887 0 0 0-.197.088c-.044.03-.066.06-.066.091-.004.064.149.145.46.245.185.059.318.107.4.143.289.126.415.252.377.38-.037.127-.233.25-.588.369a6.85 6.85 0 0 1-1.542.305 6.99 6.99 0 0 1-1.58-.017l-.986.33-.788-.153.948-.317c-.416-.11-.616-.243-.598-.395.016-.153.25-.305.703-.458l.814.158c-.288.106-.42.202-.397.288.023.086.203.162.54.228.31.06.636.08.985.06.348-.019.658-.074.934-.167.098-.032.177-.064.237-.096a.329.329 0 0 0 .118-.09c.059-.093-.082-.196-.423-.31a11.492 11.492 0 0 1-.124-.043c-.417-.145-.635-.28-.655-.408-.02-.128.157-.255.532-.38.409-.137.87-.228 1.385-.272a7.246 7.246 0 0 1 1.506.024l.812-.273.788.153z"
      />
      <path
        fill="#FFC910"
        d="M27.725 67.18l-.82.275c.327.091.481.2.461.328-.018.127-.21.259-.573.395l-.846-.164c.204-.078.292-.153.266-.227-.025-.074-.163-.135-.412-.183a3.467 3.467 0 0 0-.857-.053 3.127 3.127 0 0 0-.814.146.883.883 0 0 0-.197.088c-.044.03-.066.06-.066.091-.004.064.149.145.46.245.185.059.318.107.4.143.289.126.415.252.377.38-.037.127-.233.25-.588.368a6.84 6.84 0 0 1-1.542.306 6.984 6.984 0 0 1-1.58-.017l-.986.33-.788-.152.948-.318c-.416-.11-.616-.243-.598-.395.016-.152.25-.305.703-.458l.814.158c-.288.106-.42.202-.397.288.023.086.203.162.54.228.31.06.636.08.985.06a3.55 3.55 0 0 0 .934-.167c.098-.032.177-.064.237-.096a.326.326 0 0 0 .118-.09c.059-.093-.082-.196-.423-.31l-.124-.043c-.417-.145-.635-.281-.655-.408-.02-.128.157-.255.532-.38.409-.137.87-.228 1.385-.272a7.253 7.253 0 0 1 1.506.023l.812-.272.788.153zm-9.565.656l-.082-1.98a805.43 805.43 0 0 0 .083 1.98"
      />
      <path
        fill="#FFAB01"
        d="M34.524 65.022l.083 2.193c.021.559-.711 1.149-2.227 1.656-3.274 1.097-8.96 1.397-12.701.67-2.009-.391-3.048-.998-3.072-1.646l-.084-2.194c.025.648 1.064 1.256 3.073 1.646 3.74.727 9.427.428 12.7-.67 1.517-.507 2.25-1.096 2.228-1.655"
      />
      <path
        fill="#FFC910"
        d="M20.613 67.006c3.099.603 7.809.354 10.52-.554 2.713-.909 2.4-2.133-.699-2.736-3.098-.602-7.808-.353-10.52.555-2.712.908-2.398 2.133.7 2.735m10.838-3.63c3.74.727 4.119 2.205.845 3.302s-8.96 1.396-12.701.669c-3.74-.727-4.119-2.205-.845-3.302s8.96-1.397 12.7-.67"
      />
      <path
        fill="#FFAB01"
        d="M30.434 63.716c3.098.603 3.412 1.827.7 2.736-2.712.908-7.422 1.157-10.52.554-3.098-.602-3.412-1.827-.7-2.735 2.712-.909 7.422-1.157 10.52-.555"
      />
      <path
        fill="#F99B0F"
        d="M19.914 64.544c2.712-.909 7.422-1.157 10.52-.555 1.542.3 2.393.754 2.526 1.246.155-.594-.703-1.164-2.526-1.519-3.098-.602-7.808-.354-10.52.555-1.362.456-1.96.992-1.826 1.49.108-.416.71-.843 1.826-1.217m-2.53 2.113v2.149c.514.285 1.28.537 2.295.734 1.003.195 2.147.316 3.345.368v-2.19c-1.229-.05-2.402-.171-3.428-.37-.962-.188-1.7-.424-2.212-.691"
      />
      <path
        fill="#F99B0F"
        d="M29.163 64.4l-.82.275c.327.09.481.2.462.327-.018.128-.21.26-.574.396l-.845-.164c.203-.078.292-.153.266-.227-.026-.074-.164-.135-.413-.184a3.463 3.463 0 0 0-.857-.052 3.121 3.121 0 0 0-.814.146.882.882 0 0 0-.197.088c-.044.03-.066.06-.066.091-.003.064.15.145.46.244.185.06.318.107.4.144.289.125.415.252.377.38-.037.127-.233.25-.588.368-.448.15-.962.253-1.542.306a6.985 6.985 0 0 1-1.58-.017l-.986.33-.788-.153.948-.317c-.415-.111-.615-.243-.598-.395.016-.153.25-.305.703-.458l.814.158c-.288.106-.42.202-.397.288.023.086.203.162.54.228.31.06.637.08.985.06.348-.02.659-.075.934-.167.098-.033.177-.065.237-.096a.33.33 0 0 0 .118-.09c.06-.093-.081-.197-.422-.31l-.125-.043c-.417-.145-.635-.281-.655-.409-.02-.127.157-.254.532-.38.409-.137.87-.227 1.385-.271a7.246 7.246 0 0 1 1.506.023l.813-.272.787.153z"
      />
      <path
        fill="#FFC910"
        d="M29.163 64.092l-.82.275c.327.09.481.2.462.328-.018.127-.21.259-.574.395l-.845-.164c.203-.078.292-.153.266-.227-.026-.074-.164-.135-.413-.184a3.458 3.458 0 0 0-.857-.052 3.121 3.121 0 0 0-.814.146.887.887 0 0 0-.197.088c-.044.03-.066.06-.066.091-.003.064.15.145.46.245.185.059.318.106.4.143.289.125.415.252.377.38-.037.127-.233.25-.588.368a6.84 6.84 0 0 1-1.542.306 6.99 6.99 0 0 1-1.58-.017l-.986.33-.788-.153.948-.317c-.415-.111-.615-.243-.598-.395.016-.153.25-.305.703-.458l.814.158c-.288.106-.42.202-.397.288.023.086.203.162.54.228.31.06.637.08.985.06.348-.02.659-.075.934-.167.098-.033.177-.065.237-.096a.326.326 0 0 0 .118-.09c.06-.093-.081-.197-.422-.31l-.125-.043c-.417-.145-.635-.281-.655-.409-.02-.127.157-.254.532-.38.409-.137.87-.227 1.385-.271a7.26 7.26 0 0 1 1.506.023l.813-.272.787.153zm43.095-34.025a6.953 6.953 0 0 0 5.627 3.068l-.702.772a6.953 6.953 0 0 1-5.624-3.063l.007-.024-.007.024a6.846 6.846 0 0 1-1.188-3.821 6.591 6.591 0 0 1 1.714-4.49l.703-.772a6.59 6.59 0 0 0-1.715 4.49 6.847 6.847 0 0 0 1.185 3.816 1.658 1.658 0 0 0-.225.209c-.161.177-.305.369-.464.548.159-.18.303-.371.464-.548.103-.113.176-.17.225-.209m5.627 3.068c2.004.035 3.8-.8 5.036-2.157l-.703.771a6.616 6.616 0 0 1-5.035 2.158l.702-.772"
      />
      <path
        fill="#FFAB01"
        d="M77.12 20.375c3.746.065 6.795 3.147 6.813 6.884a6.596 6.596 0 0 1-6.75 6.648c-3.745-.066-6.795-3.148-6.812-6.885a6.596 6.596 0 0 1 6.75-6.647"
      />
      <path
        fill="#F99B0F"
        d="M74.74 22.81l-.07-.002v.007l.07.001zm.032 7.24l-.07-.002v.007l.07.002z"
      />
      <path
        fill="#F99B0F"
        d="M70.747 27.022a6.592 6.592 0 0 1 6.432-6.644c-.02 0-.039-.003-.058-.003a6.596 6.596 0 0 0-6.75 6.647c.017 3.737 3.067 6.82 6.812 6.885.107.001.212 0 .318-.003-3.719-.096-6.737-3.164-6.754-6.882"
      />
      <path
        fill="#FFAB01"
        d="M72.085 22.533l.703-.772a6.617 6.617 0 0 1 5.035-2.158l-.702.772c-2.004-.035-3.8.8-5.036 2.158"
      />
      <g transform="translate(70.963 18.16)">
        <mask id="icon-sales_svg__d" fill="#fff">
          <use xlinkHref="#icon-sales_svg__c" />
        </mask>
        <path
          fill="#EE8F01"
          d="M.072 3.418l.703-.772A7.987 7.987 0 0 1 6.853.04l-.702.772A7.987 7.987 0 0 0 .072 3.418"
          mask="url(#icon-sales_svg__d)"
        />
      </g>
      <path
        fill="#F99B0F"
        d="M83.971 31.932l-.702.771a7.956 7.956 0 0 0 2.07-5.42c-.021-4.51-3.703-8.23-8.225-8.31l.703-.772c4.521.08 8.203 3.8 8.224 8.311a7.955 7.955 0 0 1-2.07 5.42"
      />
      <path
        fill="#FFC910"
        d="M77.183 33.907a6.596 6.596 0 0 0 6.75-6.648c-.018-3.736-3.067-6.818-6.812-6.884a6.596 6.596 0 0 0-6.75 6.648c.017 3.736 3.067 6.818 6.812 6.884m-.069-14.934c4.522.08 8.204 3.8 8.225 8.31a7.964 7.964 0 0 1-8.15 8.026c-4.521-.08-8.202-3.8-8.223-8.311a7.963 7.963 0 0 1 8.148-8.025"
      />
      <path
        fill="#F99B0F"
        d="M77.688 22.052l.005 1.13c.46.08.827.293 1.105.64.278.345.435.788.469 1.326l-1.022-.018c-.023-.304-.129-.547-.317-.73-.189-.182-.435-.276-.736-.281-.322-.006-.58.09-.772.284-.193.194-.288.457-.286.786 0 .12.017.23.052.33.033.1.086.19.157.273.143.169.448.308.912.42.277.065.49.125.634.18.509.19.894.462 1.158.818.265.354.398.776.4 1.264.003.62-.156 1.142-.474 1.569-.32.425-.734.67-1.245.73l.006 1.36-.952-.017-.007-1.305c-.57-.088-1.024-.337-1.362-.749-.337-.41-.508-.929-.515-1.556l.984.017c.023.422.143.74.358.957.217.216.528.327.935.334.373.006.67-.101.89-.324.222-.222.331-.523.33-.902a1.65 1.65 0 0 0-.043-.371.992.992 0 0 0-.116-.295c-.167-.276-.512-.48-1.034-.613l-.192-.05c-.65-.178-1.13-.43-1.437-.759-.309-.327-.463-.748-.466-1.265-.002-.563.142-1.03.432-1.4.29-.37.692-.596 1.202-.68l-.005-1.12.952.017z"
      />
      <path
        fill="#FFC910"
        d="M77.264 22.052l.005 1.13c.459.08.826.293 1.105.64.278.345.435.788.469 1.326l-1.022-.018c-.023-.304-.129-.547-.317-.73-.189-.182-.435-.276-.736-.281-.323-.006-.58.09-.772.284-.193.194-.288.457-.286.786 0 .12.017.23.052.33.033.1.086.19.157.273.143.169.447.308.911.42.278.065.49.125.635.18.509.19.894.462 1.158.818.265.354.398.776.4 1.264.003.62-.156 1.142-.474 1.569-.32.425-.734.67-1.245.73l.006 1.36-.953-.017-.006-1.305c-.57-.088-1.024-.337-1.362-.749-.337-.41-.508-.929-.515-1.556l.984.017c.023.422.143.74.358.957.217.216.528.327.935.334.373.006.67-.101.89-.324.221-.222.33-.523.33-.902a1.65 1.65 0 0 0-.043-.371.992.992 0 0 0-.116-.295c-.167-.276-.512-.48-1.034-.613l-.192-.05c-.65-.178-1.13-.43-1.437-.759-.31-.327-.463-.748-.466-1.265-.002-.563.142-1.03.432-1.4.29-.37.692-.596 1.201-.68l-.005-1.12.953.017z"
      />
      <path
        fill="#D6DCEB"
        d="M107.219 47.478a2.37 2.37 0 0 1 1.821.097 2.368 2.368 0 0 1 1.218 1.355c.076.217.125.438.148.657a2.85 2.85 0 0 1 1.575 2.36 3.42 3.42 0 0 1-.84 2.496 3.417 3.417 0 0 1-2.36 1.17 4.155 4.155 0 0 1-3.03-1.02 4.15 4.15 0 0 1-1.422-2.864l.991-.068a3.167 3.167 0 0 0 1.083 2.184 3.16 3.16 0 0 0 2.31.777 2.436 2.436 0 0 0 1.679-.832c.43-.492.642-1.123.597-1.776a1.846 1.846 0 0 0-.632-1.275l-.055-.045a2.85 2.85 0 0 1-2.024 1.951c-1.043.265-1.469-.369-1.547-.814h-.005l-.001-.037a.722.722 0 0 1-.003-.189 2.37 2.37 0 0 1 .586-1.502 2.37 2.37 0 0 1 1.633-.809 2.82 2.82 0 0 1 .39 0l-.01-.032a1.379 1.379 0 0 0-.71-.79 1.378 1.378 0 0 0-1.06-.058zm1.79 2.807c-.37.025-.709.192-.953.472a1.38 1.38 0 0 0-.342.935c.161.012.319-.004.473-.058a1.847 1.847 0 0 0 1.194-1.335 1.737 1.737 0 0 0-.373-.014z"
      />
      <path
        fill="#D1D1D1"
        d="M69.834 83.431a.996.996 0 0 1-.993.994h-7.356a.996.996 0 0 1-.993-.994v-8.85c0-.548.446-.994.993-.994h7.356c.546 0 .993.446.993.993v8.851zm24.292 0a.996.996 0 0 1-.994.994h-7.356a.996.996 0 0 1-.994-.994v-8.85c0-.548.447-.994.994-.994h7.356c.547 0 .994.446.994.993v8.851zM50.96 27.246c-.064 0-.123.009-.186.01v17.18H60.1a10.968 10.968 0 0 0 1.884-6.166c0-6.088-4.936-11.024-11.025-11.024"
      />
      <path
        fill="#D7D7D7"
        d="M83.288 33.225H66.844c-10.04 0-18.538 6.6-21.397 15.697h-5.885v13.08h3.864l-.833 4.033h4.018c3.606 7.556 11.303 12.784 20.233 12.784h16.444c12.384 0 22.423-10.04 22.423-22.423v-.747c0-12.384-10.04-22.424-22.423-22.424m2.818 50.742c0 .156.018.309.047.458h6.98a.996.996 0 0 0 .993-.994v-5.254a22.297 22.297 0 0 1-8.02 2.863v2.927zm-25.614-3.081v2.546c0 .546.446.993.992.993h5.701v-3.04h-1.996c-1.613 0-3.183-.174-4.697-.5"
      />
      <path
        fill="#06497D"
        d="M56.852 53.78h-.946a2.893 2.893 0 0 0-2.89-2.89 2.893 2.893 0 0 0-2.89 2.89h-.946a3.841 3.841 0 0 1 3.836-3.838 3.841 3.841 0 0 1 3.836 3.838"
      />
      <path
        fill="#D7D7D7"
        d="M59.183 27.246c-.065 0-.125.009-.187.01v17.18h9.328a10.978 10.978 0 0 0 1.883-6.166c0-6.088-4.936-11.024-11.024-11.024"
      />
      <path
        fill="url(#icon-sales_svg__e)"
        d="M37.751 36.872l1.596-2.863-.321-.702s-.04.016-.048.205c-.002.054.123.26.099.314-.033.072 0 .324-.089.383-.644.422-1.685.927-2.221 1.613-.863 1.106.977 1.108.984 1.05"
        transform="translate(.67 18.106)"
      />
      <path
        fill="url(#icon-sales_svg__f)"
        d="M36.687 35.845l1.318.673-2.851 7.02c-.017.278-.016.601.009.981.193 2.95-.182 5.699-.182 5.699l-5.224-.233s.237-3.206-.378-5.252c-.616-2.046.047-2.683.047-2.683l1.467-.636 1.332.152 1.746-1.066c.127-.301.274-.624.442-.969 1.58-3.23 1.748-3.062 1.748-3.062l.526-.624z"
        transform="translate(.67 18.106)"
      />
      <path
        fill="url(#icon-sales_svg__g)"
        d="M38.468 33.248c.02-.253.007-.716.145-.622l.056.827.182.364.307.116-.14.236c.021.103.026.181-.144.21l-.055.101c-.185-.25-.27-.35-.295-.442l-.071-.388c-.026-.112.015-.402.015-.402"
        transform="translate(.67 18.106)"
      />
      <path
        fill="url(#icon-sales_svg__h)"
        d="M29.789 49.153l3.122.127h2.165l1.311 9.558.004-.005 1.124 8.432-1.492.644s-1.696-7.532-1.46-8.341l.065.112c-.615-.975-1.302-2.762-1.861-4.396a176.337 176.337 0 0 1-.458 3.547c-.025.737-.04 1.465-.029 2.04.026 1.454.03 4.24-.192 6.615-.013.146-1.2.036-1.2.036s-.795-9.088-.921-10.577a1.865 1.865 0 0 1 .016-.447 143.034 143.034 0 0 1-.167-4.58c-.057-2.905-.027-2.765-.027-2.765z"
        transform="translate(.67 18.106)"
      />
      <path
        fill="url(#icon-sales_svg__i)"
        d="M30.1 68.177c-.057.07-.015.251-.015.251s.997.11 1.317.036c.083-.02.224-.092.357-.168.022-.013.062-.09.084-.102.04-.024.06.016.094-.006a.963.963 0 0 0 .153-.105c.088-.457-.03-.641-.03-.641s-.848-.282-1.007-.086c-.374.463-.828.67-.953.82"
        transform="translate(.67 18.106)"
      />
      <path
        fill="url(#icon-sales_svg__j)"
        d="M36.298 67.766l.143.743h.757s.042-.105.106-.105c.054 0 .124.105.197.105h1.927s.034-.156-.007-.21c-.022-.027-.148-.075-.233-.1-.391-.11-1.511-.453-1.706-.826-.237-.455-1.184.393-1.184.393"
        transform="translate(.67 18.106)"
      />
      <path
        fill="url(#icon-sales_svg__k)"
        d="M33.057 41.065c-.036.429-.266.8-.508.818h-.69c-.244-.018-.474-.39-.51-.818l-.17-2.122c-.037-.424.128-1.276.496-1.527a.915.915 0 0 1 1.056 0c.368.251.534 1.103.496 1.527l-.17 2.122"
        transform="translate(.67 18.106)"
      />
      <path
        fill="url(#icon-sales_svg__l)"
        d="M32.81 36.732c.458.168.708.864.687 1.226-.027.602-.054 1.414-.081 2.015-.11.4-.275.496-.567.54-.278.027-.346.055-.624.083-.294.014-.595-.376-.663-.733a3.258 3.258 0 0 0-.155-.535l-.027.011c-.162.041-.341-.12-.4-.358l-.075-.3c-.05-.202.002-.394.117-.473v-.005c-.07-.352.08-.668.51-.923.393-.229.858-.695 1.277-.548z"
        transform="translate(.67 18.106)"
      />
      <path
        fill="url(#icon-sales_svg__m)"
        d="M27.9 47.912l2.463 2.52s-2.969-.68-3.1-1.91c-.133-1.228.638-.61.638-.61"
        transform="translate(.67 18.106)"
      />
      <path
        fill="url(#icon-sales_svg__n)"
        d="M30.373 42.141s-.814-.637-1.61.546c-1.221 1.816-2.035 3.819-2.177 4.229-.142.409-.332 1.637.236 2l.568.364 1.184-.727-.19-.364-.094-.228s.663-1.455 1.373-2.774c.71-1.318.71-3.046.71-3.046"
        transform="translate(.67 18.106)"
      />
      <path
        fill="#B0BFDE"
        d="M32.88 59.68l-.438.718-.863-.956.381-.41zm.91-.66l-.524.528.35.623.429-.821z"
      />
      <path
        fill="url(#icon-sales_svg__o)"
        d="M30.808 37.744l.214.46s.455-.513.776-.933c.101-.133 1.14-.145 1.397-.098.188.033.21.274.253.413.154.5-.066-.84-.066-.84s-.352-.631-1.012-.684c-.365-.03-.535.183-.673.458-.033.064.402-.105.326-.115a4.574 4.574 0 0 0-.459.402 2.251 2.251 0 0 1-.228.412c-.352.472-.528.525-.528.525"
        transform="translate(.67 18.106)"
      />
      <path
        fill="url(#icon-sales_svg__p)"
        d="M27.364 49.05l1.02-.861.4.545-1 .815z"
        transform="translate(.67 18.106)"
      />
      <path
        fill="url(#icon-sales_svg__q)"
        d="M29.632 49.47c.164.05.155.04.155.04s-.009.218.121.38c.065.079.304.303.304.303l.352.336.175.143s.105.073.116.112c.03.113-.066.094-.066.094l-.728-.496.55.641s.056.094.021.14c-.032.042-.057.016-.057.016l-.79-.578.248.363s.053.093.021.139l-.043.065-.602-.668s.06-1.078.223-1.03"
        transform="translate(.67 18.106)"
      />
      <path
        fill="url(#icon-sales_svg__r)"
        d="M29.984 50.52l.36.473s.092.097.108.17a.078.078 0 0 1-.053.099l-.613-.66.198-.082z"
        transform="translate(.67 18.106)"
      />
      <path
        fill="#B0BFDE"
        d="M32.844 68.18h.553c.577-2.86.55-5.813-.085-8.665l-.5.2a19.94 19.94 0 0 1 .032 8.466m66.307 16.935h.402l-9.336-34.842h-.402zm-5.491 0h.402l-9.337-34.842h-.401z"
      />
      <path
        fill="#B0BFDE"
        d="M90.595 52.434l-.108-.402h-5.626l.108.402zm.821 3.064l-.108-.402h-5.626l.108.402zm.821 3.064l-.108-.402h-5.626l.108.402zm.821 3.064l-.108-.402h-5.626l.108.402zm.821 3.064l-.108-.402h-5.626l.108.402zm.821 3.064l-.108-.402h-5.626l.108.402zm.82 3.064l-.107-.402h-5.626l.108.402zm.822 3.064l-.108-.402h-5.626l.108.402zm.821 3.064l-.108-.402h-5.626l.108.402zm.82 3.064l-.107-.402H92.25l.108.402zm.822 3.064l-.108-.402H93.07l.108.402z"
      />
      <path
        fill="#161711"
        d="M90.396 58.12c-.062-.063-.036-.245-.036-.245s.975-.188 1.297-.14c.084.013.228.072.365.138.022.01.067.083.09.093.041.02.059-.02.094-.002.092.048.158.086.159.091.122.444.022.635.022.635s-.788.317-.986.165c-.503-.387-.87-.595-1.005-.734m-2.266-.819l.068.745-.72.207s-.068-.088-.129-.071c-.051.015-.089.134-.159.154l-.386.112-1.444.416s-.075-.14-.05-.202c.012-.032.12-.112.193-.158.342-.211 1.311-.845 1.394-1.253.101-.497 1.233.05 1.233.05"
      />
      <path
        fill="#E9D6B8"
        d="M87.562 33.376l.117-.901s-.615-.157-.622-.519c-.016-.726.531-1.692.771-1.934.684-.687 1.344-2.072 2.842-.285.57.68-1.256 2.421-1.494 2.851-.237.43-.172 1.041-.172 1.041l-.554.182-.888-.435z"
      />
      <path
        fill="#E2C59C"
        d="M91.456 39.458l-3.746 1.087s2.216.493 4.067.096c1.852-.398-.14-1.163-.321-1.183"
      />
      <path
        fill="#4674A5"
        d="M89.67 48.62s-1.5 1.878-.953 3.475c.974 2.84 1.759 5.117 2.083 6.067.126.369.228.292.228.292l.824.025.36-.368-1.699-6.542-.844-2.95z"
      />
      <path
        fill="#E9D6B8"
        d="M81.82 33.13l-2.31-2.799s.886 3.85 2.137 4.16c1.252.312.173-1.362.173-1.362"
      />
      <path
        fill="url(#icon-sales_svg__s)"
        d="M88.439 15.446s.823-.094 2.39 2.936c1.566 3.03 1.23 3.011 1.23 3.011l.167.756-1.155.545-1.603-2.53-1.03-4.718z"
        transform="translate(.67 18.106)"
      />
      <path
        fill="#4674A5"
        d="M85.981 43.268s1.53 6.307 3.581 9.901c1.097 1.924 1.028-.84 1.028-.84l-1.697-11.35-3.915-.184s.408.516 1.003 2.473"
      />
      <path
        fill="#4674A5"
        d="M88.597 42.957s-3.147 6.35-3.298 6.803c-.737 2.212-1.674-.506-1.72-1.045-.046-.54.16-3.31.901-5.975l.741-2.663 3.376 2.88z"
      />
      <path
        fill="#4674A5"
        d="M85.378 48.524s.538 1.093 1.133 2.516c.438 1.045 1.331 4.132 1.75 6.335.026.135-1.453.128-1.453.128s-2.229-5.86-2.73-7.172c-.502-1.312.167-2.522 1.3-1.807"
      />
      <path
        fill="url(#icon-sales_svg__t)"
        d="M89.61 16.369l-.957-1.015-.404-.543-1.763-.246-.374.542-1.447.519s-.405.564-.361 2.832c.048 2.512-1.203 5.954-.702 6.33 3.282 2.463 5.02.535 5.02.535s1.677-4.734.988-8.954"
        transform="translate(.67 18.106)"
      />
      <path
        fill="url(#icon-sales_svg__u)"
        d="M87.095 15.968s.198.422-.962.751c-1.979.564-4.052.364-4.459.337-.406-.027-.974-.08-1.357-.816a51.322 51.322 0 0 0-.395-.746l1.115-.785.249.303.107.186s1.499-.083 2.904-.173c3.281-.209 2.798.943 2.798.943"
        transform="translate(.67 18.106)"
      />
      <path
        fill="url(#icon-sales_svg__v)"
        d="M87.608 12.339s.258-.059.235.116c-.022.176-.26.497-.37.497-.11 0-.292.432-.292.432l.096.58 1.329.451s.471-.041.605-.325c.133-.285 1.167-2.258 1.167-2.258l-.768-.81s-.41-.412-.72-.627c-.478-.331-.685-.086-.718.067-.034.153-.204.114-.417.042-.214-.073-.532.002-.45.14.034.057-.223.115-.192.3.045.265.423.474.334.846-.041.169-.157.733.161.549"
        transform="translate(.67 18.106)"
      />
      <path
        fill="#E9D6B8"
        d="M79.656 31.099s.556-.263.597-.434c.04-.172.146-.809.146-.809l.01-.165s-.173-.116-.24.313c-.066.43-.148.2-.148.2l-.088-.412-.147-.499-.038-.229s-.011-.13-.04-.16c-.085-.085-.117.011-.117.011l.07.902-.29-.817s-.056-.099-.114-.091c-.054.007-.045.041-.045.041l.112 1-.197-.405s-.055-.096-.113-.091c-.062.005-.08.004-.08.004l.545 1.663.177-.022z"
      />
      <path
        fill="url(#icon-sales_svg__w)"
        d="M83.098 55.705c.634.16 1.803.729 2.048 1.701.367 1.46-.368 3.602.796 5.698.371.668-6.429 1.567-6.429 1.567l-1.311-7.376s1.638-1.601 1.973-1.709c.695-.223 1.656-.2 2.923.12"
        transform="translate(.67 18.106)"
      />
      <path
        fill="url(#icon-sales_svg__x)"
        d="M78.19 57.3c-.235.589-.832 4.095-.167 4.922.077.095-.345.244-.27.68.014.073.141.42.141.42l.544.142 1.642-.935-.315-.382-.149.003s-.14-.131-.101-.292c.038-.16-.377-.423-1.005-3.144-.185-.8-.267-1.545-.32-1.415"
        transform="translate(.67 18.106)"
      />
      <path
        fill="#ECB60D"
        d="M79.496 80.161l.486-.107.197-.031c0 .133.108.233.108.233l-.377.011-.441.095-.533.304.56-.505z"
      />
      <path
        fill="#C4D1E1"
        d="M80.191 80.614s-.375-.004-.695.193c-.244.15-.468.476-.244.652.224.177.568.341.568.341s.856.024.371-1.186"
      />
      <path
        fill="#E4EEF3"
        d="M79.253 81.472l.229-.05s.787-.07.9-.01c.112.063.473.307.473.307l.074.068s-.175.11-.412-.057c-.238-.165-.257-.038-.257-.038l.04.21-.004.268.037.113s.033.061.007.084c-.075.065-.15.034-.15.034l-.24-.419-.064.456s-.033.061-.108.077c-.07.015-.07-.003-.07-.003l-.055-.116a.14.14 0 0 1-.048.08c-.06.046-.142.04-.142.04l.022-.48-.098.244s-.035.06-.108.078c-.08.018-.1.024-.1.024l.074-.91z"
      />
      <path
        fill="#E9D6B8"
        d="M83.332 79.597s-.571 1.448-1.945 1.836c-.238.067-.384.324-.27.406.148.106.349.129.694.128.718-.001 1.696-.225 2.372-.88 1-.968 1.316-1.409 1.215-1.896-.1-.488-2.066.406-2.066.406"
      />
      <path
        fill="url(#icon-sales_svg__y)"
        d="M84.95 56.918c.291.478.46 1.246.458 2.345-.002.85-.736 2.243-.849 2.671a6.784 6.784 0 0 0-.063.378l-.813.798-1.632-1.106.496-.76.148.015s.094-.42.069-.584c-.026-.163.451-2.357.687-3.763.136-.81 1.211-.468 1.5.006"
        transform="translate(.67 18.106)"
      />
      <path
        fill="url(#icon-sales_svg__z)"
        d="M83.49 61.231l-.474-.147-.194-.048c-.012.133-.127.223-.127.223l.374.043.432.132.506.348-.517-.55z"
        transform="translate(.67 18.106)"
      />
      <path
        fill="#E9D6B8"
        d="M81.492 81.428l-.831-.227s-.235.316-.19.394c.044.078-.001.545-.001.545l.402.343.327-.11s.024-.142-.088-.156c-.112-.014.075-.095.134-.13.059-.032.54-.28.247-.659"
      />
      <path
        fill="#E4EEF3"
        d="M82.764 73.741a.51.51 0 0 1-.31.647l-.472.166a.51.51 0 0 1-.647-.312l-.42-1.198a.51.51 0 0 1 .31-.647l.472-.165a.51.51 0 0 1 .648.31l.42 1.2z"
      />
      <path
        fill="url(#icon-sales_svg__A)"
        d="M81.487 53.953l.023.062.252.912.11-.03a.11.11 0 0 1 .115.038l.02.039.154.562a.11.11 0 0 1-.037.114l-.039.02-.11.03.116.421a.328.328 0 0 1-.167.38l-.062.024-1.422.392a.328.328 0 0 1-.379-.167l-.024-.062-.118-.424a.11.11 0 0 1-.109-.081l-.154-.561a.11.11 0 0 1 .076-.135l-.023.011-.253-.917a.329.329 0 0 1 .168-.38l.061-.023 1.423-.392c.152-.042.31.03.379.167z"
        transform="translate(.67 18.106)"
      />
      <path
        fill="url(#icon-sales_svg__B)"
        d="M79.574 55.016s-.247-.151-.317-.267c-.034-.058-.039-.315.093-.45.133-.136.312-.002.312-.002s-.214.21-.213.267c.002.056.125.452.125.452"
        transform="translate(.67 18.106)"
      />
      <path
        fill="url(#icon-sales_svg__C)"
        d="M81.917 54.922s-.008-.869-.113-1.094c-.105-.227-.177-.384-.474-.33-.444.08-.424.173-.671.1-.247-.074-.713-.287-.852-.282-.232.007-.552.173-.453.584.073.305-.096.431-.088.44.027.033.116.046.272.077.45.088.698.062.979-.1.281-.163.798-.49.925-.284.128.206.29.898.332.943.04.046.101.001.143-.054"
        transform="translate(.67 18.106)"
      />
      <path
        fill="url(#icon-sales_svg__D)"
        d="M81.831 55.145l.306 1.106c.068.25.12.826-1.293 1.216-.836.229-.974-.393-1.03-.595-.042-.154.005-.662.069-.775.002-.003.094.343.094.343s.082-.382.48-.492c.64-.176.89.16 1.17.075.216-.065.282-.465.204-.878"
        transform="translate(.67 18.106)"
      />
      <path
        fill="url(#icon-sales_svg__E)"
        d="M80.87 56.42l-.439.121a.11.11 0 0 1-.134-.076l-.017-.061.65-.18.016.062a.108.108 0 0 1-.076.134"
        transform="translate(.67 18.106)"
      />
      <path
        fill="#4674A5"
        d="M74.603 82.94c.202.56 3.75 2.62 4.94 2.499l3.583.17c3.048-.372 4.49-5.107 4.49-5.107-.122-1.189-3.124.535-4.194 1.069-1.695.846-2.767.888-2.767.888-1.133.116-2.448-.759-3.879-.789-1.572-.033-2.493.389-2.173 1.27"
      />
      <path
        fill="#B6C2C9"
        d="M82.399 82.372c0-.002.002-.003.003-.005v-.149l-6.169-.112-.002.149v.005l.015.028c.077.13.219.156.381.16l5.369.097c.162.003.305-.018.387-.145l.016-.028z"
      />
      <path
        fill="#D8E5ED"
        d="M80.912 82.37v.004l.015.023a.259.259 0 0 0 .089.078l.938.016c.157.003.293-.013.372-.116l.015-.022a.017.017 0 0 0 .003-.005v-.072l-1.43-.026-.002.12z"
      />
      <path fill="#879BA6" d="M80.08 82.316l-3.082-.057.002-.105 3.082.057z" />
      <path
        fill="#B0BFDE"
        d="M80.881 82.185l-.744-3.046a.202.202 0 0 0-.18-.148l-4.328-.078c-.08-.002-.13.062-.11.142l.745 3.046 4.617.084z"
      />
      <path
        fill="#E1EEFE"
        d="M78.462 80.553c.039.155-.056.279-.21.276a.391.391 0 0 1-.35-.286c-.038-.156.057-.28.211-.277a.39.39 0 0 1 .35.287"
      />
      <path
        fill="#E9D6B8"
        d="M83.674 86.398c-.427.074-.752-.422-.752-.422l-1.103-.869.233-.587 2.148.966s-.1.84-.526.912"
      />
      <path
        fill="url(#icon-sales_svg__F)"
        d="M82.25 67.837s-.002.05.08.277c.083.227.184.305.29.337.412.121.708.06 1.699.25.99.191 1.287.048 1.577-.409.29-.456-.011-.815-.494-.852-.7-.052-1.088.027-1.452-.028-.382-.058-.406-.143-.629-.28-.21-.13-.402.637-.204.815.175.156-.72.18-.868-.11"
        transform="translate(.67 18.106)"
      />
      <path
        fill="#FFF"
        d="M83.132 86.383c.411.121.708.06 1.698.25.99.192 1.288.048 1.578-.409.134-.211.14-.399.062-.546.218.15.292.412.096.72-.29.456-.586.6-1.577.41-.99-.191-1.287-.13-1.698-.252-.083-.024-.161-.078-.23-.203a.353.353 0 0 0 .07.03"
      />
      <path fill="#E9D6B8" d="M82.926 85.961l-.002-.015.01.017-.008-.002" />
      <path
        fill="#FFF"
        d="M85.06 86.299c.032.012.06-.048.07-.132.02-.172.057-.342.115-.51.029-.084.028-.158-.007-.168-.035-.009-.09.05-.118.132a2.39 2.39 0 0 0-.108.505c-.009.083.017.16.048.173m-.307-.056c.034.007.065-.052.074-.13.019-.16.056-.321.113-.48.028-.08.026-.147-.01-.152-.037-.004-.095.055-.121.133-.055.158-.09.317-.107.475-.008.078.018.147.051.154m-.321-.014c.034.005.07-.057.082-.14.026-.164.07-.33.134-.495.03-.08.031-.15-.008-.151-.038-.002-.098.062-.129.142a2.374 2.374 0 0 0-.127.49c-.012.081.013.15.048.154"
      />
      <path
        fill="#4674A5"
        d="M83.599 84.981s-7.58-3.116-8.013-3.12c-1.464-.014-1.129 1.467-.387 2.164.875.822 7.53 2.327 7.53 2.327l.87-1.37z"
      />
      <path fill="#E9D6B8" d="M80.592 85.522l2.07-1.962-.883-.873-2.26 2.375z" />
      <path
        fill="#4674A5"
        d="M80.163 83.98s5.266-4.827 5.621-4.971c1.202-.488 1.947.84 1.904 1.298-.054.595-5.404 5.429-5.404 5.429l-2.121-1.756z"
      />
      <path
        fill="url(#icon-sales_svg__G)"
        d="M76.372 67.57c-.123.114-.144.448-.144.448s1.757.665 2.374.677c.16.003.45-.062.725-.138.047-.012.154-.133.2-.147.084-.024.103.058.175.035.187-.06.32-.111.326-.12.37-.79.245-1.178.245-1.178s-.807.23-.67.05c.297-.386-.549-.782-.738-.576-.752.823-2.136.62-2.493.949"
        transform="translate(.67 18.106)"
      />
      <path
        fill="#FFF"
        d="M76.945 85.84c.54.214 1.675.658 1.9.673.925.066 1.572-.263 2.004-.456a3.57 3.57 0 0 1-.15.374c-.006.009-.14.06-.326.12-.073.023-.092-.06-.176-.035-.045.014-.152.134-.2.147-.275.076-.564.141-.724.137-.618-.012-2.375-.676-2.375-.676s.005-.152.047-.284m1.242-.512c-.016.03.052.066.15.087.2.04.4.1.594.18.096.04.184.048.197.014.013-.033-.053-.097-.148-.135a3.321 3.321 0 0 0-.587-.173c-.097-.019-.19-.003-.206.027m.394-.14c-.015.032.052.076.148.104.195.056.387.131.574.225.092.047.177.061.189.026.012-.035-.051-.106-.143-.15a3.322 3.322 0 0 0-.569-.219c-.094-.026-.184-.016-.199.014m.379-.122c-.013.033.052.083.146.119.188.071.373.161.552.269.09.053.17.072.18.035.013-.036-.048-.113-.136-.165a3.355 3.355 0 0 0-.549-.26c-.092-.035-.179-.03-.192.002"
      />
      <path
        fill="#FFAB01"
        d="M26.725 81.171l.083 2.193c.021.56-.712 1.15-2.227 1.657-3.275 1.097-8.96 1.396-12.701.669-2.009-.39-3.048-.998-3.073-1.646l-.083-2.193c.025.648 1.064 1.255 3.073 1.646 3.74.727 9.426.427 12.7-.67 1.516-.507 2.25-1.097 2.228-1.656"
      />
      <path
        fill="#FFC910"
        d="M12.814 83.156c3.098.602 7.808.354 10.52-.555 2.712-.908 2.399-2.133-.7-2.735-3.097-.602-7.807-.354-10.519.554-2.712.909-2.399 2.133.7 2.736m10.837-3.631c3.741.727 4.12 2.206.846 3.302-3.275 1.097-8.96 1.397-12.701.67-3.741-.728-4.119-2.206-.845-3.303s8.96-1.396 12.7-.669"
      />
      <path
        fill="#FFAB01"
        d="M22.635 79.866c3.098.602 3.411 1.827.7 2.735-2.712.909-7.423 1.157-10.52.555-3.099-.603-3.412-1.827-.7-2.736 2.712-.908 7.422-1.156 10.52-.554"
      />
      <path
        fill="#F99B0F"
        d="M12.115 80.693c2.712-.908 7.422-1.156 10.52-.554 1.542.3 2.393.753 2.526 1.246.155-.595-.703-1.165-2.526-1.52-3.098-.601-7.808-.353-10.52.555-1.362.457-1.96.993-1.827 1.49.109-.416.711-.843 1.827-1.217m-2.531 2.113v2.15c.514.284 1.281.537 2.296.734 1.003.195 2.146.316 3.345.367v-2.19c-1.229-.05-2.402-.17-3.428-.37-.962-.187-1.7-.424-2.213-.69"
      />
      <path
        fill="#F99B0F"
        d="M21.364 80.55l-.821.275c.328.09.482.2.462.327-.018.128-.21.26-.573.395l-.846-.164c.204-.077.292-.153.266-.226-.025-.075-.163-.136-.412-.184a3.472 3.472 0 0 0-.857-.052 3.128 3.128 0 0 0-.814.145.88.88 0 0 0-.197.089c-.044.03-.066.06-.066.09-.004.064.149.146.46.245.185.06.318.107.4.143.288.126.415.252.377.38-.038.127-.233.25-.588.369a6.84 6.84 0 0 1-1.542.306 6.985 6.985 0 0 1-1.58-.017l-.986.33-.788-.153.948-.318c-.416-.11-.616-.242-.598-.395.016-.152.25-.305.703-.458l.814.158c-.288.106-.42.202-.397.288.023.086.203.163.54.228.31.06.636.08.985.06a3.55 3.55 0 0 0 .934-.166c.098-.033.176-.065.237-.097a.328.328 0 0 0 .117-.09c.06-.092-.08-.196-.422-.31l-.124-.042c-.417-.145-.635-.282-.655-.409-.02-.128.157-.255.532-.38.409-.137.87-.228 1.385-.272a7.26 7.26 0 0 1 1.506.024l.812-.273.788.154z"
      />
      <path
        fill="#FFC910"
        d="M21.364 80.241l-.821.276c.328.09.482.2.462.327-.018.128-.21.26-.573.395l-.846-.164c.204-.077.292-.153.266-.226-.025-.075-.163-.136-.412-.184a3.472 3.472 0 0 0-.857-.052 3.128 3.128 0 0 0-.814.145.889.889 0 0 0-.197.088c-.044.03-.066.06-.066.092-.004.063.149.144.46.244.185.06.318.107.4.143.288.126.415.252.377.38-.038.127-.233.25-.588.369a6.84 6.84 0 0 1-1.542.306 7 7 0 0 1-1.58-.017l-.986.33-.788-.153.948-.318c-.416-.11-.616-.242-.598-.395.016-.152.25-.305.703-.458l.814.158c-.288.106-.42.202-.397.288.023.086.203.163.54.228.31.06.636.08.985.06.348-.019.658-.074.934-.167.098-.032.176-.064.237-.096a.326.326 0 0 0 .117-.09c.06-.092-.08-.196-.422-.31l-.124-.042c-.417-.145-.635-.282-.655-.409-.02-.128.157-.255.532-.38.409-.137.87-.228 1.385-.271a7.26 7.26 0 0 1 1.506.023l.812-.273.788.153zm-10.032 1.364l-.082-1.981a758.82 758.82 0 0 0 .082 1.98"
      />
      <path
        fill="#FFAB01"
        d="M27.696 78.79l.083 2.193c.021.56-.711 1.149-2.227 1.657-3.274 1.096-8.96 1.396-12.701.669-2.009-.39-3.048-.998-3.073-1.646l-.083-2.194c.025.649 1.064 1.256 3.073 1.646 3.74.727 9.427.428 12.7-.669 1.517-.508 2.25-1.097 2.228-1.656"
      />
      <path
        fill="#FFC910"
        d="M13.785 80.774c3.099.603 7.809.354 10.52-.554 2.713-.908 2.4-2.133-.699-2.735-3.098-.602-7.808-.354-10.52.554-2.712.909-2.398 2.133.7 2.735m10.837-3.63c3.741.727 4.12 2.205.846 3.302s-8.96 1.396-12.701.67c-3.74-.728-4.119-2.206-.845-3.303s8.96-1.396 12.7-.67"
      />
      <path
        fill="#FFAB01"
        d="M23.606 77.485c3.098.602 3.412 1.827.7 2.735-2.712.908-7.423 1.157-10.52.554-3.099-.602-3.412-1.826-.7-2.735 2.712-.908 7.422-1.156 10.52-.554"
      />
      <path
        fill="#F99B0F"
        d="M13.086 78.312c2.712-.908 7.422-1.156 10.52-.554 1.542.3 2.393.753 2.526 1.246.155-.595-.703-1.165-2.526-1.52-3.098-.601-7.808-.353-10.52.555-1.362.457-1.96.993-1.826 1.49.108-.416.71-.843 1.826-1.217m-2.53 2.113v2.15c.514.284 1.28.537 2.295.734 1.003.195 2.147.315 3.345.367v-2.19c-1.229-.05-2.402-.171-3.428-.37-.962-.188-1.7-.424-2.212-.691"
      />
      <path
        fill="#F99B0F"
        d="M22.335 78.168l-.82.275c.327.091.481.2.461.328-.018.127-.21.26-.573.395l-.845-.164c.203-.078.291-.153.266-.227-.026-.074-.164-.135-.413-.183a3.468 3.468 0 0 0-.857-.053 3.121 3.121 0 0 0-.814.146.89.89 0 0 0-.197.088c-.044.03-.066.06-.066.091-.003.064.15.145.46.245.185.06.318.107.4.143.289.126.415.252.377.38-.037.127-.233.25-.588.369-.448.15-.962.252-1.542.306a6.99 6.99 0 0 1-1.58-.018l-.986.33-.788-.152.948-.318c-.415-.11-.616-.243-.598-.395.016-.152.25-.305.703-.458l.814.158c-.288.106-.42.202-.397.288.023.086.203.162.54.228.31.06.636.08.985.06.348-.019.658-.074.934-.167.098-.032.177-.064.237-.096s.099-.062.118-.09c.059-.093-.081-.196-.422-.31l-.125-.043c-.417-.145-.635-.28-.655-.408-.02-.128.157-.255.532-.38.409-.137.87-.228 1.385-.272a7.252 7.252 0 0 1 1.506.024l.813-.273.787.153z"
      />
      <path
        fill="#FFC910"
        d="M22.335 77.86l-.82.275c.327.091.481.2.461.328-.018.127-.21.26-.573.395l-.845-.164c.203-.078.291-.153.266-.227-.026-.074-.164-.135-.413-.183a3.472 3.472 0 0 0-.857-.053 3.13 3.13 0 0 0-.814.146.897.897 0 0 0-.197.088c-.044.03-.066.06-.066.091-.003.064.15.145.46.245.185.059.318.107.4.143.289.126.415.252.377.38-.037.127-.233.25-.588.369a6.84 6.84 0 0 1-1.542.306 6.996 6.996 0 0 1-1.58-.018l-.986.33-.788-.152.948-.318c-.415-.11-.616-.243-.598-.395.016-.152.25-.305.703-.458l.814.158c-.288.106-.42.202-.397.288.023.086.203.162.54.228.31.06.636.08.985.06.348-.019.658-.074.934-.167.098-.032.177-.064.237-.096s.099-.062.118-.09c.059-.093-.081-.196-.422-.31l-.125-.042c-.417-.146-.635-.282-.655-.41-.02-.127.157-.254.532-.38.409-.136.87-.227 1.385-.27a7.265 7.265 0 0 1 1.506.022l.813-.272.787.153zm-11.781.89l-.083-1.981a803.314 803.314 0 0 0 .083 1.98"
      />
      <path
        fill="#FFAB01"
        d="M26.918 75.935L27 78.128c.022.56-.711 1.149-2.227 1.657-3.274 1.096-8.96 1.396-12.7.669-2.01-.39-3.049-.998-3.073-1.646l-.083-2.194c.024.649 1.063 1.256 3.072 1.646 3.74.727 9.427.428 12.701-.669 1.516-.508 2.249-1.097 2.228-1.656"
      />
      <path
        fill="#FFC910"
        d="M13.007 77.92c3.098.602 7.808.353 10.52-.555 2.712-.909 2.398-2.133-.7-2.735-3.098-.603-7.808-.354-10.52.554-2.711.908-2.398 2.133.7 2.735m10.838-3.63c3.74.727 4.12 2.205.845 3.302-3.274 1.097-8.96 1.396-12.701.67-3.74-.728-4.119-2.206-.845-3.303s8.96-1.396 12.7-.67"
      />
      <path
        fill="#FFAB01"
        d="M22.827 74.63c3.099.602 3.412 1.826.7 2.735-2.712.908-7.422 1.157-10.52.554-3.098-.602-3.411-1.827-.7-2.735 2.713-.908 7.422-1.157 10.52-.554"
      />
      <path
        fill="#F99B0F"
        d="M12.307 75.457c2.712-.908 7.422-1.156 10.52-.554 1.542.3 2.394.753 2.526 1.246.155-.595-.702-1.165-2.526-1.52-3.098-.602-7.808-.353-10.52.555-1.362.457-1.96.993-1.826 1.49.108-.416.71-.843 1.826-1.217m-2.53 2.113v2.15c.514.284 1.28.537 2.295.734 1.003.195 2.147.315 3.346.367v-2.19c-1.23-.05-2.402-.171-3.429-.37-.961-.188-1.7-.424-2.212-.691"
      />
      <path
        fill="#F99B0F"
        d="M21.557 75.313l-.821.275c.327.091.482.2.462.328-.018.127-.21.259-.574.395l-.845-.164c.203-.078.292-.153.266-.227-.026-.074-.163-.135-.412-.183a3.463 3.463 0 0 0-.858-.053 3.12 3.12 0 0 0-.813.146.889.889 0 0 0-.198.088c-.044.03-.065.06-.065.091-.004.064.148.145.46.245.185.059.318.107.4.143.288.126.415.252.377.38-.038.127-.233.25-.588.369-.45.15-.963.252-1.543.305a6.99 6.99 0 0 1-1.58-.017l-.985.33-.788-.152.948-.318c-.416-.11-.616-.243-.599-.395.017-.153.251-.305.703-.458l.814.158c-.288.106-.42.202-.396.288.023.086.202.162.54.228.309.06.636.08.985.06a3.51 3.51 0 0 0 .933-.167c.098-.032.177-.064.237-.096a.329.329 0 0 0 .118-.09c.06-.093-.081-.196-.422-.31l-.125-.043c-.416-.145-.634-.28-.654-.408-.021-.128.157-.255.531-.38.41-.137.87-.228 1.385-.272a7.24 7.24 0 0 1 1.506.024l.813-.273.788.153z"
      />
      <path
        fill="#FFC910"
        d="M21.557 75.005l-.821.275c.327.091.482.2.462.328-.018.127-.21.259-.574.395l-.845-.164c.203-.078.292-.153.266-.227-.026-.074-.163-.135-.412-.183a3.463 3.463 0 0 0-.858-.053 3.12 3.12 0 0 0-.813.146.885.885 0 0 0-.198.088c-.044.03-.065.06-.065.091-.004.064.148.145.46.245.185.059.318.107.4.143.288.126.415.252.377.38-.038.127-.233.25-.588.368a6.84 6.84 0 0 1-1.543.306 6.99 6.99 0 0 1-1.58-.017l-.985.33-.788-.152.948-.318c-.416-.11-.616-.243-.599-.395.017-.153.251-.305.703-.458l.814.158c-.288.106-.42.202-.396.288.023.086.202.162.54.228.309.06.636.08.985.06a3.55 3.55 0 0 0 .933-.167c.098-.032.177-.064.237-.096a.326.326 0 0 0 .118-.09c.06-.093-.081-.196-.422-.31l-.125-.043c-.416-.145-.634-.281-.654-.408-.021-.128.157-.255.531-.38.41-.137.87-.228 1.385-.272a7.253 7.253 0 0 1 1.506.023l.813-.272.788.153zm.286-.455l1.962-.494c1.929-.487 4.133.962 5.225 3.596 1.247 3.01.57 6.429-1.512 7.634l-1.962.495c2.082-1.206 2.76-4.623 1.512-7.634-1.093-2.634-3.296-4.083-5.225-3.596"
      />
      <path
        fill="#FFC910"
        d="M26.712 85.616l-1.962.494c.276-.07.546-.178.806-.329l1.962-.495c-.26.15-.53.26-.806.33"
      />
      <path
        fill="#FFAB01"
        d="M21.843 74.55l1.962-.494c-.276.07-.546.178-.806.329l-1.962.495c.26-.15.53-.26.806-.33"
      />
      <path
        fill="#FFAB01"
        d="M26.712 85.616l-1.962.494c-1.93.487-4.133-.962-5.225-3.596-1.248-3.01-.571-6.428 1.512-7.634l1.962-.495c-2.083 1.206-2.76 4.624-1.512 7.634 1.092 2.634 3.295 4.083 5.225 3.597"
      />
      <path
        fill="#F99B0F"
        d="M27.013 86.813l-1.962.495c-2.33.588-4.99-1.161-6.308-4.341-1.507-3.635-.689-7.76 1.826-9.217l1.962-.495c-2.515 1.456-3.333 5.582-1.826 9.217 1.318 3.18 3.979 4.929 6.308 4.341"
      />
      <path
        fill="#FFC910"
        d="M27.518 85.286c2.082-1.205 2.76-4.623 1.512-7.634-1.248-3.01-3.948-4.473-6.031-3.267-2.083 1.206-2.76 4.624-1.512 7.634 1.248 3.01 3.948 4.473 6.031 3.267m-4.987-12.03c2.514-1.456 5.774.31 7.28 3.944 1.507 3.634.69 7.76-1.825 9.216-2.515 1.455-5.774-.31-7.28-3.944-1.508-3.635-.69-7.76 1.825-9.217"
      />
      <path
        fill="#FFAB01"
        d="M22.999 74.385c2.083-1.205 4.783.257 6.03 3.267 1.248 3.01.571 6.429-1.511 7.634-2.083 1.206-4.783-.257-6.031-3.267-1.248-3.01-.571-6.428 1.512-7.634"
      />
      <path
        fill="#EE8F01"
        d="M21.542 73.353l1.962-.495a3.776 3.776 0 0 0-.973.398l-1.962.494c.314-.182.64-.313.973-.397"
      />
      <path
        fill="#F99B0F"
        d="M22.999 74.385a3.565 3.565 0 0 0-.933.791c.244-.265.519-.492.825-.67 2.083-1.205 4.783.258 6.031 3.268 1.037 2.501.744 5.282-.578 6.843 1.413-1.535 1.75-4.397.685-6.965-1.247-3.01-3.947-4.472-6.03-3.267"
      />
      <path
        fill="#EE8F01"
        d="M25.051 87.308l1.962-.495c-1.138.287-2.354.015-3.455-.707l-1.813.59c1.061.651 2.22.886 3.306.612"
      />
      <path
        fill="#F99B0F"
        d="M23.826 75.708l.378.91c.279-.09.552-.04.82.145.267.186.5.49.698.914l-.569.329c-.113-.238-.252-.4-.416-.484a.493.493 0 0 0-.498.021.676.676 0 0 0-.331.49c-.042.22-.007.464.103.73.04.097.086.18.138.249a.65.65 0 0 0 .177.167c.135.088.349.099.64.032.175-.04.311-.063.41-.067.343-.018.645.072.908.27.263.197.476.493.64.886.206.5.292.975.258 1.426-.034.451-.181.787-.442 1.009l.453 1.094-.53.307-.435-1.051c-.344.12-.676.072-.998-.147-.322-.218-.588-.579-.8-1.083l.548-.317c.152.333.324.55.514.652.19.102.399.087.625-.044a.779.779 0 0 0 .383-.561c.048-.254.008-.534-.118-.838a1.84 1.84 0 0 0-.146-.286.945.945 0 0 0-.162-.2c-.184-.166-.44-.213-.772-.146-.055.01-.095.019-.123.023-.416.075-.764.033-1.042-.129-.279-.16-.503-.448-.676-.864-.188-.454-.263-.879-.226-1.276.037-.396.183-.713.436-.952l-.374-.902.53-.307z"
      />
      <path
        fill="#FFC910"
        d="M24.168 75.57l.378.91c.279-.09.552-.042.82.144.267.186.5.49.698.914l-.569.329c-.113-.238-.252-.399-.416-.483a.493.493 0 0 0-.498.02.676.676 0 0 0-.331.49c-.042.221-.007.465.103.73.04.097.086.18.138.249a.65.65 0 0 0 .177.167c.135.088.349.099.64.033.175-.041.311-.063.41-.068a1.35 1.35 0 0 1 .908.27c.263.198.476.494.64.886.206.5.292.975.259 1.426-.035.451-.182.788-.443 1.01l.453 1.094-.53.306-.435-1.05c-.344.12-.676.072-.998-.147-.322-.218-.588-.58-.8-1.083l.548-.317c.152.332.324.55.514.651.19.102.399.087.625-.044a.78.78 0 0 0 .383-.56c.048-.254.008-.534-.118-.84a1.842 1.842 0 0 0-.146-.285.946.946 0 0 0-.162-.199c-.184-.166-.44-.214-.772-.147L25.52 80c-.416.075-.764.032-1.042-.13-.278-.16-.503-.448-.676-.864-.188-.453-.263-.878-.226-1.275.037-.396.183-.714.436-.952l-.374-.902.53-.307z"
      />
    </g>
  </svg>
);

export default SvgIconSales;
