import React from 'react';

const SvgIconBusOff = props => (
  <svg width={300} height={85} viewBox="0 0 300 85" {...props}>
    <defs>
      <linearGradient
        id="icon-bus-off_svg__a"
        x1="49.998%"
        x2="49.998%"
        y1="-232.251%"
        y2="116.529%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-bus-off_svg__b" x1="30.709%" x2="71.96%" y1="41.821%" y2="61.305%">
        <stop offset="0%" stopColor="#DCE6E6" />
        <stop offset="19.25%" stopColor="#CED8D8" />
        <stop offset="55.37%" stopColor="#AAB4B4" />
        <stop offset="100%" stopColor="#768080" />
      </linearGradient>
      <linearGradient
        id="icon-bus-off_svg__c"
        x1="-36.745%"
        x2="69.396%"
        y1="-142.529%"
        y2="70.672%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-bus-off_svg__d" x1="106.809%" x2="-6.766%" y1="50.61%" y2="50.61%">
        <stop offset="0%" stopOpacity={0} />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient id="icon-bus-off_svg__e" x1="25.995%" x2="52.093%" y1="49.066%" y2="50.072%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-bus-off_svg__f" x1="21.594%" x2="62.865%" y1="47.492%" y2="51.1%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-bus-off_svg__g" x1="37.56%" x2="58.119%" y1="-11.205%" y2="104.975%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-bus-off_svg__h"
        x1="-110.579%"
        x2="90.919%"
        y1="-289.417%"
        y2="114.791%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-bus-off_svg__i" x1="-20.761%" x2="65.965%" y1="24.671%" y2="55.73%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-bus-off_svg__j" x1="-.614%" x2="83.232%" y1="-37.682%" y2="107.543%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-bus-off_svg__k" x1="25.013%" x2="75.016%" y1="6.695%" y2="93.303%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient id="icon-bus-off_svg__l" x1="7.643%" x2="82.842%" y1="-23.367%" y2="106.881%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-bus-off_svg__m" x1="25%" x2="75.004%" y1="6.695%" y2="93.303%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient
        id="icon-bus-off_svg__n"
        x1="10.106%"
        x2="57.466%"
        y1="-176.281%"
        y2="97.578%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-bus-off_svg__o" x1="23.769%" x2="76.667%" y1="-4.805%" y2="105.58%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient id="icon-bus-off_svg__p" x1="21.935%" x2="77.861%" y1="11.854%" y2="88.034%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient id="icon-bus-off_svg__q" x1="32.28%" x2="65.013%" y1="2.905%" y2="101.124%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient id="icon-bus-off_svg__r" x1="41.028%" x2="58.043%" y1="7.917%" y2="94.894%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient
        id="icon-bus-off_svg__s"
        x1="-11.413%"
        x2="75.455%"
        y1="-19.841%"
        y2="69.297%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-bus-off_svg__t" x1="38.427%" x2="68.227%" y1="-2.533%" y2="91.82%">
        <stop offset="0%" stopColor="#DCE6E6" />
        <stop offset="19.25%" stopColor="#CED8D8" />
        <stop offset="55.37%" stopColor="#AAB4B4" />
        <stop offset="100%" stopColor="#768080" />
      </linearGradient>
      <linearGradient id="icon-bus-off_svg__u" x1="33.876%" x2="90.293%" y1=".584%" y2="87.936%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient id="icon-bus-off_svg__v" x1="35.352%" x2="64.648%" y1="47.154%" y2="52.832%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient id="icon-bus-off_svg__w" x1="16.015%" x2="59.927%" y1="42.988%" y2="52.045%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M0-9h300v94H0z" />
      <g opacity={0.6}>
        <path
          fill="url(#icon-bus-off_svg__a)"
          fillRule="nonzero"
          d="M66.87 77.086s11.614-4.07 27.183-.042l31.039-13.51 82.617-2.304 8.283 15.87s14.306-1.885 27.12.9L255 74.115l-5.229-18.117L56 53l1.353 19.1 9.516 4.986z"
        />
        <path
          fill="url(#icon-bus-off_svg__b)"
          fillRule="nonzero"
          d="M291.822 52.797c-.592-4.762-7.73-35.42-9.811-44.299a3.752 3.752 0 0 0-3.006-2.847C271.184 4.31 245.327.327 212.7.096 176.009-.164 25.87.18 6.235.229a2.87 2.87 0 0 0-2.776 2.16C2.485 6.08.759 14.691.655 29.652.536 47.263.125 67.154 0 72.989a1.82 1.82 0 0 0 1.636 1.859c6.798.652 32.216 3.065 36.642 3.184 2.88.077 16.519.07 27.874.042-.187-1.445-.285-3.128-.285-5.078 0-7.666 6.165-13.88 13.77-13.88 7.606 0 13.77 6.214 13.77 13.88a83.06 83.06 0 0 1-.131 5.036l121.7.05c-.189-1.453-.286-3.136-.286-5.093 0-7.666 6.165-13.88 13.77-13.88 7.606 0 13.77 6.214 13.77 13.88 0 2.322-.07 4.307-.194 6.011h41.325a8.095 8.095 0 0 0 6.707-3.577 8.239 8.239 0 0 0 1.399-4.145c.39-6.46.765-15.157.355-18.481z"
        />
        <path
          fill="url(#icon-bus-off_svg__c)"
          fillRule="nonzero"
          d="M284 54s-.098-26.633-7.03-42.384a5.884 5.884 0 0 0-3.83-3.308c-1.925-.525-5.085-1.185-10.1-1.767-10.748-1.25-18.33-1.824-22.04-1.405 0 0 14.94 3.755 17.346 10.065 3.202 8.404 3.46 38.6 3.46 38.6L284 54z"
        />
        <path
          stroke="url(#icon-bus-off_svg__d)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M284 63.64h6.183c1.136 0 1.817-.28 1.817-.28"
          opacity={0.5}
        />
        <path
          fill="url(#icon-bus-off_svg__e)"
          fillRule="nonzero"
          d="M93 68h117.162c1.755 0 3.838.464 3.838.464s-.378 1.118-.475 2.536c0 0-1.65-.32-3.405-.32H93.615S93.426 69.01 93 68z"
        />
        <path
          fill="url(#icon-bus-off_svg__f)"
          fillRule="nonzero"
          d="M9.55 71h55.858c.14-1.602.592-3 .592-3H6v2.389L9.55 71z"
        />
        <path
          fill="url(#icon-bus-off_svg__g)"
          fillRule="nonzero"
          d="M1.58 66H1l.308-17h.272C4.018 49 6 50.895 6 53.241v8.518C6 64.098 4.025 66 1.58 66z"
        />
        <g fillRule="nonzero">
          <path
            fill="url(#icon-bus-off_svg__h)"
            d="M.75 7.564h1.821V5.018h-2.3s.39 1.23.48 2.546z"
            transform="translate(243 63)"
          />
          <path
            fill="url(#icon-bus-off_svg__i)"
            d="M19.791.298h-.347v.306c0 2.436-2.05 4.407-4.58 4.407H3.704v2.545h30.84a5.701 5.701 0 0 0 2.87-.778c1.89-1.09 3.065-3.178 3.065-5.44V.291H19.79v.007z"
            transform="translate(243 63)"
          />
        </g>
        <circle cx={228.5} cy={72.5} r={12.5} fill="url(#icon-bus-off_svg__j)" fillRule="nonzero" />
        <circle cx={228.5} cy={72.5} r={7.5} fill="url(#icon-bus-off_svg__k)" fillRule="nonzero" />
        <circle cx={80.5} cy={72.5} r={12.5} fill="url(#icon-bus-off_svg__l)" fillRule="nonzero" />
        <circle cx={80.5} cy={72.5} r={7.5} fill="url(#icon-bus-off_svg__m)" fillRule="nonzero" />
        <path
          fill="url(#icon-bus-off_svg__n)"
          fillRule="nonzero"
          d="M291.924 54.003s-3.843.035-5.244 0c0 0 .076-19.41-7.694-42.843-.151-.46.124-.962.597-1.053a14.66 14.66 0 0 1 2.698-.23l-.234-.844a10.97 10.97 0 0 0-2.416.077c-1.194.174-1.935 1.422-1.51 2.573 7.935 21.571 7.55 43.317 7.55 43.317H292l-.076-.997z"
        />
        <path fill="url(#icon-bus-off_svg__o)" fillRule="nonzero" d="M1 62h5v-6H1.12z" />
        <path fill="url(#icon-bus-off_svg__p)" fillRule="nonzero" d="M18 68h4v3h-4z" />
        <path
          fill="url(#icon-bus-off_svg__q)"
          fillRule="nonzero"
          d="M283 54s-.335-19.14-2.96-28.507c-2.626-9.368-3.275-12.832-7.988-13.938-3.764-.887-11.535-1.595-12.687-1.553-.09 0-.181.013-.272.034a1.382 1.382 0 0 0-.761 2.247c.747.873 1.634 2.145 2.465 3.897 2.61 5.526 3.288 37.655 3.288 37.655L283 54z"
        />
        <path
          fill="url(#icon-bus-off_svg__r)"
          fillRule="nonzero"
          d="M291.956 52.581c-.622-4.657-8.04-34.377-10.358-43.581-1.295 0-2.265.118-2.91.23-.51.09-.806.59-.643 1.05 8.38 23.357 8.299 42.705 8.299 42.705 1.51.034 5.649 0 5.656 0l-.044-.404z"
        />
        <path
          fill="url(#icon-bus-off_svg__s)"
          fillRule="nonzero"
          d="M271.007 17.632a.63.63 0 0 1 .639-.632c2.994.076 15.637.41 18.802.86 3.599.507 6.12 1.236 7.706 4.95 1.586 3.712 2.527 10.042 1.25 10.979-.983.722-2.095-.555-2.088-1.603.007-.507-.364-3.894-1.27-5.997-.907-2.11-3.173-5.587-6.8-6.184-2.836-.465-13.981-.833-17.305-.937a.976.976 0 0 1-.941-.978v-.458h.007z"
        />
        <path
          fill="url(#icon-bus-off_svg__t)"
          fillRule="nonzero"
          d="M298.265 33.87c.351.18.745.193 1.124-.084 1.308-.934.345-7.25-1.279-10.945-.056-.132-.12-.263-.176-.388C295.516 20.53 293 21.07 293 21.07c5.3 4.172 5.265 12.792 5.265 12.8z"
        />
        <path
          fill="url(#icon-bus-off_svg__u)"
          fillRule="nonzero"
          d="M291.794 68s-2.275-.03-3.948-1.11c-1.988-1.287-1.844-3.684-1.844-3.684h4.278c1.035 0 1.72-.206 1.72-.206l-.206 5z"
        />
        <path
          fill="url(#icon-bus-off_svg__v)"
          fillRule="nonzero"
          d="M250.68 16.706c-3.503-4.674-17.167-9.913-35.784-11.137-.404-.028-.877-.05-1.427-.077l-2.66-.092c-9.124-.262-28.57-.368-51.97-.396h-2.66c-16.25-.014-34.246.014-51.943.056l-2.66.007c-12.885.036-25.56.071-37.226.113l-2.66.008c-25.205.091-45.277.176-51.858.176C8.139 5.364 8 6.248 8 7.627c0 2.73.376 7.347 1.449 10.472 1.608 4.68 4.603 6.003 10.022 7.756 5.411 1.761 13.358 4.724 29.132 5.388 1.95.085 6.54.156 13.08.22l2.66.028c9.549.085 22.517.155 37.226.22l2.66.013c16.123.064 34.155.113 51.942.156l2.66.007c18.512.042 36.648.07 51.97.085h2.66c16.723.02 29.767.028 35.736.028 2.862 0 3.83-1.358 3.803-3.267-.056-3.69-.509-9.609-2.32-12.027z"
        />
        <path
          fill="url(#icon-bus-off_svg__w)"
          fillRule="nonzero"
          d="M252.861 16.598c-3.538-5.016-17.343-10.645-36.148-11.952-17.767-1.236-184.562-.34-206.307-.22-1.323.008-2.389 1.089-2.403 2.424-.028 2.924.105 7.89 1.191 11.238 1.63 5.03 4.653 6.442 10.12 8.328 5.468 1.887 13.491 5.072 29.427 5.779 14.633.65 176.337.79 202.623.805 2.026 0 3.663-1.674 3.636-3.73-.056-3.948-.307-10.08-2.139-12.672zM62.657 31.474c-6.54-.063-11.13-.141-13.08-.219-15.776-.657-23.723-3.624-29.134-5.382-5.412-1.76-8.407-3.073-10.023-7.75-1.08-3.122-1.449-7.734-1.449-10.461 0-1.377.14-2.26 1.832-2.26 6.582 0 26.655-.092 51.86-.177v26.25h-.006zm39.88.247a8865.04 8865.04 0 0 1-37.227-.219V5.218c11.667-.043 24.336-.085 37.228-.113V31.72zm54.606.163c-17.789-.042-35.82-.092-51.945-.155V5.09c17.698-.043 35.695-.07 51.945-.057v26.85zm54.632.092c-15.323-.021-33.46-.043-51.972-.085V5.041c23.402.029 42.848.134 51.972.396v26.539zm38.398.028c-5.976 0-19.015-.007-35.737-.028V5.529c.55.02 1.03.05 1.428.077 18.617 1.215 32.282 6.457 35.785 11.126 1.811 2.416 2.264 8.328 2.326 12.008.028 1.915-.94 3.264-3.802 3.264z"
        />
      </g>
    </g>
  </svg>
);

export default SvgIconBusOff;
