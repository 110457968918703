import React from 'react';

const SvgIconCloseChip = props => (
  <svg width={21} height={21} viewBox="0 0 21 21" {...props}>
    <path
      fill="#54575F"
      fillRule="evenodd"
      d="M5.756 2.644l4.888 4.889 4.89-4.889a2.2 2.2 0 0 1 3.111 3.112l-4.889 4.888 4.89 4.89a2.2 2.2 0 0 1-3.112 3.111l-4.89-4.889-4.888 4.89a2.2 2.2 0 0 1-3.112-3.112l4.889-4.89-4.889-4.888a2.2 2.2 0 0 1 3.112-3.112z"
    />
  </svg>
);

export default SvgIconCloseChip;
