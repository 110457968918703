import React from 'react';

const SvgIconCheck = props => (
  <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
    <path
      fill="currentColor"
      d="M8.652 13.993l-3.736-3.479a1.833 1.833 0 1 0-2.499 2.683L7.5 17.93a1.833 1.833 0 0 0 2.596-.097L20.343 6.744a1.833 1.833 0 0 0-2.693-2.488l-8.998 9.737z"
    />
  </svg>
);

export default SvgIconCheck;
