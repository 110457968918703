import React from 'react';

const SvgIconSearchFilter = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.89 17.44a2.35 2.35 0 0 1 2.147 1.367l.063.153H22v1.52h-4.9A2.344 2.344 0 0 1 14.89 22a2.35 2.35 0 0 1-2.146-1.367l-.063-.153H2v-1.52h10.681a2.344 2.344 0 0 1 2.21-1.52zM8.095 10.6a2.35 2.35 0 0 1 2.147 1.367l.062.153H22v1.52H10.303a2.344 2.344 0 0 1-2.21 1.52 2.35 2.35 0 0 1-2.146-1.367l-.063-.153H2v-1.52h3.884a2.344 2.344 0 0 1 2.21-1.52zm8.28-7.6c1.019 0 1.887.635 2.21 1.52H22v1.52h-3.416a2.344 2.344 0 0 1-2.209 1.52 2.344 2.344 0 0 1-2.21-1.52H2V4.52h12.166A2.344 2.344 0 0 1 16.375 3z"
    />
  </svg>
);

export default SvgIconSearchFilter;
