import React from 'react';

const SvgIconList = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-list_svg__a"
        d="M1.61 12.746c.888 0 1.609.728 1.609 1.627S2.498 16 1.609 16C.72 16 0 15.272 0 14.373s.72-1.627 1.61-1.627zm17.805.024c.875 0 1.585.718 1.585 1.603 0 .836-.633 1.523-1.44 1.597l-.145.006H6.735a1.594 1.594 0 0 1-1.585-1.603c0-.836.633-1.523 1.441-1.597l.145-.006h12.679zM1.609 6.366c.889 0 1.61.728 1.61 1.627 0 .898-.721 1.627-1.61 1.627A1.618 1.618 0 0 1 0 7.993c0-.899.72-1.627 1.61-1.627zm17.806.024c.875 0 1.585.718 1.585 1.603 0 .836-.633 1.522-1.44 1.596l-.145.007H6.735A1.594 1.594 0 0 1 5.15 7.993c0-.836.633-1.523 1.441-1.597l.145-.006h12.679zM1.609 0c.889 0 1.61.728 1.61 1.627s-.721 1.627-1.61 1.627C.72 3.254 0 2.526 0 1.627S.72 0 1.61 0zm17.806.01C20.29.01 21 .727 21 1.612c0 .837-.633 1.523-1.44 1.597l-.145.006H6.735A1.594 1.594 0 0 1 5.15 1.612C5.15.776 5.783.09 6.591.016L6.736.01h12.679z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(1.5 4)">
      <mask id="icon-list_svg__b" fill="#fff">
        <use xlinkHref="#icon-list_svg__a" />
      </mask>
      <use fill="currentColor" fillRule="nonzero" xlinkHref="#icon-list_svg__a" />
      <g fill="currentColor" mask="url(#icon-list_svg__b)">
        <path d="M-1.5-4h24v24h-24z" />
      </g>
    </g>
  </svg>
);

export default SvgIconList;
