import React from 'react';
import _omit from 'lodash/omit';
import { Icons } from './Icons';

interface Props {
  name: string;
  width?: number | number[];
  height?: number | number[];
  className?: string;
  title?: string;
  customId?: string;
  dataValue?: string;
  customColor?: string;
  id?: string;
}

export const Icon = ({ name, ...props }: Props) => {
  const IconComponent = Icons[name];
  if (!IconComponent) {
    return null;
  }
  return <IconComponent {..._omit(props, 'customId')} />;
};
