import React from 'react';

const SvgIconArrowLeftBold = props => (
  <svg width={7} height={11} viewBox="0 0 7 11" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      stroke="currentColor"
      d="M1.222 5.744l4.009 4.12a.445.445 0 0 0 .637 0 .471.471 0 0 0 0-.653L2.177 5.417l3.69-3.794a.472.472 0 0 0 0-.654.443.443 0 0 0-.637 0l-4.009 4.12a.476.476 0 0 0 0 .655z"
    />
  </svg>
);

export default SvgIconArrowLeftBold;
