import React from 'react';

const SvgIconLogoWhite = props => (
  <svg width={104} height={54} viewBox="0 0 104 54" {...props}>
    <g fill="#FFF" fillRule="evenodd">
      <path d="M36.163 22.815c4.573-2.641 19.066-6.183 41.59-8.051 0 0-13.02-.15-24.889.523l1.555-2.967.274 2.292c2.93-.122 5.906-.19 8.764-.225L62.139 2.873H51.656L45.17 15.872c-.93.095-1.827.198-2.683.31-12.807 1.676-15.92 4.189-15.71 7.038.181 2.47 2.404 4.16 9.972 4.506-.001 0-5.16-2.27-.586-4.91z" />
      <path d="M39.256 27.725h7.087l4.541-8.68c-3.13.643-5.926 1.3-8.28 1.958l-3.348 6.722zm15.871-9.502l1.13 9.502h8.72l-1.245-10.938c-3.015.459-5.902.94-8.605 1.436z" />
      <path
        fillRule="nonzero"
        d="M11.675 35.905H6.923L2.87 48.482h3.236l.654-2.014h4.792l.696 2.014h3.273l-3.845-12.577zM7.5 44.003l1.862-5.17 1.865 5.17H7.5zm77.844-8.098h-4.75l-4.055 12.577h3.235l.656-2.014h4.79l.697 2.014h3.276l-3.849-12.577zm-4.174 8.098l1.865-5.17 1.862 5.17H81.17z"
      />
      <path d="M24.163 35.905v8.297c0 .915-.74 1.658-1.653 1.658h-.88a1.655 1.655 0 0 1-1.653-1.658v-8.297h-3.295v8.79c0 1.108.438 2.17 1.218 2.954a4.153 4.153 0 0 0 2.943 1.223h2.715c1.104 0 2.163-.44 2.943-1.223a4.184 4.184 0 0 0 1.219-2.953v-8.79h-3.557zm14.789-.114h-10.05v3.571h3.246v9.513h3.558v-9.513h3.245z" />
      <path
        fillRule="nonzero"
        d="M46.675 35.968h-1.976c-2.606 0-4.72 2.12-4.72 4.737v3.43c.002 2.615 2.114 4.734 4.72 4.735h1.976c2.607 0 4.72-2.121 4.72-4.737v-3.43c-.001-2.616-2.114-4.735-4.72-4.735zm1.693 7.454c0 .7-.277 1.37-.77 1.865a2.622 2.622 0 0 1-1.858.773h-.105a2.623 2.623 0 0 1-1.859-.773 2.642 2.642 0 0 1-.77-1.865v-2.007a2.633 2.633 0 0 1 2.629-2.637h.106a2.633 2.633 0 0 1 2.628 2.637l-.001 2.007z"
      />
      <path d="M73.018 43.918c.003.566-.22 1.11-.617 1.512a2.12 2.12 0 0 1-1.503.63h-.107a2.633 2.633 0 0 1-2.628-2.638v-2.007a2.633 2.633 0 0 1 2.628-2.637h.107a2.12 2.12 0 0 1 1.503.629c.398.402.62.946.617 1.512h3.206v-.217a4.476 4.476 0 0 0-1.184-3.313 4.443 4.443 0 0 0-3.207-1.423h-1.98c-2.606 0-4.72 2.12-4.72 4.736v3.43c0 2.616 2.113 4.737 4.72 4.738h1.976a4.444 4.444 0 0 0 3.207-1.424 4.477 4.477 0 0 0 1.184-3.313v-.218l-3.202.003z" />
      <path
        fillRule="nonzero"
        d="M101.13 40.369v-.417a4.038 4.038 0 0 0-4.03-4.044h-7.137v12.574h3.559v-3.257h2.847a1.2 1.2 0 0 1 .85.354c.225.226.352.533.351.853v2.05h3.56V46.07a2.312 2.312 0 0 0-1.894-2.275 4.039 4.039 0 0 0 1.894-3.425zm-5.013-1.697c.98 0 1.775.798 1.775 1.782v.184c0 .984-.795 1.782-1.775 1.782h-2.595v-3.752l2.595.004z"
      />
      <path d="M57.44 38.555h1.009c1.281 0 1.751.385 1.896 1.604h3.203v-.489a3.695 3.695 0 0 0-3.689-3.702h-3.355A3.473 3.473 0 0 0 54 37.082a3.498 3.498 0 0 0-.924 2.588v.767h.018a3.359 3.359 0 0 0 3.41 3.134h1.97c1.26 0 1.874.322 1.874 1.252 0 1.222-.567 1.238-1.652 1.238h-.965a1.567 1.567 0 0 1-1.548-1.384h-3.109v.876a3.31 3.31 0 0 0 3.304 3.317h4.197a2.981 2.981 0 0 0 2.974-2.985v-1.967c0-1.92-1.652-2.777-3.689-2.777h-2.423a1.245 1.245 0 0 1-1.277-1.203v-.104a1.283 1.283 0 0 1 1.28-1.279z" />
    </g>
  </svg>
);

export default SvgIconLogoWhite;
