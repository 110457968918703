import React from 'react';

const SvgIconStore = props => (
  <svg width={32} height={25} viewBox="0 0 32 25" {...props}>
    <defs>
      <path
        id="icon-store_svg__a"
        d="M14.357 13.342v-2.47c0-.347-.234-.63-.522-.63h-2.67c-.288 0-.522.283-.522.63v2.47c0 .347.234.63.522.63h2.67c.288 0 .522-.283.522-.63zm-.522-3.874c.643 0 1.165.63 1.165 1.404v2.47c0 .774-.522 1.404-1.165 1.404h-2.67c-.643 0-1.165-.63-1.165-1.404v-2.47c0-.774.522-1.404 1.165-1.404h2.67zm5.418-2.912l.035-.214h-4.162l.034.213c.174 1.082 1.034 1.868 2.044 1.868 1.008 0 1.87-.785 2.049-1.867zM17.54 18.244V9.143h-.166c-.03 0-.06 0-.09.002l-.08.002c-.912 0-1.763-.494-2.274-1.322l-.137-.223-.138.223c-.51.828-1.36 1.322-2.27 1.322-.91 0-1.758-.494-2.27-1.322l-.136-.222-.138.221c-.516.829-1.366 1.323-2.275 1.323-.913 0-1.763-.494-2.274-1.322l-.137-.223-.138.223c-.51.828-1.361 1.322-2.274 1.322-.057 0-.11 0-.162-.004l-.178-.014v9.12h2.093v-6.044c0-.925.69-1.678 1.537-1.678H7.48c.848 0 1.538.753 1.538 1.678v6.04h8.523zm-9.182 0v-6.039c0-.526-.393-.954-.875-.954H6.037c-.482 0-.874.428-.874.954v6.04h3.195zM.698 6.55c.176 1.084 1.031 1.87 2.037 1.87h.009c1.013 0 1.874-.786 2.049-1.869l.034-.212H.665l.034.212zm8.947-.212H5.483l.035.213c.179 1.082 1.04 1.868 2.048 1.868 1.01 0 1.87-.786 2.045-1.869l.034-.212zm4.82 0h-4.158l.034.212c.174 1.083 1.034 1.869 2.044 1.869s1.87-.786 2.045-1.869l.034-.212zM3.536.728l-2.54 4.89h17.956L16.406.729H3.537zm16.37 5.252c.028.05.042.107.042.172 0 1.189-.648 2.267-1.65 2.748l-.13.062.035.144v9.5c0 .2-.149.362-.331.362h-15.8c-.183 0-.332-.162-.332-.362V8.94l-.099-.048C.644 8.411 0 7.336 0 6.152c0-.06.015-.12.045-.177L3.058.182A.322.322 0 0 1 3.344 0h13.255a.33.33 0 0 1 .287.184l3.02 5.796z"
      />
    </defs>
    <use
      fill="currentColor"
      fillRule="evenodd"
      transform="translate(6 6)"
      xlinkHref="#icon-store_svg__a"
    />
  </svg>
);

export default SvgIconStore;
