import React from 'react';

const SvgIconGarage = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-garage_svg__a"
        d="M16.347 0H3.632L0 6.25v2.5c0 .666.239 1.393.626 1.875L.644 17.5c0 1.38 1.155 2.5 2.579 2.5h13.534c1.424 0 2.618-1.12 2.618-2.5v-6.875c.438-.515.625-1.044.625-1.875v-2.5L16.347 0zm1.778 17.5c0 .69-.656 1.25-1.368 1.25H3.222c-.711 0-1.345-.56-1.345-1.25v-1.25h16.248v1.25zm0-2.5H1.877l-.002-3.75h1.228c.67 0 1.735-.599 2.185-1.515.506.89 1.45 1.515 2.351 1.515.953 0 2.001-.567 2.351-1.385.35.799 1.376 1.385 2.309 1.385.925 0 1.883-.68 2.368-1.61.567.939 1.025 1.61 2.208 1.61.183 0 1.09.03 1.25 0V15zm.625-6.25c-.009.863-1.004 1.25-1.875 1.25-1.005 0-1.91-.609-1.91-1.25h-.59c0 .63-1.12 1.25-2.083 1.25-.924 0-2-.639-2-1.25h-.605c0 .63-1.094 1.25-2.018 1.25-.643 0-1.33-.417-2.064-1.25h-.591c-.644.846-1.275 1.27-1.892 1.27-.94 0-1.872-.407-1.872-1.27V7.5h17.5v1.25zm.039-2.5H1.275l2.912-5h11.607l2.974 5h.02z"
      />
    </defs>
    <use
      fill="currentColor"
      fillRule="nonzero"
      xlinkHref="#icon-garage_svg__a"
      transform="translate(2 2)"
    />
  </svg>
);

export default SvgIconGarage;
