import React from 'react';

const SvgIconSupport = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <g fill="currentColor">
      <circle cx={10.5} cy={13.9} r={0.8} />
      <path d="M2.1 18c-.1.3 0 .6.2.9.1.1.3.2.5.2.1 0 .2 0 .3-.1l4.2-1.8c.5.2 1.1.4 1.6.5.6.9 1.4 1.7 2.4 2.3 1.1.6 2.4 1 3.7 1h.1c.9 0 1.8-.2 2.6-.5L21 22c.1 0 .2.1.3.1.2 0 .4-.1.5-.2.2-.2.3-.6.2-.9l-1.1-2.8c.8-1.1 1.2-2.3 1.2-3.6 0-2.1-1.1-4-2.9-5.3-.2-1.9-1-3.6-2.5-4.9C15 2.8 12.8 2 10.6 2c-2.3 0-4.4.8-6 2.3C2.9 5.8 2 7.8 2 9.9c0 1.6.5 3.2 1.5 4.5L2.1 18zm8.5-14.4c3.9 0 7 2.8 7 6.3 0 1.1-.3 2.2-1 3.2-.2.4-.1.8.2 1.1.4.2.8.1 1.1-.2.6-.9.9-1.8 1.1-2.8.9.9 1.4 2.1 1.4 3.3 0 1.1-.4 2.1-1.1 3-.2.2-.2.5-.1.8l.6 1.5L18 19c-.2-.1-.4-.1-.6 0-.7.3-1.5.5-2.3.5-1.5 0-3-.6-4-1.6 1.3-.1 2.6-.4 3.7-1 .4-.2.5-.7.3-1.1-.2-.4-.7-.5-1.1-.3-1 .5-2.2.8-3.4.8-1 0-2-.2-3-.6-.2-.1-.4-.1-.6 0l-2.7 1.2.9-2.3c.1-.3 0-.6-.2-.8-1-1.1-1.5-2.5-1.5-3.9.1-3.5 3.2-6.3 7.1-6.3z" />
      <path d="M8.4 8.9c.4 0 .8-.3.8-.8 0-.8.6-1.4 1.4-1.4s1.4.6 1.4 1.4c0 .5-.3 1-.8 1.2-.8.3-1.3 1.1-1.3 2v.1c0 .4.3.8.8.8s.8-.3.8-.8v-.1c0-.3.1-.5.4-.6 1.1-.5 1.8-1.5 1.8-2.7 0-1.6-1.3-2.9-2.9-2.9-1.6 0-2.9 1.3-2.9 2.9-.3.5.1.9.5.9z" />
    </g>
  </svg>
);

export default SvgIconSupport;
