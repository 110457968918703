import React from 'react';

const SvgIconHeart = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id={`icon-heart_svg__a${props.customId}`}
        d="M10.972 18.996a.788.788 0 0 1-.358-.086C3.287 14.814.667 9.222.561 8.992v-.087A6.147 6.147 0 0 1 0 6.325 6.322 6.322 0 0 1 4.014.448a6.405 6.405 0 0 1 6.987 1.532 6.417 6.417 0 0 1 8.205-.868 6.302 6.302 0 0 1 2.206 7.89h-.058c-.6 1.591-3.867 6.502-9.996 9.936a.789.789 0 0 1-.386.058zM1.914 8.445a23.429 23.429 0 0 0 9.058 8.987 22.994 22.994 0 0 0 9.086-9.054 4.768 4.768 0 0 0 .474-2.072 4.886 4.886 0 0 0-3.442-4.672 4.94 4.94 0 0 0-5.519 1.871.727.727 0 0 1-1.189 0 4.94 4.94 0 0 0-5.519-1.87 4.886 4.886 0 0 0-3.442 4.67c0 .714.159 1.418.464 2.063l.029.077z"
      />
    </defs>
    <use
      fill="currentColor"
      transform="translate(1 3)"
      xlinkHref={`#icon-heart_svg__a${props.customId}`}
    />
  </svg>
);

export default SvgIconHeart;
