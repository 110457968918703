import React from 'react';

const SvgIconStepedit = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-stepedit_svg__a"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0 2C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12zM8.531 11c.354 0 .628.1.82.3.193.201.29.453.29.755 0 .297-.097.543-.29.738-.192.195-.466.293-.82.293-.338 0-.607-.096-.804-.29a.99.99 0 0 1-.297-.741c0-.302.096-.554.289-.754.192-.2.463-.301.812-.301zm3.578 0c.355 0 .628.1.82.3.193.201.29.453.29.755 0 .297-.097.543-.29.738-.192.195-.465.293-.82.293-.338 0-.606-.096-.804-.29a.99.99 0 0 1-.297-.741c0-.302.096-.554.289-.754.193-.2.463-.301.812-.301zm3.446 0c.354 0 .627.1.82.3.193.201.29.453.29.755 0 .297-.097.543-.29.738-.193.195-.466.293-.82.293-.339 0-.607-.096-.805-.29a.99.99 0 0 1-.297-.741c0-.302.096-.554.29-.754.192-.2.463-.301.812-.301z"
      />
    </defs>
    <use fill="#54575F" xlinkHref="#icon-stepedit_svg__a" />
  </svg>
);

export default SvgIconStepedit;
