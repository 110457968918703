import React from 'react';

const SvgIconTruckOff = props => (
  <svg width={179} height={86} viewBox="0 0 179 86" {...props}>
    <defs>
      <linearGradient
        id="icon-truck-off_svg__a"
        x1="21.624%"
        x2="69.358%"
        y1="21.722%"
        y2="69.312%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-truck-off_svg__b"
        x1="65.934%"
        x2="48.107%"
        y1="69.655%"
        y2="42.527%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-truck-off_svg__c"
        x1="59.204%"
        x2="43.532%"
        y1="77.425%"
        y2="40.472%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-truck-off_svg__d" x1="98.388%" x2="45.141%" y1="84.84%" y2="27.386%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-truck-off_svg__e"
        x1="55.676%"
        x2="53.202%"
        y1="105.726%"
        y2="81.359%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-truck-off_svg__f"
        x1="21.715%"
        x2="75.949%"
        y1="31.539%"
        y2="66.149%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-truck-off_svg__g"
        x1="79.684%"
        x2="39.067%"
        y1="86.549%"
        y2="41.193%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-truck-off_svg__h"
        x1="6.855%"
        x2="80.707%"
        y1="10.286%"
        y2="120.411%"
      >
        <stop offset="0%" stopColor="#DCE6E6" />
        <stop offset="19.25%" stopColor="#CED8D8" />
        <stop offset="55.37%" stopColor="#AAB4B4" />
        <stop offset="100%" stopColor="#768080" />
      </linearGradient>
      <linearGradient
        id="icon-truck-off_svg__i"
        x1="-4.974%"
        x2="76.958%"
        y1="-69.177%"
        y2="84.388%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-truck-off_svg__j"
        x1="18.416%"
        x2="55.004%"
        y1="22.557%"
        y2="60.437%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-truck-off_svg__k" x1="25.023%" x2="64.315%" y1="45.559%" y2="52.02%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-truck-off_svg__l"
        x1="17.172%"
        x2="62.618%"
        y1="35.177%"
        y2="56.454%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-truck-off_svg__m"
        x1="-26.648%"
        x2="69.93%"
        y1="-53.88%"
        y2="92.153%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-truck-off_svg__n" x1="4.9%" x2="64.159%" y1="3.224%" y2="117.995%">
        <stop offset="0%" stopColor="#DCE6E6" />
        <stop offset="19.25%" stopColor="#CED8D8" />
        <stop offset="55.37%" stopColor="#AAB4B4" />
        <stop offset="100%" stopColor="#768080" />
      </linearGradient>
      <linearGradient
        id="icon-truck-off_svg__o"
        x1="25.868%"
        x2="62.471%"
        y1="41.572%"
        y2="54.275%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-truck-off_svg__p" x1="7.993%" x2="70.298%" y1="31.559%" y2="58.933%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-truck-off_svg__q"
        x1="15.644%"
        x2="52.109%"
        y1="21.585%"
        y2="66.439%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-truck-off_svg__r"
        x1="26.529%"
        x2="83.061%"
        y1="28.318%"
        y2="80.031%"
      >
        <stop offset="0%" stopColor="#DCE6E6" />
        <stop offset="19.25%" stopColor="#CED8D8" />
        <stop offset="55.37%" stopColor="#AAB4B4" />
        <stop offset="100%" stopColor="#768080" />
      </linearGradient>
      <linearGradient
        id="icon-truck-off_svg__s"
        x1="13.389%"
        x2="80.589%"
        y1="-13.422%"
        y2="102.97%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-truck-off_svg__t" x1="25.008%" x2="75.022%" y1="6.695%" y2="93.323%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient
        id="icon-truck-off_svg__u"
        x1="1.399%"
        x2="82.78%"
        y1="-34.197%"
        y2="106.759%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-truck-off_svg__v" x1="25.014%" x2="75.029%" y1="6.695%" y2="93.323%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient
        id="icon-truck-off_svg__w"
        x1="31.348%"
        x2="73.057%"
        y1="33.662%"
        y2="63.775%"
      >
        <stop offset="0%" stopColor="#DCE6E6" />
        <stop offset="19.25%" stopColor="#CED8D8" />
        <stop offset="55.37%" stopColor="#AAB4B4" />
        <stop offset="100%" stopColor="#768080" />
      </linearGradient>
      <linearGradient id="icon-truck-off_svg__x" x1="30.426%" x2="64.344%" y1="9.778%" y2="80.651%">
        <stop offset="0%" stopColor="#DCE6E6" />
        <stop offset="19.25%" stopColor="#CED8D8" />
        <stop offset="55.37%" stopColor="#AAB4B4" />
        <stop offset="100%" stopColor="#768080" />
      </linearGradient>
      <linearGradient id="icon-truck-off_svg__y" x1="18.047%" x2="81.061%" y1="8.588%" y2="91.817%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-truck-off_svg__z" x1="43.707%" x2="55.714%" y1="8.721%" y2="90.858%">
        <stop offset="0%" stopColor="#DCE6E6" />
        <stop offset="19.25%" stopColor="#CED8D8" />
        <stop offset="55.37%" stopColor="#AAB4B4" />
        <stop offset="100%" stopColor="#768080" />
      </linearGradient>
      <linearGradient id="icon-truck-off_svg__A" x1="48.529%" x2="51.666%" y1="9.782%" y2="90.331%">
        <stop offset="0%" stopColor="#DCE6E6" />
        <stop offset="19.25%" stopColor="#CED8D8" />
        <stop offset="55.37%" stopColor="#AAB4B4" />
        <stop offset="100%" stopColor="#768080" />
      </linearGradient>
      <linearGradient
        id="icon-truck-off_svg__B"
        x1="48.105%"
        x2="52.033%"
        y1="12.121%"
        y2="87.548%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-truck-off_svg__C"
        x1="19.213%"
        x2="57.888%"
        y1="42.312%"
        y2="53.143%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-truck-off_svg__D" x1="-3.758%" x2="103.715%" y1="50.06%" y2="50.06%">
        <stop offset="0%" stopOpacity={0} />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient id="icon-truck-off_svg__E" x1="16.389%" x2="76.353%" y1="6.864%" y2="98.902%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient id="icon-truck-off_svg__F" x1="8.362%" x2="66.75%" y1="-47.427%" y2="98.551%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-truck-off_svg__G" x1="35.663%" x2="89.54%" y1="15.263%" y2="72.36%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M0-8h179v94H0z" />
      <g opacity={0.6}>
        <path
          fill="url(#icon-truck-off_svg__a)"
          fillRule="nonzero"
          d="M15.869 64v1.397L6 67.536v.977h.714l.206 3.433-.92.363V73h.88L20 69.03V64z"
        />
        <path
          fill="url(#icon-truck-off_svg__b)"
          fillRule="nonzero"
          d="M10 60.664v2.191h2.685s3.956-.437 4.683.955h2.1v3.222c0 .707 1.004 1.88 1.004 1.88s-.698-.08-.698.708c0 .782-.153 2.042 1.164 2.508 1.317.472 5.534.943 8.98.863 3.445-.08 7.396-1.726 7.589-2.589.193-.862.193-2.456.193-2.456h1.163s-.658 1.283-.579 1.634c.08.35.908.333 1.924.19 0 0 3.332-4.878 3.786-9.037.454-4.16-26.853-5.707-33.994-.07z"
        />
        <path
          fill="url(#icon-truck-off_svg__c)"
          fillRule="nonzero"
          d="M42 71.94c2.492-2.166 4.797-2.038 4.797-2.038L65.063 77C67.456 75.92 69 73.446 69 73.446s.06-7.053-2.437-9.813c-2.498-2.76-14.991-5.004-19.606-2.637C42.337 63.364 42 71.94 42 71.94z"
        />
        <path
          fill="url(#icon-truck-off_svg__d)"
          fillRule="nonzero"
          d="M70.184 71s1.463-1.394 4.067-1.729c2.604-.34 4.749.295 4.749.295v-7.26L64 62s2.871 1.99 3.74 3.162C68.983 66.857 70.183 71 70.183 71z"
        />
        <path
          fill="url(#icon-truck-off_svg__e)"
          fillRule="nonzero"
          d="M122.573 3s-1.27.869-2.257 2.829l-.588 56.994L92 62.263v9.246s1.015-.103 3.433-.109v-2.863c0-1.034.787-1.88 1.785-1.931 1.903-.086 5.125-.206 8.69-.206 3.566 0 6.788.12 8.69.206.999.045 1.786.897 1.786 1.931v4.086c1.198.114 2.451.24 3.76.377l1.425-50.109.505.018.2-7.006.726-2.469V4.297L122.573 3z"
        />
        <path
          fill="url(#icon-truck-off_svg__f)"
          fillRule="nonzero"
          d="M117 74.344c0 .566-.471 1.037-1.094 1.1-1.92.194-6.07.556-9.906.556-3.486 0-7.234-.393-9.33-.655-.96-.12-1.67-.855-1.67-1.73V68.96c0-.948.826-1.724 1.874-1.771 1.996-.079 5.378-.189 9.12-.189 3.743 0 7.124.11 9.12.189 1.048.042 1.874.823 1.874 1.771v5.384H117z"
        />
        <path
          fill="url(#icon-truck-off_svg__g)"
          fillRule="nonzero"
          d="M170.079 74.196s-.424-.757-2.107-.898l-20.264 3.574s-5.354.486-7.215-.435c-1.86-.92-3.476-2.959-3.476-2.959s-.921-11.372 10.65-15.093c11.573-3.72 22.52.588 23.162 6.601.647 6.02-.75 9.21-.75 9.21z"
        />
        <path
          fill="url(#icon-truck-off_svg__h)"
          fillRule="nonzero"
          d="M177.785 63.74v-.94a.37.37 0 0 0-.37-.367h-.225v-.637l.399-.361a.594.594 0 0 0 .196-.442l-.006-2.839c0-.2-.115-.384-.294-.482l-.295-.154v-1.125s.491-.258.295-.636c-.197-.379-11.242-9.952-18.344-11.81 0 0-13.13-15.131-16.537-17.953-3.412-2.822-8.413-2.598-22.109-2.994L119 73.744s18.702.252 20.555.333c1.854.074 3.551.923 3.551.923h2.316s1.004-2.38 1.426-4.606c.427-2.225 1.547-5.948 5.058-7.749 3.516-1.8 10.543-1.227 12.125-.499 1.582.729 4.672 2.495 5.29 6.826.617 4.336.461 5.753.461 5.753s3.401-.04 3.823-.803c.427-.769 1.004-2.644 2.546-3.797 1.547-1.153 2.783-1.842 2.818-2.8.034-.957.248-3.487-1.184-3.584z"
        />
        <path
          fill="url(#icon-truck-off_svg__i)"
          fillRule="nonzero"
          d="M174.043 73.19c.392-.769.923-2.646 2.34-3.8 1.421-1.154 2.557-1.843 2.589-2.802.037-.959.212-3.49-1.099-3.588l-9.873.465c.966 1.074 1.82 2.6 2.106 4.777.568 4.34.425 5.758.425 5.758s3.125-.04 3.512-.81z"
        />
        <path
          fill="url(#icon-truck-off_svg__j)"
          fillRule="nonzero"
          d="M167.26 64.162l2.74-.121s-2.484-3.79-6.897-4.625-9.66-.443-12.584 1.446c-2.923 1.894-4.744 5.453-5.24 8.742-.498 3.282-.578 4.33-1.228 4.452-.651.12-1.051 0-1.051 0v.685c.371.15.6.259.6.259h2.289s.993-2.39 1.41-4.624c.423-2.235 1.53-5.973 5.002-7.78 3.477-1.81 10.425-1.233 11.99-.502.782.369 1.935.996 2.968 2.068z"
        />
        <path
          fill="url(#icon-truck-off_svg__k)"
          fillRule="nonzero"
          d="M178 57h-8.994s-.101.699.508 1H178v-1z"
        />
        <path
          fill="url(#icon-truck-off_svg__l)"
          fillRule="nonzero"
          d="M178 62h-5s.202-1 1.065-1h1.345l2.59.28V62z"
        />
        <path
          fill="url(#icon-truck-off_svg__m)"
          fillRule="nonzero"
          d="M127.74 54H124v-5h3.74c1.251 0 2.26 1.063 2.26 2.367v.26c0 1.316-1.009 2.373-2.26 2.373z"
        />
        <path
          fill="url(#icon-truck-off_svg__n)"
          fillRule="nonzero"
          d="M144 28s-.572-3.457-3.457-4.483c0 0-6.383-12.522-18.045-19.517l.447 1.31v9.275l-.741 2.505-.204 7.111s10.253.157 12.992.476c2.738.319 6.264.452 9.008 3.323z"
        />
        <path
          fill="url(#icon-truck-off_svg__o)"
          fillRule="nonzero"
          d="M148 62.172L121.126 62 121 66.675l24.665.325c0-.006.662-2.74 2.335-4.828z"
        />
        <path
          fill="url(#icon-truck-off_svg__p)"
          fillRule="nonzero"
          d="M173 53s-1.22-1.63-2.22-2H165l1.532 2H173z"
        />
        <path
          fill="url(#icon-truck-off_svg__q)"
          fillRule="nonzero"
          d="M154.582 41.291c-1.273-1.62-7.8-10.327-11.608-12.853-2.67-1.773-9.51-2.289-13.368-2.436-1.48-.057-2.686 1.184-2.602 2.685.179 3.286.497 8.764.782 12.106a2.77 2.77 0 0 0 2.54 2.543c2.413.176 6.076.493 7.605.867 2.38.584 6.718 2.459 6.718 2.459s10.497.492 11.15.289c.687-.221 1.206-.76 1.201-1.626-.006-.708-1.15-2.413-2.418-4.034zm-10.916 3.937s-3.82-1.654-5.913-2.164c-1.485-.362-5.249-.662-7.31-.804a1.698 1.698 0 0 1-1.563-1.546c-.29-3.1-.63-9.212-.787-12.112a1.49 1.49 0 0 1 1.519-1.58c3.143.09 10.028.475 12.58 2.17 2.133 1.415 5.232 5.03 7.493 7.84l-.2 8.429c-2.72-.09-5.819-.233-5.819-.233z"
        />
        <path
          fill="url(#icon-truck-off_svg__r)"
          fillRule="nonzero"
          d="M3 60.724L119.406 63 120 2.493a.523.523 0 0 0-.52-.524L4.719 1 3 60.724z"
        />
        <circle cx={159} cy={76} r={10} fill="url(#icon-truck-off_svg__s)" fillRule="nonzero" />
        <circle cx={159} cy={76} r={6} fill="url(#icon-truck-off_svg__t)" fillRule="nonzero" />
        <circle cx={54} cy={76} r={10} fill="url(#icon-truck-off_svg__u)" fillRule="nonzero" />
        <circle cx={55} cy={76} r={6} fill="url(#icon-truck-off_svg__v)" fillRule="nonzero" />
        <path
          fill="url(#icon-truck-off_svg__w)"
          fillRule="nonzero"
          d="M41 62l1.161 1.956 8.665.302s1.466-1.154 4.685-1.132c3.219.023 5.006 1.368 5.006 1.368h2.491c1.066 0 2.019.638 2.419 1.619L69.057 75l1.63-.353L72 73.263s-1.59-6.595-3.692-8.372c-2.103-1.776-3.22-2.42-3.22-2.42L41 62z"
        />
        <path
          fill="url(#icon-truck-off_svg__x)"
          fillRule="nonzero"
          d="M46 64.13s-3.433 3.353-5.418 9.87H39s.269-5.857 2.71-10l4.29.13z"
        />
        <path fill="url(#icon-truck-off_svg__y)" fillRule="nonzero" d="M92 63.204L81 63v9h11z" />
        <path
          fill="url(#icon-truck-off_svg__z)"
          fillRule="nonzero"
          d="M114 67s.459 5.545.287 9l1.713-.157s.019-7.015-.433-8.742L114 67z"
        />
        <path
          fill="url(#icon-truck-off_svg__A)"
          fillRule="nonzero"
          d="M99 67s-.22 4.907-.071 9l-.832-.167s-.264-4.934.078-8.783L99 67z"
        />
        <path
          fill="url(#icon-truck-off_svg__B)"
          fillRule="nonzero"
          d="M3.713 0H2.488l1.176 1.641-.171 5.31h-.687v-.437h-.643l.012 3.606h.668l.006-.396h.558L2.83 27.869h-.772v-.436h-.644v3.606h.668v-.397h.662l-.582 18.36H1.31v-.43H.668v3.651h.668v-.441h.766l-.196 6.424H0L1.844 60h1.287L5 0z"
        />
        <path
          fill="url(#icon-truck-off_svg__C)"
          fillRule="nonzero"
          d="M71 73l11-.873h-2.171V71l-8.796.68.302 1.002z"
        />
        <path
          stroke="url(#icon-truck-off_svg__D)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M81 64.31l11 .38"
          opacity={0.5}
        />
        <path
          fill="url(#icon-truck-off_svg__E)"
          fillRule="nonzero"
          d="M144.868 44.76s-3.891-1.704-6.025-2.23c-1.513-.373-5.347-.682-7.446-.828-.842-.058-1.513-.735-1.593-1.593-.296-3.193-.643-9.491-.802-12.48-.045-.905.666-1.652 1.547-1.628 3.203.093 10.217.49 12.817 2.235 2.173 1.46 5.33 5.183 7.634 8.079L150.795 45c-2.77-.093-5.927-.24-5.927-.24z"
        />
        <path
          fill="url(#icon-truck-off_svg__F)"
          fillRule="nonzero"
          d="M152.011 45.998l-3.925-.266c-.47-.03-.84-.398-.853-.85l-.232-6.97c-.02-.502.395-.912.915-.912 1.28 0 3.462.066 3.9.573.597.676 1.242 3.006 1.18 7.556-.007.507-.458.905-.985.869z"
        />
        <path
          fill="url(#icon-truck-off_svg__G)"
          fillRule="nonzero"
          d="M174.7 61.802l2.698.198.403-.338a.537.537 0 0 0 .199-.413l-.006-2.654a.514.514 0 0 0-.298-.45l-.298-.145H172l2.7 3.802z"
        />
      </g>
    </g>
  </svg>
);

export default SvgIconTruckOff;
