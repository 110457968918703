import React from 'react';

const SvgIconFunilaria = props => (
  <svg width={19} height={14.25} viewBox="0 0 40 30" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="#54575F" fillRule="nonzero">
        <path d="M39.56 28.833h-5.612a4.49 4.49 0 001.783-3.586c0-2.467-1.974-4.48-4.406-4.48-2.427 0-4.407 2.006-4.407 4.48a4.47 4.47 0 001.784 3.586h-17.05a4.49 4.49 0 001.784-3.586c0-2.467-1.974-4.48-4.406-4.48-2.433 0-4.407 2.006-4.407 4.48a4.47 4.47 0 001.784 3.586H1.089c-.243 0-.44.2-.44.447s.197.447.44.447h38.478A.433.433 0 0040 29.28a.443.443 0 00-.44-.447zm-8.235-.006c-1.948 0-3.528-1.614-3.528-3.587s1.587-3.587 3.528-3.587c1.94 0 3.527 1.614 3.527 3.587s-1.58 3.587-3.527 3.587zm-22.295 0c-1.948 0-3.528-1.614-3.528-3.587s1.587-3.587 3.528-3.587c1.947 0 3.527 1.614 3.527 3.587s-1.58 3.587-3.527 3.587z" />
        <path d="M2.177 19.167v2.906a1.643 1.643 0 00-.866.567c-.314.4-.439.92-.334 1.42l.492 2.347c.098.486.524.84 1.023.84h1.344c.243 0 .44-.2.44-.447a.443.443 0 00-.44-.447H2.505a.182.182 0 01-.17-.14l-.499-2.333A.832.832 0 012 23.2c.15-.193.374-.3.623-.3.243 0 .44-.2.44-.447v-3.42a.429.429 0 00-.066-.226L1.718 16.64c-.046-.073-.02-.14-.007-.173.027-.04.073-.094.151-.094h1.974a.433.433 0 00.354-.186L7.016 12.2a2.612 2.612 0 012.125-1.107h12.315c.59 0 1.174.214 1.646.594l.091.073h-5.58c-.013 0-.02-.007-.033-.007-.013 0-.02.007-.032.007h-5.935c-.813 0-1.547.52-1.836 1.293l-.682 1.86c-.23.614-.15 1.3.223 1.84.36.54.964.86 1.613.86h19.482l4.97.88c.676.12 1.26.494 1.647 1.027h-.617c-.367 0-.702.187-.892.507-.19.313-.21.7-.046 1.04l.78 1.58a.426.426 0 00.388.246h1.587l.183.094a.812.812 0 01.4.966l-.747 2.28a.172.172 0 01-.164.12h-1.397c-.243 0-.44.2-.44.447s.197.447.44.447h1.397c.446 0 .852-.3.977-.72l.747-2.28a1.702 1.702 0 00-.833-2.04l-.065-.034-.367-1.786a3.687 3.687 0 00-.177-.58c-.014-.027-.033-.054-.046-.08a3.455 3.455 0 00-2.604-2.1l-4.852-.86-5.974-4.907c-.006-.007-.013-.007-.026-.013L23.646 11a3.433 3.433 0 00-2.19-.793H9.134c-1.114 0-2.17.553-2.826 1.473l-2.701 3.813H1.856c-.387 0-.722.2-.912.54a1.1 1.1 0 00.013 1.074l1.22 2.06zm7.85-2.907a1.146 1.146 0 01-.125-1.027l.682-1.86c.15-.393.505-.673.911-.72h5.646v4.08h-6.223c-.354 0-.682-.173-.892-.473zm17.894-.607l1.318 1.087H18.013v-4.08h6.256l3.652 2.993zm9.574 4.92l.302 1.434h-.879l-.669-1.34c-.04-.08-.013-.134.007-.174.04-.06.085-.086.15-.086h1.043c.02.06.04.106.046.166z" />
        <path d="M13.784 26.793c0 .247.196.447.439.447h11.908c.243 0 .44-.2.44-.447a.443.443 0 00-.44-.446H14.223a.452.452 0 00-.44.446zm17.541-4.16c-1.417 0-2.57 1.174-2.57 2.614s1.153 2.613 2.57 2.613c1.416 0 2.57-1.173 2.57-2.613.007-1.44-1.154-2.614-2.57-2.614zm1.698 2.607c0 .953-.76 1.727-1.698 1.727s-1.699-.774-1.699-1.727c0-.953.76-1.727 1.699-1.727.937 0 1.698.774 1.698 1.727zM9.03 22.633c-1.417 0-2.571 1.174-2.571 2.614s1.154 2.613 2.57 2.613c1.417 0 2.571-1.173 2.571-2.613.007-1.44-1.148-2.614-2.57-2.614zm1.704 2.607c0 .953-.76 1.727-1.698 1.727s-1.698-.774-1.698-1.727c0-.953.76-1.727 1.698-1.727s1.698.774 1.698 1.727zm18.702-6.82v1.827c0 .246.197.446.44.446.242 0 .439-.2.439-.446V18.42a.443.443 0 00-.44-.447c-.242 0-.439.2-.439.447zm-11.856-.453c-.242 0-.439.2-.439.446V21.9l-2.511-.507a.434.434 0 00-.512.347.45.45 0 00.341.52l2.675.547v2.746c0 .247.197.447.44.447.242 0 .44-.2.44-.447V22.98l1.016.207a.29.29 0 00.098.013c.065 0 .144-.007.197-.053l3.042-1.547V24c0 .14.066.273.177.36.072.06.158.087.256.087a.53.53 0 00.131-.02l2.748-.934a.445.445 0 00.282-.56.435.435 0 00-.551-.286l-2.177.74v-2.494c0-.013-.007-.026-.007-.04 0-.013-.006-.02-.006-.033a.64.64 0 00-.033-.12c0-.007 0-.013-.007-.013-.006-.02-.026-.027-.039-.047-.013-.02-.02-.04-.04-.06l-1.52-1.547a.433.433 0 00-.309-.126.444.444 0 00-.308.126.448.448 0 000 .627l1.088 1.107-2.99 1.52-1.049-.214v-3.66a.442.442 0 00-.433-.446zM6.656 6.747c.911 0 1.652-.754 1.652-1.68 0-.927-.74-1.68-1.652-1.68-.912 0-1.653.753-1.653 1.68 0 .926.735 1.68 1.653 1.68zm-.794-1.68c0-.44.354-.8.787-.8.433 0 .787.36.787.8 0 .44-.354.8-.787.8a.796.796 0 01-.787-.8z" />
        <path d="M6.65 8.307c.825 0 1.632-.34 2.222-.934h15.653a1.1 1.1 0 01.95.554l.512.92c.347.62.997 1.006 1.705 1.006h3.115c.708 0 1.363-.386 1.704-1.006l.958-1.707a.47.47 0 00-.007-.447.444.444 0 00-.373-.213h-3.876l-.695-1.42.695-1.42h3.876a.437.437 0 00.38-.22.474.474 0 000-.44l-.958-1.7A1.956 1.956 0 0030.807.273h-3.115c-.702 0-1.351.387-1.705 1.007l-.512.92c-.19.34-.557.553-.95.553H8.872A3.178 3.178 0 006.65 1.82c-1.757 0-3.187 1.453-3.187 3.24 0 1.787 1.43 3.247 3.187 3.247zm0-5.587c.655 0 1.265.28 1.717.787a.46.46 0 00.322.14h15.836c.701 0 1.35-.387 1.705-1.007l.511-.92c.19-.34.557-.553.95-.553h3.116a1.1 1.1 0 01.95.553l.584 1.04h-3.39a.443.443 0 00-.394.247l-.918 1.86a.431.431 0 000 .4l.918 1.86a.429.429 0 00.394.246h3.39l-.584 1.04c-.19.34-.557.554-.95.554h-3.115a1.1 1.1 0 01-.951-.554l-.511-.926a1.956 1.956 0 00-1.705-1.007H8.689a.44.44 0 00-.328.153 2.26 2.26 0 01-1.712.787c-1.272 0-2.315-1.053-2.315-2.353 0-1.3 1.036-2.347 2.315-2.347z" />
        <path d="M10.925 5.507H23.14c.243 0 .44-.2.44-.447a.443.443 0 00-.44-.447H10.925a.447.447 0 000 .893z" />
      </g>
      <path d="M.656-5H40v40H.656z" />
    </g>
  </svg>
);

export default SvgIconFunilaria;
