import React from 'react';

const SvgIconBuy2 = props => (
  <svg width={114} height={111} viewBox="0 0 114 111" {...props}>
    <defs>
      <linearGradient id="icon-buy-2_svg__a" x1="26.626%" x2="78.316%" y1="10.48%" y2="85.131%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__b" x1="29.5%" x2="63.762%" y1="21.503%" y2="104.102%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__c" x1="32.443%" x2="71.268%" y1="10.48%" y2="85.131%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__d" x1="49.388%" x2="50.411%" y1="-7.028%" y2="158.269%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__e" x1="47.78%" x2="51.491%" y1="-7.028%" y2="158.269%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__f" x1="26.626%" x2="78.316%" y1="22.555%" y2="74.396%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__g" x1="47.117%" x2="51.935%" y1="-7.028%" y2="158.269%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__h" x1="29.5%" x2="63.762%" y1="41.98%" y2="65.225%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__i" x1="67.225%" x2="41.495%" y1="87.854%" y2="52.079%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#E35563" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__j" x1="32.827%" x2="70.804%" y1="10.48%" y2="85.131%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__k" x1="32.745%" x2="58.204%" y1="9.241%" y2="94.889%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__l" x1="19.324%" x2="64.585%" y1="16.064%" y2="87.375%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__m" x1="19.324%" x2="64.585%" y1="31.885%" y2="69.951%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__n" x1="48.485%" x2="50.72%" y1="9.241%" y2="94.889%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__o" x1="19.324%" x2="64.585%" y1="38.576%" y2="62.581%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__p" x1="47.096%" x2="51.381%" y1="9.241%" y2="94.889%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__q" x1="19.324%" x2="64.585%" y1="38.406%" y2="62.768%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__r" x1="36.366%" x2="56.482%" y1="9.241%" y2="94.889%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__s" x1="38.957%" x2="55.251%" y1="9.241%" y2="94.889%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__t" x1="19.324%" x2="64.585%" y1="29.205%" y2="72.903%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__u" x1="19.324%" x2="64.585%" y1="49.035%" y2="51.062%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__v" x1="19.324%" x2="64.585%" y1="49.898%" y2="50.112%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__w" x1="29.066%" x2="59.953%" y1="9.241%" y2="94.889%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__x" x1="48.986%" x2="50.482%" y1="9.241%" y2="94.889%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__y" x1="48.416%" x2="50.753%" y1="9.241%" y2="94.889%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__z" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__A" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__B" x1="41.106%" x2="54.229%" y1="9.241%" y2="94.889%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__C" x1="43.223%" x2="58.21%" y1="10.48%" y2="85.131%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__D" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__E" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__F" x1="38.732%" x2="55.357%" y1="9.241%" y2="94.889%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__G" x1="19.324%" x2="64.585%" y1="41.318%" y2="59.562%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__H" x1="19.324%" x2="64.585%" y1="46.673%" y2="53.664%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__I" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__J" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-buy-2_svg__K" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#163F59"
        d="M25.674 107.168c.127.188.032.683.032.683s-2.228.312-2.944.108c-.185-.053-.5-.246-.796-.452-.05-.034-.139-.243-.186-.277-.09-.065-.136.045-.213-.015-.197-.152-.337-.27-.34-.285-.192-1.243.07-1.746.07-1.746s1.9-.775 2.254-.242c.832 1.257 1.846 1.815 2.123 2.226m12.506-1.708l.337 1.913h.888s.098-.272.251-.272c.127 0 .292.272.467.272h4.573s.08-.403-.018-.542c-.052-.07-.353-.194-.552-.256-.93-.283-3.587-1.168-4.05-2.13-.56-1.172-1.896 1.016-1.896 1.016"
      />
      <path
        fill="url(#icon-buy-2_svg__a)"
        d="M16.71 62.853l-2.062-1.114-.244-.248.286-.444.455.054-.082-1.302 2.162-.371 1.107-.504-1.023 3.532z"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__b)"
        d="M31.072 28.153s.42.782.904 2.323c2.836 3.339 4.763 4.757 5.091 5.208.342.468.997.438 1.082.432l.01-.001.367-.419 3.376.758-1.567 7.107-2.113 1.36s-.84 1.23-4.894-1.72c-.174 3.38-.73 7.318-1.908 11.795l-11.35-.254-4.808-16.283-3.788 10.669-.873.34.485.753-4.973.847-2.185-3.713 5.809-15.412s2.246-3.24 7.25-3.778c.343-.037.611.011.814.13.098-.01.2-.014.304-.017 6.024-.125 12.967-.125 12.967-.125z"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__c)"
        d="M54.506 24.225c.43.016.26.362.26.362l-2.537 2.2 2.854-1.348s.392-.108.516.078c.116.175 0 .239 0 .239l-.597.482a.942.942 0 0 1 .334-.047c.331.032.315.332.315.332l-2.716 1.384-.232.188 1.546-.519s.388-.093.517.08c.14.192.182.24.182.24l-4.754 2.4c.053.26.105.53.154.798.383 2.09-.897 7.978-4.636 10.345-3.74 2.367-5.337 2.947-6.78 1.983-1.447-.965 2.757-6.857 2.757-6.857h.02c.377-.013 6.11-.308 7.34-6.097l-.172-.26s-.678-2.282-.328-2.852c.35-.569 1.764-2.56 1.764-2.56l.403-.475.012.004c.091.032.649.287-.241 1.512-.964 1.329-.163.955-.163.955l1.3-.847 1.668-.907.693-.503s.372-.315.52-.31z"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__d)"
        d="M31.155 54.246l.271 24.71-1.322.703 1.322-.438-.069 26.765s-1.073 1.58-1.86.1c-1.814-3.405-4.513-11.818-4.605-15.648-.123-5.148.675-8.828.775-9.265-.844-1.742-2.997-6.724-3.322-12.754-.173-3.196-.259-10.362 1.595-12.799 2.517-3.302 7.215-1.374 7.215-1.374z"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__e)"
        d="M29.462 54.444l-.851 8.585-2.32 9.312-2.47 8.702-8.815 24.157-2.566.21s.323-18.134 3.454-23.422c1.658-2.799 1.656-9.008 2.421-15.696.137-1.204-.54-3.926-.432-5.025.432-4.333 2.654-7.58 2.654-7.58l8.925.757z"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__f)"
        d="M10.558 49.758l7.12 9.002s-.258 1.312-.829.615c-.068-.082.275-.347-.157-.594-1.75-.992-5.856-2.518-7.38-4.045-1.997-2.007-3.42-4.369-3.42-4.369l4.666-.609z"
        transform="translate(8.718)"
      />
      <path
        fill="#F087A8"
        d="M33.597 30.117s5.122 9.286 3.668 19.563c-.115.809-1.031 1.388-1.031 1.388l-1.26-1.156s.1-11.632-1.377-19.795"
      />
      <path
        fill="#B0BFDE"
        d="M33.597 29.987l-.302 2.094-3.626-3.403v-.525zm.792-1.179l.517 2.618-1.31-1.32z"
      />
      <path
        fill="url(#icon-buy-2_svg__g)"
        d="M36.01 37.684l.796-1.678.354-.651c.331.335.814.28.814.28l-.794 1.121-.73 1.52-.409 2.316-.032-2.908z"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__h)"
        d="M5.892 48.449s3.367.575 5.134.82l-.378.137.482.698-1.709.258-2.489.051-.39-.84.728-.165-1.378-.959z"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__i)"
        d="M20.296 25.534c.134.665.366 1.697.624 2.299.4.938 3.873 2.146 4.407 2.28.534.134-.134-2.82-.134-3.488 0-.514-.315-.353.065-1.091h-4.962z"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__j)"
        d="M25.163 17.852l.497 3.182.497-.08a.345.345 0 0 1 .392.284l.29 1.782a.338.338 0 0 1-.284.389l-.512.08.133.853a.983.983 0 0 1-.794 1.131l-4.27.703c-.522.087-1.014-.28-1.1-.817l-.181-1.164-.156.025a.343.343 0 0 1-.394-.28l-.29-1.785a.341.341 0 0 1 .284-.39l.172-.027-.448-2.871a.984.984 0 0 1 .796-1.131l4.268-.7a.962.962 0 0 1 1.1.816z"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__k)"
        d="M25.99 18.352c.028-.234-.333-1.044-.959-1.28-.626-.235-1.003.443-1.003.443s.996.352 1.072.531c.08.18.264 1.595.264 1.595s.574-.826.627-1.289z"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__l)"
        d="M17.677 19.058c.006-.746.006-1.261.915-1.485 1.363-.332 1.427-.053 2.032-.56.606-.506 1.653-1.662 2.051-1.823.667-.27 1.79-.214 2.039 1.029.186.918.83 1.05.82 1.087-.036.12-.27.265-.675.543-1.16.8-1.896 1.037-2.902.943-1.008-.093-2.725-.404-2.82.314-.096.717.515 2.783.46 2.957-.058.175-.635.208-.825.11 0 0-1.1-2.37-1.095-3.115z"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__m)"
        d="M20.195 26.29c.124.763.71 2.388 5.116 1.695 2.608-.408 2.205-2.268 2.103-2.885-.076-.468-1.17-1.71-1.49-1.936-.01-.007.182.958.182.958s-.399-.923-1.638-.728c-2 .312-2.267 1.529-3.152 1.648-.682.09-1.368-.912-1.675-2.127l.554 3.374z"
        transform="translate(8.718)"
      />
      <path
        fill="#E9D6B8"
        d="M32.68 24.876l1.305-.18c.173-.024.29-.164.262-.314l-.029-.157-1.93.268.03.156c.027.149.19.25.363.227"
      />
      <path
        fill="url(#icon-buy-2_svg__n)"
        d="M92.146 87.077c.623 0 1.606-.137 2.468-.862 1.24-1.042 1.776-2.896 1.595-5.508-.12-1.763-.839-3.548-1.6-5.439-1.28-3.18-2.73-6.784-1.971-11.55l-1.41-.21c-.815 5.132.776 9.083 2.051 12.26.752 1.865 1.401 3.478 1.508 5.03.146 2.119-.232 3.629-1.096 4.366-.818.7-1.841.52-1.885.512l-.286 1.35c.027.005.266.051.626.051"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__o)"
        d="M81.844 97.553l-1.314 2.131 11.039 3.761s.737-.75-1.972-2.255c-3.153-1.756-7.753-3.637-7.753-3.637"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__o)"
        d="M79.87 97.553l1.315 2.131-11.039 3.761s-.737-.75 1.971-2.255c3.154-1.756 7.754-3.637 7.754-3.637"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__p)"
        d="M79.22 100.172h2.62V91.66h-2.62z"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__q)"
        d="M81.689 98.207l-1.159 1.895 9.74 3.343s.65-.666-1.738-2.006c-2.784-1.56-6.843-3.232-6.843-3.232"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__q)"
        d="M79.372 98.207l1.158 1.895-9.74 3.343s-.65-.666 1.74-2.006c2.783-1.56 6.842-3.232 6.842-3.232"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__r)"
        d="M89.041 104.428c0 .543.294.981.655.981.361 0 .655-.438.655-.98 0-.544-.294-.984-.655-.984-.36 0-.655.44-.655.983m-18.331 0c0 .543.294.981.655.981.361 0 .654-.438.654-.98 0-.544-.293-.984-.654-.984-.361 0-.656.44-.656.983m20.297 0c0 .543.292.981.654.981.362 0 .655-.438.655-.98 0-.544-.293-.984-.655-.984-.362 0-.654.44-.654.983"
        transform="translate(8.718)"
      />
      <path
        fill="#367082"
        d="M77.463 104.428c0 .543.293.981.655.981.361 0 .654-.438.654-.98 0-.544-.293-.984-.654-.984-.362 0-.655.44-.655.983"
      />
      <path
        fill="url(#icon-buy-2_svg__s)"
        d="M79.875 91.66h1.965v-3.273h-1.965z"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__t)"
        d="M78.566 87.405c0 .903.8 1.636 1.787 1.636h1.009c.986 0 1.787-.733 1.787-1.636 0-.905-.8-1.637-1.787-1.637h-1.009c-.986 0-1.787.732-1.787 1.637"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__u)"
        d="M68.154 85.768c-.04-.19-.064-1.376-.064-1.58 0-1.297.87-2.348 1.943-2.348h21.65c1.071 0 1.941 1.05 1.941 2.348 0 .204-.023 1.39-.064 1.58H68.154z"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__v)"
        d="M93.624 86.422c0 .362-.242.655-.543.655H67.98c-.302 0-.544-.293-.544-.655 0-.36.242-.654.544-.654h25.1c.302 0 .544.293.544.654"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__w)"
        d="M92.866 73.217c-.541.04-3.978.101-4.57.11-3.763.045-7.003-.672-7.183-1.613a33.113 33.113 0 0 1-.098-11.832c.161-.94 3.45-1.658 7.292-1.61.603.007 4.11.07 4.663.11a32.493 32.493 0 0 0-.104 14.835"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__x)"
        d="M92.88 58.27c.472.005.802.202.736.44a27.392 27.392 0 0 0-.132 13.523c.06.239-.267.436-.729.44-.462.006-.89-.185-.948-.424a27.422 27.422 0 0 1 .1-13.555c.064-.24.5-.43.973-.424"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__y)"
        d="M92.86 72.61c-.107.034-.818.061-.943.064-.782.01-1.589-.699-1.744-1.59a31.641 31.641 0 0 1 .05-11.225c.164-.892.99-1.599 1.788-1.59.126.003.85.03.959.066a31.552 31.552 0 0 0-.11 14.274"
        transform="translate(8.718)"
      />
      <path
        fill="#E2C59C"
        d="M78.772 61.978s-.025.87-4.55.875c-4.521.004-11.163-1.035-11.163-1.035l10.434-.921s4.006-.174 5.28 1.081"
      />
      <path
        fill="#E2C59C"
        d="M66.9 62.194c-2.163.068-3.059 1.193-3.906 1.56s-1.694.914-1.688.904c.453-.95 1.151-.999 1.492-1.312.34-.315.255-.16-.518-.193-.439-.019-1.185.785-1.726 1.236-.47.388-.768.428-.768.428s.534-.805 1.23-1.464c.839-.793 1.884-1.468 2.133-1.578.457-.197 1.903-.294 2.938-.189 1.168.12 2.372.557.814.608m-3.841-.651s-1.83.525-2.145.777c-.221.175-1.13 1.187-1.13 1.187s.686-.131 1.072-.516c.387-.384 2.202-1.448 2.202-1.448"
      />
      <path
        fill="url(#icon-buy-2_svg__z)"
        d="M69.61 60.238s3.254-.213 3.055 2.515c-.112 1.55-.65 2.123-2.284 2.06-2.322-.092-4.953-.396-4.953-.396l-1.266-3.744 5.449-.435z"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__A)"
        d="M69.062 60.834l3.714-13.34s.547-1.954 1.571-1.627c1.477.472.806 2.078.712 3.02-.197 1.922.005 2.747-1.183 7.403-.733 2.87-1.338 6.17-2 8.063-.454 1.305-1.366-.51-2.667-.98-2.428-.877-.147-2.54-.147-2.54"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__B)"
        d="M71.543 39.961c.478 2.187 4.303 4.13 4.817 8.488 0 0 3.954-10.178 3.475-12.366l-.325-1.485c-.48-2.187-2.6-3.562-4.736-3.073l-.966.222c-2.137.49-3.481 2.66-3.002 4.847l.737 3.367z"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__C)"
        d="M72.013 34.045c.09.364.622.93.976 1.122 1.275.695 2.411 4.03 4.202 3.89.319 1.142.795 2.13 1.283 2.951.555.936 2.007 1.735 2.007 1.735.702 1.718-6.34 7.472-6.64 7.322-.641-.32.505-4.452 1.656-5.732.542-.6 1.092-1.307 1.103-1.712.041-1.419-.42-1.72-1.119-2.37-1.227-.902-2.63-.58-2.63-.58a.494.494 0 0 1-.38-.071 2.845 2.845 0 0 1-.317-.803L71.4 36.52c-.208-.908.527-1.657.612-2.475"
        transform="translate(8.718)"
      />
      <path
        fill="#4674A5"
        d="M87.284 70.254s-1.679 1.594-2.151 1.736c-.632.189-2.401-.613-2.401-.613s.488-8.261.307-11.535c-.183-3.274-.915-4.222-.833-5.232.137-1.617-1.115-2.443-.747-3.674.37-1.234 1.3-5.663 1.363-5.048.545 5.237 2.463 1.198 2.845.566.21-.348 1.617 23.8 1.617 23.8"
      />
      <path
        fill="url(#icon-buy-2_svg__D)"
        d="M75.54 44.65s-2.235 1.725-2.206 2.52c.03.797-1.154 3.152-.674 6.691.548 4.041.753 6.754.641 9.331-.113 2.58-1.243 4.813-1.281 7.987-.03 2.485.51 2.707.51 2.707s1.044.406 1.919-.444c.878-.849.722-9.358.588-11.5-.132-2.14-.767-7.185-.914-8.658-.182-1.829.093-3.612.183-4.137.31-1.811.68-2.921.938-3.587.045-.115.625-.605.662-.694.159-.386-.183-.433-.366-.216"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__E)"
        d="M76.321 43.874s.57-.111.085.502c-.264.335-.28.635-.28.635s-.246-.244-.617.105c-.337.318-.871.714-.871.714s.345-.913.421-.978c.279-.236 1.262-.978 1.262-.978"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__F)"
        d="M80.556 45.198c1.798 2.54 1.144 9.79 4.257 9.097 3.115-.693 4.214-3.65 3.217-5.26-1.208-1.95-7.792-10.523-8.47-14.113-.78-4.122-5.14-1.434-4.111.124 3.115 4.705-1.994.124 5.107 10.152"
        transform="translate(8.718)"
      />
      <path
        fill="#E9D6B8"
        d="M75.082 98.862l1.071 7.907-4.63 2.046-2.571.523 4.563-3.28-1.493-7.135z"
      />
      <path
        fill="url(#icon-buy-2_svg__G)"
        d="M68.09 107.922l-.986.246-.662-.57-3.634 1.689-3.222.705s-.04-.242.07-.372c.403-.488 2.66-1.579 2.66-1.579s.037.716.358.736c.32.021 3.104-1.332 3.748-1.708 1.108-.648 1.316-1.05 1.4-1 .223.133.268 1.853.268 1.853"
        transform="translate(8.718)"
      />
      <path
        fill="#E9D6B8"
        d="M64.369 87.276l-3.624 7.003-5.013-.25-2.494-.703 5.536-.92 3.023-5.983z"
      />
      <path
        fill="url(#icon-buy-2_svg__H)"
        d="M51.704 95.589l-.997-.236-.313-.864-4.497-.066-2.686-.63s.326-.363.526-.446c.598-.248 2.72-.377 2.72-.377s-.224.606.051.78c.277.173 3.367.277 4.123.221 1.3-.093 1.678-.38 1.731-.292.134.235-.658 1.91-.658 1.91"
        transform="translate(8.718)"
      />
      <path
        fill="#4674A5"
        d="M90.761 66.781l-2.686 1.163-1.168 3.49L69.629 68.6s-1.76-.54-2.881 2.228c-1.595 3.94-5.653 16.192-5.653 16.192l2.57 2.675 8.057-13.842s16.514 7.714 21.96 6.05c2.09-.64 3.685-4.315 3.386-8.027a47.45 47.45 0 0 0-.817-5.584l-5.49-1.512z"
      />
      <path
        fill="#4674A5"
        d="M88.593 82.176l-13.307-.969 1.144 20.802-3.924.127-2.9-24.363s.782-3.625 3.323-3.08c2.543.548 8.693 5.32 15.664 7.483"
      />
      <path
        fill="url(#icon-buy-2_svg__I)"
        d="M78.077 71.982s-.322-8.46-.568-12.472c-.249-4.013-.193-11.755-.245-12.719-.054-.965.21-2.325.21-2.325s1.211-2.08 2.562-1.898c1.189.159 3.093 1.723 4.171 2.593 2.016 1.626.998 14.896 1.054 15.609.203 2.57 1.725 5.106 2.874 7.574.737 1.583.906 4.081.906 4.081s-1.502 2.301-10.964-.443"
        transform="translate(8.718)"
      />
      <path
        fill="url(#icon-buy-2_svg__J)"
        d="M79.72 42.557l-1.022.924-1.441 1.416.73 1.588 2.543-3.266z"
        transform="translate(8.718)"
      />
      <path
        fill="#E9D6B8"
        d="M89.902 64.27s-.144.918-5.613.376c-5.474-.543-13.373-2.448-13.373-2.448l12.742.287s4.87.3 6.244 1.785"
      />
      <path
        fill="#E9D6B8"
        d="M75.238 62.687c-2.622-.239-3.837.98-4.904 1.294-1.067.316-2.153.847-2.145.836.66-1.073 1.51-1.03 1.958-1.356.45-.327.327-.153-.602-.308-.529-.089-1.525.768-2.233 1.228-.612.397-.98.402-.98.402s.743-.89 1.662-1.577c1.109-.829 2.451-1.486 2.765-1.58.575-.169 2.333-.07 3.57.21 1.396.318 2.798 1.022.909.85"
      />
      <path
        fill="#E9D6B8"
        d="M70.916 62.198s-2.128.252-2.515.43c-.269.125-1.414.88-1.414.88s.79-.042 1.273-.324c.48-.281 2.656-.986 2.656-.986"
      />
      <path
        fill="url(#icon-buy-2_svg__K)"
        d="M78.84 61.58l.602-12.871s.694-2.697 1.702-3.316c1.196-.735 2.842.627 2.842.627s.842 2.01.277 11.5c-.378 6.348-1.041 9.02-4.045 8.555-2.802-.433-6.285-1.03-6.285-1.03l-.605-3.332 5.511-.133z"
        transform="translate(8.718)"
      />
      <path
        fill="#D0DEE6"
        d="M76.797 64.765l.011-.02-.01-.583h-26.83v.584s-.007.012 0 .019l.066.109c.345.506.965.598 1.672.598h23.35c.708 0 1.327-.092 1.672-.598l.07-.11z"
      />
      <path
        fill="#B6C2C9"
        d="M70.263 65.171s-.005.008 0 .013l.063.065c.098.098.227.17.37.223h3.864c.644 0 1.207-.056 1.52-.362l.062-.066.011-.01-.005-.217h-5.885v.354zm-16.37-.354h13.094v-.655H53.893z"
      />
      <path
        fill="#D7DFE2"
        d="M70.261 64.162l-3.477-13.121c-.092-.347-.447-.628-.795-.628H47.176c-.347 0-.554.28-.463.628l3.476 13.121h20.072z"
      />
      <path
        fill="#B6C2C9"
        d="M59.742 57.616c.184.721-.233 1.308-.932 1.308-.7 0-1.415-.587-1.6-1.308-.185-.724.232-1.31.933-1.31.698 0 1.414.586 1.599 1.31"
      />
      <path fill="#D6DCEB" d="M11.463 110.647H8.718l4.53-43.866h3.981z" />
      <path fill="#C8CEDC" d="M17.229 69.425l-1.797 13.07L13.3 68.408l.104-.973h3.582z" />
      <path fill="#D6DCEB" d="M48.128 110.647h-2.746l4.53-43.866h3.981z" />
      <path fill="#C8CEDC" d="M53.238 69.425l-1.796 13.07-2.132-14.086.105-.973h3.582z" />
      <path fill="#D6DCEB" d="M76.68 110.647h2.747l-4.53-43.866h-3.981z" />
      <path fill="#C8CEDC" d="M70.916 68.823l5.237 13.671-1.485-14.713-.1-1h-3.42z" />
      <path fill="#D6DCEB" d="M45.382 68.09h32.736v-2.618H45.382z" />
      <path fill="#D6DCEB" d="M10.027 68.09h37.319v-2.618H10.027z" />
      <path
        fill="#BEC9DD"
        d="M40.77 2.997c3.333-.387 14.69-.995 17.785 1.24 3.073 2.274 5.501 12.992 2.433 15.063-3.043 2.032-12.769 1.823-17.986 1.285l.203.02c-.646.99-1.768 2.12-3.716 2.965l.012-.025c.113-.236.998-2.155 1.059-4.059-4.637-3.743-6.927-15.662.21-16.49z"
      />
      <path
        fill="#FFC910"
        d="M48.04 14.988c.202.084.407.156.615.218-.471.17-.942.338-1.414.507l1.399-.51-1.399.51a6.234 6.234 0 0 1-.614-.218c-2.613-1.08-4.513-3.896-4.519-7.064 0-.245.01-.486.032-.722.471-.17.943-.338 1.414-.507a7.844 7.844 0 0 0-.032.722c.006 3.167 1.904 5.981 4.515 7.063h-.003c-.06.006-.138-.022-.197-.032a2.972 2.972 0 0 0-.72-.039c-.114.013-.21.033-.285.06a2.012 2.012 0 0 0-.287.12c-.102.05-.102.05-.195.086.093-.036.093-.036.195-.085.156-.075.181-.085.287-.122a1.35 1.35 0 0 1 .284-.059c.06-.007.21-.015.386 0 .178.013.289.031.532.072-.503.18-.973.35-1.406.507.963-.35 1.406-.507 1.406-.507h.007"
      />
      <path
        fill="#FFC910"
        d="M46.969 15.611l.045-.016.047-.018.048-.018.05-.018.05-.019.051-.019.052-.019.051-.02.052-.019.052-.018.05-.02.051-.018.05-.019.048-.018.047-.017.045-.017-.045.017-.047.017-.048.018-.05.019-.05.018-.051.02-.052.018-.052.02-.051.019-.052.02-.05.018-.051.019-.05.018-.048.018-.047.018-.045.016zm1.077-.553c.2.062.403.113.61.152l-1.355.503a6.03 6.03 0 0 1-.61-.15l.677-.253.177-.066.174-.064.085-.032.164-.06c.023-.01.053-.029.078-.03z"
      />
      <path
        fill="#FFC910"
        d="M48.686 15.058c.205.045.414.076.624.093l-1.34.562a4.918 4.918 0 0 1-.624-.092l.67-.281.175-.074.173-.072.084-.035.082-.035c.047-.02.107-.06.156-.066"
      />
      <path
        fill="#FFC910"
        d="M49.343 15.058c.21.02.417.024.622.013l-1.342.636a4.32 4.32 0 0 1-.622-.012c.402-.191.919-.56 1.342-.637m1.135.012c.048-.002.095-.006.141-.012l-1.17.643a3.519 3.519 0 0 1-.139.012l.067-.037a1747112.233 1747112.233 0 0 1 .67-.368l.15-.083.145-.08c.041-.022.094-.062.136-.074"
      />
      <path
        fill="#FFC910"
        d="M51.274 15.058l-.003.002-1.472.592a5.4 5.4 0 0 1-.489.061l1.478-.593a5.29 5.29 0 0 0 .486-.062"
      />
      <path
        fill="#FFC910"
        d="M50.68 15.192c.201-.033.4-.078.594-.134l-1.369.522c-.194.055-.393.1-.595.133l1.366-.52-1.286.5.474-.185a46.258 46.258 0 0 0 .337-.13l.476-.185h.002m.798.002a6.21 6.21 0 0 0 .452-.137l-1.512.519c-.148.05-.3.096-.452.136l.756-.26.099-.033.098-.034.098-.034.191-.065.093-.032c.053-.018.123-.044.177-.06"
      />
      <path
        fill="#FFA601"
        d="M51.776 14.571l-1.416.487c2.393-.822 4.086-3.165 4.08-6.042-.006-3.745-2.89-6.978-6.44-7.22l1.417-.487c3.55.243 6.433 3.475 6.44 7.22.005 2.877-1.687 5.22-4.081 6.042"
      />
      <path
        fill="#FFA601"
        d="M48.315 1.979c-3.434-.245-6.214 2.628-6.207 6.417.007 3.789 2.797 7.058 6.232 7.303 3.435.244 6.214-2.629 6.208-6.418-.007-3.788-2.797-7.058-6.233-7.302"
      />
      <path
        fill="#EE8F01"
        d="M48.41 15.7c-3.318-.244-6.014-3.515-6.02-7.304-.006-3.587 2.399-6.351 5.47-6.43-3.204-.089-5.758 2.734-5.752 6.43.007 3.79 2.702 7.06 6.02 7.304.178.013.353.016.527.011a4.843 4.843 0 0 1-.245-.01"
      />
      <path
        fill="#FFA601"
        d="M46.036 1.964l1.484-.397a7.87 7.87 0 0 1 2.445-.246l-1.484.397a7.857 7.857 0 0 0-2.445.246"
      />
      <path
        fill="#FFA601"
        d="M52.253 15.885l-1.412.483c2.881-.986 4.92-3.791 4.913-7.237-.008-4.487-3.48-8.358-7.753-8.648L49.413 0c4.274.29 7.745 4.16 7.754 8.648.006 3.445-2.032 6.251-4.914 7.237"
      />
      <path
        fill="#FFC910"
        d="M48.34 15.604c-3.444-.242-6.242-3.466-6.248-7.202-.007-3.736 2.78-6.57 6.223-6.328 3.445.241 6.243 3.465 6.25 7.201.006 3.737-2.781 6.57-6.226 6.329M48.313.672c-4.158-.291-7.522 3.129-7.514 7.64.008 4.51 3.385 8.402 7.543 8.693 4.158.291 7.523-3.128 7.515-7.64-.008-4.51-3.386-8.402-7.544-8.693"
      />
      <path
        fill="#EE8F01"
        d="M45.382.655l1.314-.367A8.067 8.067 0 0 1 49.31.013L47.996.38a8.063 8.063 0 0 0-2.614.275m3.078 2.682l.002 1.168c.396.103.713.34.953.712.239.372.373.835.402 1.393l-.883-.068c-.019-.316-.11-.572-.272-.768a.879.879 0 0 0-.635-.327c-.278-.022-.5.064-.667.256-.167.192-.25.457-.249.797 0 .125.015.239.044.344a.925.925 0 0 0 .136.29c.123.181.385.34.786.477.239.08.422.153.547.218.439.22.771.52.998.899.228.38.342.82.343 1.326 0 .638-.138 1.172-.413 1.595-.277.426-.636.657-1.077.697l.002 1.403-.822-.063-.002-1.35a1.902 1.902 0 0 1-1.176-.837c-.29-.442-.436-.986-.44-1.632l.85.065c.018.436.121.77.306 1.004.187.233.456.365.807.392.322.024.579-.073.77-.293.19-.219.286-.523.285-.915 0-.139-.012-.267-.035-.386a1.215 1.215 0 0 0-.1-.31c-.144-.291-.44-.52-.891-.683-.075-.026-.13-.047-.166-.06-.56-.216-.974-.5-1.24-.853-.265-.353-.398-.796-.398-1.33-.001-.581.124-1.056.376-1.423a1.452 1.452 0 0 1 1.038-.645l-.001-1.156.822.063z"
      />
      <path
        fill="#FFC910"
        d="M48.46 3.337l.002 1.168c.396.103.713.34.953.712.239.372.374.835.402 1.393l-.883-.068c-.019-.316-.11-.572-.272-.768a.879.879 0 0 0-.635-.327c-.278-.022-.501.064-.667.256-.167.192-.25.457-.249.797 0 .125.015.239.044.344a.925.925 0 0 0 .136.29c.123.181.385.34.786.477.24.08.422.153.547.218.439.22.771.52.998.899.228.38.342.82.343 1.326 0 .638-.137 1.172-.413 1.595-.277.426-.635.657-1.077.697l.002 1.403-.822-.063-.002-1.35a1.902 1.902 0 0 1-1.176-.837c-.29-.442-.436-.986-.44-1.632l.85.065c.018.436.121.77.307 1.004.186.233.455.365.806.392.322.024.579-.073.77-.293.191-.219.286-.523.286-.915 0-.139-.013-.267-.036-.386a1.215 1.215 0 0 0-.1-.31c-.143-.291-.44-.52-.891-.683-.075-.026-.129-.047-.166-.06-.56-.216-.974-.5-1.24-.853-.265-.353-.398-.796-.398-1.33-.001-.581.124-1.056.376-1.423a1.452 1.452 0 0 1 1.038-.645l-.001-1.156.822.063z"
      />
    </g>
  </svg>
);

export default SvgIconBuy2;
