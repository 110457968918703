import React from 'react';

const SvgIconTrocaDeOleo = props => (
  <svg width={19} height={16.15} viewBox="0 0 40 34" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="#54575F" fillRule="nonzero">
        <path d="M38.938 32.447H23.266a7.266 7.266 0 003.842-6.427c0-4-3.2-7.253-7.134-7.253-3.935 0-7.135 3.253-7.135 7.253a7.26 7.26 0 003.843 6.427H.407A.412.412 0 000 32.86c0 .227.184.413.407.413h38.537a.412.412 0 00.407-.413.422.422 0 00-.413-.413zM19.98 32.44c-3.488 0-6.321-2.887-6.321-6.427 0-3.54 2.84-6.426 6.321-6.426 3.482 0 6.322 2.886 6.322 6.426s-2.84 6.427-6.322 6.427z" />
        <path d="M.708 28.913h11.699a.412.412 0 00.406-.413.412.412 0 00-.406-.413H.708a.412.412 0 00-.406.413c0 .227.183.413.406.413zm15.194-15.96v5.6c0 .227.183.414.406.414a.412.412 0 00.407-.414v-5.6a.412.412 0 00-.407-.413.412.412 0 00-.406.413zM30.689 4.5v.273a4.02 4.02 0 00-3.128 1.554l-.309.386a.406.406 0 00-.085.3.377.377 0 00.144.274l.958.78a.419.419 0 00.249.086h.052a.368.368 0 00.27-.146l.308-.387c.373-.48.937-.76 1.54-.773v1.126c0 .574.46 1.034 1.017 1.034h6.728a.721.721 0 00.715-.72V3.62c0-.573-.46-1.033-1.017-1.033h-5.56a.999.999 0 00-.715.3l-.866.88a1.05 1.05 0 00-.301.733zm0 1.093v.427a2.831 2.831 0 00-2.19 1.087l-.053.066-.321-.26.052-.066a3.189 3.189 0 012.512-1.254zm7.639-.513H34.15a.721.721 0 00-.715.72v2.393h-1.744a.209.209 0 01-.21-.213V6.533c.007-.033.02-.066.02-.1V5.187c0-.034-.013-.067-.02-.1v-.58a.19.19 0 01.066-.147l.865-.88a.21.21 0 01.144-.067h5.554c.119 0 .21.094.21.214V5.08h.007zm-4.079 3.113V5.907h4.079v2.08c0 .113-.085.193-.197.206H34.25zm-5.882.474a.418.418 0 00-.347.2l-1.056 1.82a1.68 1.68 0 00.269 2.026c.308.3.708.467 1.128.467.42 0 .82-.167 1.128-.467.544-.526.655-1.36.268-2.026l-1.055-1.82a.372.372 0 00-.335-.2zm.57 3.46a.81.81 0 01-1.14 0 .838.838 0 01-.131-1.02l.708-1.22.708 1.22a.85.85 0 01-.144 1.02zM19.98 21.88c-2.242 0-4.072 1.86-4.072 4.14 0 2.28 1.83 4.14 4.072 4.14 2.243 0 4.072-1.86 4.072-4.14 0-2.28-1.829-4.14-4.072-4.14zm3.266 4.14c0 1.833-1.469 3.32-3.266 3.32-1.803 0-3.265-1.493-3.265-3.32 0-1.833 1.469-3.32 3.265-3.32 1.797 0 3.26 1.487 3.266 3.32z" />
        <path d="M14.472 11.3H.708a.412.412 0 00-.406.413c0 .227.183.414.406.414H16.27l11.744 2.44c1.312.266 2.46 1 3.279 2.026H28.44c-.354 0-.682.187-.865.494-.184.306-.204.68-.046 1.006l1.272 2.594c.334.666.997 1.08 1.731 1.08h3.115l.026.06a.425.425 0 00.217.213l.806.353c.55.234.82.874.61 1.447l-1.272 3.5a1.135 1.135 0 01-1.056.753h-5.436a.412.412 0 00-.407.414c0 .226.184.413.407.413h5.436c.807 0 1.534-.52 1.81-1.287l1.279-3.506c.354-.987-.105-2.074-1.05-2.487l-.655-.28-.066-.16v-.007l-1.39-3.46a7.256 7.256 0 00-.269-.56 10.943 10.943 0 00-.21-.38c-.006-.006-.006-.013-.006-.02a6.493 6.493 0 00-4.236-3.02l-2.735-.566v-9.14a8.588 8.588 0 012.676-1.2l2.662-.667a.411.411 0 00.295-.5.404.404 0 00-.492-.3l-2.669.673a9.487 9.487 0 00-4.098 2.22l-7.482 6.854L9.26 2.973c-.072-.086-.157-.16-.23-.24l-.026-.026A6.468 6.468 0 004.373.733H.709a.412.412 0 00-.406.414c0 .226.183.413.406.413h3.66c1.173 0 2.3.373 3.252 1.04H.708a.391.391 0 00-.4.4c0 .227.184.413.407.413h7.842c.033.034.073.067.105.107l6.761 7.78h-.951zM24.63 4.66v8.367l-7.456-1.547 7.187-6.6c.085-.087.183-.147.269-.22zm7.527 13.4l1.168 2.887h-2.78c-.434 0-.814-.24-1.01-.634l-1.273-2.593a.212.212 0 01.007-.207.217.217 0 01.124-.093h3.456c.125.227.223.433.308.64z" />
      </g>
      <path d="M0-3h39.344v40H0z" />
    </g>
  </svg>
);

export default SvgIconTrocaDeOleo;
