import React from 'react';

const SvgIconTractorOff = props => (
  <svg width={129} height={83} viewBox="0 0 129 83" {...props}>
    <defs>
      <linearGradient
        id="icon-tractor-off_svg__a"
        x1="48.194%"
        x2="52.333%"
        y1="14.232%"
        y2="85.76%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-off_svg__b"
        x1="28.775%"
        x2="82.666%"
        y1="-9.152%"
        y2="93.227%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="17.26%" stopColor="#B1BBBB" />
        <stop offset="54.15%" stopColor="#869090" />
        <stop offset="82.98%" stopColor="#6C7676" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-off_svg__c"
        x1="36.134%"
        x2="63.468%"
        y1="48.125%"
        y2="52.22%"
      >
        <stop offset="0%" stopColor="#DCE6E6" />
        <stop offset="19.25%" stopColor="#CED8D8" />
        <stop offset="55.37%" stopColor="#AAB4B4" />
        <stop offset="100%" stopColor="#768080" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-off_svg__d"
        x1="25.668%"
        x2="60.364%"
        y1="40.823%"
        y2="53.159%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-off_svg__e"
        x1="28.565%"
        x2="87.506%"
        y1="-9.655%"
        y2="99.3%"
      >
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-off_svg__f"
        x1="48.433%"
        x2="51.56%"
        y1="15.416%"
        y2="84.432%"
      >
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-off_svg__g"
        x1="21.939%"
        x2="75.959%"
        y1="-3.512%"
        y2="100.687%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-off_svg__h"
        x1="-4.489%"
        x2="78.657%"
        y1="-27.804%"
        y2="80.981%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-off_svg__i"
        x1="26.979%"
        x2="72.567%"
        y1="30.398%"
        y2="78.304%"
      >
        <stop offset="0%" stopColor="#DCE6E6" />
        <stop offset="19.25%" stopColor="#CED8D8" />
        <stop offset="55.37%" stopColor="#AAB4B4" />
        <stop offset="100%" stopColor="#768080" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-off_svg__j"
        x1="45.626%"
        x2="53.341%"
        y1="-1.756%"
        y2="84.307%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-off_svg__k"
        x1="14.461%"
        x2="82.194%"
        y1="-55.174%"
        y2="82.926%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-off_svg__l"
        x1="32.869%"
        x2="85.184%"
        y1="14.903%"
        y2="76.074%"
      >
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-off_svg__m"
        x1="35.476%"
        x2="67.598%"
        y1="46.052%"
        y2="53.547%"
      >
        <stop offset="0%" stopColor="#DCE6E6" />
        <stop offset="19.25%" stopColor="#CED8D8" />
        <stop offset="55.37%" stopColor="#AAB4B4" />
        <stop offset="100%" stopColor="#768080" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-off_svg__n"
        x1="32.829%"
        x2="66.579%"
        y1="42.267%"
        y2="63.338%"
      >
        <stop offset="0%" stopColor="#DCE6E6" />
        <stop offset="19.25%" stopColor="#CED8D8" />
        <stop offset="55.37%" stopColor="#AAB4B4" />
        <stop offset="100%" stopColor="#768080" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-off_svg__o"
        x1="25.332%"
        x2="69.111%"
        y1="28.859%"
        y2="74.615%"
      >
        <stop offset="0%" stopColor="#DCE6E6" />
        <stop offset="19.25%" stopColor="#CED8D8" />
        <stop offset="55.37%" stopColor="#AAB4B4" />
        <stop offset="100%" stopColor="#768080" />
      </linearGradient>
      <linearGradient id="icon-tractor-off_svg__p" x1="8.273%" x2="63.24%" y1="8.104%" y2="65.712%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-off_svg__q"
        x1="-16.269%"
        x2="116.319%"
        y1="49.71%"
        y2="49.71%"
      >
        <stop offset="0%" stopOpacity={0} />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-off_svg__r"
        x1="29.593%"
        x2="70.345%"
        y1="39.869%"
        y2="60.153%"
      >
        <stop offset="0%" stopColor="#DCE6E6" />
        <stop offset="19.25%" stopColor="#CED8D8" />
        <stop offset="55.37%" stopColor="#AAB4B4" />
        <stop offset="100%" stopColor="#768080" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-off_svg__s"
        x1="10.714%"
        x2="78.297%"
        y1="-18.039%"
        y2="99.019%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-tractor-off_svg__t" x1="25%" x2="74.998%" y1="6.71%" y2="93.309%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-off_svg__u"
        x1="11.512%"
        x2="78.329%"
        y1="-16.657%"
        y2="99.072%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-off_svg__v"
        x1="24.986%"
        x2="75.001%"
        y1="6.684%"
        y2="93.313%"
      >
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-off_svg__w"
        x1="-52.333%"
        x2="151.493%"
        y1="50.209%"
        y2="50.209%"
      >
        <stop offset="0%" stopOpacity={0} />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-off_svg__x"
        x1="-13.951%"
        x2="65.172%"
        y1="-45.494%"
        y2="83.264%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-off_svg__y"
        x1="31.336%"
        x2="59.34%"
        y1="-41.043%"
        y2="96.986%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-off_svg__z"
        x1="-10.4%"
        x2="84.427%"
        y1="-19.311%"
        y2="82.904%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-off_svg__A"
        x1="14.81%"
        x2="85.106%"
        y1="-7.006%"
        y2="106.947%"
      >
        <stop offset="0%" stopColor="#DCE6E6" />
        <stop offset="19.25%" stopColor="#CED8D8" />
        <stop offset="55.37%" stopColor="#AAB4B4" />
        <stop offset="100%" stopColor="#768080" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-off_svg__B"
        x1="27.343%"
        x2="54.903%"
        y1="-2.476%"
        y2="57.397%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M0-11h129v94H0z" />
      <g opacity={0.6}>
        <path
          fill="url(#icon-tractor-off_svg__a)"
          fillRule="nonzero"
          d="M69 18s-1.633 1.503-2.289 3.084v.014C66.742 31.48 66 40.959 66 40.959l.91.022c.154.007.278.15.278.327V44h.012l.34-19.434c.452.021.903.043 1.349.071.037-2.321.074-4.579.111-6.637z"
        />
        <path
          fill="url(#icon-tractor-off_svg__b)"
          fillRule="nonzero"
          d="M56.03 40.948l10.18.244s1.648-18.536.185-31.894a1.97 1.97 0 0 0-1.69-1.749c-4.21-.564-16.758-2.014-31.477-1.4a1.755 1.755 0 0 0-1.635 1.386c-.446 2.049-1.16 7.115-1.593 19.4l.007.007c10.687.195 19.925 6.578 24.581 15.63.151.829-.027 1.184-.165 1.324.605 1.135.948 1.881.948 1.881.028-.181.165-.286.426-.286.144 0 .79-.055.927.119.13.167.323.39.488.39l.48-.641-1.675-.042a.32.32 0 0 1-.31-.32v-3.728c.008-.182.152-.328.324-.321z"
        />
        <path
          fill="url(#icon-tractor-off_svg__c)"
          fillRule="nonzero"
          d="M35.56 1.308c1.43.58 1.71 1.39 2.942 1.506 1.233.116 20.805.348 21.162.348.357 0 .953-1.159 2.347-1.316 1.394-.156 7.958.116 9.19 1.316.35.34.61.62.799.838C70.08.408 66.319.865 55.195.422c-7.187-.28-20.342-.573-23.81-.334C27.918.326 27 2.058 27 2.058c1.562-.77 7.32-1.255 8.56-.75z"
        />
        <path
          fill="url(#icon-tractor-off_svg__d)"
          fillRule="nonzero"
          d="M71.929 4.941s-.077-.426-1.31-1.72c-1.234-1.294-7.8-1.588-9.195-1.42-1.395.17-1.99 1.42-2.348 1.42-.357 0-19.937-.25-21.17-.375-1.234-.125-1.514-1-2.944-1.625s-8.872.17-8.956 1.213c-.077 1.044.624 1.794.624 1.794.651 2.97 4.078 4.662 4.078 4.662a26.77 26.77 0 0 1 .295-1.662c.175-.823.862-1.426 1.667-1.463 15.018-.647 27.822.882 32.118 1.478.91.125 1.626.89 1.724 1.845.035.302.063.603.09.912 3.463-.404 4.661-2.985 4.661-2.985 1.058-.765.666-2.074.666-2.074z"
        />
        <path
          fill="url(#icon-tractor-off_svg__e)"
          fillRule="nonzero"
          d="M55.647 41.368c0-.182.15-.322.335-.322l8.092.189.086-.007s2.387-23.322-.887-33.916l-.393-.05S51.615 5.676 33.831 6.06c0 0-1.623 12.72-1.823 15.91-.121 1.894 1.108 3.629 2.967 4.181C47.54 29.857 52.83 38.885 54.038 41.284c.2.399.608.664 1.065.7.172.013.358.02.55.013v-.63h-.006z"
        />
        <path
          fill="url(#icon-tractor-off_svg__f)"
          fillRule="nonzero"
          d="M32 6c-.418.203-.742.68-.84 1.282C30.835 9.237 30.333 13.945 30 25c.005-.007.407-1.254.496-2.326C30.58 21.602 31.99 6.112 32 6z"
        />
        <path
          fill="url(#icon-tractor-off_svg__g)"
          fillRule="nonzero"
          d="M72.763 47.765c-.367-.989-4.511-3.355-5.195-3.737L67.47 44v.516a.325.325 0 0 1-.33.325l-9.756-.233-.493.65c-.163 0-.36-.227-.5-.396-.142-.177-.804-.12-.952-.12-.268 0-.41.106-.437.29-.029.183 1.113 4.076 1.29 6.725.176 2.65.324 10.392.324 10.78 0 .39.634 1.463.634 1.463l13.822-.629.924-11.24c1.163 2.48 1.163-3.292.768-4.366z"
        />
        <path
          fill="url(#icon-tractor-off_svg__h)"
          fillRule="nonzero"
          d="M57 63.989l-.626-1.431c.056-4.126-.118-8.612-.236-11.238-.668-3.76-1.356-5.89-1.356-5.89s-.355-.739-.96-1.865c-5.118-9.414-15.118-15.98-26.397-15.545-7.83.305-15.02 3.166-20.284 7.79 0 0-.57.843.584 1.03 1.161.186 2.128 0 2.128 0l.653.477c2.497 24.557 30.952 28.089 39.137 28.6 1.384.083 2.183.083 2.183.083l.543-1.81L57 63.988z"
        />
        <path
          fill="url(#icon-tractor-off_svg__i)"
          fillRule="nonzero"
          d="M118.207 54.894l2.662-9.471 1.129-10.402a.455.455 0 0 0-.153-.387c-.384-.324-1.31-1.078-2.119-1.459-1.045-.493-25.535-8.167-52.052-9.175l-.39 19.795s4.836 2.713 5.226 3.784c.39 1.071.39 6.829-.746 4.37l-.913 11.204-18.307.839L52 65.838h1.777c.892 0 1.75-.423 1.75-.423l.215.183h4.14v-.43h.502l.425.29h1.568c.78 0 1.213.542 1.213.542h.39s.571-.472.822-.754h1.777l.16-.416h.133s.32.522.62.543c.3.028.537-.247.676-.656l2.83-.056.083-.19h2.502a3.04 3.04 0 0 0 1.004-.176c.32-.12.662-.177 1.003-.177h5.283l1.86-.303 35.474-8.921z"
        />
        <path
          fill="url(#icon-tractor-off_svg__j)"
          fillRule="nonzero"
          d="M69.274 44.95v-1.657A.54.54 0 0 1 69 43.14s.675-32.936.764-34.391c.089-1.456.193-2.264 1.527-3.921C72.626 3.17 76 2 76 2c-.111 2.828-.69 4.402-.69 4.402-.927.404-1.438 1.35-1.675 2.061-.238.71-.623 34.705-.69 34.788-.067.084-.252.105-.252.105s.089 4.457-.149 5.544c-.237 1.086-1.379 1.1-1.379 1.1s-2.18-1.08-1.89-5.05z"
        />
        <path
          fill="url(#icon-tractor-off_svg__k)"
          fillRule="nonzero"
          d="M122 35.773C119.554 35.228 104 33 104 33c1.488 3.466 3.054 9.92 3.054 9.92 2.536.969 4.947 1.952 7.155 4.556 2.2 2.604 2.92 7.524 2.92 7.524l1.077-.256 2.669-9.045L122 35.773z"
        />
        <path
          fill="url(#icon-tractor-off_svg__l)"
          fillRule="nonzero"
          d="M121.708 39s-3.889-1.285-5.708-4c0 0 6.007.887 6 1.306L121.708 39z"
        />
        <path
          fill="url(#icon-tractor-off_svg__m)"
          fillRule="nonzero"
          d="M69.445 48s.495-.03.536-.146c.042-.116 0-1.494 0-1.64 0-.147-.71-.11-.912-.11-.202 0-12.068-.104-12.068-.104s-.014.104.083.317c.07.159.314.89.432 1.244a.33.33 0 0 0 .3.207l7.2.232h4.43z"
        />
        <path
          fill="url(#icon-tractor-off_svg__n)"
          fillRule="nonzero"
          d="M54.64 44s.537-.174.3-1.416C50 33.148 39.98 26.62 28.512 27.017c-4.368.153-8.347.972-11.872 2.347 0 .007-.411.576-.572 1.472 0 0-5.74 2.222-8.068 5.284 5.274-4.645 12.486-7.52 20.324-7.825C39.764 27.843 49.763 34.467 54.64 44z"
        />
        <path
          fill="url(#icon-tractor-off_svg__o)"
          fillRule="nonzero"
          d="M123.021 59.827l.234-.5a.295.295 0 0 1 .276-.173h1.132c.337 0 .669.034 1 .104.351.076.71.11 1.069.104l2.234-.035s.076-.97 0-1.484-.138-2.38-.138-2.38-3.269-1.026-5.91-1.463h-.532v.395h-2.082c-.607 0-1.138.403-1.304.992l.11 4.613h3.628a.32.32 0 0 0 .283-.173z"
        />
        <path
          fill="url(#icon-tractor-off_svg__p)"
          fillRule="nonzero"
          d="M119 55.783s.606-.643 1.417-.74c.817-.097 1.686 0 1.686 0l.897.318c-2.286-.076-2.514 1.017-2.914 1.342-.332.27-1.069.297-1.069.297L119 55.783z"
        />
        <path
          stroke="url(#icon-tractor-off_svg__q)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M70 44.45l3 .1"
          opacity={0.5}
        />
        <path
          fill="url(#icon-tractor-off_svg__r)"
          fillRule="nonzero"
          d="M66.672 45l-11.358-.229c-.174-.006-.314-.126-.314-.276v-3.218c0-.157.147-.277.328-.277l11.358.229c.174.006.314.126.314.276v3.218c-.007.156-.153.283-.328.277z"
        />
        <path
          fill="#626C6C"
          fillRule="nonzero"
          d="M60.738 40.29l-.469-.02c-.152 0-.269.17-.269.38v.05l1 .03v-.06c-.007-.21-.117-.38-.262-.38zm3 0l-.469-.02c-.152 0-.269.17-.269.38v.05l1 .03v-.06c0-.21-.117-.38-.262-.38z"
        />
        <circle cx={102} cy={64} r={19} fill="url(#icon-tractor-off_svg__s)" fillRule="nonzero" />
        <circle
          cx={101.5}
          cy={64.5}
          r={10.5}
          fill="url(#icon-tractor-off_svg__t)"
          fillRule="nonzero"
        />
        <circle cx={26} cy={57} r={26} fill="url(#icon-tractor-off_svg__u)" fillRule="nonzero" />
        <circle
          cx={25.5}
          cy={57.5}
          r={14.5}
          fill="url(#icon-tractor-off_svg__v)"
          fillRule="nonzero"
        />
        <path
          stroke="url(#icon-tractor-off_svg__w)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M54 44c.438.843 1 2 1 2"
          opacity={0.5}
        />
        <path
          fill="url(#icon-tractor-off_svg__x)"
          fillRule="nonzero"
          d="M62.904 6.3s-1.867-1.054-2.281-1.253c-.408-.2-.65.28-.62.728.028.449.097 1.275.223 1.69 0 0 .098.2.523.535 0 0-.241-1.469-.241-1.879 0-.41-.086-.556.184-.448.27.108 1.638.82 2.011.999.316.146.374-.276.201-.373z"
        />
        <path
          fill="url(#icon-tractor-off_svg__y)"
          fillRule="nonzero"
          d="M58.743 7.328c-.011 0-.023-.007-.034-.007.052.458.098.822.098.822-.426-.451-.524-.72-.524-.72-.017-.065-.028-.138-.046-.225A7.979 7.979 0 0 0 56.893 7c-.972-.05-.891 1.345-.891 2.101s.402 6.085 1.04 6.659c.39.349 1.5.262 1.902.08.374-.174.77-.284.972-.865.19-.553.115-5.62-.816-7.35a.593.593 0 0 0-.357-.298z"
        />
        <path
          fill="url(#icon-tractor-off_svg__z)"
          fillRule="nonzero"
          d="M65.815 7.765c-.18-.127-2.923-1.63-3.222-1.74a.415.415 0 0 0-.346.028c.16.077.26.127.26.127.2.1.133.53-.227.381-.066-.028-.16-.072-.28-.121v.01c-.02.288.52.498 1.012.846.626.441 2.47 1.778 2.676 1.7 0 0 .26-.187.3-.474.033-.265.006-.63-.173-.757z"
        />
        <path
          fill="url(#icon-tractor-off_svg__A)"
          fillRule="nonzero"
          d="M67 50.955L63.116 51 63 47.038 66.877 47z"
        />
        <path
          fill="url(#icon-tractor-off_svg__B)"
          fillRule="nonzero"
          d="M99.912 39.553c-.158-.133-.473-.49-1.199-.553C87.144 39.721 78 48.64 78 59.532c0 1.757.24 3.458.685 5.082.35 1.029.815 1.386.815 1.386.295-.588.486-2.023.486-2.023a18.994 18.994 0 0 1-.198-2.737c0-9.997 6.555-18.313 17.822-20.02 0 0 1.918-.582 2.357-1.366a.23.23 0 0 0-.055-.301z"
        />
      </g>
    </g>
  </svg>
);

export default SvgIconTractorOff;
