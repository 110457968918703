import React from 'react';

const SvgIconView = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-view_svg__a"
        d="M10.344 21.219c0 .431-.35.781-.781.781H6.124A3.128 3.128 0 0 1 3 18.875V5.125A3.128 3.128 0 0 1 6.125 2h9.605a3.129 3.129 0 0 1 3.125 3.125v7.46a.781.781 0 0 1-1.562 0v-7.46c0-.862-.701-1.563-1.563-1.563H6.125c-.862 0-1.563.701-1.563 1.563v13.75c0 .862.701 1.563 1.563 1.563h3.438c.431 0 .78.35.78.78zm4.605-14.532H6.902a.781.781 0 1 0 0 1.563h8.047a.781.781 0 0 0 0-1.563zm.781 3.907a.781.781 0 0 0-.781-.781H6.902a.781.781 0 1 0 0 1.562h8.047c.431 0 .781-.35.781-.781zm-8.828 2.344a.781.781 0 1 0 0 1.562h4.106a.781.781 0 1 0 0-1.563H6.902zm13.886 5.722c-.028.04-.122.172-.18.246-.262.33-.875 1.1-1.727 1.78C17.79 21.558 16.664 22 15.54 22s-2.25-.442-3.342-1.314c-.852-.68-1.465-1.45-1.726-1.78a6.58 6.58 0 0 1-.18-.246.781.781 0 0 1 0-.898 6.58 6.58 0 0 1 .18-.246c.261-.33.874-1.1 1.726-1.78 1.093-.872 2.217-1.314 3.342-1.314s2.25.442 3.342 1.314c.852.68 1.465 1.45 1.726 1.78.059.073.153.207.18.246.19.27.19.629 0 .898zm-1.63-.45c-1.242-1.477-2.459-2.226-3.619-2.226-1.16 0-2.377.749-3.619 2.227 1.242 1.478 2.459 2.227 3.62 2.227 1.16 0 2.376-.75 3.618-2.227zm-3.58-1.483a1.484 1.484 0 1 0 0 2.968 1.484 1.484 0 0 0 0-2.968z"
      />
    </defs>
    <use fill="currentColor" xlinkHref="#icon-view_svg__a" />
  </svg>
);

export default SvgIconView;
