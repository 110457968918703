import React from 'react';

const SvgIconCar = props => (
  <svg width={32} height={23} viewBox="0 0 32 23" {...props}>
    <defs>
      <path
        id="icon-car_svg__a"
        d="M24.497 5.385l-2.061-.976-1.82-3.012A2.844 2.844 0 0 0 18.17 0h-7.752c-.9 0-1.752.431-2.299 1.173L5.77 4.365 1.85 5.478C.857 5.762.168 6.706.168 7.775V9.33c0 1.315 1.01 2.4 2.277 2.4h.337c.405 1.621 1.998 2.592 3.56 2.172a2.976 2.976 0 0 0 2.094-2.171h8.836c.405 1.62 1.998 2.591 3.56 2.171a2.976 2.976 0 0 0 2.094-2.171h.63c1.268 0 2.278-1.086 2.278-2.401V7.557c.006-.933-.52-1.784-1.336-2.172zM5.605 12.898c-1.03 0-1.867-.867-1.867-1.937s.836-1.937 1.867-1.937c1.03 0 1.867.868 1.867 1.937.005 1.064-.83 1.932-1.867 1.937zm14.49 0c-1.03 0-1.867-.867-1.867-1.937s.836-1.937 1.867-1.937c1.031 0 1.867.868 1.867 1.937.005 1.064-.83 1.932-1.867 1.937zm4.686-3.568c0 .715-.536 1.31-1.225 1.31h-.558c-.173-1.665-1.62-2.865-3.224-2.68-1.362.153-2.435 1.272-2.582 2.68H8.508c-.173-1.665-1.62-2.865-3.224-2.68-1.362.153-2.435 1.272-2.582 2.68h-.263c-.69 0-1.226-.595-1.226-1.31V7.775a1.28 1.28 0 0 1 .91-1.25L6.22 5.364a.513.513 0 0 0 .28-.196l2.45-3.334a1.836 1.836 0 0 1 1.468-.742h7.752a1.797 1.797 0 0 1 1.557.884L21.63 5.13a.525.525 0 0 0 .226.207l2.199 1.042c.441.213.725.671.72 1.179V9.33h.005z"
      />
    </defs>
    <use
      fill="currentColor"
      fillRule="evenodd"
      transform="matrix(-1 0 0 1 29 9)"
      xlinkHref="#icon-car_svg__a"
    />
  </svg>
);

export default SvgIconCar;
