import React from 'react';

const SvgIconHideMe = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-hide-me_svg__a"
        d="M17.86 5.813a.636.636 0 0 1 0 .798c-2.303 2.867-5.42 4.632-8.86 4.632-.958 0-1.89-.137-2.788-.396l1.414-1.328a4.008 4.008 0 0 0 1.62.235c1.882-.114 3.41-1.547 3.532-3.312a3.35 3.35 0 0 0-.25-1.52l2.151-2.018c1.173.757 2.245 1.742 3.18 2.909zm-12.638.166a3.355 3.355 0 0 0 .249 1.518L3.318 9.515C2.147 8.758 1.076 7.775.141 6.608a.636.636 0 0 1 0-.798C2.44 2.942 5.56 1.177 9 1.177c.957 0 1.888.137 2.784.396L10.37 2.9a4.01 4.01 0 0 0-1.617-.233c-1.885.11-3.413 1.543-3.53 3.312zm5.814.352c-.066.957-.891 1.727-1.904 1.786h-.011l1.915-1.796v.01zm-4.069-.245c.063-.953.888-1.724 1.904-1.786h.007l-1.91 1.793v-.007zm8.65-4.918L3.007 13 1.76 11.832 14.372 0l1.246 1.168z"
      />
    </defs>
    <use
      fill="#54575F"
      fillRule="evenodd"
      transform="translate(3 6)"
      xlinkHref="#icon-hide-me_svg__a"
    />
  </svg>
);

export default SvgIconHideMe;
