import React from 'react';

const SvgIconTractorOn = props => (
  <svg width={129} height={83} viewBox="0 0 129 83" {...props}>
    <defs>
      <linearGradient id="icon-tractor-on_svg__a" x1="47.607%" x2="53.09%" y1="14.232%" y2="85.76%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-on_svg__b"
        x1="28.158%"
        x2="83.601%"
        y1="-9.152%"
        y2="93.227%"
      >
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-on_svg__c"
        x1="36.128%"
        x2="63.463%"
        y1="48.019%"
        y2="52.346%"
      >
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-on_svg__d"
        x1="35.706%"
        x2="65.348%"
        y1="44.904%"
        y2="54.483%"
      >
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-tractor-on_svg__e" x1="29.506%" x2="85.89%" y1="-9.655%" y2="99.3%">
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-on_svg__f"
        x1="47.185%"
        x2="52.81%"
        y1="15.416%"
        y2="84.432%"
      >
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-on_svg__g"
        x1="21.814%"
        x2="76.053%"
        y1="-3.512%"
        y2="100.687%"
      >
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-on_svg__h"
        x1="24.118%"
        x2="52.421%"
        y1="9.114%"
        y2="46.619%"
      >
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-on_svg__i"
        x1="26.993%"
        x2="63.931%"
        y1="30.822%"
        y2="68.802%"
      >
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-on_svg__j"
        x1="47.417%"
        x2="53.074%"
        y1="14.372%"
        y2="85.943%"
      >
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-on_svg__k"
        x1="36.357%"
        x2="82.296%"
        y1="-12.752%"
        y2="88.274%"
      >
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-on_svg__l"
        x1="32.829%"
        x2="85.146%"
        y1="15.753%"
        y2="75.442%"
      >
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-on_svg__m"
        x1="35.512%"
        x2="67.633%"
        y1="44.848%"
        y2="54.629%"
      >
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-on_svg__n"
        x1="32.838%"
        x2="66.589%"
        y1="42.209%"
        y2="63.439%"
      >
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-on_svg__o"
        x1="25.377%"
        x2="69.157%"
        y1="29.087%"
        y2="74.35%"
      >
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-on_svg__p"
        x1="33.75%"
        x2="55.461%"
        y1="39.654%"
        y2="55.199%"
      >
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-on_svg__q"
        x1="-16.343%"
        x2="116.243%"
        y1="49.71%"
        y2="49.71%"
      >
        <stop offset="0%" stopOpacity={0} />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-on_svg__r"
        x1="29.632%"
        x2="70.384%"
        y1="36.362%"
        y2="63.668%"
      >
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-on_svg__s"
        x1="31.607%"
        x2="65.51%"
        y1="34.277%"
        y2="74.783%"
      >
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-on_svg__t"
        x1="31.352%"
        x2="65.248%"
        y1="34.401%"
        y2="74.912%"
      >
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-tractor-on_svg__u" x1="25%" x2="74.986%" y1="6.695%" y2="93.317%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-tractor-on_svg__v" x1="25.002%" x2="74.974%" y1="6.71%" y2="93.309%">
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-on_svg__w"
        x1="25.017%"
        x2="74.993%"
        y1="6.696%"
        y2="93.302%"
      >
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-on_svg__x"
        x1="25.015%"
        x2="75.004%"
        y1="6.684%"
        y2="93.313%"
      >
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-on_svg__y"
        x1="-51.875%"
        x2="151.944%"
        y1="50.209%"
        y2="50.209%"
      >
        <stop offset="0%" stopOpacity={0} />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient id="icon-tractor-on_svg__z" x1="17.844%" x2="61.322%" y1="-.82%" y2="79.483%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-on_svg__A"
        x1="34.845%"
        x2="63.677%"
        y1="4.275%"
        y2="93.163%"
      >
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-on_svg__B"
        x1="23.153%"
        x2="82.999%"
        y1="1.786%"
        y2="95.443%"
      >
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-on_svg__C"
        x1="19.27%"
        x2="80.706%"
        y1="-7.006%"
        y2="106.947%"
      >
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient
        id="icon-tractor-on_svg__D"
        x1="36.831%"
        x2="61.706%"
        y1="19.335%"
        y2="71.11%"
      >
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="url(#icon-tractor-on_svg__a)"
        fillRule="nonzero"
        d="M69.723 18.309s-1.841 1.472-2.58 3.02v.014c.035 10.17-.802 19.453-.802 19.453l1.025.02a.323.323 0 0 1 .314.321v2.637h.014l.383-19.034c.51.02 1.018.041 1.52.07.042-2.274.084-4.485.126-6.501z"
      />
      <path
        fill="url(#icon-tractor-on_svg__b)"
        fillRule="nonzero"
        d="M56.007 40.544l10.334.245s1.673-18.553.188-31.924a1.985 1.985 0 0 0-1.715-1.75C60.539 6.548 47.8 5.098 32.857 5.711c-.802.035-1.486.6-1.66 1.388-.453 2.05-1.178 7.122-1.618 19.418l.007.007c10.85.195 20.229 6.584 24.957 15.645.153.83-.028 1.185-.168 1.325a40.7 40.7 0 0 1 .963 1.883c.027-.181.167-.286.432-.286.146 0 .802-.056.941.119.133.167.328.39.495.39l.488-.642-1.701-.041a.323.323 0 0 1-.314-.321v-3.732c0-.181.147-.328.328-.32z"
      />
      <path
        fill="url(#icon-tractor-on_svg__c)"
        fillRule="nonzero"
        d="M35.144 1.395c1.422.593 1.701 1.423 2.928 1.541 1.228.119 20.71.356 21.066.356.355 0 .948-1.186 2.336-1.346 1.387-.16 7.921.119 9.148 1.346.349.349.607.635.795.858C69.507.474 65.762.942 54.69.488 47.535.202 34.439-.098 30.988.146c-3.452.245-4.365 2.016-4.365 2.016 1.548-.788 7.28-1.283 8.52-.767z"
      />
      <path
        fill="url(#icon-tractor-on_svg__d)"
        fillRule="nonzero"
        d="M71.926 4.924s-.076-.404-1.304-1.632c-1.227-1.227-7.76-1.506-9.148-1.346-1.388.16-1.98 1.346-2.336 1.346-.356 0-19.838-.237-21.066-.356-1.227-.118-1.506-.948-2.928-1.541-1.423-.593-8.828.16-8.912 1.15-.076.991.62 1.703.62 1.703.65 2.817 4.06 4.422 4.06 4.422.104-.663.201-1.18.292-1.577a1.783 1.783 0 0 1 1.66-1.388c14.943-.613 27.682.837 31.957 1.402a1.985 1.985 0 0 1 1.715 1.751c.035.286.063.572.09.865 3.445-.384 4.638-2.832 4.638-2.832 1.053-.725.662-1.967.662-1.967z"
      />
      <path
        fill="url(#icon-tractor-on_svg__e)"
        fillRule="nonzero"
        d="M55.68 40.865a.32.32 0 0 1 .327-.32l7.893.188.084-.007s2.329-23.261-.865-33.828l-.383-.049s-10.99-1.583-28.338-1.2c0 0-1.583 12.688-1.778 15.868a4.096 4.096 0 0 0 2.893 4.171C47.772 29.385 52.932 38.39 54.11 40.782c.196.397.593.662 1.04.697.167.014.348.021.536.014v-.628h-.007z"
      />
      <path
        fill="url(#icon-tractor-on_svg__f)"
        fillRule="nonzero"
        d="M32.32 5.817c-.558.202-.99.677-1.123 1.276-.432 1.946-1.102 6.633-1.548 17.64.007-.007.544-1.249.663-2.316.118-1.067 1.994-16.488 2.008-16.6z"
      />
      <path
        fill="url(#icon-tractor-on_svg__g)"
        fillRule="nonzero"
        d="M72.91 48.07c-.363-.976-4.463-3.313-5.14-3.69l-.097-.027v.509c0 .181-.147.32-.328.32l-9.65-.23-.489.642c-.16 0-.355-.223-.495-.39-.14-.175-.795-.119-.941-.119-.265 0-.405.105-.432.286-.028.181 1.101 4.024 1.276 6.64.174 2.616.32 10.26.32 10.644 0 .383.628 1.443.628 1.443l13.674-.62.913-11.097c1.15 2.448 1.15-3.25.76-4.31z"
      />
      <path
        fill="url(#icon-tractor-on_svg__h)"
        fillRule="nonzero"
        d="M57.569 64.098l-.628-1.443c.056-4.164-.118-8.691-.237-11.341-.67-3.795-1.36-5.943-1.36-5.943s-.355-.746-.962-1.883c-5.132-9.5-15.159-16.126-26.47-15.686-7.85.306-15.06 3.194-20.34 7.86 0 0-.571.851.586 1.04 1.165.188 2.134 0 2.134 0l.656.48C13.45 61.965 41.984 65.529 50.19 66.045c1.388.084 2.19.084 2.19.084l.544-1.827 4.644-.203z"
      />
      <path
        fill="url(#icon-tractor-on_svg__i)"
        fillRule="nonzero"
        d="M118.624 55.303l2.664-9.374 1.13-10.295a.448.448 0 0 0-.154-.383c-.383-.321-1.31-1.068-2.12-1.444-1.046-.488-25.549-8.084-52.08-9.081l-.391 19.592s4.839 2.685 5.23 3.745c.39 1.06.39 6.759-.747 4.325l-.92 11.09-18.318.83-.544 1.827h1.778c.893 0 1.75-.419 1.75-.419l.216.182h4.142v-.426h.502l.426.286h1.569c.788 0 1.213.537 1.213.537h.39s.572-.467.823-.746h1.778l.16-.411h.133s.321.516.62.537c.3.02.538-.244.677-.649l2.831-.056.084-.188h2.503a3.07 3.07 0 0 0 1.004-.175 2.916 2.916 0 0 1 1.005-.174h5.285l1.862-.3 35.5-8.83z"
      />
      <path
        fill="url(#icon-tractor-on_svg__j)"
        fillRule="nonzero"
        d="M69.472 45.713v-1.66c-.16-.035-.258-.154-.258-.154s.634-32.983.718-34.441c.084-1.458.181-2.267 1.436-3.927 1.256-1.66 4.428-2.832 4.428-2.832-.104 2.832-.648 4.408-.648 4.408-.872.405-1.353 1.353-1.576 2.065-.223.711-.586 34.755-.649 34.839-.062.084-.237.105-.237.105s.084 4.463-.14 5.551c-.222 1.089-1.296 1.102-1.296 1.102s-2.057-1.08-1.778-5.056z"
      />
      <path
        fill="url(#icon-tractor-on_svg__k)"
        fillRule="nonzero"
        d="M122.418 35.648c-2.441-.565-17.963-2.873-17.963-2.873 1.485 3.592 3.047 10.28 3.047 10.28 2.532 1.005 4.937 2.023 7.14 4.722 2.197 2.7 2.915 7.798 2.915 7.798l1.074-.265 2.664-9.374 1.123-10.288z"
      />
      <path
        fill="url(#icon-tractor-on_svg__l)"
        fillRule="nonzero"
        d="M122.041 39.122s-3.996-1.305-5.864-4.06c0 0 6.17.9 6.164 1.325l-.3 2.735z"
      />
      <path
        fill="url(#icon-tractor-on_svg__m)"
        fillRule="nonzero"
        d="M69.674 47.882s.495-.035.537-.167c.042-.133 0-1.71 0-1.877 0-.167-.711-.125-.914-.125-.202 0-12.084-.119-12.084-.119s-.014.119.084.363c.07.181.314 1.018.432 1.423.042.132.16.23.3.237l7.21.265h4.435z"
      />
      <path
        fill="url(#icon-tractor-on_svg__n)"
        fillRule="nonzero"
        d="M54.243 43.585s.537-.174.3-1.422c-4.944-9.48-14.971-16.035-26.449-15.638-4.372.154-8.354.977-11.882 2.358 0 .007-.411.579-.572 1.478 0 0-5.745 2.232-8.074 5.308 5.278-4.666 12.495-7.554 20.34-7.86 11.456-.454 21.463 6.2 26.337 15.776z"
      />
      <path
        fill="url(#icon-tractor-on_svg__o)"
        fillRule="nonzero"
        d="M122.676 59.767l.237-.502a.299.299 0 0 1 .279-.175h1.143c.342 0 .676.035 1.011.105.356.077.718.112 1.081.105l2.26-.035s.076-.977 0-1.493c-.077-.516-.14-2.392-.14-2.392s-3.305-1.032-5.976-1.472h-.537v.398h-2.106c-.613 0-1.15.404-1.318.997l.112 4.638h3.668c.132 0 .237-.07.286-.174z"
      />
      <path
        fill="url(#icon-tractor-on_svg__p)"
        fillRule="nonzero"
        d="M118.631 55.568s.74-.649 1.73-.746c.997-.098 2.057 0 2.057 0l1.094.32c-2.789-.076-3.068 1.026-3.556 1.354-.404.272-1.304.3-1.304.3l-.02-1.228z"
      />
      <path
        stroke="url(#icon-tractor-on_svg__q)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M69.472 44.053l3.214.07"
        opacity={0.5}
      />
      <path
        fill="url(#icon-tractor-on_svg__r)"
        fillRule="nonzero"
        d="M67.345 45.176l-11.352-.265a.323.323 0 0 1-.314-.321v-3.732c0-.181.147-.32.328-.32l11.352.265a.323.323 0 0 1 .314.32v3.732a.317.317 0 0 1-.328.32z"
      />
      <path
        fill="url(#icon-tractor-on_svg__s)"
        fillRule="nonzero"
        d="M60.602 40.35l-.474-.015a.266.266 0 0 0-.272.265v.035l1.011.021v-.042a.265.265 0 0 0-.265-.265z"
      />
      <path
        fill="url(#icon-tractor-on_svg__t)"
        fillRule="nonzero"
        d="M63.363 40.412l-.474-.014a.266.266 0 0 0-.272.265v.035l1.011.02v-.041a.27.27 0 0 0-.265-.265z"
      />
      <ellipse
        cx={102.091}
        cy={63.945}
        fill="url(#icon-tractor-on_svg__u)"
        fillRule="nonzero"
        rx={18.799}
        ry={18.804}
      />
      <ellipse
        cx={102.091}
        cy={63.945}
        fill="url(#icon-tractor-on_svg__v)"
        fillRule="nonzero"
        rx={10.669}
        ry={10.671}
      />
      <ellipse
        cx={26.086}
        cy={57.019}
        fill="url(#icon-tractor-on_svg__w)"
        fillRule="nonzero"
        rx={25.73}
        ry={25.737}
      />
      <ellipse
        cx={26.086}
        cy={57.019}
        fill="url(#icon-tractor-on_svg__x)"
        fillRule="nonzero"
        rx={14.594}
        ry={14.598}
      />
      <path
        stroke="url(#icon-tractor-on_svg__y)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M54.375 43.488c.44.746 1.004 1.771 1.004 1.771"
        opacity={0.5}
      />
      <path
        fill="url(#icon-tractor-on_svg__z)"
        fillRule="nonzero"
        d="M62.47 5.65s-2.265-1.36-2.767-1.619c-.502-.258-.788.363-.753.942.034.586.118 1.646.271 2.183 0 0 .119.258.635.69 0 0-.293-1.897-.293-2.427s-.104-.718.223-.578c.328.139 1.988 1.06 2.44 1.29.377.188.447-.356.245-.481z"
      />
      <path
        fill="url(#icon-tractor-on_svg__A)"
        fillRule="nonzero"
        d="M59.786 7.065c-.014 0-.028-.007-.042-.007.063.44.119.789.119.789-.516-.433-.635-.69-.635-.69-.02-.064-.034-.133-.055-.217a12.085 12.085 0 0 0-1.632-.188c-1.178-.05-1.08 1.29-1.08 2.015 0 .726.487 5.838 1.261 6.39.474.334 1.82.25 2.308.076.454-.167.935-.272 1.179-.83.23-.53.14-5.392-.99-7.052a.705.705 0 0 0-.433-.286z"
      />
      <path
        fill="url(#icon-tractor-on_svg__B)"
        fillRule="nonzero"
        d="M65.93 7.651c-.189-.16-3.062-2.057-3.375-2.197a.37.37 0 0 0-.363.035l.272.16c.21.126.14.67-.237.482-.07-.035-.168-.09-.293-.154v.014c-.021.363.544.628 1.06 1.067.655.558 2.587 2.246 2.803 2.149 0 0 .272-.237.314-.6.042-.335.014-.795-.182-.956z"
      />
      <path
        fill="url(#icon-tractor-on_svg__C)"
        fillRule="nonzero"
        d="M67.122 51l-3.947.048-.118-4.31 3.946-.042z"
      />
      <path
        fill="url(#icon-tractor-on_svg__D)"
        fillRule="nonzero"
        d="M99.462 40.328c-.16-.132-.48-.488-1.22-.55-11.777.718-21.086 9.604-21.086 20.456 0 1.751.244 3.446.697 5.064.356 1.025.83 1.381.83 1.381.3-.586.495-2.016.495-2.016a18.53 18.53 0 0 1-.202-2.727c0-9.96 6.673-18.246 18.144-19.948 0 0 1.952-.579 2.398-1.36a.243.243 0 0 0-.056-.3z"
      />
      <path d="M0-11h129v94H0z" />
    </g>
  </svg>
);

export default SvgIconTractorOn;
