import React from 'react';

const SvgIconBack = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-back_svg__a"
        d="M2.54 7.723l4.997 5.016a.705.705 0 0 1 0 1.013.683.683 0 0 1-.508.248.712.712 0 0 1-.51-.21L.292 7.58a.717.717 0 0 1-.26-.377.704.704 0 0 1 .184-.712L6.52.214a.712.712 0 0 1 1.017 0c.28.28.28.733 0 1.012L2.451 6.29h18.83c.397 0 .719.32.719.716a.718.718 0 0 1-.72.717H2.54z"
      />
    </defs>
    <use
      fill="#FF0000"
      fillRule="evenodd"
      transform="translate(1 5)"
      xlinkHref="#icon-back_svg__a"
    />
  </svg>
);

export default SvgIconBack;
