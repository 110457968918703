import React from 'react';

const SvgIconDollar = props => (
  <svg width={37} height={37} viewBox="0 0 37 37" {...props}>
    <defs>
      <path id="icon-dollar_svg__a" d="M0 .32h35.68V36H0z" />
      <path id="icon-dollar_svg__c" d="M0 .318h34.996v35.545H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#F9DCDF"
        d="M18.322 28.777c-.017.95-.74 1.713-1.63 1.713-.866 0-1.572-.725-1.625-1.639-2.317-.7-4.037-3.247-4.037-6.273 0-.965.729-1.748 1.63-1.748.901 0 1.631.783 1.631 1.748 0 1.582.964 2.97 2.064 2.97.58 0 1.434-.148 2.092-.567.693-.442 1.001-1.065 1.001-2.024 0-1.628-1.94-2.548-2.43-2.59-1.784-.006-5.668-1.36-5.668-5.363 0-3.265 1.93-4.96 3.712-5.67V9.22c0-.965.73-1.749 1.63-1.749.87 0 1.577.73 1.627 1.647.89.271 1.744.815 2.458 1.578 1.186 1.272 1.186 2.537 1.186 3.654 0 .966-.73 1.751-1.63 1.751-.901 0-1.632-.779-1.632-1.744 0-.942-.022-.965-.231-1.19-.435-.465-.986-.754-1.44-.754-.024 0-2.418.071-2.418 2.591 0 1.64 2.394 1.868 2.419 1.868 1.927 0 5.68 2.148 5.68 6.086 0 3.284-2.033 5.163-4.39 5.819M17 4c-3.166 0-6.152 1.104-8.636 3.194a14.463 14.463 0 0 0-1.599 1.572 15.017 15.017 0 0 0-1.918 2.782A15.833 15.833 0 0 0 3 19c0 8.27 6.28 15 14 15 7.719 0 14-6.73 14-15 0-8.272-6.281-15-14-15"
      />
      <g transform="translate(1 1)">
        <mask id="icon-dollar_svg__b" fill="#fff">
          <use xlinkHref="#icon-dollar_svg__a" />
        </mask>
        <path
          fill="#F78092"
          d="M5.28 10.971a14.512 14.512 0 0 1 3.635-4.2 14.322 14.322 0 0 1 8.925-3.08c7.978 0 14.47 6.49 14.47 14.468 0 7.98-6.492 14.47-14.47 14.47-7.978 0-14.47-6.49-14.47-14.47 0-2.525.661-5.01 1.91-7.188M17.84 36c9.838 0 17.84-8.003 17.84-17.84S27.679.32 17.84.32a17.658 17.658 0 0 0-11.007 3.8 17.93 17.93 0 0 0-4.477 5.174A17.839 17.839 0 0 0 0 18.158C0 27.997 8.003 36 17.84 36"
          mask="url(#icon-dollar_svg__b)"
        />
      </g>
      <path
        fill="#F78092"
        d="M20.62 24.735c-.676.4-1.554.542-2.149.542-1.13 0-2.12-1.327-2.12-2.84 0-.922-.75-1.67-1.676-1.67-.926 0-1.675.748-1.675 1.67 0 2.893 1.767 5.326 4.147 5.997.055.873.78 1.566 1.67 1.566.915 0 1.657-.73 1.674-1.638 2.42-.626 4.509-2.422 4.509-5.56 0-3.764-3.855-5.817-5.835-5.817-.025 0-2.485-.218-2.485-1.786 0-2.408 2.46-2.476 2.485-2.476.466 0 1.032.276 1.479.72.214.215.237.238.237 1.138 0 .922.751 1.667 1.676 1.667.925 0 1.675-.751 1.675-1.674 0-1.067 0-2.277-1.218-3.493-.734-.729-1.612-1.248-2.526-1.508A1.672 1.672 0 0 0 18.818 8c-.926 0-1.676.749-1.676 1.67v.11c-1.83.678-3.813 2.298-3.813 5.419 0 3.826 3.99 5.12 5.824 5.126.503.04 2.496.92 2.496 2.476 0 .916-.317 1.511-1.03 1.934"
      />
      <mask id="icon-dollar_svg__d" fill="#fff">
        <use xlinkHref="#icon-dollar_svg__c" />
      </mask>
      <path
        fill="#FF1D46"
        d="M17.498 32.505c-7.825 0-14.192-6.466-14.192-14.415 0-2.516.648-4.992 1.872-7.16a14.421 14.421 0 0 1 3.566-4.185 13.908 13.908 0 0 1 8.754-3.069c7.824 0 14.191 6.466 14.191 14.414 0 7.949-6.367 14.415-14.191 14.415m0-32.187c-3.957 0-7.69 1.309-10.796 3.785A17.839 17.839 0 0 0 2.31 9.258 17.987 17.987 0 0 0 0 18.09c0 9.8 7.85 17.773 17.498 17.773 9.648 0 17.498-7.972 17.498-17.773 0-9.799-7.85-17.772-17.498-17.772"
        mask="url(#icon-dollar_svg__d)"
      />
      <path
        fill="#FF1D46"
        d="M18.165 16.985c-.025 0-2.485-.218-2.485-1.786 0-2.408 2.46-2.476 2.485-2.476.465 0 1.032.276 1.478.72.215.215.238.237.238 1.138 0 .922.75 1.667 1.676 1.667.925 0 1.675-.751 1.675-1.673 0-1.068 0-2.278-1.218-3.494-.734-.729-1.612-1.248-2.526-1.508A1.672 1.672 0 0 0 17.818 8c-.926 0-1.676.749-1.676 1.67v.11c-1.83.678-3.813 2.299-3.813 5.419 0 3.826 3.99 5.12 5.824 5.125.503.041 2.496.92 2.496 2.476 0 .917-.317 1.512-1.03 1.935-.675.4-1.553.542-2.148.542-1.13 0-2.12-1.327-2.12-2.84 0-.922-.75-1.67-1.676-1.67-.926 0-1.675.748-1.675 1.67 0 2.893 1.767 5.326 4.147 5.997.055.873.78 1.566 1.67 1.566.915 0 1.657-.73 1.674-1.638C21.911 27.736 24 25.939 24 22.8c0-3.763-3.855-5.816-5.835-5.816"
      />
    </g>
  </svg>
);

export default SvgIconDollar;
