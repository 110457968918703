import React from 'react';

const SvgIconCircleFacebook = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-circle-facebook_svg__a"
        d="M13.873 6.638a.995.995 0 0 0-.715.27.95.95 0 0 0-.298.69v1.12h2.594l-.438 2.47H12.89V20H9.82v-8.813H8.5v-2.44h1.32V6.871c0-.785.327-1.535.907-2.077a2.97 2.97 0 0 1 2.163-.79l2.61.112v2.522h-1.627zM12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12zm0-1c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11z"
      />
    </defs>
    <use fill="currentColor" fillRule="evenodd" xlinkHref="#icon-circle-facebook_svg__a" />
  </svg>
);

export default SvgIconCircleFacebook;
