import React from 'react';

const IconMySales = ({ customColor, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 25 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0-2h24v24H0z" />
      <path
        className="test"
        fill={customColor}
        fillRule="nonzero"
        d="M23.9 5.9l-4-5.2c-.1-.2-.4-.2-.6-.1l-2.6 1.6h-3.5l-2.7.8-2.7-.8L5.2.6C5 .4 4.7.5 4.5.7L.1 6.2c-.2.2-.1.4.1.6l2.3 2 2.1 2.8c-.6.7-.6 1.7 0 2.3.3.3.6.4 1 .5v.3c0 .4.2.9.5 1.2.3.3.7.5 1.2.5 0 .4.2.9.5 1.2.3.3.7.4 1.2.4h.3c0 .4.2.7.5 1 .3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l.3-.3.1.1c.3.3.7.5 1.2.5.4 0 .9-.2 1.2-.5.3-.3.5-.8.5-1.2.4 0 .9-.2 1.2-.5.3-.3.5-.8.5-1.2.5 0 .9-.2 1.2-.5.3-.3.5-.8.5-1.2.4 0 .9-.2 1.2-.5.6-.6.6-1.4.2-2.1l1.5-3.2 2.3-2c-.1 0 0-.3-.2-.5zM5 1.6l1.9 1.2-4.1 5-1.7-1.4L5 1.6zm.3 10.7l.6-.6H6c.1-.1.2-.1.4-.2h.1c.2 0 .4.1.5.2.3.3.3.7 0 1l-.6.6c-.3.3-.7.3-1 0-.4-.3-.4-.8-.1-1zm1.5 2.9c-.1-.1-.2-.3-.2-.5s.1-.4.2-.5l1.1-1.1c.2-.2.5-.3.8-.1.3 0 .4.4.4.7 0 .2-.1.3-.2.4l-1.1 1.1c-.1.1-.3.2-.5.2s-.4-.1-.5-.2zm1.6 1.7c-.1-.1-.2-.3-.2-.5s.1-.4.2-.5l1.1-1.1c.2-.2.6-.3.9-.1.3.1.4.4.3.7 0 .2-.1.3-.2.4l-1.1 1.1c-.1.1-.3.2-.4.2-.1 0-.4-.1-.6-.2zm2 1.5c-.3-.3-.3-.7 0-1l.6-.6h.1c.1-.1.2-.1.3-.1h.2c.2 0 .4.1.5.2v.1c.2.3.2.7-.1 1l-.6.6c-.3 0-.8 0-1-.2zm8.7-5.3c-.3.3-.7.3-1 0l-3-3.1c-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7l3.1 3.1c.3.3.3.7 0 1-.1.1-.3.2-.5.2s-.4-.1-.5-.2l-2-2.1c-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7l2 2c.3.3.3.7 0 1-.3.3-.7.3-1 0l-1.1-1.1c-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7l1.1 1.1c.3.3.3.7 0 1-.3.3-.7.3-1 0L13 18v-.1s0-.1.1-.1v-.1c.1-.2.1-.5.1-.7-.1-.5-.3-.9-.7-1.1-.2-.1-.4-.2-.7-.3.1-.5 0-1.1-.4-1.5-.3-.3-.7-.5-1.2-.5 0-.4-.1-.9-.4-1.2-.3-.3-.7-.5-1.2-.5h-.4c-.1-.4-.3-.8-.6-1-.3-.3-.7-.4-1.1-.4-.4 0-.8.1-1.1.4L3.6 8.4 8 3.1l1.4.4-2 3.3c-.3.4-.3.8-.2 1.2.1.4.4.8.8 1h.1c.1 0 .1.1.2.1.3.1.7.1 1 0 .4-.1.8-.4 1-.8l1.2-2.1 2.1.1 5.6 5.6c.2.5.2.9-.1 1.2zm.2-2.1L14 5.7c-.1-.1-.2-.1-.3-.1l-2.6-.2c-.2 0-.4.1-.4.2L9.4 8c-.1.2-.2.3-.4.3-.2.1-.4 0-.5-.1-.2-.1-.3-.2-.4-.4-.1-.2 0-.4.1-.5L10 4.2l.7-.2 2.7-.8h3.2l4 5.1-1.3 2.7zm1.9-3.5l-3.7-4.7 1.9-1.2 3.5 4.5-1.7 1.4z"
       />
    </g>
  </svg>
);

export default IconMySales;
