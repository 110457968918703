import React from 'react';

const SvgIconPart = props => (
  <svg width={32} height={23} viewBox="0 0 32 23" {...props}>
    <defs>
      <path
        id="icon-part_svg__a"
        d="M16.04 11.635a.43.43 0 0 0-.427.431v2.07H9.386l-2.697-2.273a.426.426 0 0 0-.274-.1H4.012V4.076h1.7a.43.43 0 0 0 .429-.432v-.798h7.031v2.072a.43.43 0 0 0 .429.432h2.012v1.11a.43.43 0 0 0 .428.432h1.844a.43.43 0 0 0 .429-.432V5.162h.938l.844 1.977-.02 4.346-.824 1.88h-.938v-1.299a.43.43 0 0 0-.429-.431h-1.844zm1.845 2.593h1.647c.169 0 .322-.1.39-.257l.994-2.266a.435.435 0 0 0 .037-.175V6.997a.433.433 0 0 0-.037-.174l-.993-2.267a.427.427 0 0 0-.391-.257h-1.647a.43.43 0 0 0-.428.432v1.298h-.988V4.92a.43.43 0 0 0-.428-.432h-2.012V2.414a.43.43 0 0 0-.428-.432h-3.517V.863h3.517a.43.43 0 0 0 .428-.432A.43.43 0 0 0 13.6 0H5.712a.43.43 0 1 0 0 .863h3.516v1.12H5.712a.43.43 0 0 0-.428.431v.798h-1.7a.43.43 0 0 0-.428.432v3.844H1.764V3.644a.43.43 0 0 0-.428-.432.43.43 0 0 0-.429.432v8.551a.43.43 0 0 0 .429.431.43.43 0 0 0 .428-.431V8.351h1.392v3.844a.43.43 0 0 0 .428.431h2.675L8.956 14.9a.426.426 0 0 0 .275.101h6.81a.43.43 0 0 0 .428-.432v-2.07h.988v1.298a.43.43 0 0 0 .428.432z"
      />
    </defs>
    <use
      fill="currentColor"
      fillRule="evenodd"
      transform="translate(5 7)"
      xlinkHref="#icon-part_svg__a"
    />
  </svg>
);

export default SvgIconPart;
