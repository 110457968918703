import React from 'react';

const SvgIconTruck = props => (
  <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
    <defs>
      <path
        id="icon-truck_svg__a"
        d="M19.95 4.993l-1.369-2.276c-.47-.768-.913-1.217-1.8-1.293a.365.365 0 0 0-.09-.012h-2.74V.701A.708.708 0 0 0 13.237 0H.714A.708.708 0 0 0 0 .7v8.774c0 .386.32.7.714.7h.745c.171.994 1.054 1.753 2.114 1.753 1.06 0 1.943-.759 2.113-1.752h8.647c.17.993 1.053 1.752 2.114 1.752 1.06 0 1.942-.759 2.113-1.752h1.083a.354.354 0 0 0 .357-.35V5.17a.346.346 0 0 0-.05-.178zm-.878-.082H15.79a.366.366 0 0 0-.073-.007.362.362 0 0 1-.365-.358V3.142h2.654l1.065 1.769zM.714.701h12.523v8.773h-7.55A2.139 2.139 0 0 0 3.572 7.72c-1.06 0-1.943.76-2.114 1.753H.714V.7zm2.86 10.526c-.79 0-1.43-.63-1.43-1.403s.64-1.402 1.43-1.402c.787 0 1.428.629 1.428 1.402 0 .774-.64 1.403-1.429 1.403zm12.873 0c-.789 0-1.43-.63-1.43-1.403s.641-1.402 1.43-1.402c.788 0 1.429.629 1.429 1.402 0 .774-.641 1.403-1.43 1.403zm0-3.506c-1.06 0-1.943.76-2.114 1.753h-.382v-7.36h2.548c.456 0 .74.093.991.328h-2.494a.354.354 0 0 0-.357.35v1.754c0 .555.437 1.01.99 1.055.029.007.058.01.089.01h3.568v3.864h-.726c-.17-.994-1.053-1.753-2.113-1.753z"
      />
    </defs>
    <use
      fill="currentColor"
      fillRule="nonzero"
      xlinkHref="#icon-truck_svg__a"
      transform="translate(1 5)"
    />
  </svg>
);

export default SvgIconTruck;
