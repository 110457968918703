import React from 'react';

const SvgIconArrowdown = props => (
  <svg width={25} height={25} viewBox="0 0 25 25" {...props}>
    <defs>
      <path
        id={`icon-arrowdown_svg__a${props.customId}`}
        d="M16.681 5.215l-9.8-9.89a1.097 1.097 0 0 0-1.558 0 1.116 1.116 0 0 0 0 1.57l9.023 9.104-9.022 9.106a1.117 1.117 0 0 0 0 1.57c.43.433 1.128.433 1.558 0l9.8-9.89a1.128 1.128 0 0 0 0-1.57z"
      />
    </defs>
    <use
      fill="currentColor"
      transform="rotate(90 8.5 9.5)"
      xlinkHref={`#icon-arrowdown_svg__a${props.customId}`}
      fillRule="evenodd"
    />
  </svg>
);

export default SvgIconArrowdown;
