import React from 'react';

const SvgIconSell = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-sell_svg__a"
        d="M21.35 16.077a1.859 1.859 0 0 1-.97 2.437l-7.08 3.034a5.497 5.497 0 0 1-4.656-.137l-3.181-1.6v.332a.617.617 0 0 1-.616.62H1.115a.617.617 0 0 1-.615-.62v-8.662c0-.342.275-.619.615-.619h2.502c.922 0 1.688.683 1.824 1.573l1.522-.438a3.053 3.053 0 0 1 2.221.208l5.102 2.566a1.84 1.84 0 0 1 1.016 1.533l3.726-1.25c.896-.3 1.933.107 2.322 1.023zM4.232 19.525v-6.806a.618.618 0 0 0-.615-.62H1.73v7.426h2.502zm15.666-2.149a.62.62 0 0 0 .32-.812c-.129-.305-.493-.439-.8-.336l-4.723 1.584a1.84 1.84 0 0 1-2.06.28L9.591 16.56a.615.615 0 0 0-.757.169.613.613 0 0 1-.864.1.62.62 0 0 1-.1-.87 1.847 1.847 0 0 1 2.272-.506l3.044 1.53c.298.15.67.035.825-.276a.621.621 0 0 0-.275-.83l-5.102-2.566a1.83 1.83 0 0 0-1.333-.125l-1.838.529v4.712l3.73 1.876a4.273 4.273 0 0 0 3.622.106l7.083-3.034zm-4.552-9.374a.6.6 0 0 1 .18 0 .588.588 0 0 0-.047-1.175 1.826 1.826 0 0 1-.665-3.529V3.12a.62.62 0 1 1 1.238 0v.145A1.83 1.83 0 0 1 17.306 5a.62.62 0 1 1-1.239 0 .589.589 0 1 0-.588.589 1.827 1.827 0 0 1 .573 3.562v.156a.62.62 0 1 1-1.238 0v-.19a1.83 1.83 0 0 1-1.162-1.7.62.62 0 0 1 1.239-.001c0 .299.224.55.455.586zm.029 4.098c-3.262 0-5.906-2.66-5.906-5.94 0-3.28 2.644-5.94 5.906-5.94 3.262 0 5.906 2.66 5.906 5.94 0 3.28-2.644 5.94-5.906 5.94zm0-1.32c2.537 0 4.594-2.068 4.594-4.62s-2.057-4.62-4.594-4.62c-2.537 0-4.594 2.068-4.594 4.62s2.057 4.62 4.594 4.62z"
      />
    </defs>
    <use fill="currentColor" transform="translate(1 1)" xlinkHref="#icon-sell_svg__a" />
  </svg>
);

export default SvgIconSell;
