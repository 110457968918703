import React from 'react';

const SvgIconBusOn = props => (
  <svg width={294} height={84} viewBox="0 0 294 84" {...props}>
    <defs>
      <linearGradient id="icon-bus-on_svg__a" x1="50.001%" x2="50.001%" y1="-98.694%" y2="93.832%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-bus-on_svg__b" x1="30.71%" x2="66.28%" y1="41.957%" y2="58.479%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-bus-on_svg__c" x1="26.777%" x2="88.17%" y1="-11.196%" y2="102.26%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-bus-on_svg__d" x1="100.048%" x2=".017%" y1="50.648%" y2="50.648%">
        <stop offset="0%" stopOpacity={0} />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient id="icon-bus-on_svg__e" x1="37.064%" x2="63.162%" y1="49.068%" y2="50.917%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-bus-on_svg__f" x1="36.639%" x2="63.536%" y1="48.767%" y2="51.198%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-bus-on_svg__g" x1="41.206%" x2="55.106%" y1="9.426%" y2="87.972%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-bus-on_svg__h" x1="22.432%" x2="93.311%" y1="-8.395%" y2="100.465%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-bus-on_svg__i" x1="38.721%" x2="63.636%" y1="46.91%" y2="53.743%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-bus-on_svg__j" x1="24.996%" x2="75.003%" y1="6.692%" y2="93.307%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-bus-on_svg__k" x1="24.997%" x2="75%" y1="6.695%" y2="93.303%">
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
      <linearGradient id="icon-bus-on_svg__l" x1="24.988%" x2="74.995%" y1="6.692%" y2="93.307%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-bus-on_svg__m" x1="24.985%" x2="74.988%" y1="6.695%" y2="93.303%">
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
      <linearGradient id="icon-bus-on_svg__n" x1="41.68%" x2="57.386%" y1="8.37%" y2="95.285%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-bus-on_svg__o" x1="23.734%" x2="76.632%" y1="-4.805%" y2="105.58%">
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
      <linearGradient id="icon-bus-on_svg__p" x1="22.045%" x2="77.972%" y1="25.587%" y2="74.342%">
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
      <linearGradient id="icon-bus-on_svg__q" x1="31.452%" x2="65.726%" y1="2.905%" y2="101.124%">
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
      <linearGradient id="icon-bus-on_svg__r" x1="41.903%" x2="57.264%" y1="7.917%" y2="94.894%">
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
      <linearGradient id="icon-bus-on_svg__s" x1="30.342%" x2="79.243%" y1="24.76%" y2="71.649%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-bus-on_svg__t" x1="38.417%" x2="68.218%" y1="-2.533%" y2="91.82%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-bus-on_svg__u" x1="33.951%" x2="90.368%" y1="13.694%" y2="77.871%">
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
      <linearGradient id="icon-bus-on_svg__v" x1="35.35%" x2="64.646%" y1="47.295%" y2="52.691%">
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
      <linearGradient id="icon-bus-on_svg__w" x1="35.255%" x2="64.748%" y1="46.856%" y2="53.14%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <path
          fill="url(#icon-bus-on_svg__a)"
          d="M63.705 75.086s11.44-4.07 26.774-.042l30.571-13.51 81.372-2.304 8.158 15.87s14.09-1.885 26.712.9L249 72.115l-5.15-18.117L53 51l1.332 19.1 9.373 4.986z"
        />
        <path
          fill="url(#icon-bus-on_svg__b)"
          d="M286.82 51.46c-.58-4.642-7.598-34.522-9.642-43.177a3.675 3.675 0 0 0-2.955-2.775c-7.687-1.306-33.1-5.189-65.169-5.414C172.993-.16 25.428.176 6.128.224c-1.286 0-2.4.86-2.729 2.105C2.442 5.925.746 14.319.643 28.9.527 46.067.123 65.454 0 71.141a1.78 1.78 0 0 0 1.608 1.812c6.681.636 31.664 2.987 36.014 3.104 2.831.075 16.235.068 27.397.04-.185-1.408-.28-3.048-.28-4.949 0-7.472 6.059-13.528 13.534-13.528 7.475 0 13.534 6.056 13.534 13.528 0 1.846-.041 3.473-.13 4.909l119.614.047c-.185-1.415-.28-3.055-.28-4.963 0-7.471 6.059-13.528 13.534-13.528 7.475 0 13.534 6.057 13.534 13.528 0 2.263-.068 4.198-.191 5.859h40.616a7.986 7.986 0 0 0 7.967-7.527c.39-6.296.76-14.772.35-18.013z"
        />
        <path
          fill="url(#icon-bus-on_svg__c)"
          d="M279 52s-.098-25.546-7.03-40.654c-.72-1.566-2.135-2.73-3.83-3.173-1.925-.503-5.085-1.137-10.1-1.695C247.293 5.28 239.71 4.728 236 5.13c0 0 14.94 3.602 17.346 9.655 3.202 8.06 3.46 37.024 3.46 37.024L279 52z"
        />
        <path
          fill="url(#icon-bus-on_svg__d)"
          d="M6.59 1.02H.679C.582 1.02.498.9.498.76S.582.5.678.5h5.913C7.636.5 8.273.24 8.273.23c.097-.04.194.04.222.17.027.14-.028.28-.118.32-.028.03-.685.3-1.786.3z"
          opacity={0.5}
          transform="translate(278 61)"
        />
        <path
          fill="url(#icon-bus-on_svg__e)"
          d="M92 66h115.225c1.726 0 3.775.62 3.775.62s-.371 1.489-.468 3.38c0 0-1.622-.427-3.348-.427H92.605S92.419 67.347 92 66z"
        />
        <path
          fill="url(#icon-bus-on_svg__f)"
          d="M8.49 69h54.928c.137-1.602.582-3 .582-3H5v2.389L8.49 69z"
        />
        <path
          fill="url(#icon-bus-on_svg__g)"
          d="M.58 64H0l.308-17H.58C3.018 47 5 48.895 5 51.241v8.518C5 62.098 3.018 64 .58 64z"
        />
        <path
          fill="url(#icon-bus-on_svg__h)"
          d="M.785 6.618h1.82V4.391h-2.3s.383 1.075.48 2.227z"
          transform="translate(238 63)"
        />
        <path
          fill="url(#icon-bus-on_svg__i)"
          d="M19.819.26h-.347v.268c0 2.132-2.05 3.857-4.58 3.857H3.732v2.227h30.834a6.338 6.338 0 0 0 2.87-.681c1.89-.955 3.064-2.781 3.064-4.76V.255H19.819V.26z"
          transform="translate(238 63)"
        />
        <circle cx={224.5} cy={71.5} r={12.5} fill="url(#icon-bus-on_svg__j)" />
        <circle cx={224.5} cy={70.5} r={7.5} fill="url(#icon-bus-on_svg__k)" />
        <circle cx={77.5} cy={71.5} r={12.5} fill="url(#icon-bus-on_svg__l)" />
        <circle cx={77.5} cy={70.5} r={7.5} fill="url(#icon-bus-on_svg__m)" />
        <path
          fill="url(#icon-bus-on_svg__n)"
          d="M286.924 52.024s-3.843.034-5.244 0c0 0 .076-18.987-7.694-41.911a.79.79 0 0 1 .597-1.03c.597-.11 1.496-.225 2.698-.225l-.234-.826a11.212 11.212 0 0 0-2.416.075c-1.194.17-1.935 1.392-1.51 2.518 7.935 21.102 7.55 42.375 7.55 42.375H287l-.076-.976z"
        />
        <path fill="url(#icon-bus-on_svg__o)" d="M0 61h5v-6H.112z" />
        <path fill="url(#icon-bus-on_svg__p)" d="M17 66h5v3h-5z" />
        <path
          fill="url(#icon-bus-on_svg__q)"
          d="M277 52s-.335-18.705-2.96-27.86c-2.626-9.154-3.275-12.54-7.988-13.62-3.764-.867-11.535-1.559-12.687-1.518-.09 0-.181.013-.272.033-1.027.229-1.438 1.417-.761 2.197.747.852 1.634 2.095 2.465 3.808 2.61 5.4 3.288 36.799 3.288 36.799L277 52z"
        />
        <path
          fill="url(#icon-bus-on_svg__r)"
          d="M285.959 51.59c-.578-4.551-7.466-33.595-9.618-42.59a15.05 15.05 0 0 0-2.701.224.787.787 0 0 0-.598 1.026c7.782 22.828 7.706 41.735 7.706 41.735 1.402.034 5.245 0 5.252 0a3.469 3.469 0 0 0-.041-.394z"
        />
        <path
          fill="url(#icon-bus-on_svg__s)"
          d="M264.007 16.632a.64.64 0 0 1 .66-.632c3.098.076 16.177.41 19.452.86 3.723.507 6.33 1.236 7.971 4.95 1.641 3.712 2.614 10.042 1.293 10.979-1.016.722-2.167-.555-2.16-1.603.007-.507-.376-3.894-1.314-5.997-.938-2.11-3.282-5.587-7.033-6.184-2.934-.465-14.464-.833-17.903-.937a.992.992 0 0 1-.973-.978v-.458h.007z"
        />
        <path
          fill="url(#icon-bus-on_svg__t)"
          d="M292.265 32.87c.351.18.745.193 1.124-.084 1.308-.934.345-7.25-1.279-10.945-.056-.132-.12-.263-.176-.388C289.516 19.53 287 20.07 287 20.07c5.293 4.172 5.265 12.792 5.265 12.8z"
        />
        <path
          fill="url(#icon-bus-on_svg__u)"
          d="M286.76 66s-2.655-.03-4.606-1.11c-2.32-1.287-2.151-3.684-2.151-3.684h4.99C286.2 61.206 287 61 287 61l-.24 5z"
        />
        <path
          fill="url(#icon-bus-on_svg__v)"
          d="M245.702 17.272c-3.46-4.5-16.957-9.546-35.345-10.724-.399-.027-.867-.047-1.41-.075l-2.628-.088c-9.012-.252-28.218-.354-51.332-.381h-2.628c-16.048-.014-33.824.013-51.304.054l-2.628.007c-12.726.034-25.246.068-36.768.109l-2.628.007c-24.896.088-44.721.17-51.222.17C6.138 6.35 6 7.2 6 8.53c0 2.628.371 7.074 1.43 10.084 1.59 4.507 4.548 5.78 9.9 7.469 5.345 1.695 13.194 4.548 28.775 5.188 1.926.082 6.46.15 12.919.212l2.628.027c9.431.081 22.24.15 36.769.21l2.627.014c15.925.062 33.735.11 51.305.15l2.627.007c18.285.04 36.198.068 51.332.082h2.628c16.517.02 29.401.027 35.303.027 2.828 0 3.784-1.307 3.757-3.146-.062-3.554-.51-9.253-2.298-11.582z"
        />
        <path
          fill="url(#icon-bus-on_svg__w)"
          d="M246.896 16.598c-3.481-5.016-17.062-10.645-35.563-11.952-17.48-1.236-181.574-.34-202.966-.22-1.302.008-2.35 1.089-2.364 2.424-.027 2.924.103 7.89 1.172 11.238 1.603 5.03 4.577 6.442 9.956 8.328 5.379 1.887 13.273 5.072 28.95 5.779 14.396.65 173.482.79 199.342.805 1.993 0 3.604-1.674 3.577-3.73-.055-3.948-.302-10.08-2.104-12.672zM59.765 31.474c-6.434-.063-10.95-.141-12.868-.219-15.52-.657-23.339-3.624-28.663-5.382-5.324-1.76-8.27-3.073-9.86-7.75-1.062-3.122-1.425-7.734-1.425-10.461 0-1.377.137-2.26 1.802-2.26 6.475 0 26.223-.092 51.02-.177v26.25h-.006zm39.242.247c-14.472-.063-27.23-.134-36.625-.219V5.218c11.478-.043 23.942-.085 36.625-.113V31.72zm53.713.163c-17.5-.042-35.24-.092-51.103-.155V5.09c17.412-.043 35.117-.07 51.103-.057v26.85zm53.748.092c-15.074-.021-32.917-.043-51.13-.085V5.041c23.023.029 42.154.134 51.13.396v26.539zm37.783.028c-5.88 0-18.706-.007-35.158-.028V5.529c.541.02 1.014.05 1.404.077 18.316 1.215 31.76 6.457 35.206 11.126 1.782 2.416 2.227 8.328 2.29 12.008.026 1.915-.933 3.264-3.742 3.264z"
        />
      </g>
      <path d="M0-10h294v94H0z" />
    </g>
  </svg>
);

export default SvgIconBusOn;
