import React from 'react';

const SvgIconMecanica = props => (
  <svg width={19} height={19} viewBox="0 0 40 40" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="#54575F" fillRule="nonzero">
        <path d="M14.133 19.493a5.61 5.61 0 005.607-5.606 5.61 5.61 0 00-5.607-5.607 5.61 5.61 0 00-5.606 5.607c.006 3.093 2.52 5.606 5.606 5.606zm-4.66-5.606a4.665 4.665 0 014.66-4.66 4.665 4.665 0 014.66 4.66 4.665 4.665 0 01-4.66 4.66c-2.566.006-4.66-2.087-4.66-4.66z" />
        <path d="M14.133 16.72a2.84 2.84 0 002.834-2.833 2.84 2.84 0 00-2.834-2.834 2.84 2.84 0 00-2.833 2.834 2.84 2.84 0 002.833 2.833zm-1.886-2.833c0-1.04.846-1.894 1.893-1.894 1.04 0 1.893.847 1.893 1.894 0 1.04-.846 1.893-1.893 1.893a1.896 1.896 0 01-1.893-1.893zm8.506 4.426a.566.566 0 10.001-1.132.566.566 0 000 1.132zm-13.226 0a.566.566 0 100-1.132.566.566 0 000 1.132zm6.606 2.78a.566.566 0 100 1.133.566.566 0 000-1.133zm6.62-10.406a.566.566 0 10.001-1.133.566.566 0 000 1.133zm-13.226 0a.566.566 0 100-1.133.566.566 0 000 1.133zm6.606-3.854a.566.566 0 10.001-1.132.566.566 0 000 1.132zM30.1 32.213c1.3 0 2.36-1.06 2.36-2.36 0-1.3-1.06-2.36-2.36-2.36-1.3 0-2.36 1.06-2.36 2.36 0 1.3 1.06 2.36 2.36 2.36zm-1.427-2.353c0-.787.64-1.427 1.427-1.427s1.427.64 1.427 1.427-.64 1.427-1.427 1.427-1.427-.64-1.427-1.427z" />
        <path d="M25.06 29.853c0 2.78 2.26 5.04 5.04 5.04 2.78 0 5.04-2.26 5.04-5.04 0-2.78-2.26-5.04-5.04-5.04-2.78 0-5.04 2.26-5.04 5.04zm9.14 0c0 2.26-1.84 4.1-4.1 4.1s-4.1-1.84-4.1-4.1 1.84-4.1 4.1-4.1 4.1 1.84 4.1 4.1z" />
        <path d="M23.34 8.22a10.877 10.877 0 00-2.8-3.04 10.55 10.55 0 00-5.653-2.14 10.59 10.59 0 00-5.874 1.307c-.053.026-.1.06-.153.086-.207.12-.42.24-.62.374a10.756 10.756 0 00-2.713 2.526c-.027.034-.054.074-.08.107-.134.18-.267.36-.387.547a10.6 10.6 0 00-1.813 6.773A10.575 10.575 0 007.5 22.453l18.053 13.394a7.247 7.247 0 004.54 1.62 7.13 7.13 0 005.167-2.2l.34-.347c2.553-2.627 2.733-6.733.433-9.553L23.34 8.22zm11.767 17.473l.186.254c.087.1.16.206.234.313a6.477 6.477 0 011.08 3.587 6.518 6.518 0 01-6.514 6.513 6.518 6.518 0 01-6.513-6.513 6.518 6.518 0 016.513-6.514 6.54 6.54 0 015.014 2.36zM30.1 22.407c-4.107 0-7.447 3.346-7.447 7.453 0 1.153.274 2.24.74 3.213L11.76 24.44c.767.173 1.56.273 2.373.273 5.967 0 10.827-4.853 10.827-10.826 0-.76-.08-1.5-.227-2.214l8.44 11.4a7.417 7.417 0 00-3.073-.666zM6.94 7.167l.327-.334C7.7 6.393 8.173 6 8.673 5.66a9.853 9.853 0 015.46-1.653c2.247 0 4.314.76 5.98 2.026a9.645 9.645 0 011.807 1.84l.647.874a9.825 9.825 0 011.466 5.153c0 5.453-4.433 9.887-9.893 9.887a9.838 9.838 0 01-6.26-2.24c-.02-.014-.033-.034-.053-.047a9.872 9.872 0 01-3.574-7.607c0-1.973.587-3.806 1.587-5.353.32-.487.687-.953 1.1-1.373z" />
        <path d="M37.98 24.747L24.613 5.44a13.39 13.39 0 00-9.84-5.4 13.377 13.377 0 00-10.5 3.967l-.42.426a13.434 13.434 0 001.58 20.207l19.334 13.32a9.008 9.008 0 005.72 2.04c2.453 0 4.766-.987 6.506-2.767l.434-.446c3.206-3.314 3.44-8.487.553-12.04zm-1.227 11.386l-.433.447c-2.96 3.047-7.673 3.32-10.973.64L5.987 23.873A12.47 12.47 0 01.98 14.82C.7 11.22 1.993 7.68 4.527 5.1l.42-.427A12.381 12.381 0 0114.713.98c3.647.26 6.98 2.087 9.147 5.013L37.233 25.32c2.6 3.2 2.4 7.853-.48 10.813z" />
      </g>
      <path d="M0 0h40v40H0z" />
    </g>
  </svg>
);

export default SvgIconMecanica;
