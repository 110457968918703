import React from 'react';

const SvgIconShare = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id={`icon-share_svg__a${props.customId}`}
        d="M20 9.995L10.882 0v5.987H9.823a9.76 9.76 0 0 0-6.946 2.876A9.754 9.754 0 0 0 0 15.807V20l1.75-1.917a12.559 12.559 0 0 1 9.132-4.08v5.986L20 9.995zM1.172 16.987v-1.18c0-2.31.9-4.482 2.534-6.115a8.595 8.595 0 0 1 6.117-2.533h2.231V3.024l6.36 6.97-6.36 6.971v-4.134h-1.051c-3.701 0-7.26 1.51-9.83 4.156z"
      />
    </defs>
    <use
      fill="#FF0000"
      transform="translate(2 2)"
      xlinkHref={`#icon-share_svg__a${props.customId}`}
    />
  </svg>
);

export default SvgIconShare;
