import React from 'react';

const SvgIconFarm = props => (
  <svg width={32} height={25} viewBox="0 0 32 25" {...props}>
    <defs>
      <path
        id="icon-farm_svg__a"
        d="M9.224 0h9.663c.243 0 .44.208.44.464a.458.458 0 0 1-.361.456l-.08.008h-.327l.963 8.102a5.703 5.703 0 0 1 1.561 3.952c0 3.073-2.36 5.564-5.27 5.564-2.216 0-4.112-1.444-4.892-3.488l-.08-.221h-2.96c-.218 2.086-1.893 3.709-3.928 3.709-2.117 0-3.845-1.757-3.948-3.965L0 14.373V9.766c.571-1.176 2.399-1.76 4.154-2.048l.238-.038V2.782l-.004-.927a.453.453 0 0 0-.44-.464.452.452 0 0 1-.439-.463c0-.256.197-.464.44-.464.685 0 1.247.552 1.315 1.257l.007.134v5.714a24.31 24.31 0 0 1 2.635-.131h.008L9.83.928h-.606a.452.452 0 0 1-.44-.464.46.46 0 0 1 .361-.457L9.225 0h9.662zM3.9 11.228c-1.68.004-3.04 1.444-3.037 3.218.002 1.774 1.367 3.21 3.048 3.206 1.68-.003 3.04-1.444 3.037-3.217-.003-1.774-1.368-3.21-3.048-3.207zm11.874-2.832c-2.413 0-4.37 2.065-4.37 4.613 0 2.547 1.957 4.613 4.37 4.613 2.414 0 4.37-2.066 4.37-4.613 0-2.548-1.956-4.613-4.37-4.613zm-11.82 4.586c.727 0 1.317.623 1.317 1.391s-.59 1.39-1.318 1.39c-.728 0-1.318-.622-1.318-1.39 0-.768.59-1.39 1.318-1.39zm11.858-2.318c1.213 0 2.197 1.038 2.197 2.318s-.984 2.318-2.197 2.318c-1.212 0-2.196-1.038-2.196-2.318s.984-2.318 2.197-2.318zM3.954 13.91a.453.453 0 0 0-.439.464c0 .256.197.464.44.464.241 0 .438-.208.438-.464a.453.453 0 0 0-.439-.464zm11.86-2.318c-.728 0-1.318.624-1.318 1.391s.59 1.391 1.318 1.391c.727 0 1.317-.624 1.317-1.39 0-.768-.59-1.392-1.317-1.392zM17.672.928H10.75L8.753 7.713l-.196.665-.66-.013-.168-.001c-.465 0-2.108.023-3.718.318-1.5.276-2.58.721-3.05 1.246l-.081.1.008 1.71C1.613 10.8 2.716 10.2 3.953 10.2c1.967 0 3.598 1.516 3.902 3.502l.027.207h2.733a5.902 5.902 0 0 1-.073-.927c0-1.33.442-2.55 1.18-3.508l.162-.2h-2.67l2.182-7.42h5.502l.7 5.892c.249.095.488.208.717.338l.226.137-.868-7.293zm-1.55 1.854h-4.08l-1.636 5.563h2.491a5.053 5.053 0 0 1 3.5-.892l.286.042-.561-4.713z"
      />
    </defs>
    <use fill="currentColor" transform="matrix(-1 0 0 1 26.083 6)" xlinkHref="#icon-farm_svg__a" />
  </svg>
);

export default SvgIconFarm;
