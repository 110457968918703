import React from 'react';

const SvgIconEletrica = props => (
  <svg width={19} height={16.15} viewBox="0 0 40 34" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="#54575F" fillRule="nonzero">
        <path d="M38.873 5.587H1.127c-.62 0-1.127.5-1.127 1.126v2.054c0 .626.507 1.126 1.127 1.126h37.746C39.5 9.893 40 9.387 40 8.767V6.713c0-.626-.507-1.126-1.127-1.126zM.88 6.713a.25.25 0 01.247-.246h37.746c.14 0 .247.106.247.246v2.054a.25.25 0 01-.247.246H1.127a.242.242 0 01-.247-.246V6.713zm38.093 22.894v-18.78a.438.438 0 00-.446-.447.438.438 0 00-.447.447v19.22c0 .253.193.446.447.446h.346c.14 0 .247.107.247.247v1.373a.25.25 0 01-.247.247H1.127a.242.242 0 01-.247-.247v-1.38a.25.25 0 01.247-.246h.346a.438.438 0 00.447-.447V10.827a.438.438 0 00-.447-.447.438.438 0 00-.446.447v18.78A1.13 1.13 0 000 30.727V32.1c0 .627.507 1.127 1.127 1.127h37.746C39.5 33.227 40 32.72 40 32.1v-1.373a1.12 1.12 0 00-1.027-1.12zM8.673 5.093a.438.438 0 00.447-.446V2.933c0-.7.573-1.273 1.273-1.273h19.214c.7 0 1.273.573 1.273 1.273v1.72c0 .254.193.447.447.447a.432.432 0 00.44-.447v-1.72A2.157 2.157 0 0029.613.78H10.387a2.157 2.157 0 00-2.154 2.153v1.72c0 .247.194.44.44.44zm-5.833 0a.438.438 0 00.447-.446V3.273a.25.25 0 01.246-.246h2.054a.24.24 0 01.246.246v1.374a.444.444 0 10.767.307.42.42 0 00.113-.307V3.273c0-.626-.506-1.126-1.126-1.126H3.52c-.627 0-1.127.506-1.127 1.126v1.374c0 .253.194.446.447.446zm30.88 0a.438.438 0 00.447-.446V3.273a.25.25 0 01.246-.246h2.054a.24.24 0 01.246.246v1.374c0 .253.194.446.447.446a.42.42 0 00.32-.14.42.42 0 00.113-.306V3.273c0-.626-.506-1.126-1.126-1.126H34.4c-.627 0-1.127.506-1.127 1.126v1.374c0 .253.194.446.447.446z" />
        <path d="M7.647 13.813c-.627 0-1.127.507-1.127 1.127v12.353c0 .627.507 1.127 1.127 1.127h24.7c.626 0 1.126-.507 1.126-1.127V14.947c0-.627-.506-1.127-1.126-1.127.006-.007-24.7-.007-24.7-.007zM7.4 14.947a.25.25 0 01.247-.247h24.7c.14 0 .246.107.246.247V27.3a.25.25 0 01-.246.247h-24.7A.242.242 0 017.4 27.3V14.947z" />
        <path d="M16.573 23.273h6.86c.627 0 1.127-.506 1.127-1.126v-2.054c0-.626-.507-1.126-1.127-1.126h-6.86c-.626 0-1.126.506-1.126 1.126v2.054c-.007.626.5 1.126 1.126 1.126zm-.246-1.126v-2.054a.25.25 0 01.246-.246h6.86c.14 0 .247.106.247.246v2.054a.25.25 0 01-.247.246h-6.86a.242.242 0 01-.246-.246zm20.146-6.767c.247 0 .44-.2.44-.453V11.5a.438.438 0 00-.446-.447H33.04a.438.438 0 00-.447.447.44.44 0 00.447.447h2.993v2.993a.427.427 0 00.44.44z" />
      </g>
      <path d="M0-3h40v40H0z" />
    </g>
  </svg>
);

export default SvgIconEletrica;
