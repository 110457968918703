import React from 'react';

const SvgIconAddBold = props => (
  <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
    <path
      fill="#54575F"
      fillRule="evenodd"
      d="M13.095 9.429h5.762a1.833 1.833 0 0 1 0 3.666h-5.762v5.762a1.833 1.833 0 0 1-3.666 0v-5.762H3.667a1.833 1.833 0 1 1 0-3.666h5.762V3.667a1.833 1.833 0 1 1 3.666 0v5.762z"
    />
  </svg>
);

export default SvgIconAddBold;
