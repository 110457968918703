import React from 'react';

const SvgIconShowPhone = props => (
  <svg width={28} height={28} viewBox="0 0 28 28" {...props}>
    <defs>
      <path id="icon-show-phone_svg__a" d="M.05.067h12.166v12.635H.05z" />
      <path id="icon-show-phone_svg__c" d="M0 .063h25.341v25.392H0z" />
      <path id="icon-show-phone_svg__e" d="M.05.067h12.166v12.635H.05z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#37E2F4"
        d="M24.28 22.875c-.222.24-.45.456-.696.696-.371.353-.748.724-1.102 1.14-.577.616-1.257.907-2.147.907-.086 0-.177 0-.263-.006-1.696-.108-3.272-.77-4.453-1.334-3.232-1.563-6.07-3.78-8.428-6.592-1.947-2.344-3.25-4.51-4.111-6.837-.531-1.42-.725-2.526-.64-3.57.057-.667.314-1.22.788-1.693L5.175 3.64c.28-.262.577-.405.868-.405.36 0 .65.217.834.4l.017.017c.348.325.68.661 1.028 1.02.177.183.36.365.542.554l1.559 1.556c.605.605.605 1.164 0 1.768-.166.165-.326.33-.491.49-.48.49-.937.947-1.433 1.392-.012.011-.023.017-.029.028-.49.49-.4.97-.297 1.294l.017.052c.406.98.977 1.904 1.845 3.005l.005.006c1.576 1.938 3.238 3.45 5.07 4.607.235.149.475.268.703.382.206.103.4.2.565.302.023.012.046.029.069.04.194.097.376.143.565.143.474 0 .77-.297.868-.394l1.953-1.95c.194-.194.502-.427.862-.427.354 0 .645.222.822.416l3.157 3.153c.589.582.589 1.18.006 1.785zm-2.044-6.004c-.554-.576-1.222-.884-1.93-.884-.702 0-1.376.302-1.952.878l-1.805 1.796c-.148-.08-.297-.154-.44-.228-.205-.103-.4-.2-.565-.302-1.69-1.072-3.226-2.47-4.699-4.277-.714-.901-1.193-1.66-1.542-2.429.469-.428.903-.873 1.325-1.3.16-.16.32-.325.48-.485 1.199-1.197 1.199-2.748 0-3.946l-1.56-1.557c-.176-.176-.359-.359-.53-.541a27.04 27.04 0 0 0-1.073-1.06c-.554-.548-1.217-.839-1.913-.839-.697 0-1.37.29-1.942.838l-.011.011-1.942 1.956A4.17 4.17 0 0 0 .898 7.154c-.136 1.665.355 3.216.732 4.23.924 2.493 2.306 4.802 4.367 7.277 2.501 2.982 5.51 5.337 8.948 6.997 1.313.621 3.066 1.357 5.024 1.482.12.006.246.012.36.012 1.32 0 2.427-.473 3.295-1.415.005-.011.017-.017.023-.028.297-.36.639-.684.999-1.032a23.3 23.3 0 0 0 .742-.736c.565-.587.862-1.271.862-1.973 0-.707-.302-1.385-.879-1.956l-3.135-3.141zM14.613 5.834c1.445.261 2.757.998 3.805 2.127a7.79 7.79 0 0 1 1.975 4.1c.06.393.374.666.733.666.044 0 .083-.006.127-.012.408-.071.678-.487.612-.927a9.425 9.425 0 0 0-2.388-4.962c-1.263-1.36-2.857-2.252-4.605-2.573-.408-.071-.788.22-.86.654-.072.434.193.856.6.927"
      />
      <g transform="translate(15.75)">
        <mask id="icon-show-phone_svg__b" fill="#fff">
          <use xlinkHref="#icon-show-phone_svg__a" />
        </mask>
        <path
          fill="#37E2F4"
          d="M8.344 4.09C6.298 1.963 3.726.58.9.079.504.006.131.287.06.698a.76.76 0 0 0 .601.877c2.523.445 4.825 1.688 6.655 3.584a12.92 12.92 0 0 1 3.449 6.913.735.735 0 0 0 .844.619c.395-.062.666-.456.596-.867a14.47 14.47 0 0 0-3.86-7.734"
          mask="url(#icon-show-phone_svg__b)"
        />
      </g>
      <path fill="#000" d="M22.755 22.062c-.008 0-.008.008 0 0" />
      <g transform="translate(0 1.697)">
        <mask id="icon-show-phone_svg__d" fill="#fff">
          <use xlinkHref="#icon-show-phone_svg__c" />
        </mask>
        <path
          fill="#FFF"
          d="M23.374 21.188c-.222.24-.45.456-.696.695-.37.352-.747.722-1.1 1.137-.576.615-1.255.904-2.144.904-.086 0-.177 0-.262-.005-1.694-.108-3.268-.768-4.448-1.331-3.228-1.559-6.062-3.771-8.417-6.576-1.944-2.338-3.244-4.499-4.105-6.82-.53-1.416-.724-2.52-.639-3.56a2.608 2.608 0 0 1 .787-1.69l1.945-1.94c.279-.261.575-.404.866-.404.36 0 .65.217.833.399l.017.017c.348.324.678.66 1.026 1.018.177.182.36.364.542.552l1.557 1.553c.604.603.604 1.16 0 1.763-.166.165-.325.33-.49.49-.48.488-.936.943-1.432 1.387-.011.011-.023.017-.029.028-.49.49-.399.967-.296 1.291l.017.052c.405.978.975 1.9 1.842 2.998l.006.005c1.574 1.934 3.233 3.442 5.063 4.596.234.148.473.268.702.381.205.103.399.2.564.302.023.011.046.028.069.04.193.096.376.142.564.142.473 0 .77-.296.867-.393l1.95-1.945c.194-.194.502-.427.861-.427.354 0 .644.222.821.416l3.153 3.145c.588.58.588 1.178.006 1.78zm-2.041-5.99c-.553-.573-1.22-.88-1.928-.88-.7 0-1.374.3-1.95.875l-1.802 1.792c-.148-.08-.296-.154-.439-.227-.205-.103-.399-.2-.564-.302-1.688-1.07-3.222-2.463-4.693-4.266-.713-.899-1.192-1.656-1.54-2.423.468-.427.901-.87 1.323-1.297l.48-.484c1.196-1.194 1.196-2.741 0-3.936L8.661 2.497c-.176-.176-.359-.358-.53-.54A26.99 26.99 0 0 0 7.06.899C6.507.353 5.845.063 5.15.063c-.696 0-1.369.29-1.939.836L3.2.91 1.26 2.861A4.158 4.158 0 0 0 .024 5.506c-.136 1.661.354 3.208.73 4.22.924 2.487 2.304 4.79 4.363 7.26 2.497 2.974 5.502 5.323 8.935 6.978 1.311.62 3.062 1.354 5.018 1.48.12.005.245.01.36.01 1.317 0 2.423-.471 3.29-1.41.005-.011.017-.017.022-.028.297-.359.639-.683.998-1.03.245-.233.496-.478.741-.734.565-.586.861-1.268.861-1.968 0-.705-.302-1.382-.878-1.951l-3.13-3.134z"
          mask="url(#icon-show-phone_svg__d)"
        />
      </g>
      <path
        fill="#FFF"
        d="M13.738 5.834c1.445.261 2.757.998 3.805 2.127a7.79 7.79 0 0 1 1.975 4.1c.06.393.374.666.733.666.044 0 .083-.006.127-.012.408-.071.678-.487.612-.927a9.425 9.425 0 0 0-2.388-4.962c-1.263-1.36-2.857-2.252-4.605-2.573-.408-.071-.788.22-.86.654-.072.434.193.856.6.927"
      />
      <g transform="translate(14.875)">
        <mask id="icon-show-phone_svg__f" fill="#fff">
          <use xlinkHref="#icon-show-phone_svg__e" />
        </mask>
        <path
          fill="#FFF"
          d="M8.344 4.09C6.298 1.963 3.726.58.9.079.504.006.131.287.06.698a.76.76 0 0 0 .601.877c2.523.445 4.825 1.688 6.655 3.584a12.92 12.92 0 0 1 3.449 6.913.735.735 0 0 0 .844.619c.395-.062.666-.456.596-.867a14.47 14.47 0 0 0-3.86-7.734"
          mask="url(#icon-show-phone_svg__f)"
        />
      </g>
    </g>
  </svg>
);

export default SvgIconShowPhone;
