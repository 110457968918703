import React from 'react';

const SvgIconArrowRight = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id={`icon-arrow-right_svg__a${props.customId}`}
        d="M11.681 10.215l-9.8-9.89a1.097 1.097 0 0 0-1.558 0 1.116 1.116 0 0 0 0 1.57l9.023 9.104-9.022 9.106a1.117 1.117 0 0 0 0 1.57c.43.433 1.128.433 1.558 0l9.8-9.89a1.128 1.128 0 0 0 0-1.57z"
      />
    </defs>
    <use
      fill="currentColor"
      fillRule="evenodd"
      transform="translate(6 1)"
      xlinkHref={`#icon-arrow-right_svg__a${props.customId}`}
    />
  </svg>
);

export default SvgIconArrowRight;
