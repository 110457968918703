import React from 'react';

const SvgIconSearch = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.14 2.59c4.1 0 7.437 3.198 7.437 7.125 0 3.929-3.336 7.125-7.438 7.125-4.099 0-7.435-3.196-7.435-7.125 0-3.927 3.336-7.124 7.435-7.124m13.495 19.235l-5.915-5.93c1.521-1.744 2.355-3.937 2.355-6.221C20.074 4.34 15.572 0 10.036 0 4.502 0 0 4.34 0 9.675c0 5.336 4.502 9.676 10.036 9.676 2.077 0 4.059-.603 5.75-1.752l5.963 5.977c.249.251.583.388.944.388.339 0 .66-.125.905-.352a1.23 1.23 0 0 0 .036-1.786"
    />
  </svg>
);

export default SvgIconSearch;
