import React from 'react';

const SvgIconGraphic = props => (
  <svg width={111} height={93} viewBox="0 0 332 279" {...props}>
    <g fill="none">
      <path
        fill="#F9DCDF"
        d="M322.96 262.65h-18.03v-80.73c0-4.61-3.74-8.35-8.35-8.35h-59.39c-4.62 0-8.35 3.74-8.35 8.35 0 .17.02.34.03.51-.01.17-.03.34-.03.51v79.72h-25.13V136.2c0-4.61-3.74-8.35-8.35-8.35h-59.39c-4.61 0-8.35 3.74-8.35 8.35v126.45h-25.13V207.7c0-4.61-3.74-8.35-8.35-8.35H34.73c-4.61 0-8.35 3.74-8.35 8.35v54.95H8.35C3.74 262.65 0 266.39 0 271c0 4.61 3.74 8.35 8.35 8.35h314.61c4.61 0 8.35-3.74 8.35-8.35 0-4.61-3.74-8.35-8.35-8.35z"
      />
      <path
        fill="#F78092"
        d="M327.41 268.59h-18.03v-80.73c0-4.61-3.74-8.35-8.35-8.35h-59.39c-4.62 0-8.35 3.74-8.35 8.35 0 .17.02.34.03.51-.01.17-.03.34-.03.51v79.72h-25.13V142.14c0-4.61-3.74-8.35-8.35-8.35h-59.39c-4.61 0-8.35 3.74-8.35 8.35v126.45h-25.13v-54.95c0-4.61-3.74-8.35-8.35-8.35h-59.4c-4.61 0-8.35 3.74-8.35 8.35v54.95H12.81c-4.61 0-8.35 3.74-8.35 8.35 0 4.61 3.74 8.35 8.35 8.35h314.61c4.61 0 8.35-3.74 8.35-8.35 0-4.61-3.75-8.35-8.36-8.35zm-237.18 0H47.54V222h42.69v46.59zm101.23 0h-42.69v-118.1h42.69v118.1zm101.22 0h-42.69v-72.38h42.69v72.38z"
      />
      <path
        d="M322.96 262.65h-18.03v-80.73c0-4.61-3.74-8.35-8.35-8.35h-59.39c-4.62 0-8.35 3.74-8.35 8.35 0 .17.02.34.03.51-.01.17-.03.34-.03.51v79.72h-25.13V136.2c0-4.61-3.74-8.35-8.35-8.35h-59.39c-4.61 0-8.35 3.74-8.35 8.35v126.45h-25.13V207.7c0-4.61-3.74-8.35-8.35-8.35H34.73c-4.61 0-8.35 3.74-8.35 8.35v54.95H8.35C3.74 262.65 0 266.39 0 271c0 4.61 3.74 8.35 8.35 8.35h314.61c4.61 0 8.35-3.74 8.35-8.35 0-4.61-3.74-8.35-8.35-8.35zm-237.18 0H43.09v-46.59h42.69v46.59zm101.22 0h-42.69V144.56H187v118.09zm101.23 0h-42.69v-72.38h42.69v72.38zM55.39 114.86c1.68 0 3.37-.5 4.84-1.55l104.22-74.34 41.64 46a8.341 8.341 0 0 0 11.17 1.1l72.86-54.05-.03 4.58c-.03 4.61 3.69 8.37 8.31 8.4h.05c4.59 0 8.32-3.71 8.35-8.3l.12-20.91c.02-.28.02-.56.01-.83l.01-1.42c.02-4.06-2.87-7.55-6.86-8.27l-22.79-4.12c-4.54-.83-8.89 2.19-9.71 6.73-.82 4.54 2.19 8.89 6.73 9.7l5.82 1.06-66.74 49.51-41.55-45.89c-2.84-3.14-7.59-3.65-11.04-1.2L50.53 99.7a8.347 8.347 0 0 0-1.95 11.65 8.339 8.339 0 0 0 6.81 3.51z"
        fill="#FF1D46"
      />
    </g>
  </svg>
);

export default SvgIconGraphic;
