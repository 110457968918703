import React from 'react';

const SvgIconCopy = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-copy_svg__a"
        d="M10.832 3.78c.74 0 1.341.613 1.341 1.37v13.48c0 .757-.6 1.37-1.34 1.37H1.34C.601 20 0 19.387 0 18.63V5.15c0-.757.6-1.37 1.341-1.37h9.491zm-.206 1.58H1.547v13.06h9.079V5.36zM13.886 0C15.052 0 16 .967 16 2.16v.032l-.172 11.465c-.018 1.18-.96 2.126-2.115 2.126h-.254a.782.782 0 0 1-.774-.79c0-.436.346-.79.774-.79h.254c.31 0 .563-.253.568-.57l.171-11.465a.575.575 0 0 0-.567-.588H5.977a.48.48 0 0 0-.475.484c0 .436-.346.79-.773.79a.782.782 0 0 1-.774-.79C3.955.924 4.86 0 5.977 0z"
      />
    </defs>
    <use fill="currentColor" transform="translate(4 2)" xlinkHref="#icon-copy_svg__a" />
  </svg>
);

export default SvgIconCopy;
