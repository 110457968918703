import React from 'react';

const SvgIconWhatsappLine = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-whatsapp-line_svg__a"
        d="M19.785 9.638c0 5.323-4.348 9.637-9.713 9.637a9.742 9.742 0 0 1-4.695-1.198L0 19.785l1.753-5.172A9.54 9.54 0 0 1 .36 9.637C.36 4.315 4.708 0 10.073 0c5.364.001 9.712 4.315 9.712 9.638zm-9.714-8.101c-4.503 0-8.165 3.635-8.165 8.103a8.02 8.02 0 0 0 1.555 4.75l-1.02 3.008 3.138-.997a8.164 8.164 0 0 0 4.492 1.34c4.503 0 8.166-3.635 8.166-8.103.002-4.466-3.663-8.101-8.166-8.101zm4.906 10.321c-.06-.097-.22-.157-.457-.275-.237-.12-1.409-.69-1.626-.768-.22-.079-.379-.119-.537.118-.157.237-.614.768-.754.927-.138.158-.277.178-.515.061-.238-.12-1.006-.367-1.916-1.172-.707-.627-1.186-1.4-1.324-1.636-.14-.236-.014-.364.104-.481.107-.107.238-.277.358-.415.119-.138.158-.235.238-.393.078-.158.038-.296-.02-.415-.06-.119-.535-1.28-.734-1.753-.199-.472-.397-.394-.536-.394s-.297-.02-.456-.02a.872.872 0 0 0-.634.295c-.218.237-.833.808-.833 1.97 0 1.164.852 2.286.971 2.443.12.157 1.648 2.621 4.068 3.567 2.421.945 2.421.63 2.858.59.437-.04 1.409-.572 1.606-1.122.199-.554.199-1.028.139-1.127z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(2 2)">
      <mask id="icon-whatsapp-line_svg__b" fill="#fff">
        <use xlinkHref="#icon-whatsapp-line_svg__a" />
      </mask>
      <use fill="#54575F" fillRule="nonzero" xlinkHref="#icon-whatsapp-line_svg__a" />
      <g fill="#54575F" mask="url(#icon-whatsapp-line_svg__b)">
        <path d="M-2-2h24v24H-2z" />
      </g>
    </g>
  </svg>
);

export default SvgIconWhatsappLine;
