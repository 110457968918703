import React from 'react';

const SvgIconEdit = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-edit_svg__a"
        d="M18.303.899a3.068 3.068 0 0 0-4.34 0L1.8 13.062a.682.682 0 0 0-.175.3l-1.6 5.774a.682.682 0 0 0 .84.84l5.775-1.6a.682.682 0 0 0 .3-.175L19.103 6.037a3.071 3.071 0 0 0 0-4.338l-.8-.8zM3.285 13.505L13.24 3.55l3.211 3.21-9.956 9.955-3.21-3.21zm-.641 1.287l2.565 2.565-3.548.983.983-3.548zm15.495-9.719l-.724.723-3.21-3.21.723-.723a1.705 1.705 0 0 1 2.41 0l.8.8a1.707 1.707 0 0 1 0 2.41z"
      />
    </defs>
    <use fill="currentColor" transform="translate(2 2)" xlinkHref="#icon-edit_svg__a" />
  </svg>
);

export default SvgIconEdit;
