import React from 'react';

const SvgIconPrint = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-print_svg__a"
        d="M14.4 11.52v6.24H4.8v-6.24h9.6zm-1.2 3.744H6v1.248h7.2v-1.248zm0-2.496H6v1.248h7.2v-1.248zM3.51 3.36v3.52h11.7V3.36h1.17c1.17 0 2.34 1.173 2.34 2.347v5.866c0 1.174-1.17 2.347-2.34 2.347h-1.17V10.4H3.51v3.52H2.34C1.17 13.92 0 12.747 0 11.573V5.707C0 4.533 1.17 3.36 2.34 3.36h1.17zM14.4 0v6.24H4.8V0h9.6z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <g transform="translate(2.88 2.88)">
        <mask id="icon-print_svg__b" fill="#fff">
          <use xlinkHref="#icon-print_svg__a" />
        </mask>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#icon-print_svg__a" />
        <g fill="currentColor" mask="url(#icon-print_svg__b)">
          <path d="M-2.88-2.88h24v24h-24z" />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgIconPrint;
