import React from 'react';

const SvgIconCurrency = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-currency_svg__a"
        d="M9 0a8.94 8.94 0 0 1 6.364 2.636A8.941 8.941 0 0 1 18 9a8.941 8.941 0 0 1-2.636 6.364A8.941 8.941 0 0 1 9 18a8.941 8.941 0 0 1-6.364-2.636A8.941 8.941 0 0 1 0 9a8.94 8.94 0 0 1 2.636-6.364A8.941 8.941 0 0 1 9 0zm0 1.5C4.864 1.5 1.5 4.864 1.5 9s3.364 7.5 7.5 7.5 7.5-3.364 7.5-7.5S13.136 1.5 9 1.5zM9.25 3a.75.75 0 0 1 .75.75v1.11l1.222.001a.75.75 0 0 1 .743.648l.007.102a.75.75 0 0 1-.75.75H8.627a.945.945 0 1 0 0 1.889h.934a2.411 2.411 0 0 1 .44 4.782L10 13.95a.75.75 0 1 1-1.5 0v-.878H6.933a.75.75 0 0 1-.743-.648l-.007-.102a.75.75 0 0 1 .75-.75H9.56a.911.911 0 1 0 0-1.822h-.934a2.445 2.445 0 0 1 0-4.889l-.127.004V3.75A.75.75 0 0 1 9.25 3z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(3 3)">
      <mask id="icon-currency_svg__b" fill="#fff">
        <use xlinkHref="#icon-currency_svg__a" />
      </mask>
      <use fill="#000" fillRule="nonzero" xlinkHref="#icon-currency_svg__a" />
      <g fill="#757575" mask="url(#icon-currency_svg__b)">
        <path d="M-3-3h24v24H-3z" />
      </g>
    </g>
  </svg>
);

export default SvgIconCurrency;
