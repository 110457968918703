import React from 'react';

const SvgIconGraphicPizza = props => (
  <svg width={125} height={91} viewBox="0 0 395 281" {...props}>
    <g fill="none">
      <path
        fill="#F9DCDF"
        d="M366.8 18.91H211.6h155.2zm-183.4 0H17.2c-3.56 0-6.86 1.09-9.61 2.94 2.75-1.86 6.05-2.94 9.61-2.94h166.2zm194.41 4c-.03-.02-.05-.05-.07-.07.02.02.04.05.07.07zM47.75 73.28h299.51v189.34H47.75zM28.2 40.11h338.59v11.96H28.2zM6.27 22.84a17.32 17.32 0 0 0-4.91 6.58c1.08-2.57 2.78-4.82 4.91-6.58z"
      />
      <path
        fill="#F78092"
        d="M200.6 18.91h11v-5.68a8.6 8.6 0 0 0-11.95-7.92c.61 1.18.95 2.51.95 3.92v9.68zM17.2 69.28c.72.61 1.51 1.15 2.34 1.64v-1.64H17.2zm166.2 210.55H36.74c.07.06.14.11.21.17H183.4v-.17zM382.65 29.42c.22.51.41 1.04.58 1.58.5 1.61.77 3.33.77 5.11v15.96c0 9.48-7.72 17.2-17.2 17.2h-2.34v193.35c0 9.49-7.72 17.2-17.2 17.2H200.6v.18h168.45c3.9-3.16 6.4-7.97 6.4-13.37V73.28h2.34c9.48 0 17.2-7.72 17.2-17.2V40.12c0-9.48-7.71-17.2-17.19-17.2a17.16 17.16 0 0 1 4.85 6.5z"
      />
      <path
        fill="#F78092"
        d="M28.2 40.11h338.6v-4H17.2v15.97h11zm19.55 33.17h299.5v-4H36.75v193.34h11z"
      />
      <path
        fill="#FFF"
        d="M183.4 279.83v.17h17.2v-.17h146.65c9.48 0 17.2-7.72 17.2-17.2V69.28h2.34c9.48 0 17.2-7.72 17.2-17.2V36.12c0-1.78-.27-3.49-.77-5.11-.17-.54-.36-1.07-.58-1.58a17.274 17.274 0 0 0-4.84-6.51c-.03-.02-.05-.05-.07-.07a17.076 17.076 0 0 0-10.93-3.93H200.6V9.24c0-1.41-.35-2.75-.95-3.92-1.43-2.78-4.31-4.68-7.65-4.68a8.6 8.6 0 0 0-8.6 8.6v9.68H17.2a17.153 17.153 0 0 0-10.93 3.93 17.32 17.32 0 0 0-5.49 8.16c-.5 1.61-.77 3.33-.77 5.11v15.96c0 9.48 7.71 17.2 17.19 17.2h2.35v193.35c0 9.48 7.71 17.2 17.19 17.2H183.4zM36.75 69.28h310.51v193.34H36.75V69.28zM17.2 52.08V36.11h349.6v15.96H17.2v.01z"
      />
      <path
        fill="#FF1D46"
        d="M191.84 94.19c-4.27 0-7.74 3.47-7.74 7.74v59.37h-59.37c-4.27 0-7.74 3.47-7.74 7.74 0 41.27 33.58 74.85 74.85 74.85 41.27 0 74.86-33.58 74.86-74.85-.01-41.27-33.59-74.85-74.86-74.85zm0 134.22c-30.11 0-55.06-22.54-58.86-51.63h58.86a7.74 7.74 0 0 0 7.74-7.74v-58.87c29.09 3.8 51.62 28.75 51.62 58.87 0 32.74-26.63 59.37-59.36 59.37z"
      />
      <path
        fill="#FF1D46"
        d="M165.63 86.18c-31.24 0-56.65 25.42-56.65 56.66 0 4.27 3.47 7.74 7.74 7.74h48.91c4.28 0 7.74-3.46 7.74-7.74V93.92c.01-4.27-3.46-7.74-7.74-7.74zm-7.74 48.92h-32.7c3.15-16.51 16.2-29.55 32.7-32.71v32.71z"
      />
    </g>
  </svg>
);

export default SvgIconGraphicPizza;
