import React from 'react';

const SvgIconEmptyProposal = props => (
  <svg width={201} height={141} viewBox="0 0 201 141" {...props}>
    <defs>
      <linearGradient
        id="icon-empty-proposal_svg__a"
        x1="1.314%"
        x2="98.881%"
        y1="49.982%"
        y2="49.982%"
      >
        <stop offset="0%" stopColor="#E00700" />
        <stop offset="31.28%" stopColor="#C70300" />
        <stop offset="60.84%" stopColor="#B50100" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient
        id="icon-empty-proposal_svg__b"
        x1="-.591%"
        x2="99.551%"
        y1="50.058%"
        y2="50.058%"
      >
        <stop offset="0%" stopColor="#E00700" />
        <stop offset="31.28%" stopColor="#C70300" />
        <stop offset="60.84%" stopColor="#B50100" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient
        id="icon-empty-proposal_svg__c"
        x1=".159%"
        x2="100.122%"
        y1="50.148%"
        y2="50.148%"
      >
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#B50100" />
      </linearGradient>
      <linearGradient
        id="icon-empty-proposal_svg__d"
        x1="100.666%"
        x2="117.006%"
        y1="49.783%"
        y2="49.783%"
      >
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#B50100" />
      </linearGradient>
      <linearGradient
        id="icon-empty-proposal_svg__e"
        x1="-.031%"
        x2="100.039%"
        y1="49.945%"
        y2="49.945%"
      >
        <stop offset="0%" stopColor="#004381" />
        <stop offset="25.42%" stopColor="#00417D" />
        <stop offset="47.3%" stopColor="#003971" />
        <stop offset="71.02%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002D5D" />
      </linearGradient>
      <linearGradient
        id="icon-empty-proposal_svg__f"
        x1="33.876%"
        x2="134.037%"
        y1="49.945%"
        y2="49.945%"
      >
        <stop offset="19.5%" stopColor="#FF96B2" />
        <stop offset="28.9%" stopColor="#FC91AC" />
        <stop offset="41.55%" stopColor="#F5839B" />
        <stop offset="56.04%" stopColor="#EA6B7F" />
        <stop offset="71.92%" stopColor="#D94A57" />
        <stop offset="88.72%" stopColor="#C52126" />
        <stop offset="100%" stopColor="#B50100" />
      </linearGradient>
      <linearGradient
        id="icon-empty-proposal_svg__g"
        x1="49.869%"
        x2="49.869%"
        y1=".058%"
        y2="265.712%"
      >
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#B50100" />
      </linearGradient>
      <linearGradient
        id="icon-empty-proposal_svg__h"
        x1="-.124%"
        x2="216.631%"
        y1="49.786%"
        y2="49.786%"
      >
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#B50100" />
      </linearGradient>
      <linearGradient
        id="icon-empty-proposal_svg__i"
        x1=".016%"
        x2="100.131%"
        y1="49.365%"
        y2="49.365%"
      >
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#B50100" />
      </linearGradient>
      <linearGradient
        id="icon-empty-proposal_svg__j"
        x1="-.009%"
        x2="99.991%"
        y1="50.045%"
        y2="50.045%"
      >
        <stop offset="0%" stopColor="#E00700" />
        <stop offset="31.28%" stopColor="#C70300" />
        <stop offset="60.84%" stopColor="#B50100" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
    </defs>
    <g fill="none">
      <path
        fill="#D6DCEB"
        d="M183.462 106.874c-.526.75-1.051 1.43-1.577 2.18a85.948 85.948 0 0 1-9.59 10.558 109.16 109.16 0 0 1-4.598 3.95c-3.284 2.52-6.306 4.428-8.736 5.518-23.122 10.353-66.409 16.416-102.011 6.948a91.243 91.243 0 0 1-4.598-1.295c-8.67-2.656-16.75-6.334-23.844-11.17-.92-.614-1.84-1.295-2.759-1.976a35.881 35.881 0 0 1-2.496-1.975C9.196 108.032.13 91.07.13 66.958c0-2.18.066-4.496.263-6.812C3.153 19.55 30.02.613 56.49.068h2.365c4.072.068 8.014.613 11.955 1.499 19.64 4.7 27.325 17.301 39.871 29.698 21.48 21.252 42.96-14.44 72.846 21.116 16.16 19.209 10.51 39.44-.065 54.493z"
      />
      <path
        fill="#E0E6FF"
        d="M185.432 108.1l-3.612.954-37.31 9.808s-72.584 2.793-118.827 2.657C10.247 109.939.066 92.365.066 66.889l57.54-36.373 125.724 76.358 2.102 1.226z"
      />
      <path
        fill="url(#icon-empty-proposal_svg__a)"
        d="M16.881.068v39.916h-2.43V.068a14.472 14.472 0 0 1 2.43 0z"
        transform="translate(42.04)"
      />
      <path
        fill="#FFF"
        d="M70.481 41.891H45.324c-.854 0-1.511-.749-1.511-1.566 0-.886.722-1.567 1.51-1.567h25.158c.854 0 1.511.75 1.511 1.567s-.657 1.566-1.51 1.566z"
      />
      <path fill="#E35563" d="M57.738 30.516l-15.108 9.4h30.545z" />
      <path
        fill="url(#icon-empty-proposal_svg__b)"
        d="M56.95 121.11v14.85a91.243 91.243 0 0 1-4.598-1.295v-13.487h4.598v-.068z"
      />
      <path
        fill="#F087A8"
        d="M58.132 95.158c-1.97 0-3.547 1.635-3.547 3.678V109.6c0 2.043 1.577 3.678 3.547 3.678 1.97 0 3.547-1.635 3.547-3.678V98.836c0-2.043-1.576-3.678-3.547-3.678z"
      />
      <path
        fill="#F087A8"
        d="M64.832 100.54c-.394-.75-1.313-1.023-2.036-.546l-3.81 2.248a1.514 1.514 0 0 0-.525 2.112c.394.749 1.314 1.021 2.036.545l3.81-2.248a1.514 1.514 0 0 0 .525-2.112z"
      />
      <path fill="#4674A5" d="M51.958 106.601h12.35v12.806h-12.35z" />
      <path
        fill="#005592"
        d="M140.503 50.406s4.204-.545 5.977 3.406c1.774 3.95.592 16.075.592 16.075s-10.182-14.577-6.57-19.481z"
      />
      <path
        fill="url(#icon-empty-proposal_svg__c)"
        d="M130.453 64.506l-6.437 17.846 11.035-.34 3.678-17.506z"
      />
      <path
        fill="url(#icon-empty-proposal_svg__d)"
        d="M141.488 63.757h4.598c1.38 0 2.496 1.157 2.496 2.588v7.97h-7.094V63.756z"
      />
      <path
        fill="url(#icon-empty-proposal_svg__e)"
        d="M84.341 107.078l36.85-31.333h23.384v43.73H76.46l.985-2.52c1.445-3.883 3.81-7.288 6.897-9.877z"
      />
      <path
        fill="url(#icon-empty-proposal_svg__f)"
        d="M144.575 119.475h-16.684l8.342-43.73h8.342z"
      />
      <path
        fill="url(#icon-empty-proposal_svg__g)"
        d="M140.24 72.952h-5.715c-2.693 0-4.86-2.248-4.86-5.04v-10.49c0-2.793 2.167-5.04 4.86-5.04h5.715c2.693 0 4.861 2.247 4.861 5.04v10.49c0 2.792-2.168 5.04-4.86 5.04z"
      />
      <path
        fill="url(#icon-empty-proposal_svg__h)"
        d="M146.612 71.59h-9.985c-1.116 0-1.97.885-1.97 2.043v1.908c0 1.158.854 2.043 1.97 2.043h10.05c1.117 0 1.971-.885 1.971-2.043v-1.908c-.066-1.158-.92-2.043-2.036-2.043z"
      />
      <path
        fill="#005592"
        d="M108.251 89.3l-1.576-1.09c-1.314-2.111-1.051-5.109 2.036-8.242 1.38-1.498 3.087-3.133 5.649-4.223 7.948-3.27 4.466-4.564 7.16-9.536 2.693-4.973 6.174-2.997 6.371-8.31.197-5.313 3.547-9.673 9.459-9.4 0 0 5.32 0 4.73 4.359-.132 1.226-2.89 1.567-5.387 2.452-2.627.954-5.78 3.133-6.503 11.512-.525 6.266-1.248 12.737-9.327 14.1-6.635 1.09-3.022 5.449-4.992 7.833a7.312 7.312 0 0 1-1.38 1.09c-1.97 1.022-4.4.75-6.24-.545z"
      />
      <path
        fill="url(#icon-empty-proposal_svg__i)"
        d="M96.099 119.475s0-.272.131-.68c1.248-6.2 6.832-10.423 12.875-9.878l35.93 3.134s7.554-3.542 9.13-2.861c1.249.545 4.993 2.724 6.57 3.678.46.273.853.681 1.05 1.226l2.365 5.45h-3.022l-2.693-4.837-3.218-1.566 2.496 2.452.722 3.882H96.1z"
      />
      <path
        fill="#FFF"
        d="M137.416 116.683h20.1c.788 0 1.38.68 1.38 1.43 0 .817-.658 1.43-1.38 1.43h-20.1c-.789 0-1.38-.68-1.38-1.43 0-.817.591-1.43 1.38-1.43z"
      />
      <path
        fill="#FFF"
        d="M186.286 119.543h-32.974l.065-.136.92-2.793 1.117-3.473v-.069l.985-2.929 11.167-34.33c.854-2.588 3.153-4.291 5.714-4.291h27.195c.656 14.713-4.73 33.853-14.189 48.021z"
      />
      <ellipse cx={186.812} cy={94.886} fill="#D1D9FF" rx={3.284} ry={3.406} />
      <path
        fill="url(#icon-empty-proposal_svg__j)"
        d="M172.295 119.543a109.16 109.16 0 0 1-4.598 3.951H28.507c-.919-.613-1.838-1.294-2.758-1.975a35.881 35.881 0 0 1-2.496-1.976h149.042z"
      />
    </g>
  </svg>
);

export default SvgIconEmptyProposal;
