import React from 'react';

const SvgIconBus = props => (
  <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
    <defs>
      <path
        id="icon-bus_svg__a"
        d="M17.125 1C18.156 1 19 1.865 19 2.923V16.77c0 .635-.3 1.193-.75 1.539v1.346c0 .75-.581 1.346-1.313 1.346h-.375c-.73 0-1.312-.596-1.312-1.346v-.962h-7.5v.962c0 .75-.581 1.346-1.313 1.346h-.375c-.73 0-1.312-.596-1.312-1.346v-1.346c0-.039.019-.077.019-.096-.45-.347-.769-.847-.769-1.443V2.923C4 1.865 4.844 1 5.875 1h11.25zM7 19.654v-.962h-.938a1.92 1.92 0 0 1-.562-.096v1.058a.56.56 0 0 0 .563.577h.375A.56.56 0 0 0 7 19.654zm10.5 0v-1c-.113.02-.244.038-.375.038H16v.962a.56.56 0 0 0 .563.577h.375a.56.56 0 0 0 .562-.577zm.75-2.885v-3.461H4.75v3.461c0 .635.713 1.154 1.313 1.154h11.062c.619 0 1.125-.52 1.125-1.154zm0-4.23V4.845H4.75v7.692h13.5zm0-8.462V2.923c0-.635-.506-1.154-1.125-1.154H5.875c-.619 0-1.125.52-1.125 1.154v1.154h13.5zm-7.5-1.539c.206 0 .375.174.375.385a.381.381 0 0 1-.375.385H8.312a.381.381 0 0 1-.374-.385c0-.211.168-.385.375-.385h2.437zm2.25 0c.206 0 .375.174.375.385a.381.381 0 0 1-.375.385h-.563a.381.381 0 0 1-.374-.385c0-.211.168-.385.374-.385H13zM6.812 16.577c-.618 0-1.125-.52-1.125-1.154 0-.635.507-1.154 1.125-1.154.62 0 1.125.52 1.125 1.154 0 .635-.506 1.154-1.125 1.154zm0-1.539a.381.381 0 0 0-.375.385c0 .212.17.385.375.385a.381.381 0 0 0 .375-.385.381.381 0 0 0-.375-.385zm9.375 1.539c-.618 0-1.125-.52-1.125-1.154 0-.635.507-1.154 1.126-1.154.618 0 1.125.52 1.125 1.154 0 .635-.507 1.154-1.125 1.154zm0-1.539a.381.381 0 0 0-.375.385c0 .212.17.385.376.385a.381.381 0 0 0 .375-.385.381.381 0 0 0-.375-.385z"
      />
    </defs>
    <use fill="currentColor" fillRule="nonzero" xlinkHref="#icon-bus_svg__a" />
  </svg>
);

export default SvgIconBus;
