import React from 'react';

const SvgIconTractorSmall = props => (
  <svg width={32} height={25} viewBox="0 0 32 25" {...props}>
    <defs>
      <path
        id="icon-tractor-small_svg__a"
        d="M15.813 10.664c1.212 0 2.196 1.038 2.196 2.318s-.984 2.318-2.197 2.318c-1.212 0-2.196-1.038-2.196-2.318s.984-2.318 2.197-2.318zm0 3.709c.727 0 1.317-.624 1.317-1.39 0-.768-.59-1.392-1.317-1.392-.728 0-1.318.624-1.318 1.391s.59 1.391 1.318 1.391zm3.71-5.343a5.703 5.703 0 0 1 1.56 3.952c0 3.073-2.36 5.564-5.27 5.564-2.296 0-4.247-1.549-4.972-3.71h-2.96c-.218 2.087-1.893 3.71-3.928 3.71C1.77 18.546 0 16.677 0 14.373V9.766C.597 8.537 2.567 7.955 4.392 7.68V2.782l-.004-.927a.453.453 0 0 0-.44-.464.452.452 0 0 1-.439-.463c0-.256.197-.464.44-.464.727 0 1.317.623 1.322 1.39V7.57a24.31 24.31 0 0 1 2.643-.13L9.83.927h-.606a.452.452 0 0 1-.44-.464c0-.256.197-.464.44-.464h9.663c.243 0 .44.208.44.464a.452.452 0 0 1-.44.464h-.328l.963 8.102zM3.91 17.652c1.68-.003 3.04-1.444 3.037-3.217-.003-1.774-1.368-3.21-3.048-3.207-1.68.004-3.04 1.444-3.037 3.218.002 1.774 1.367 3.21 3.048 3.206zm6.704-3.743a5.902 5.902 0 0 1-.073-.927 5.72 5.72 0 0 1 1.342-3.709h-2.67l2.182-7.418h5.502l.7 5.891c.331.126.647.285.943.475L17.673.928H10.75L8.753 7.713l-.196.665-.66-.013-.168-.001c-.465 0-2.108.023-3.718.318-1.594.293-2.714.777-3.131 1.346l.008 1.71C1.613 10.8 2.716 10.2 3.953 10.2c2.035 0 3.71 1.623 3.929 3.71h2.733zm6.069-6.414l-.561-4.713h-4.08l-1.636 5.563h2.491a5.053 5.053 0 0 1 3.786-.85zm-.91 10.127c2.414 0 4.37-2.066 4.37-4.613 0-2.548-1.956-4.613-4.37-4.613-2.413 0-4.37 2.065-4.37 4.613 0 2.547 1.957 4.613 4.37 4.613zm-11.82-4.64c.727 0 1.317.623 1.317 1.391s-.59 1.39-1.318 1.39c-.728 0-1.318-.622-1.318-1.39 0-.768.59-1.39 1.318-1.39zm0 1.855c.241 0 .438-.208.438-.464a.453.453 0 0 0-.439-.464.453.453 0 0 0-.439.464c0 .256.197.464.44.464z"
      />
    </defs>
    <use
      fill="currentColor"
      transform="matrix(-1 0 0 1 26.083 6)"
      xlinkHref="#icon-tractor-small_svg__a"
    />
  </svg>
);

export default SvgIconTractorSmall;
