import React from 'react';

const SvgIconBuy = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-buy_svg__a"
        d="M1.611 7.598c2.347-2.085 5.04-3.167 8.06-3.23 3.082-.063 5.52.876 7.64 2.562.435.346.724.6 1.174 1.034A8.727 8.727 0 0 0 1.61 7.598zm17.112 2.441l-.502-.545c-.84-.834-1.14-1.116-1.704-1.565-1.9-1.51-4.057-2.341-6.82-2.284-3.12.065-5.839 1.334-8.204 3.85l-.211.21a8.882 8.882 0 0 0 .106 1.69c1.69.124 3.081.864 4.506 2.313 1.025 1.043 1.763 2.649 2.236 4.815a8.756 8.756 0 0 0 3.67.015c.473-2.174 1.212-3.785 2.239-4.83 1.444-1.468 2.853-2.209 4.574-2.318.07-.44.108-.891.11-1.35zM6.711 18.082c-.42-1.58-1-2.74-1.727-3.479-1.092-1.11-2.096-1.704-3.277-1.888a8.748 8.748 0 0 0 5.004 5.367zm11.585-5.377c-1.21.17-2.234.766-3.347 1.898-.731.745-1.315 1.913-1.735 3.51a8.748 8.748 0 0 0 5.082-5.408zM10 13.19a3.191 3.191 0 1 1 0-6.382 3.191 3.191 0 0 1 0 6.382zm0-1.276a1.915 1.915 0 1 0 0-3.83 1.915 1.915 0 0 0 0 3.83zM10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z"
      />
    </defs>
    <use fill="currentColor" transform="translate(2 2)" xlinkHref="#icon-buy_svg__a" />
  </svg>
);

export default SvgIconBuy;
