import React from 'react';

const SvgIconGear = props => (
  <svg width={12} height={12} viewBox="0 0 12 12" {...props}>
    <defs>
      <path id="icon-gear_svg__a" d="M0 0h11.368v11.368H0z" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(.316 .316)">
      <mask id="icon-gear_svg__b" fill="#fff">
        <use xlinkHref="#icon-gear_svg__a" />
      </mask>
      <path
        fill="#54575F"
        d="M10.952 4.856L9.826 4.67a4.242 4.242 0 0 0-.356-.953l.72-.894a.497.497 0 0 0-.015-.643l-.448-.504a.497.497 0 0 0-.636-.091l-.964.605a4.237 4.237 0 0 0-1.42-.641L6.517.416A.497.497 0 0 0 6.028 0h-.675a.496.496 0 0 0-.49.416l-.19 1.132a4.23 4.23 0 0 0-1.195.497l-.916-.654a.498.498 0 0 0-.64.053l-.478.477a.497.497 0 0 0-.052.641l.655.918c-.22.363-.388.762-.493 1.186l-1.138.19A.497.497 0 0 0 0 5.347v.674c0 .244.176.451.416.491l1.138.19c.086.35.211.683.377.992l-.717.888a.497.497 0 0 0 .015.643l.447.504c.163.183.432.22.637.091l.977-.613c.414.282.881.49 1.383.613l.19 1.133c.038.24.246.415.49.415h.674a.497.497 0 0 0 .491-.415l.189-1.133a4.23 4.23 0 0 0 1.181-.488l.954.681c.197.142.469.12.64-.053l.478-.477a.496.496 0 0 0 .052-.64l-.679-.953c.221-.364.39-.764.494-1.19l1.126-.188a.497.497 0 0 0 .415-.49v-.675a.497.497 0 0 0-.416-.49zm-5.25 2.96a2.132 2.132 0 1 1 .001-4.264 2.132 2.132 0 0 1 0 4.264z"
        mask="url(#icon-gear_svg__b)"
      />
    </g>
  </svg>
);

export default SvgIconGear;
