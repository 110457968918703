import React from 'react';

const SvgIconTiktok = props => (
  <svg
    fill="#ffffff"
    width="35px"
    height="35px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    stroke="#ffffff"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0" />
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
    <g id="SVGRepo_iconCarrier" transform="scale(0.8) translate(2.5, 2.5)">
      <path d="M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z" />
    </g>
    <circle cx="12" cy="12" r="11" stroke="#ffffff" strokeWidth="0.8" fill="none" />
    <use
      fill="currentColor"
      fillRule="evenodd"
      xlinkHref="#icon-circle-tiktok_svg__a"
      transform="translate(4 4)"
    />
  </svg>
);

export default SvgIconTiktok;
