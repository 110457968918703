import React from 'react';

const SvgIconPerformance = props => (
  <svg width={19} height={19} viewBox="0 0 40 40" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="#54575F" fillRule="nonzero">
        <path d="M19.993 22.073c-9.14 0-16.58 7.44-16.58 16.58 0 .227.187.414.414.414a.415.415 0 00.413-.414c0-8.693 7.073-15.76 15.76-15.76s15.76 7.074 15.76 15.76c0 .227.187.414.413.414a.415.415 0 00.287-.12.42.42 0 00.113-.287c0-9.147-7.44-16.587-16.58-16.587z" />
        <path d="M20 25.187c-5.593 0-10.667 3.526-12.627 8.773-.22.6-.133 1.247.234 1.767.373.533.98.853 1.626.853h.507a.415.415 0 00.413-.413.415.415 0 00-.413-.414h-.507c-.373 0-.733-.186-.953-.5a1.084 1.084 0 01-.133-1.013c1.84-4.927 6.6-8.233 11.853-8.233s10.02 3.306 11.853 8.226c.127.34.08.714-.133 1.014-.22.313-.58.5-.953.5h-.507a.415.415 0 00-.413.413c0 .227.186.413.413.413h.507c.653 0 1.26-.32 1.62-.853.373-.527.46-1.173.233-1.773-1.953-5.234-7.027-8.76-12.62-8.76zm-5.853-2.64a.38.38 0 00.206.053c.14 0 .28-.073.354-.207a.426.426 0 00-.147-.566c-3.293-1.934-5.347-5.494-5.347-9.3C9.213 6.58 14.053 1.74 20 1.74c5.947 0 10.787 4.84 10.793 10.787 0 3.8-2.046 7.366-5.34 9.3a.4.4 0 00-.153.553c.1.187.367.26.553.153C29.4 20.453 31.6 16.62 31.6 12.527c0-6.4-5.207-11.6-11.6-11.6-6.4 0-11.6 5.206-11.6 11.6 0 4.106 2.2 7.94 5.747 10.02z" />
        <path d="M29.74 12.54a9.69 9.69 0 00-2.813-6.833c-.014-.02-.02-.04-.04-.054-.014-.013-.034-.02-.054-.033A9.645 9.645 0 0020 2.8a9.706 9.706 0 00-6.84 2.813l-.053.034c-.014.013-.027.04-.04.053a9.719 9.719 0 00-2.814 6.833c0 4.014 2.527 7.667 6.28 9.094.047.02.1.026.14.026.027 0 .054 0 .074-.006a17.29 17.29 0 016.466 0 .3.3 0 00.234-.014c3.766-1.426 6.293-5.08 6.293-9.093zm-17.513.407a.415.415 0 00.413-.414.415.415 0 00-.413-.413h-1.12a8.89 8.89 0 012.32-5.587l.786.787c.08.08.18.12.287.12a.41.41 0 00.287-.7L14 5.96a8.888 8.888 0 015.587-2.32v1.12c0 .227.186.413.413.413a.415.415 0 00.413-.413V3.64c2.147.1 4.094.96 5.587 2.32l-.787.787a.41.41 0 00.287.7c.113 0 .22-.047.287-.12l.786-.787a8.888 8.888 0 012.32 5.587h-1.12a.415.415 0 00-.413.413c0 .227.187.413.413.413H28.9a9.005 9.005 0 01-5.627 7.88 18.066 18.066 0 00-6.546 0c-3.247-1.286-5.46-4.413-5.627-7.88h1.127v-.006z" />
        <path d="M20 14.187a1.65 1.65 0 10.413-3.247V6.933A.415.415 0 0020 6.52a.415.415 0 00-.413.413v4.007a1.65 1.65 0 00-1.24 1.593c0 .914.74 1.654 1.653 1.654zm0-.814a.834.834 0 110-1.667.834.834 0 010 1.667zm-2.173 4.86h4.353c.573 0 1.033-.466 1.033-1.033v-.627c0-.573-.466-1.033-1.033-1.033h-4.353c-.574 0-1.034.467-1.034 1.033v.627a1.026 1.026 0 001.034 1.033zm-.214-1.026v-.627a.21.21 0 01.214-.213h4.353a.21.21 0 01.213.213v.627a.21.21 0 01-.213.213h-4.353a.21.21 0 01-.214-.213zM28.98 35.76h-3.02A6.667 6.667 0 0020 32.033a6.667 6.667 0 00-5.96 3.727h-3.02c-.18 0-.34.12-.393.3-.24.86-.36 1.733-.36 2.593 0 .227.186.414.413.414a.415.415 0 00.413-.414c0-.673.087-1.373.254-2.073h2.966c.167 0 .314-.1.374-.247a5.81 5.81 0 015.32-3.48 5.8 5.8 0 015.32 3.487.406.406 0 00.373.247h2.967c.166.7.253 1.4.253 2.073 0 .227.187.413.413.413a.415.415 0 00.414-.413c0-.86-.12-1.733-.36-2.593a.428.428 0 00-.407-.307zM3.233 12.873a.41.41 0 000 .58L5.72 15.94c.08.08.18.12.287.12a.41.41 0 00.287-.7l-2.487-2.487a.402.402 0 00-.574 0zM34.4 15.647v-3.514a.415.415 0 00-.413-.413.415.415 0 00-.414.413v3.514c0 .226.187.413.414.413a.415.415 0 00.413-.413z" />
        <path d="M10.133 20.013a.394.394 0 00.127-.286.407.407 0 00-.113-.294.41.41 0 00-.58-.02 5.196 5.196 0 01-3.56 1.42A5.192 5.192 0 01.82 15.647a5.192 5.192 0 015.187-5.187c.546 0 1.093.087 1.633.267a.4.4 0 00.313-.027.361.361 0 00.2-.24.396.396 0 00-.026-.313.361.361 0 00-.24-.2A5.833 5.833 0 006 9.633a6.02 6.02 0 00-6 6.014 6.012 6.012 0 006.007 6.006 5.997 5.997 0 004.126-1.64zm23.854-10.38c-.627 0-1.267.107-1.887.307a.39.39 0 00-.24.207.392.392 0 00-.02.313c.04.107.107.193.2.24.1.047.207.06.313.02a5.141 5.141 0 011.634-.267 5.192 5.192 0 015.186 5.187 5.192 5.192 0 01-5.186 5.187 5.172 5.172 0 01-3.56-1.42.418.418 0 00-.294-.114.394.394 0 00-.286.127.425.425 0 00-.114.293c0 .107.047.214.127.287a5.981 5.981 0 004.12 1.647c3.313 0 6.013-2.694 6.013-6.007a6.008 6.008 0 00-6.006-6.007z" />
      </g>
      <path d="M0 0h40v40H0z" />
    </g>
  </svg>
);

export default SvgIconPerformance;
