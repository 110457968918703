import React from 'react';

const SvgIconCircleTwitter = props => (
  <svg width={20} height={20} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-circle-twitter_svg__a"
        d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"
      />
    </defs>
    <circle cx="12" cy="12" r="11.5" stroke="currentColor" fill="none" />
    <use
      fill="currentColor"
      fillRule="evenodd"
      xlinkHref="#icon-circle-twitter_svg__a"
      transform="translate(4 4)"
    />
  </svg>
);

export default SvgIconCircleTwitter;
