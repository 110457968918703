import React from 'react';

function IconCreditcard() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <defs>
        <rect id="b" width="308" height="522" x="607" y="126" rx="8" />
        <filter
          id="a"
          width="104.2%"
          height="102.5%"
          x="-2.1%"
          y="-1.1%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="2"
           />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"
           />
        </filter>
        <path
          id="c"
          d="M15.81 0c1.052 0 1.909.853 1.909 1.9v9.688c0 1.048-.857 1.9-1.91 1.9H1.91a1.907 1.907 0 01-1.91-1.9V1.9C0 .853.856 0 1.91 0zm.87 4.355H1.039v7.233c0 .478.39.867.871.867h13.9a.87.87 0 00.872-.867V4.355zm-2.26 5.332a.518.518 0 110 1.034h-2.78a.518.518 0 110-1.034zM6.08 6.92a.52.52 0 01.366.152l1.112 1.107a.516.516 0 01.152.365v1.66a.518.518 0 01-.519.518H3.3a.518.518 0 01-.519-.517V7.436c0-.285.233-.517.52-.517zm-.216 1.034H3.818v1.734h2.854v-.93l-.808-.804zM14.42 6.92a.518.518 0 010 1.034H9.97a.518.518 0 110-1.034zm1.39-5.885H1.91a.87.87 0 00-.872.867v1.42h15.643v-1.42a.87.87 0 00-.871-.867z"
         />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fill="transparent" d="M-631-412H393v2237H-631z" />
        <g transform="translate(-631 -412)">
          <use fill="transparent" filter="url(#a)" xlinkHref="#b" />
          <use fill="transparent" xlinkHref="#b" />
        </g>
        <g transform="translate(1 3)">
          <mask id="d" fill="transparent">
            <use xlinkHref="#c" />
          </mask>
          <use fill="#EB0000" fillRule="nonzero" xlinkHref="#c" />
          <g fill="#EB0000" mask="url(#d)">
            <path d="M-2-3h22.098v20.089H-2z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconCreditcard;
