import React from 'react';

const SvgIconPurpleError = props => (
  <svg width={23} height={23} viewBox="0 0 23 23" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx={11.5} cy={11.5} r={9.5} fill="#FFF" />
      <path
        fill="#C143A8"
        d="M11.5 23C5.149 23 0 17.851 0 11.5S5.149 0 11.5 0 23 5.149 23 11.5 17.851 23 11.5 23zm.056-8.118c-.745 0-1.37.617-1.37 1.353s.625 1.353 1.37 1.353c.717 0 1.368-.617 1.335-1.32a1.328 1.328 0 0 0-1.335-1.386zM11.22 5.53c-.638.182-1.034.76-1.034 1.461.032.423.059.851.09 1.274l.274 4.8a.981.981 0 0 0 1.002.943c.547 0 .975-.423 1.002-.974 0-.332 0-.637.032-.974.059-1.033.123-2.066.183-3.1.031-.668.09-1.337.123-2.006a1.66 1.66 0 0 0-.123-.67 1.344 1.344 0 0 0-1.549-.754z"
      />
    </g>
  </svg>
);

export default SvgIconPurpleError;
