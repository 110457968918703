import React from 'react';

const SvgIconVanOn = props => (
  <svg width={190} height={77} viewBox="0 0 190 77" {...props}>
    <defs>
      <linearGradient id="icon-van-on_svg__a" x1="50.01%" x2="50.01%" y1="-55.023%" y2="92.358%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__b" x1="27.217%" x2="64.127%" y1="40.288%" y2="61.864%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__c" x1="49.772%" x2="51.111%" y1="48.53%" y2="50.86%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__d" x1="30.666%" x2="78.685%" y1="12.045%" y2="84.239%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__e" x1="49.861%" x2="51.052%" y1="49.05%" y2="51.13%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__f" x1="20.036%" x2="47.609%" y1="32.04%" y2="56.391%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__g" x1="21.348%" x2="77.168%" y1="13.769%" y2="87.435%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__h" x1="50.33%" x2="50.33%" y1=".005%" y2="99.999%">
        <stop offset="0%" stopOpacity={0} />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__i" x1="49.95%" x2="49.95%" y1=".005%" y2="99.999%">
        <stop offset="0%" stopOpacity={0} />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__j" x1="31.365%" x2="60.893%" y1="37.396%" y2="57.343%">
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__k" x1="26.33%" x2="73.732%" y1="15.671%" y2="85.156%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__l" x1="2.236%" x2="52.273%" y1="-32.787%" y2="53.878%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__m" x1="35.486%" x2="64.411%" y1="47.417%" y2="52.58%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__n" x1="3.78%" x2="53.816%" y1="-29.944%" y2="56.722%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__o" x1="35.534%" x2="64.46%" y1="47.417%" y2="52.58%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__p" x1="50.008%" x2="50.008%" y1=".001%" y2="100.002%">
        <stop offset="0%" stopOpacity={0} />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__q" x1="35.873%" x2="71.507%" y1="36.149%" y2="63.633%">
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__r" x1="40.177%" x2="60.541%" y1="7.065%" y2="96.342%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__s" x1="17.358%" x2="82.961%" y1="23.683%" y2="76.751%">
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__t" x1="16.74%" x2="83.943%" y1="-7.708%" y2="109.088%">
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__u" x1="24.992%" x2="74.988%" y1="6.711%" y2="93.306%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__v" x1="24.975%" x2="74.993%" y1="6.698%" y2="93.331%">
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__w" x1="25.014%" x2="75.01%" y1="6.711%" y2="93.306%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__x" x1="25.01%" x2="75.028%" y1="6.698%" y2="93.331%">
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__y" x1="19.817%" x2="71.287%" y1="24.955%" y2="78.714%">
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__z" x1="24.875%" x2="74.953%" y1="6.686%" y2="93.422%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__A" x1="10.058%" x2="60.112%" y1="-18.909%" y2="67.786%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__B" x1="23.003%" x2="68.125%" y1="2.433%" y2="103.939%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__C" x1="21.315%" x2="77.257%" y1="14.507%" y2="86.452%">
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__D" x1="31.156%" x2="75.403%" y1="31.08%" y2="67.58%">
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__E" x1="-25.686%" x2="59.104%" y1="7.706%" y2="55.227%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__F" x1="36.666%" x2="63.359%" y1="47.296%" y2="52.699%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__G" x1="36.558%" x2="63.34%" y1="47.82%" y2="52.169%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__H" x1="33.192%" x2="72.032%" y1="33.953%" y2="64.793%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-van-on_svg__I" x1="24.502%" x2="64.486%" y1="29.475%" y2="68.852%">
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <path
          fill="url(#icon-van-on_svg__a)"
          d="M22.129 63.6s15.699-.049 31.495.4l33.83-6.102h44.876l6.246 6.094s14.665-.762 31.511-.714l4.913-4.98V45.855l-22.72-4.515L21.481 40 18 56.214l4.129 7.385z"
        />
        <path
          fill="url(#icon-van-on_svg__b)"
          d="M188.99 55.657a1.177 1.177 0 0 0-.234-.669l-.793-1.056v-1.571s.477.016.477-.387-.372-.387-.372-.387v-.935s.51-.016.51-.42c0-.402-.478-.402-.478-.402v-4.7s-.242-.782-1.868-1.62c0 0 .38-.137.38-1.136 0-1-.024-4.514-2.305-6.49 0 0-7.934-8.068-24.602-12.251 0 0-1.148-.29-2.264-.202 0 0-15.95-13.26-24.409-18.063C124.572.572 121.75.983 118.378.846c-3.373-.137-90.322-2.193-108.002.653 0 0-2.102.306-3.825 6.126-1.723 5.82-3.64 15.79-4.117 24.012-.477 8.222-.477 22.723-.477 22.723A1.95 1.95 0 0 0 0 56.31v3.966a2.26 2.26 0 0 0 2.143 2.257l18.966 1.064h.25a42.975 42.975 0 0 1-.056-2.096c0-8.713 7.084-15.774 15.827-15.774s15.827 7.06 15.827 15.774c0 .879-.024 1.71-.08 2.499h84.845l.194-.008a40.296 40.296 0 0 1-.073-2.49c0-8.714 7.085-15.775 15.828-15.775 8.742 0 15.827 7.06 15.827 15.774 0 .613-.016 1.201-.04 1.774h.129s8.953-.315 18.157-3.386a1.601 1.601 0 0 0 1.083-1.257c.114-.621.203-1.572.162-2.975z"
        />
        <path fill="url(#icon-van-on_svg__c)" d="M24.022 1.364z" transform="translate(163 41)" />
        <path
          fill="url(#icon-van-on_svg__d)"
          d="M26.435 15.081a1.205 1.205 0 0 0-.237-.681l-.802-1.076v-1.602s.483.016.483-.394c0-.411-.376-.395-.376-.395V9.98s.515-.016.515-.427c0-.41-.483-.41-.483-.41v-4.79s-.245-.796-1.89-1.65c0 0-10.047.123-13.876-1.413A7.513 7.513 0 0 1 8.133.37L.442 8.272a16.083 16.083 0 0 1 6.292 12.773c0 .624-.017 1.224-.041 1.807h.13s9.058-.32 18.369-3.45a1.628 1.628 0 0 0 1.096-1.281c.098-.641.188-1.61.147-3.04z"
          transform="translate(163 41)"
        />
        <path fill="url(#icon-van-on_svg__e)" d="M24.014.936z" transform="translate(163 41)" />
        <path
          fill="url(#icon-van-on_svg__f)"
          d="M148.664 26.358l-21.1-17.782a12.183 12.183 0 0 0-7.21-2.844l-14.14-.729a2.101 2.101 0 0 0-2.191 2.403l2.641 18.03a2.213 2.213 0 0 0 2.16 1.89l39.23.674c.875.024 1.284-1.074.61-1.642zm-39.182.176a2.056 2.056 0 0 1-1.999-1.754l-2.57-17.566a1.233 1.233 0 0 1 1.286-1.41l13.97.721a11.255 11.255 0 0 1 6.68 2.635l11.112 9.364a.48.48 0 0 1 .169.344l.393 7.65a.476.476 0 0 1-.49.505l-28.55-.49z"
        />
        <path
          fill="url(#icon-van-on_svg__g)"
          d="M152.747 23.416L131.984 5.785c-.032-.033-.064-.058-.096-.09-.184-.215-.463-.84-.048-1.448L131.377 4c-.192.123-.288.345-.344.584-.128.617.128 1.258.6 1.653L152.546 24h5.429l.024-.19-.464-.394h-4.789z"
        />
        <path
          fill="url(#icon-van-on_svg__h)"
          d="M1.13 61.233c-.14 0-.26-.098-.26-.212V.531c0-.115.12-.213.26-.213s.26.098.26.212v60.491c0 .114-.11.212-.26.212z"
          opacity={0.5}
          transform="translate(100 2)"
        />
        <path
          fill="url(#icon-van-on_svg__i)"
          d="M.65 61.233c-.14 0-.26-.098-.26-.212V.531C.39.415.51.317.65.317S.91.416.91.53v60.491c0 .114-.12.212-.26.212z"
          opacity={0.5}
          transform="translate(58 2)"
        />
        <path
          fill="url(#icon-van-on_svg__j)"
          d="M144.034 36h3.778c.308 0 .598-.14.82-.39L150 34.05l-6-.05.034 2z"
        />
        <path fill="url(#icon-van-on_svg__k)" d="M14 32.979L14.05 31l2.95.053V33z" />
        <circle cx={109} cy={31} r={2} fill="url(#icon-van-on_svg__l)" />
        <path
          fill="url(#icon-van-on_svg__m)"
          d="M112.423 31h-6.846c-.314 0-.577-.21-.577-.476v-.048c0-.26.255-.476.577-.476h6.846c.314 0 .577.21.577.476v.048c-.008.26-.263.476-.577.476z"
        />
        <circle cx={93} cy={31} r={2} fill="url(#icon-van-on_svg__n)" />
        <path
          fill="url(#icon-van-on_svg__o)"
          d="M96.423 31h-6.846c-.314 0-.577-.21-.577-.476v-.048c0-.26.255-.476.577-.476h6.846c.314 0 .577.21.577.476v.048c0 .26-.255.476-.577.476z"
        />
        <path
          fill="url(#icon-van-on_svg__p)"
          d="M.938 53.927c-.113 0-.195-.096-.195-.21 0-.2.33-19.915.33-22.284 0-7.214 3.9-30.474 3.937-30.708.022-.112.12-.185.225-.169a.214.214 0 0 1 .157.242c-.037.234-3.937 23.462-3.937 30.635 0 2.377-.323 22.091-.33 22.293 0 .113-.083.201-.188.201z"
          opacity={0.5}
          transform="translate(7 1)"
        />
        <path
          fill="url(#icon-van-on_svg__q)"
          d="M188.666 60h-3.768c-.5 0-.898-.451-.898-1 0-.558.405-1 .898-1H189s-.064 1.124-.135 1.407c-.064.283-.12.47-.199.593z"
        />
        <path
          fill="url(#icon-van-on_svg__r)"
          d="M3 45h4.824L8 33.024C5.81 33.008 4.562 33 4.462 33c-1.133 0-1.179 1.09-1.179 1.09L3 45z"
        />
        <path fill="url(#icon-van-on_svg__s)" d="M2 43h3v-2H2.058z" />
        <path fill="url(#icon-van-on_svg__t)" d="M3 38h2v-2H3.068z" />
        <circle cx={154.5} cy={62.5} r={14.5} fill="url(#icon-van-on_svg__u)" />
        <circle cx={154} cy={62} r={9} fill="url(#icon-van-on_svg__v)" />
        <circle cx={37.5} cy={62.5} r={14.5} fill="url(#icon-van-on_svg__w)" />
        <circle cx={38} cy={62} r={9} fill="url(#icon-van-on_svg__x)" />
        <path
          fill="url(#icon-van-on_svg__y)"
          d="M109.633 26.516c-1.015-.016-1.875-.753-2.022-1.736l-2.598-17.383c-.113-.761.512-1.435 1.3-1.395l14.126.713a11.52 11.52 0 0 1 6.755 2.608l11.237 9.266a.467.467 0 0 1 .17.341L139 26.5a.476.476 0 0 1-.495.5l-28.87-.484z"
        />
        <circle cx={141.5} cy={25.5} r={2.5} fill="url(#icon-van-on_svg__z)" />
        <circle cx={141.5} cy={25.5} r={1.5} fill="url(#icon-van-on_svg__A)" />
        <path
          fill="url(#icon-van-on_svg__B)"
          d="M136.288 27h4.614c.64 0 1.145-.553 1.095-1.2l-.38-4.779a1.804 1.804 0 0 0-.563-1.182c-.716-.655-2.232-1.659-4.985-1.837a1.016 1.016 0 0 0-1.069 1.02V25.7a1.3 1.3 0 0 0 1.288 1.3z"
        />
        <path
          fill="url(#icon-van-on_svg__C)"
          d="M157 23c-2.671-2.242-16.205-13.506-23.746-17.867A72.022 72.022 0 0 0 131.217 4c-.417.603-.137 1.223.048 1.435a.967.967 0 0 0 .096.09L152.195 23H157z"
        />
        <path
          fill="url(#icon-van-on_svg__D)"
          d="M186.613 43.998s.387-.14.387-1.161-.025-4.612-2.346-6.63c-4.413-1.481-13.708-2.033-17.578-2.206a1.028 1.028 0 0 0-.955 1.507c1.243 2.322 3.507 5.855 6.529 7.057 3.853 1.556 13.963 1.433 13.963 1.433z"
        />
        <path
          fill="url(#icon-van-on_svg__E)"
          d="M177.98 49c-.718 0-1.36.385-1.635.982l-1.267 2.757c-.275.596.209 1.261.926 1.261h12.362v-1.465s.492.015.492-.363c0-.377-.383-.362-.383-.362v-.876s.525-.016.525-.393c0-.378-.492-.378-.492-.378V49H177.98z"
        />
        <path
          fill="url(#icon-van-on_svg__F)"
          d="M188.642 52.02h-10.269c-.21 0-.373.21-.373.48s.163.48.373.48h10.168s.459.05.459-.48c-.008-.49-.358-.48-.358-.48z"
        />
        <path
          fill="url(#icon-van-on_svg__G)"
          d="M188.472 50h-11.028c-.243 0-.444.226-.444.5s.201.5.444.5h11.028s.528-.01.528-.51c-.008-.49-.528-.49-.528-.49z"
        />
        <path
          fill="url(#icon-van-on_svg__H)"
          d="M1 56.345v4.166c0 1.262.932 2.304 2.128 2.371L21.96 64h.25a47.752 47.752 0 0 1-.057-2.202c0-2.82.666-5.478 1.847-7.798l-21.057.296c-1.076 0-1.943.915-1.943 2.05z"
        />
        <path
          fill="url(#icon-van-on_svg__I)"
          d="M1 59h3.12c.483 0 .88.442.88 1 0 .549-.39 1-.88 1H1v-2z"
        />
      </g>
      <path d="M0-17h190v94H0z" />
    </g>
  </svg>
);

export default SvgIconVanOn;
