import React from 'react';

const SvgIconKm = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-km_svg__a"
        d="M20 9.995a9.905 9.905 0 0 1-2.02 6.023.781.781 0 0 1-1.245-.942 8.354 8.354 0 0 0 1.703-5.08A8.43 8.43 0 0 0 10 1.561a8.43 8.43 0 0 0-8.438 8.433c0 1.851.59 3.608 1.703 5.08a.781.781 0 1 1-1.246.943A9.906 9.906 0 0 1 0 9.995C0 4.468 4.476 0 10 0c5.527 0 10 4.471 10 9.995zM14.95 5.29c.305.305.305.8 0 1.105l-2.546 2.547c.21.386.329.829.329 1.298A2.736 2.736 0 0 1 10 12.973a2.736 2.736 0 0 1-2.733-2.733A2.736 2.736 0 0 1 11.3 7.837l2.546-2.547a.781.781 0 0 1 1.105 0zm-3.78 4.95c0-.645-.525-1.17-1.17-1.17a1.17 1.17 0 1 0 1.17 1.17z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(2 3.5)">
      <mask id="icon-km_svg__b" fill="#fff">
        <use xlinkHref="#icon-km_svg__a" />
      </mask>
      <use fill="#000" fillRule="nonzero" xlinkHref="#icon-km_svg__a" />
      <g fill="#757575" mask="url(#icon-km_svg__b)">
        <path d="M-2-4h24v24H-2z" />
      </g>
    </g>
  </svg>
);

export default SvgIconKm;
