import React from 'react';

const SvgIconMail = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id={`icon-mail_svg__a${props.customId}`}
        d="M.735 15.522a.716.716 0 0 1-.184-.172A2.017 2.017 0 0 1 0 13.963V2.037c0-.403.118-.779.322-1.095A.714.714 0 0 1 .937.33 2.069 2.069 0 0 1 2.062 0H19.94A2.05 2.05 0 0 1 22 2.037v11.926c0 .54-.213 1.032-.56 1.397a.736.736 0 0 1-.165.155c-.36.302-.826.485-1.336.485H2.061c-.505 0-.968-.18-1.326-.478zm1.842-.943h16.837l-6.252-5.645-.758.681a2.161 2.161 0 0 1-1.409.55 2.152 2.152 0 0 1-1.438-.55l-.745-.667-6.235 5.631zm-1.14-.889l6.31-5.696-6.31-5.647V13.69zm11.33-6.323l6.615-5.946H2.532l7.946 7.143a.716.716 0 0 0 .958 0l1.148-1.033a.716.716 0 0 1 .183-.164zm7.795 6.332V2.282l-6.336 5.695 6.336 5.722z"
      />
    </defs>
    <use
      fill="currentColor"
      transform="translate(1 4)"
      xlinkHref={`#icon-mail_svg__a${props.customId}`}
    />
  </svg>
);

export default SvgIconMail;
