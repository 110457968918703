import React from 'react';

const SvgIconMenu = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-menu_svg__a"
        d="M20 9.999a.586.586 0 0 0-.325-.525L15.8 7.544l3.876-1.93a.586.586 0 0 0 0-1.05L10.958.225a2.16 2.16 0 0 0-1.916 0L.325 4.564a.586.586 0 0 0 0 1.05L4.2 7.544.325 9.474a.586.586 0 0 0 0 1.05l3.876 1.93-3.876 1.93a.586.586 0 0 0 0 1.049l8.717 4.34a2.15 2.15 0 0 0 1.916 0l8.717-4.34a.586.586 0 0 0 0-1.05l-3.876-1.93 3.876-1.93A.586.586 0 0 0 20 10zM9.565 1.273a.982.982 0 0 1 .87 0L18.1 5.089l-3.877 1.93-3.788 1.886a.985.985 0 0 1-.87 0L5.777 7.02 1.9 5.09l7.665-3.816zm4.92 11.835l3.615 1.8-7.665 3.817a.982.982 0 0 1-.87 0L1.9 14.908l3.616-1.8 3.526 1.756a2.09 2.09 0 0 0 .445.162l.023.006a2.16 2.16 0 0 0 1.448-.168l3.526-1.756zm-.262-1.18v.001l-3.788 1.886a.981.981 0 0 1-.87 0l-3.788-1.886L1.9 9.999l3.616-1.8 3.526 1.755a2.155 2.155 0 0 0 1.915 0L14.484 8.2 18.1 10l-3.877 1.93z"
      />
    </defs>
    <use fill="currentColor" transform="translate(2 2)" xlinkHref="#icon-menu_svg__a" />
  </svg>
);

export default SvgIconMenu;
