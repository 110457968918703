import React from 'react';

const SvgIconMoney = props => (
  <svg
    id="icon-money_svg__Camada_1"
    x={0}
    y={0}
    width={126}
    height={126}
    viewBox="0 0 410 415"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.icon-money_svg__st0{fill:#f9dcdf}.icon-money_svg__st2{fill:#ff1d46}'}</style>
    <path
      className="icon-money_svg__st0"
      d="M114.82 205.17c-28.08 23.25-31.99 64.85-8.74 92.93 23.25 28.07 64.85 31.99 92.93 8.74V205.17c-24.43-20.22-59.78-20.22-84.19 0zM197 88l20-15V40h-15.52l-10.24 10.24a6 6 0 0 1-8.48 0L172.52 40h-31.03l-10.24 10.24a6 6 0 0 1-8.48 0L112.52 40H97v33l20 15h80zM121 100h72v18h-72z"
    />
    <path
      className="icon-money_svg__st0"
      d="M191.04 130H123c-57.6 20.84-92 92.27-92 149.67C31 366.62 119.12 376 157 376c14.09.06 28.16-1.24 42-3.89v-50.46c-25.66 16.46-58.56 16.46-84.22 0-36.26-23.26-46.8-71.51-23.54-107.77 23.26-36.26 71.51-46.8 107.77-23.54V166c0-3.31 2.69-6 6-6h30.64a118.378 118.378 0 0 0-44.61-30z"
    />
    <path
      className="icon-money_svg__st0"
      d="M211 376h156V220H211v156zm108-138c0-3.31 2.69-6 6-6h24c3.31 0 6 2.69 6 6v24c0 3.31-2.69 6-6 6h-24c-3.31 0-6-2.69-6-6v-24zm0 48c0-3.31 2.69-6 6-6h24c3.31 0 6 2.69 6 6v72c0 3.31-2.69 6-6 6h-24c-3.31 0-6-2.69-6-6v-72zm-48-48c0-3.31 2.69-6 6-6h24c3.31 0 6 2.69 6 6v24c0 3.31-2.69 6-6 6h-24c-3.31 0-6-2.69-6-6v-24zm0 48c0-3.31 2.69-6 6-6h24c3.31 0 6 2.69 6 6v24c0 3.31-2.69 6-6 6h-24c-3.31 0-6-2.69-6-6v-24zm0 48c0-3.31 2.69-6 6-6h24c3.31 0 6 2.69 6 6v24c0 3.31-2.69 6-6 6h-24c-3.31 0-6-2.69-6-6v-24zm-48-96c0-3.31 2.69-6 6-6h24c3.31 0 6 2.69 6 6v24c0 3.31-2.69 6-6 6h-24c-3.31 0-6-2.69-6-6v-24zm0 48c0-3.31 2.69-6 6-6h24c3.31 0 6 2.69 6 6v24c0 3.31-2.69 6-6 6h-24c-3.31 0-6-2.69-6-6v-24zm0 48c0-3.31 2.69-6 6-6h24c3.31 0 6 2.69 6 6v24c0 3.31-2.69 6-6 6h-24c-3.31 0-6-2.69-6-6v-24zM211 208h156v-36H211v36zm132-24h12v12h-12v-12zm-24 0h12v12h-12v-12zm-24 0h12v12h-12v-12z"
    />
    <path
      d="M379 165H257.7a138.364 138.364 0 0 0-46.7-37.12V102l21.6-16.2A5.996 5.996 0 0 0 235 81V39c0-3.31-2.69-6-6-6h-24c-1.59 0-3.12.63-4.24 1.76L193 42.52l-7.76-7.76A5.97 5.97 0 0 0 181 33h-36c-1.59 0-3.12.63-4.24 1.76L133 42.52l-7.76-7.76A5.97 5.97 0 0 0 121 33H97c-3.31 0-6 2.69-6 6v42c0 1.89.89 3.67 2.4 4.8L115 102v25.94c-45.42 21.98-90 81.01-90 156.73C25 353.52 75.3 393 163 393c14.24.05 28.45-1.21 42.45-3.77A6.01 6.01 0 0 0 211 393h168c3.31 0 6-2.69 6-6V171c0-3.31-2.69-6-6-6zM103 78V45h15.52l10.24 10.24a6 6 0 0 0 8.48 0L147.48 45h31.03l10.24 10.24a6 6 0 0 0 8.48 0L207.48 45H223v33l-20 15h-80l-20-15zm96 27v18h-72v-18h72zm6 206.84c-28.07 23.25-69.68 19.33-92.93-8.74-23.25-28.08-19.34-69.68 8.74-92.93 24.42-20.22 59.77-20.22 84.18 0v101.67zM205 171v24.34c-36.26-23.26-84.51-12.72-107.77 23.54-23.26 36.26-12.72 84.51 23.54 107.77 25.66 16.46 58.56 16.46 84.22 0v50.46a218.42 218.42 0 0 1-42 3.89c-37.88 0-126-9.38-126-96.33 0-57.4 34.41-128.83 92-149.67h68.04a118.264 118.264 0 0 1 44.6 30H211c-3.31 0-6 2.69-6 6zm168 210H217V225h156v156zm0-168H217v-36h156v36z"
      fill="#f78092"
    />
    <path
      className="icon-money_svg__st0"
      d="M283 244h12v12h-12zM235 244h12v12h-12zM283 292h12v12h-12zM331 292h12v60h-12zM283 340h12v12h-12zM331 244h12v12h-12zM235 340h12v12h-12zM235 292h12v12h-12z"
    />
    <path
      className="icon-money_svg__st2"
      d="M151 213v10.71c-10.56 2.27-17.96 10.2-18 19.29-.01 8.13 6.23 15.35 15.49 17.91l13.23 3.67c4.35 1.21 7.29 4.6 7.29 8.42 0 5.52-5.37 10-12 10s-12-4.48-12-10h-12c.04 9.09 7.44 17.02 18 19.29V303h12v-10.71c10.57-2.27 17.96-10.2 18-19.29.01-8.13-6.23-15.35-15.48-17.91l-13.23-3.67c-4.35-1.21-7.29-4.6-7.29-8.42 0-5.52 5.37-10 12-10s12 4.48 12 10h12c-.04-9.09-7.43-17.02-18-19.29V213H151z"
    />
    <path
      d="M373 160H251.7a138.364 138.364 0 0 0-46.7-37.12V97l21.6-16.2A5.996 5.996 0 0 0 229 76V34c0-3.31-2.69-6-6-6h-24c-1.59 0-3.12.63-4.24 1.76L187 37.52l-7.76-7.76A5.97 5.97 0 0 0 175 28h-36c-1.59 0-3.12.63-4.24 1.76L127 37.52l-7.76-7.76A5.97 5.97 0 0 0 115 28H91c-3.31 0-6 2.69-6 6v42c0 1.89.89 3.67 2.4 4.8L109 97v25.94c-45.42 21.98-90 81.01-90 156.73C19 348.52 69.3 388 157 388c14.24.05 28.45-1.21 42.45-3.77A6.01 6.01 0 0 0 205 388h168c3.31 0 6-2.69 6-6V166c0-3.31-2.69-6-6-6zM97 73V40h15.52l10.24 10.24a6 6 0 0 0 8.48 0L141.48 40h31.03l10.24 10.24a6 6 0 0 0 8.48 0L201.48 40H217v33l-20 15h-80L97 73zm96 27v18h-72v-18h72zm6 206.84c-28.07 23.25-69.68 19.33-92.93-8.74-23.25-28.08-19.34-69.68 8.74-92.93 24.42-20.22 59.77-20.22 84.18 0v101.67zM199 166v24.34c-36.26-23.26-84.51-12.72-107.77 23.54-23.26 36.26-12.72 84.51 23.54 107.77 25.66 16.46 58.56 16.46 84.22 0v50.46a218.42 218.42 0 0 1-42 3.89c-37.88 0-126-9.38-126-96.33 0-57.4 34.41-128.83 92-149.67h68.04a118.264 118.264 0 0 1 44.6 30H205c-3.31 0-6 2.69-6 6zm168 210H211V220h156v156zm0-168H211v-36h156v36z"
      fill="#fff"
    />
    <path
      className="icon-money_svg__st2"
      d="M229 268h24c3.31 0 6-2.69 6-6v-24c0-3.31-2.69-6-6-6h-24c-3.31 0-6 2.69-6 6v24c0 3.31 2.69 6 6 6zm6-24h12v12h-12v-12zM277 268h24c3.31 0 6-2.69 6-6v-24c0-3.31-2.69-6-6-6h-24c-3.31 0-6 2.69-6 6v24c0 3.31 2.69 6 6 6zm6-24h12v12h-12v-12zM325 268h24c3.31 0 6-2.69 6-6v-24c0-3.31-2.69-6-6-6h-24c-3.31 0-6 2.69-6 6v24c0 3.31 2.69 6 6 6zm6-24h12v12h-12v-12zM229 316h24c3.31 0 6-2.69 6-6v-24c0-3.31-2.69-6-6-6h-24c-3.31 0-6 2.69-6 6v24c0 3.31 2.69 6 6 6zm6-24h12v12h-12v-12zM277 316h24c3.31 0 6-2.69 6-6v-24c0-3.31-2.69-6-6-6h-24c-3.31 0-6 2.69-6 6v24c0 3.31 2.69 6 6 6zm6-24h12v12h-12v-12zM229 364h24c3.31 0 6-2.69 6-6v-24c0-3.31-2.69-6-6-6h-24c-3.31 0-6 2.69-6 6v24c0 3.31 2.69 6 6 6zm6-24h12v12h-12v-12zM277 364h24c3.31 0 6-2.69 6-6v-24c0-3.31-2.69-6-6-6h-24c-3.31 0-6 2.69-6 6v24c0 3.31 2.69 6 6 6zm6-24h12v12h-12v-12zM325 364h24c3.31 0 6-2.69 6-6v-72c0-3.31-2.69-6-6-6h-24c-3.31 0-6 2.69-6 6v72c0 3.31 2.69 6 6 6zm6-72h12v60h-12v-60zM343 184h12v12h-12zM319 184h12v12h-12zM295 184h12v12h-12z"
    />
  </svg>
);

export default SvgIconMoney;
