import React from 'react';

const SvgIconWarning = props => (
  <svg width={23} height={21} viewBox="0 0 23 21" {...props}>
    <g fill="currentColor">
      <path d="M21.734 18.61a4.006 4.006 0 0 0 .01-4.008L14.571 2.018A3.887 3.887 0 0 0 11.142 0c-1.437 0-2.72.756-3.43 2.014L.533 14.61a4.04 4.04 0 0 0 .014 4.032 3.91 3.91 0 0 0 3.42 1.99h14.325c1.433 0 2.72-.756 3.443-2.022zm-1.557-.91a2.158 2.158 0 0 1-1.892 1.11H3.962a2.13 2.13 0 0 1-1.869-1.086 2.22 2.22 0 0 1-.004-2.213L9.27 2.918a2.12 2.12 0 0 1 1.873-1.1 2.13 2.13 0 0 1 1.873 1.104l7.176 12.593c.384.678.38 1.495-.014 2.186z" />
      <path d="M11.443 14.5c-.622 0-1.145.528-1.145 1.16 0 .63.522 1.16 1.145 1.16.6 0 1.145-.53 1.118-1.132a1.126 1.126 0 0 0-1.118-1.189zm-.285-8.267c-.545.158-.884.659-.884 1.267.028.366.05.737.078 1.104l.234 4.162a.843.843 0 0 0 .856.817c.467 0 .833-.366.856-.844 0-.288 0-.553.028-.845.05-.895.105-1.791.155-2.686.028-.58.078-1.16.105-1.74 0-.209-.027-.394-.105-.58a1.143 1.143 0 0 0-1.323-.655z" />
    </g>
  </svg>
);

export default SvgIconWarning;
