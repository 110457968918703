import React from 'react';

const SvgIconVanOff = props => (
  <svg width={188} height={75} viewBox="0 0 188 75" {...props}>
    <defs>
      <linearGradient
        id="icon-van-off_svg__a"
        x1="50.006%"
        x2="50.006%"
        y1="-110.87%"
        y2="101.174%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__b" x1="27.218%" x2="74.186%" y1="40.079%" y2="68.125%">
        <stop offset="0%" stopColor="#DCE6E6" />
        <stop offset="19.25%" stopColor="#CED8D8" />
        <stop offset="55.37%" stopColor="#AAB4B4" />
        <stop offset="100%" stopColor="#768080" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__c" x1="49.12%" x2="50.46%" y1="48.538%" y2="50.855%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient
        id="icon-van-off_svg__d"
        x1="-25.574%"
        x2="79.988%"
        y1="-70.864%"
        y2="85.706%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__e" x1="49.2%" x2="50.41%" y1="49.055%" y2="51.124%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__f" x1="8.35%" x2="56.657%" y1="21.729%" y2="64.391%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__g" x1="21.358%" x2="77.178%" y1="17.302%" y2="83.785%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__h" x1="49.67%" x2="49.67%" y1="-1.01%" y2="101.015%">
        <stop offset="0%" stopOpacity={0} />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__i" x1="49.92%" x2="49.92%" y1="-1.01%" y2="101.015%">
        <stop offset="0%" stopOpacity={0} />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__j" x1="31.413%" x2="60.942%" y1="37.396%" y2="57.343%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient
        id="icon-van-off_svg__k"
        x1="-26.049%"
        x2="60.051%"
        y1="-81.573%"
        y2="67.556%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__l" x1="17.404%" x2="64.614%" y1="44.184%" y2="52.609%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__m" x1="-25.105%" x2="61.016%" y1="-80.08%" y2="69.085%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__n" x1="15.494%" x2="64.893%" y1="43.834%" y2="52.651%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__o" x1="50.005%" x2="50.005%" y1="-1.149%" y2="101.147%">
        <stop offset="0%" stopOpacity={0} />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__p" x1="35.768%" x2="71.402%" y1="36.149%" y2="63.633%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__q" x1="40.186%" x2="60.55%" y1="7.065%" y2="96.342%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__r" x1="17.468%" x2="83.068%" y1="-9.214%" y2="110.19%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__s" x1="16.851%" x2="84.057%" y1="-7.708%" y2="109.088%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__t" x1="8.619%" x2="78.474%" y1="-21.664%" y2="99.328%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__u" x1="24.991%" x2="75.008%" y1="6.698%" y2="93.331%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__v" x1="12.807%" x2="80.21%" y1="-14.399%" y2="102.347%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__w" x1="24.98%" x2="74.998%" y1="6.698%" y2="93.331%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__x" x1="19.802%" x2="71.271%" y1="24.955%" y2="78.714%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__y" x1="10.243%" x2="77.38%" y1="-18.76%" y2="97.524%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-van-off_svg__z"
        x1="-37.851%"
        x2="77.886%"
        y1="-102.03%"
        y2="98.435%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__A" x1="-4.049%" x2="72.301%" y1="-32.96%" y2="102.749%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__B" x1="21.325%" x2="77.268%" y1="14.507%" y2="86.452%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__C" x1="31.17%" x2="75.416%" y1="29.141%" y2="69.382%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__D" x1="-25.665%" x2="59.125%" y1=".949%" y2="56.062%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__E" x1="36.69%" x2="63.383%" y1="47.296%" y2="52.699%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__F" x1="36.581%" x2="63.363%" y1="47.406%" y2="52.582%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__G" x1="33.204%" x2="72.044%" y1="37.002%" y2="61.982%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-van-off_svg__H" x1="24.568%" x2="64.553%" y1="29.475%" y2="68.852%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g opacity={0.6}>
        <path
          fill="url(#icon-van-off_svg__a)"
          fillRule="nonzero"
          d="M22.076 63.6s15.5-.049 31.094.4l33.4-6.102h44.304l6.166 6.094s14.478-.762 31.11-.714l4.85-4.98V45.855l-22.431-4.515L21.439 40 18 56.214l4.076 7.385z"
        />
        <path
          fill="url(#icon-van-off_svg__b)"
          fillRule="nonzero"
          d="M186.987 55.657a1.185 1.185 0 0 0-.232-.669l-.785-1.056v-1.571s.473.016.473-.387-.369-.387-.369-.387v-.935s.505-.016.505-.42c0-.402-.473-.402-.473-.402v-4.7s-.24-.782-1.848-1.62c0 0 .376-.137.376-1.136 0-1-.024-4.514-2.28-6.49 0 0-7.85-8.068-24.342-12.251 0 0-1.136-.29-2.24-.202 0 0-15.78-13.26-24.15-18.063-8.37-4.796-11.163-4.385-14.5-4.522-3.336-.137-89.364-2.193-106.856.653 0 0-2.08.306-3.785 6.126-1.704 5.82-3.6 15.79-4.072 24.012-.473 8.222-.473 22.723-.473 22.723A1.94 1.94 0 0 0 0 56.31v3.966a2.25 2.25 0 0 0 2.12 2.257l18.765 1.064h.248a43.434 43.434 0 0 1-.056-2.096c0-8.713 7.01-15.774 15.66-15.774s15.659 7.06 15.659 15.774c0 .879-.024 1.71-.08 2.499h83.948l.191-.008a40.727 40.727 0 0 1-.072-2.49c0-8.714 7.01-15.775 15.66-15.775s15.66 7.06 15.66 15.774c0 .613-.017 1.201-.04 1.774h.127s8.858-.315 17.964-3.386c.56-.185.977-.669 1.073-1.257.12-.621.208-1.572.16-2.975z"
        />
        <g fillRule="nonzero">
          <path fill="url(#icon-van-off_svg__c)" d="M23.1 1.304z" transform="translate(162 41)" />
          <path
            fill="url(#icon-van-off_svg__d)"
            d="M25.417 14.426a1.148 1.148 0 0 0-.229-.652l-.772-1.03v-1.532s.465.016.465-.377-.362-.377-.362-.377v-.912s.496-.015.496-.408c0-.393-.465-.393-.465-.393v-4.58s-.236-.763-1.82-1.58c0 0-9.675.118-13.362-1.351a7.249 7.249 0 0 1-1.576-.88L.386 7.912c3.68 2.813 6.051 7.237 6.051 12.218 0 .597-.016 1.17-.04 1.729h.127s8.721-.307 17.688-3.3a1.56 1.56 0 0 0 1.055-1.226c.11-.613.197-1.54.15-2.907z"
            transform="translate(162 41)"
          />
          <path fill="url(#icon-van-off_svg__e)" d="M23.093.896z" transform="translate(162 41)" />
        </g>
        <path
          fill="url(#icon-van-off_svg__f)"
          fillRule="nonzero"
          d="M147.665 26.358l-21.1-17.782a12.184 12.184 0 0 0-7.21-2.844l-14.14-.729a2.101 2.101 0 0 0-2.192 2.403l2.641 18.03a2.213 2.213 0 0 0 2.16 1.89l39.231.674c.867.024 1.285-1.074.61-1.642zm-39.182.176a2.056 2.056 0 0 1-2-1.754l-2.569-17.566a1.233 1.233 0 0 1 1.285-1.41l13.97.721a11.256 11.256 0 0 1 6.68 2.635l11.113 9.364a.477.477 0 0 1 .17.344l.392 7.65a.476.476 0 0 1-.49.505l-28.551-.49z"
        />
        <path
          fill="url(#icon-van-off_svg__g)"
          fillRule="nonzero"
          d="M150.747 22.445l-20.763-16.75c-.032-.03-.064-.054-.096-.086-.184-.203-.463-.796-.048-1.375L129.377 4c-.192.117-.288.328-.344.555-.128.586.128 1.195.6 1.57L150.546 23h5.429l.024-.18-.464-.375h-4.789z"
        />
        <path
          stroke="url(#icon-van-off_svg__h)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M100.5 62V3"
          opacity={0.5}
        />
        <path
          stroke="url(#icon-van-off_svg__i)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M57.5 62V3"
          opacity={0.5}
        />
        <path
          fill="url(#icon-van-off_svg__j)"
          fillRule="nonzero"
          d="M143.034 35h3.778c.308 0 .598-.14.82-.39L149 33.05l-6-.05.034 2z"
        />
        <circle cx={108} cy={30} r={2} fill="url(#icon-van-off_svg__k)" fillRule="nonzero" />
        <path
          fill="url(#icon-van-off_svg__l)"
          fillRule="nonzero"
          d="M111.423 31h-6.846c-.314 0-.577-.21-.577-.476v-.048c0-.26.255-.476.577-.476h6.846c.314 0 .577.21.577.476v.048c0 .26-.263.476-.577.476z"
        />
        <circle cx={92} cy={30} r={2} fill="url(#icon-van-off_svg__m)" fillRule="nonzero" />
        <path
          fill="url(#icon-van-off_svg__n)"
          fillRule="nonzero"
          d="M95.423 31h-6.846c-.314 0-.577-.21-.577-.476v-.048c0-.26.255-.476.577-.476h6.846c.314 0 .577.21.577.476v.048c0 .26-.255.476-.577.476z"
        />
        <path
          stroke="url(#icon-van-off_svg__o)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M7 54s.387-19.901.387-22.305C7.387 24.41 12 1 12 1"
          opacity={0.5}
        />
        <path
          fill="url(#icon-van-off_svg__p)"
          fillRule="nonzero"
          d="M186.666 59h-3.768c-.5 0-.898-.451-.898-1 0-.558.405-1 .898-1H187s-.064 1.124-.135 1.407c-.072.283-.127.47-.199.593z"
        />
        <path
          fill="url(#icon-van-off_svg__q)"
          fillRule="nonzero"
          d="M3 45h4.824L8 33.024C5.81 33.008 4.562 33 4.462 33c-1.133 0-1.179 1.09-1.179 1.09L3 45z"
        />
        <path fill="url(#icon-van-off_svg__r)" fillRule="nonzero" d="M3 42h2v-2H3.039z" />
        <path fill="url(#icon-van-off_svg__s)" fillRule="nonzero" d="M3 37h2v-2H3.068z" />
        <circle cx={153} cy={61} r={14} fill="url(#icon-van-off_svg__t)" fillRule="nonzero" />
        <circle cx={153} cy={61} r={9} fill="url(#icon-van-off_svg__u)" fillRule="nonzero" />
        <circle cx={37} cy={61} r={14} fill="url(#icon-van-off_svg__v)" fillRule="nonzero" />
        <circle cx={37} cy={61} r={9} fill="url(#icon-van-off_svg__w)" fillRule="nonzero" />
        <path
          fill="url(#icon-van-off_svg__x)"
          fillRule="nonzero"
          d="M107.633 26.516c-1.015-.016-1.875-.753-2.022-1.736l-2.598-17.383c-.113-.761.512-1.435 1.3-1.395l14.126.713a11.52 11.52 0 0 1 6.755 2.608l11.237 9.266a.467.467 0 0 1 .17.341L137 26.5a.476.476 0 0 1-.495.5l-28.87-.484z"
        />
        <circle cx={139.5} cy={24.5} r={2.5} fill="url(#icon-van-off_svg__y)" fillRule="nonzero" />
        <circle cx={139.5} cy={24.5} r={1.5} fill="url(#icon-van-off_svg__z)" fillRule="nonzero" />
        <path
          fill="url(#icon-van-off_svg__A)"
          fillRule="nonzero"
          d="M134.288 26h4.614c.64 0 1.145-.491 1.095-1.066l-.38-4.249a1.54 1.54 0 0 0-.563-1.05c-.716-.583-2.232-1.475-4.985-1.633-.58-.03-1.069.385-1.069.907v5.934c.008.643.58 1.157 1.288 1.157z"
        />
        <path
          fill="url(#icon-van-off_svg__B)"
          fillRule="nonzero"
          d="M156 23c-2.671-2.242-16.205-13.506-23.746-17.867A72.022 72.022 0 0 0 130.217 4c-.417.603-.137 1.223.048 1.435a.967.967 0 0 0 .096.09L151.195 23H156z"
        />
        <path
          fill="url(#icon-van-off_svg__C)"
          fillRule="nonzero"
          d="M184.631 43.998s.369-.14.369-1.161-.024-4.612-2.235-6.63c-4.202-1.481-13.055-2.033-16.74-2.206-.76-.033-1.263.807-.91 1.507 1.184 2.322 3.34 5.855 6.218 7.057 3.67 1.556 13.298 1.433 13.298 1.433z"
        />
        <path
          fill="url(#icon-van-off_svg__D)"
          fillRule="nonzero"
          d="M176.767 49a1.64 1.64 0 0 0-1.518.982l-1.176 2.757c-.256.596.193 1.261.859 1.261h11.48v-1.465s.456.015.456-.363c0-.377-.356-.362-.356-.362v-.876s.488-.016.488-.393c0-.378-.457-.378-.457-.378V49h-9.776z"
        />
        <path
          fill="url(#icon-van-off_svg__E)"
          fillRule="nonzero"
          d="M186.642 51.02h-10.269c-.21 0-.373.21-.373.48s.163.48.373.48h10.168s.459.05.459-.48c0-.49-.358-.48-.358-.48z"
        />
        <path
          fill="url(#icon-van-off_svg__F)"
          fillRule="nonzero"
          d="M186.516 50h-10.109c-.223 0-.407.226-.407.5s.184.5.407.5h10.109s.484-.01.484-.51c0-.49-.484-.49-.484-.49z"
        />
        <path
          fill="url(#icon-van-off_svg__G)"
          fillRule="nonzero"
          d="M0 56.11v3.75c0 1.136.932 2.073 2.128 2.134L20.96 63h.25a38.69 38.69 0 0 1-.057-1.981c0-2.538.666-4.93 1.847-7.019l-21.057.267C.875 54.267 0 55.09 0 56.11z"
        />
        <path
          fill="url(#icon-van-off_svg__H)"
          fillRule="nonzero"
          d="M1 58h3.12c.483 0 .88.442.88 1 0 .549-.39 1-.88 1H1v-2z"
        />
      </g>
      <path d="M0-19h188v94H0z" />
    </g>
  </svg>
);

export default SvgIconVanOff;
