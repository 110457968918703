import React from 'react';

const SvgIconUtilityOff = props => (
  <svg width={182} height={87} viewBox="0 0 182 87" {...props}>
    <defs>
      <linearGradient
        id="icon-utility-off_svg__a"
        x1="49.997%"
        x2="49.997%"
        y1="-68.516%"
        y2="87.884%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-utility-off_svg__b"
        x1="25.222%"
        x2="77.289%"
        y1="36.916%"
        y2="75.24%"
      >
        <stop offset="0%" stopColor="#DCE6E6" />
        <stop offset="19.25%" stopColor="#CED8D8" />
        <stop offset="55.37%" stopColor="#AAB4B4" />
        <stop offset="100%" stopColor="#768080" />
      </linearGradient>
      <linearGradient
        id="icon-utility-off_svg__c"
        x1="24.569%"
        x2="77.512%"
        y1="-6.78%"
        y2="105.865%"
      >
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient
        id="icon-utility-off_svg__d"
        x1="49.62%"
        x2="49.96%"
        y1="50.008%"
        y2="50.582%"
      >
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient
        id="icon-utility-off_svg__e"
        x1="49.29%"
        x2="49.96%"
        y1="49.474%"
        y2="50.604%"
      >
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient
        id="icon-utility-off_svg__f"
        x1="49.49%"
        x2="50.07%"
        y1="49.124%"
        y2="50.088%"
      >
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient
        id="icon-utility-off_svg__g"
        x1="-17.866%"
        x2="74.592%"
        y1="-107.114%"
        y2="101.946%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-utility-off_svg__h"
        x1="49.79%"
        x2="50.41%"
        y1="49.757%"
        y2="50.808%"
      >
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient
        id="icon-utility-off_svg__i"
        x1="44.291%"
        x2="53.173%"
        y1="-9.627%"
        y2="92.773%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-utility-off_svg__j"
        x1="20.323%"
        x2="80.139%"
        y1="10.395%"
        y2="89.963%"
      >
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient
        id="icon-utility-off_svg__k"
        x1="20.329%"
        x2="80.062%"
        y1="10.813%"
        y2="89.716%"
      >
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient
        id="icon-utility-off_svg__l"
        x1="8.749%"
        x2="99.064%"
        y1="1.02%"
        y2="100.524%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-utility-off_svg__m" x1=".221%" x2="73.812%" y1="6.236%" y2="85.828%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-utility-off_svg__n"
        x1="35.828%"
        x2="64.092%"
        y1="48.116%"
        y2="51.874%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-utility-off_svg__o" x1="48.63%" x2="51.18%" y1="48.17%" y2="52.59%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-utility-off_svg__p" x1="42.68%" x2="57.68%" y1="36.71%" y2="62.7%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-utility-off_svg__q" x1="43.47%" x2="56.41%" y1="39.35%" y2="61.75%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-utility-off_svg__r" x1="47.17%" x2="52.88%" y1="45.25%" y2="55.13%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-utility-off_svg__s" x1="49.6%" x2="50.23%" y1="48.89%" y2="49.98%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-utility-off_svg__t" x1="49.16%" x2="50.65%" y1="48.3%" y2="50.89%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient
        id="icon-utility-off_svg__u"
        x1="-19.152%"
        x2="74.65%"
        y1="-59.43%"
        y2="95.93%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-utility-off_svg__v"
        x1="-8.526%"
        x2="75.152%"
        y1="-40.652%"
        y2="98.336%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-utility-off_svg__w"
        x1="-26.756%"
        x2="83.777%"
        y1="-83.984%"
        y2="99.611%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-utility-off_svg__x"
        x1="-6.395%"
        x2="55.491%"
        y1="8.042%"
        y2="61.413%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-utility-off_svg__y"
        x1="5.216%"
        x2="78.587%"
        y1="-27.575%"
        y2="99.507%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-utility-off_svg__z"
        x1="25.008%"
        x2="74.997%"
        y1="6.701%"
        y2="93.285%"
      >
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient
        id="icon-utility-off_svg__A"
        x1="12.571%"
        x2="82.906%"
        y1="-14.852%"
        y2="106.972%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-utility-off_svg__B"
        x1="25.021%"
        x2="75.011%"
        y1="6.701%"
        y2="93.285%"
      >
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient
        id="icon-utility-off_svg__C"
        x1="18.714%"
        x2="71.346%"
        y1="21.347%"
        y2="83.357%"
      >
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient
        id="icon-utility-off_svg__D"
        x1="-75.898%"
        x2="63.768%"
        y1="-245.608%"
        y2="76.175%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-utility-off_svg__E"
        x1="10.663%"
        x2="81.87%"
        y1="-59.307%"
        y2="134.542%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-utility-off_svg__F"
        x1="26.42%"
        x2="69.017%"
        y1="3.046%"
        y2="96.796%"
      >
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient
        id="icon-utility-off_svg__G"
        x1="30.223%"
        x2="52.155%"
        y1="46.258%"
        y2="50.218%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-utility-off_svg__H"
        x1="29.787%"
        x2="59.721%"
        y1="44.194%"
        y2="52.422%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-utility-off_svg__I"
        x1="49.851%"
        x2="49.851%"
        y1="-.966%"
        y2="100.975%"
      >
        <stop offset="0%" stopOpacity={0} />
        <stop offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M0-9h182v94H0z" />
      <g opacity={0.6}>
        <path
          fill="url(#icon-utility-off_svg__a)"
          fillRule="nonzero"
          d="M13.003 67.442s-.047 1.443.241 1.827c.288.385 1.526-.58 6.642 0 5.115.58 22.074 2.095 23.366 2.518 1.293.424 4.54-1.357 4.54-1.357s70.209-2.76 85.4 3.075l1.752 1.656s1.323.533 3.208.376c1.884-.157 26.2-.157 26.2-.157s1.674-.008 3.59.62c0 0 10.13-10.825-1.62-19.996-11.75-9.162-51.817-22.914-100.061-21.957C18 34.997 14.085 44.3 13.003 67.442z"
        />
        <path
          fill="url(#icon-utility-off_svg__b)"
          fillRule="nonzero"
          d="M178.683 55.871s.07-6.527-5.67-10.19c-5.74-3.662-17.516-6.172-22.905-7.395-5.388-1.223-14.887-1.792-17.445-3.662-2.558-1.87-21.415-19.59-25.814-23.11-4.398-3.52-9.218-5.817-15.886-7.174C84.296 2.974 64.16 1.254 46.573.464 28.989-.325 13.244-.254 11.122 1.83 8.99 3.914 5.638 8.46 4.07 23.337 2.503 38.215 2.55 49.541 2.55 49.541s-.586.055-.85.592c-.266.537-1.015 2.62-1.015 3.852 0 1.23.328 6.535.328 6.535S0 60.914 0 62.146c0 1.23.523 3.812 3.314 4.514 2.785.695 10.295 1.658 10.295 1.658s-.242-4.736 1.957-8.122c2.153-3.299 5.911-5.58 9.71-6.211 3.805-.632 8.243-.45 12.15 1.894 3.805 2.28 7.385 6.496 7.12 15.288 0 0 55.612 1.997 90.417 4.136 0 0 .281-7.435 3.135-12.092 2.855-4.657 7.862-8.01 14.085-7.719 6.231.292 11.877 4.949 13.733 9.187 1.864 4.239 2.215 7.6 2.098 11.31 0 0 8.033.174 9.897-.884 1.863-1.057 1.801-1.886 2.214-3.772.406-1.887 1.747-8.84 0-10.545 0 0 .094-3.717-.218-4.057-.32-.34-.702-.56-1.224-.86z"
        />
        <path
          fill="url(#icon-utility-off_svg__c)"
          fillRule="nonzero"
          d="M174.348 45.582c-1.428-.936-3.237-1.8-5.217-2.582-.893 3.3 2.99 7.174 5.124 9.264C176.39 54.354 180 56 180 56s.07-6.682-5.652-10.418z"
        />
        <g fillRule="nonzero">
          <path
            fill="url(#icon-utility-off_svg__d)"
            d="M16.213 4.758s0 .008 0 0c0 .008 0 0 0 0z"
            transform="translate(163 51)"
          />
          <path
            fill="url(#icon-utility-off_svg__e)"
            d="M16.213 4.68z"
            transform="translate(163 51)"
          />
          <path fill="url(#icon-utility-off_svg__f)" d="M15.295.5z" transform="translate(163 51)" />
          <path
            fill="url(#icon-utility-off_svg__g)"
            d="M17.67 9.656s.095-3.68-.222-4.015c-.316-.336-.704-.555-1.235-.852 0 0-3.68-1.601-5.858-3.617a68.26 68.26 0 0 1-.87-.82l.403 6.07H5.81L.143 9.289c1.393 1.328 2.477 2.797 3.119 4.219 1.892 4.195 2.248 7.523 2.13 11.195 0 0 8.153.172 10.046-.875 1.892-1.047 1.828-1.867 2.248-3.734.396-1.867 1.757-8.742-.016-10.438z"
            transform="translate(163 51)"
          />
          <path
            fill="url(#icon-utility-off_svg__h)"
            d="M16.205 4.563v-.001z"
            transform="translate(163 51)"
          />
        </g>
        <path
          fill="url(#icon-utility-off_svg__i)"
          fillRule="nonzero"
          d="M7.196 49.957C7.628 31.76 8 23.839 8 22.616c0-.931-1.89-3.448-2.8-4.616a104.32 104.32 0 0 0-.72 5.563C2.955 38.436 3 49.759 3 49.759c2.375.379 4.196.198 4.196.198z"
        />
        <path
          fill="url(#icon-utility-off_svg__j)"
          fillRule="nonzero"
          d="M3 43.739L7.895 44c.035-1.305.07-2.552.105-3.739L3.14 40c-.052 1.381-.096 2.627-.14 3.739z"
        />
        <path
          fill="url(#icon-utility-off_svg__k)"
          fillRule="nonzero"
          d="M3 40.739L7.885 41c.035-1.314.08-2.56.115-3.739L3.194 37A255.62 255.62 0 0 0 3 40.739z"
        />
        <path
          fill="url(#icon-utility-off_svg__l)"
          fillRule="nonzero"
          d="M3 47H1.31c-.171 0-.31.088-.31.197v1.606c0 .109.139.197.31.197H3v-2z"
        />
        <circle cx={168} cy={62} r={1} fill="#626C6C" fillRule="nonzero" />
        <path
          fill="url(#icon-utility-off_svg__m)"
          fillRule="nonzero"
          d="M128.886 36.409c-.195-.767-.772-1.643-1.403-2.464-2.993-3.943-14.678-14.918-19.121-17.515-4.451-2.598-6.642-2.8-13.08-3.732-6.439-.93-9.69-.93-10.485 0-.795.931-.756 2.19-.795 3.16-.039.97.406 11.915 1.622 15.959 1.216 4.044 1.808 5.054 4.536 5.71 2.729.658 21.928 1.229 27.322 1.69 3.367.282 6.688.798 9.105.783 1.831-.016 2.751-1.807 2.3-3.591zm-6.524.853c-.133.43-.265.829-.39 1.197-1.777-.157-3.796-.415-5.83-.58-4.99-.414-22.754-.938-25.28-1.525-2.526-.594-3.071-1.51-4.194-5.178-1.122-3.67-1.543-13.166-1.574-14.05-.04-1.056-.219-2.582.522-3.427.74-.844 4.03-.72 9.985.133 5.956.845 7.982 1.025 12.098 3.38 2.705 1.549 8.957 7.01 13.36 11.273.913 2.096 2.191 5.859 1.303 8.777z"
        />
        <path
          fill="url(#icon-utility-off_svg__n)"
          fillRule="nonzero"
          d="M135.734 75s.047-1.132.266-2.778c0 0-72.569-3.512-91-4.222.07.913.094 1.88.063 2.91.007 0 55.763 1.975 90.671 4.09z"
        />
        <g fillRule="nonzero">
          <path
            fill="url(#icon-utility-off_svg__o)"
            d="M2.932 1.567z"
            transform="translate(0 47)"
          />
          <path fill="url(#icon-utility-off_svg__p)" d="M2.932.888z" transform="translate(0 47)" />
          <path
            fill="url(#icon-utility-off_svg__q)"
            d="M2.932 1.559v-.242.242z"
            transform="translate(0 47)"
          />
          <path
            fill="url(#icon-utility-off_svg__r)"
            d="M2.932 1.284V1.18v.105z"
            transform="translate(0 47)"
          />
          <path
            fill="url(#icon-utility-off_svg__s)"
            d="M2.932 1.696v-.008.008z"
            transform="translate(0 47)"
          />
          <path
            fill="url(#icon-utility-off_svg__t)"
            d="M2.932 1.664V1.64v.024z"
            transform="translate(0 47)"
          />
          <path
            fill="url(#icon-utility-off_svg__u)"
            d="M20.402 8.78l-5.177-5.953-7.827-.913S5.46 2.1 2.932 1.712c0 0-.606.057-.88.606C1.777 2.86 1.002 5 1.002 6.26c0 1.26.339 6.687.339 6.687s-1.05.404-1.05 1.664c0 1.26.54 3.901 3.432 4.62 2.884.71 10.694 1.72 10.694 1.72s0-4.805 2.278-8.262a14.888 14.888 0 0 1 3.715-3.877c0-.024-.008-.032-.008-.032z"
            transform="translate(0 47)"
          />
        </g>
        <path
          fill="url(#icon-utility-off_svg__v)"
          fillRule="nonzero"
          d="M91.485 48h-3.631C86.83 48 86 47.104 86 46v-4c0-1.104.83-2 1.854-2h3.623C93.423 40 95 41.701 95 43.8v.416C94.992 46.299 93.423 48 91.485 48z"
        />
        <path
          fill="url(#icon-utility-off_svg__w)"
          fillRule="nonzero"
          d="M73.515 48h3.631C78.17 48 79 47.104 79 46v-4c0-1.104-.83-2-1.854-2h-3.623C71.577 40 70 41.701 70 43.8v.416C70 46.307 71.57 48 73.515 48z"
        />
        <path
          fill="url(#icon-utility-off_svg__x)"
          fillRule="nonzero"
          d="M95 43.778C94.823 41.656 93.237 40 91.297 40h-3.334C86.878 40 86 40.983 86 42.2v1.509c1.124.146 4.68.535 9 .069z"
        />
        <circle cx={152} cy={73} r={14} fill="url(#icon-utility-off_svg__y)" fillRule="nonzero" />
        <circle cx={152} cy={73} r={9} fill="url(#icon-utility-off_svg__z)" fillRule="nonzero" />
        <circle cx={30} cy={73} r={14} fill="url(#icon-utility-off_svg__A)" fillRule="nonzero" />
        <circle cx={30} cy={73} r={9} fill="url(#icon-utility-off_svg__B)" fillRule="nonzero" />
        <ellipse
          cx={179.611}
          cy={69.746}
          fill="#626C6C"
          fillRule="nonzero"
          rx={2.374}
          ry={1.117}
          transform="rotate(-78.428 179.61 69.746)"
        />
        <path
          fill="url(#icon-utility-off_svg__C)"
          fillRule="nonzero"
          d="M121.705 36.811c.875-2.88-.39-6.594-1.273-8.655-4.333-4.208-10.484-9.597-13.145-11.126-4.05-2.324-6.043-2.501-11.902-3.335-5.86-.834-9.104-.965-9.825-.131-.728.833-.552 2.339-.514 3.381.031.873.445 10.246 1.55 13.867 1.104 3.621 1.649 4.524 4.126 5.111 2.485.587 19.963 1.104 24.871 1.506 2.002.17 3.988.417 5.737.571.122-.37.245-.757.375-1.189z"
        />
        <path
          fill="url(#icon-utility-off_svg__D)"
          fillRule="nonzero"
          d="M123.37 36.123c.156.183.276.731.32 1.025.045.295.389 1.169 1.57.732 1.18-.437 1.755-1.375 1.74-2.584-.023-1.208-.389-1.852-1.09-2.035-.703-.183-1.33-.087-1.801-.254-.478-.143-1.726 1.955-.74 3.116z"
        />
        <path
          fill="url(#icon-utility-off_svg__E)"
          fillRule="nonzero"
          d="M124.992 31.606c.037-.446-.014-1.585-1.147-2.099-1.134-.513-3.756-.822-4.221 0-.465.823-1.032 5.413-.196 6.004.828.59 2.76 1.72 3.792 1.447 1.031-.274 1.525-.668 1.627-1.447.102-.78.145-3.905.145-3.905z"
        />
        <path
          fill="url(#icon-utility-off_svg__F)"
          fillRule="nonzero"
          d="M1.008 53.052c0 .056-.008.104-.008.153 0 .47.065 1.635.139 2.784.99.174 2.101-1.828 1.815-2.52-.278-.687-1.938-.417-1.946-.417z"
        />
        <circle cx={16} cy={56} r={1} fill="#626C6C" fillRule="nonzero" />
        <path
          fill="url(#icon-utility-off_svg__G)"
          fillRule="nonzero"
          d="M47.9 55.001s81.657 3.733 82.317 3.733c.659 0 1.783.534 1.783 1.145 0 .61.039 1.427-.915 2.694-.954 1.267-1.869 1.427-3.69 1.427-1.823 0-74.154-3.145-75.123-3.183-.969-.038-2.341-.488-3.055-1.312C48.504 58.68 47 57.07 47 56.162c.008-.94.264-1.183.9-1.16z"
        />
        <path
          fill="url(#icon-utility-off_svg__H)"
          fillRule="nonzero"
          d="M43 37.905l-32.487-1.902c-.8-.051-1.481.625-1.512 1.496-.024.837.58 1.548 1.355 1.59L42.992 41v-3.095H43z"
        />
        <path
          stroke="url(#icon-utility-off_svg__I)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M80 8s3.757 38.117.972 61"
          opacity={0.5}
        />
      </g>
    </g>
  </svg>
);

export default SvgIconUtilityOff;
