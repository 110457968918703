import React from 'react';

const SvgIconDelete = props => (
  <svg width={24} height={25} viewBox="0 0 24 25" {...props}>
    <defs>
      <path
        id="icon-delete_svg__a"
        d="M13.495 3.623h4.765c.409 0 .74.324.74.723 0 .4-.331.723-.74.723h-.819v16.199a.74.74 0 0 1-.7.732H2.229a.741.741 0 0 1-.74-.723V5.07H.74A.731.731 0 0 1 0 4.346c0-.4.331-.723.74-.723h4.696v-2.9c0-.4.33-.723.74-.723h6.58c.408 0 .74.324.74.723v2.9zm-1.4 0V1.445h-5.1l-.04 2.178h5.14zm.7 1.446H3.009v15.486H16.04V5.069h-3.245zm-5.83 12.489a.731.731 0 0 1-.74-.723v-8.24c0-.398.331-.722.74-.722.408 0 .74.324.74.723v8.239a.741.741 0 0 1-.74.723zm5.13 0a.741.741 0 0 1-.74-.723v-8.24a.73.73 0 0 1 .74-.722c.408 0 .74.324.74.723v8.239a.732.732 0 0 1-.74.723z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(3 1)">
      <mask id="icon-delete_svg__b" fill="#fff">
        <use xlinkHref="#icon-delete_svg__a" />
      </mask>
      <use fill="currentColor" xlinkHref="#icon-delete_svg__a" />
      <g fill="currentColor" mask="url(#icon-delete_svg__b)">
        <path d="M-3-1h24v24H-3z" />
      </g>
    </g>
  </svg>
);

export default SvgIconDelete;
