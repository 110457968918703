import React from 'react';

const SvgIconPlay = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.254 10.577L7.702 3.305a1.721 1.721 0 0 0-1.78-.107c-.566.3-.922.888-.922 1.53v14.545c0 .64.356 1.229.922 1.53a1.732 1.732 0 0 0 1.78-.107l10.552-7.273a1.728 1.728 0 0 0 0-2.846"
    />
  </svg>
);

export default SvgIconPlay;
