import React from 'react';

const SvgIconLocation = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id={`icon-location_svg__a${props.customId}`}
        d="M8.504 22a.781.781 0 0 1-.485-.182C-.437 14.465-.01 8.647.01 8.398.009 3.76 3.812 0 8.504 0S17 3.76 17 8.397c0 .556.184 6.24-8.02 13.373a.73.73 0 0 1-.476.23zm0-20.495c-3.886.005-7.035 3.118-7.04 6.96 0 .115-.31 5.233 7.04 11.848 7.351-6.615 7.05-11.733 7.05-11.791v-.058c-.015-3.837-3.167-6.94-7.05-6.94v-.019zm0 10.602c-2.035 0-3.685-1.63-3.685-3.643 0-2.011 1.65-3.642 3.685-3.642 2.036 0 3.685 1.63 3.685 3.642.006.97-.38 1.901-1.072 2.589a3.707 3.707 0 0 1-2.613 1.073v-.019zm0-5.847c-1.232 0-2.23.987-2.23 2.204 0 1.218.998 2.205 2.23 2.205s2.23-.987 2.23-2.205c-.01-1.21-1.005-2.185-2.23-2.185v-.02z"
      />
    </defs>
    <use
      fill="currentColor"
      transform="translate(4 1)"
      xlinkHref={`#icon-location_svg__a${props.customId}`}
    />
  </svg>
);

export default SvgIconLocation;
