import React from 'react';

const SvgIconLogout = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-logout_svg__a"
        d="M8.333 17.5v.833c0 .92-.747 1.667-1.666 1.667a1.7 1.7 0 0 1-.517-.078l-5.015-1.67A1.682 1.682 0 0 1 0 16.667v-15C0 .747.748 0 1.667 0c.178 0 .347.025.516.078l3.52 1.172H1.666a.625.625 0 0 1 0-1.25h9.374a2.293 2.293 0 0 1 2.292 2.292v3.75a.625.625 0 0 1-1.25 0v-3.75c0-.574-.467-1.042-1.042-1.042H5.702l1.496.498a1.682 1.682 0 0 1 1.135 1.585V16.25h2.708c.575 0 1.042-.468 1.042-1.042v-3.75a.625.625 0 0 1 1.25 0v3.75A2.293 2.293 0 0 1 11.04 17.5H8.333zm9.532-8.125h-6.407a.625.625 0 0 1 0-1.25h6.409l-2.06-2.06a.625.625 0 1 1 .885-.883l3.125 3.125a.625.625 0 0 1-.015.898l-3.11 3.11a.62.62 0 0 1-.884.002.625.625 0 0 1 0-.885l2.057-2.057zM1.667 1.25a.418.418 0 0 0-.417.417v15c0 .177.12.342.29.401l4.99 1.664a.453.453 0 0 0 .137.018.418.418 0 0 0 .416-.417v-15a.433.433 0 0 0-.29-.401l-4.99-1.664a.452.452 0 0 0-.136-.018z"
      />
    </defs>
    <use fill="currentColor" transform="translate(2 2)" xlinkHref="#icon-logout_svg__a" />
  </svg>
);

export default SvgIconLogout;
