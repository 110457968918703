import React from 'react';

const SvgIconAccount = props => (
  <svg width={114} height={111} viewBox="0 0 114 111" {...props}>
    <defs>
      <linearGradient id="icon-account_svg__a" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-account_svg__b" x1="26.626%" x2="78.316%" y1="29.717%" y2="68.03%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-account_svg__c" x1="19.324%" x2="64.585%" y1="28.733%" y2="73.422%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <filter
        id="icon-account_svg__d"
        width="120.9%"
        height="135.8%"
        x="-10.5%"
        y="-17.9%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation={2.783} />
      </filter>
      <path id="icon-account_svg__e" d="M0 .043h79.744v46.604H0z" />
      <path id="icon-account_svg__g" d="M.055.211h11.057v5.09H.055z" />
      <linearGradient id="icon-account_svg__h" x1="19.324%" x2="64.585%" y1="41.361%" y2="59.514%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-account_svg__j" x1="22.443%" x2="63.102%" y1="9.241%" y2="94.889%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-account_svg__k" x1="46.192%" x2="51.811%" y1="9.241%" y2="94.889%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-account_svg__l" x1="19.324%" x2="64.585%" y1="19.562%" y2="83.523%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <path id="icon-account_svg__m" d="M.091.215h11.512v11.066H.091z" />
      <linearGradient id="icon-account_svg__n" x1="19.324%" x2="64.585%" y1="12.342%" y2="91.474%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-account_svg__p" x1="29.941%" x2="74.299%" y1="10.48%" y2="85.131%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-account_svg__q" x1="26.626%" x2="78.316%" y1="25.532%" y2="71.75%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#B1BAC9"
        d="M5.591 33.562l14.676-8.467c.389-.225.744-.24 1.018-.085v-.002l.71.364h-.001c.357.178.586.632.586 1.29v12.243c0 1.154-.702 2.495-1.568 2.995L6.336 50.367c-.38.22-.73.24-1.001.096l-.728-.374c-.355-.178-.583-.632-.583-1.29V36.557c0-1.155.701-2.496 1.567-2.995"
        opacity={0.1}
      />
      <path
        fill="#B1BAC9"
        d="M98.947 10.181L84.272 1.714c-.39-.225-.745-.24-1.019-.085v-.001l-.71.363.002.001c-.357.177-.586.632-.586 1.29v12.242c0 1.155.702 2.496 1.567 2.995l14.676 8.468c.38.22.73.24 1.002.095l.727-.374c.356-.178.584-.632.584-1.29V13.177c0-1.154-.702-2.495-1.568-2.995"
        opacity={0.2}
      />
      <path
        fill="url(#icon-account_svg__a)"
        d="M11.611 12.63L32.734.445C33.294.12 33.805.099 34.2.32l1.02.523v.002c.513.255.843.909.843 1.856v17.621c0 1.661-1.01 3.591-2.256 4.31L12.684 36.82c-.548.316-1.051.345-1.442.137l-1.047-.538v-.001c-.512-.256-.84-.91-.84-1.855V16.94c0-1.661 1.01-3.591 2.256-4.31"
        transform="translate(4.024 1.341)"
      />
      <path
        fill="url(#icon-account_svg__b)"
        d="M15.393 2.693l-1.645-1.072-1.061-.649-5.03 6.41-3.309.217L.01 12.106v1.593l1.06.648 4.312-4.48 3.428-.225 4.69-5.975 1.385.903 1.06.649 3.95-2.974V.75l-1.06-.648z"
        transform="translate(24.806 10.434)"
      />
      <path
        fill="url(#icon-account_svg__c)"
        d="M16.422 3.365L13.748 1.62l-5.03 6.41-3.309.216-4.339 4.507v1.593l4.312-4.48 3.428-.225 4.711-6.003 2.424 1.58 3.95-2.974V.75z"
        transform="translate(24.806 10.434)"
      />
      <path
        fill="#61E8FF"
        fillOpacity={0.1}
        d="M7.037 14.246L.215 34.926a4.255 4.255 0 0 0 1.82 4.963L22.85 51.857a10.128 10.128 0 0 0 6.457 1.42l39.489-4.603a4.254 4.254 0 0 0 3.522-2.818l7.185-20.503a4.253 4.253 0 0 0-1.888-5.09L56.546 8.097a10.13 10.13 0 0 0-6.216-1.292l-39.737 4.549a4.255 4.255 0 0 0-3.556 2.892"
        filter="url(#icon-account_svg__d)"
        transform="translate(30.373 56.267)"
      />
      <path
        fill="#B1BAC9"
        d="M109.876 75.613l-7.185 20.504a4.254 4.254 0 0 1-3.521 2.818l-39.49 4.601a10.127 10.127 0 0 1-6.456-1.419L32.407 90.15a4.251 4.251 0 0 1-2.004-3.133l.341-3.206c.113 1.332.466-1.952 1.663-1.22l20.817 11.968a10.127 10.127 0 0 0 6.456 1.42l39.49-4.603a4.254 4.254 0 0 0 3.521-2.818l6.258-16.611c.066-.192.456.377 1.167 1.705-.054 1.008-.134 1.662-.24 1.962z"
      />
      <g transform="translate(30.373 56.315)">
        <mask id="icon-account_svg__f" fill="#fff">
          <use xlinkHref="#icon-account_svg__e" />
        </mask>
        <path
          fill="#FFF"
          d="M7.037 7.549L.215 28.229a4.255 4.255 0 0 0 1.82 4.962L22.85 45.16a10.128 10.128 0 0 0 6.457 1.419l39.489-4.602a4.254 4.254 0 0 0 3.522-2.818l7.185-20.503a4.253 4.253 0 0 0-1.888-5.09L56.546 1.399A10.13 10.13 0 0 0 50.33.11L10.593 4.655A4.255 4.255 0 0 0 7.037 7.55"
          mask="url(#icon-account_svg__f)"
        />
      </g>
      <g>
        <path
          fill="#DFE3E8"
          d="M50.43 36.53v-.24l.1-.025a.823.823 0 0 1 .26-.227l9.89-5.71c.539-.311 1.49-.272 2.113.088l2.737 1.58c.197.114.338.246.422.385l.093-.024v.357h-.001c-.011.19-.13.368-.363.502l-9.889 5.71c-.54.31-1.49.271-2.113-.088l-2.737-1.58c-.359-.207-.533-.477-.513-.729"
        />
        <path
          fill="#EFF0F2"
          d="M55.792 38.599l9.89-5.71c.539-.311.47-.86-.152-1.22l-2.737-1.58c-.623-.36-1.574-.4-2.113-.088l-9.89 5.71c-.54.311-.47.86.152 1.22l2.737 1.58c.623.36 1.574.399 2.113.088"
        />
        <path
          fill="#DFE3E8"
          d="M59.963 35.972l4.414-2.548c.157-.09.137-.25-.045-.355-.181-.105-.459-.117-.616-.026l-4.413 2.548c-.158.09-.138.251.044.356.182.105.459.116.616.025"
        />
        <path
          fill="#FFF"
          d="M59.963 35.863l4.414-2.548c.157-.09.137-.25-.045-.355-.181-.105-.459-.117-.616-.026l-4.413 2.548c-.158.09-.138.251.044.356.182.105.459.116.616.025"
        />
        <path
          fill="#DFE3E8"
          d="M54.603 34.954c.168.097.426.108.572.023.146-.084.128-.233-.041-.33s-.426-.108-.572-.024c-.147.085-.128.233.04.33"
        />
        <path
          fill="#FFF"
          d="M54.603 34.845c.168.097.426.108.572.023.146-.084.128-.233-.041-.33s-.426-.108-.572-.024c-.147.085-.128.233.04.33"
        />
        <path
          fill="#DFE3E8"
          d="M53.704 35.473c.168.097.426.108.572.023.146-.084.127-.233-.041-.33-.169-.097-.426-.108-.573-.024-.146.085-.127.233.042.33"
        />
        <path
          fill="#FFF"
          d="M53.704 35.364c.168.097.426.108.572.024.146-.085.127-.233-.041-.33-.169-.098-.426-.11-.573-.025-.146.085-.127.233.042.33"
        />
        <path
          fill="#DFE3E8"
          d="M52.805 35.992c.168.097.426.108.572.024.146-.085.127-.233-.041-.33-.169-.098-.426-.11-.573-.025-.146.085-.127.233.042.33"
        />
        <path
          fill="#FFF"
          d="M52.805 35.883c.168.097.426.108.572.024.146-.085.127-.233-.041-.33-.169-.098-.426-.109-.573-.025-.146.085-.127.233.042.33"
        />
        <g transform="translate(60.909 81.182)">
          <mask id="icon-account_svg__i" fill="#fff">
            <use xlinkHref="#icon-account_svg__g" />
          </mask>
          <path
            fill="url(#icon-account_svg__h)"
            d="M6.168 1.073c-.37.308-2.114 1.785-4.781 2.216-2.667.43-1.682 2.831 6.196 1.723 0 0 1.293-.615 2.832-.308 0 0 1.498-1.354.102-3.139L7.4.211l-1.231.862z"
            mask="url(#icon-account_svg__i)"
          />
        </g>
        <path
          fill="url(#icon-account_svg__j)"
          d="M21.087 63.243s-4.159-1.286-1.644 2.631c.73 1.138 3.505 3.332 4.572 4.358.88.845 2.248 0 1.534-2.709l-4.462-4.28z"
          transform="translate(53.197 12.475)"
        />
        <path
          fill="url(#icon-account_svg__k)"
          d="M12.702 36.45s-.792 19.254-.546 21.06c.246 1.807 1.723 12.27 1.723 12.27s1.437 2.954 4.35.493c0 0-1.504-11.655-1.21-14.157.293-2.503 2.26-16.496 2.26-16.496s-1.05.37 1.453.493c0 0-.82 8.617-.82 10.956 0 2.339 1.477 14.772 1.477 14.772s4.965 3.698 4.431.492c-.123-.738-.661-14.236-.638-17.52.023-3.284 1.8-10.74 2.15-13.906l-7.612-6.859-1.491 4.063-3.365 3.077-2.162 1.262z"
          transform="translate(53.197 12.475)"
        />
        <path
          fill="#FEC2BE"
          d="M55.956 35.025s-.82-1.566-1.97-1.111c-1.148.455-1.08 2.716.445 3.145 0 0 .95-.804 1.525-2.034"
        />
        <path
          fill="url(#icon-account_svg__l)"
          d="M17.432 13.276s-5.663 2.642-6.77 4.337c-1.109 1.695-2.463 8.343-4.555 6.866C4.014 23 2.742 22.55 2.742 22.55s-1.87 1.56-1.468 2.626c.4 1.067 5.16 5.088 6.802 4.186 1.642-.903 3.144-2.79 4.609-5.58 0 0-.157 4.28.448 6.325 0 0-1.282 4.179-1.282 6.067 0 0 5.417 2.133 9.684 0v-3.858l1.806 3.447s3.282-.41 4.431-1.067c0 0-.246-2.954 0-6.072 0 0 1.806-1.843 2.462-3.425v-7.586s-1.066-3.926-4.76-5.567l-4.76.06s-1.025-.307-3.282 1.17"
          transform="translate(53.197 12.475)"
        />
        <g transform="translate(64.523 12.5)">
          <mask id="icon-account_svg__o" fill="#fff">
            <use xlinkHref="#icon-account_svg__m" />
          </mask>
          <path
            fill="url(#icon-account_svg__n)"
            d="M1.557 4.582s-.85-.38-1.466-1.734c0 0 1.292-2.607 4.308-1.796 0 0 3.851-2.64 6.358 1.457 2.506 4.094-1.351 7.403-1.351 7.403L8.4 11.281 3.907 9.912 2.06 8.203l-.503-3.621z"
            mask="url(#icon-account_svg__o)"
          />
        </g>
        <path
          fill="url(#icon-account_svg__p)"
          d="M12.884 4.608s2.514.38 2.185 2.021l-.656 1.395.686.687s.299-1.015.627-1.425c.328-.41 2.544 0 .738 2.051 0 0 2.134 1.54 3.365 1.55l.903-.95v2.17s-.082-.493-3.283 1.17c0 0 1.847-2.094-1.723-1.293 0 0-3.9 1.805-2.842-7.376"
          transform="translate(53.197 12.475)"
        />
        <path
          fill="url(#icon-account_svg__q)"
          d="M16.464 9.337s.37 1.877-.738 2.647c0 0 3.385-.924 1.723 1.292 0 0 2.873-1.539 3.283-1.17V9.939l-.903.95s-1.21.02-3.365-1.55"
          transform="translate(53.197 12.475)"
        />
      </g>
    </g>
  </svg>
);

export default SvgIconAccount;
