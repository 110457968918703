import React from 'react';

const SvgIconBoatOn = props => (
  <svg width={180} height={77} viewBox="0 0 180 77" {...props}>
    <defs>
      <linearGradient id="icon-boat-on_svg__a" x1="40.87%" x2="53.633%" y1="45.74%" y2="51.864%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__b" x1="31.672%" x2="56.8%" y1="43.385%" y2="54.234%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__c" x1="50.005%" x2="50.005%" y1=".006%" y2="100.068%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__d" x1="39.138%" x2="60.672%" y1="46.459%" y2="53.374%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__e" x1="39.282%" x2="63.192%" y1="42.665%" y2="55.817%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__f" x1="37.184%" x2="63.099%" y1="46.012%" y2="52.866%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__g" x1="65.692%" x2="33.673%" y1="41.6%" y2="57.398%">
        <stop offset="0%" stopOpacity={0} />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__h" x1="125.33%" x2="-24.67%" y1="49.969%" y2="49.969%">
        <stop offset="0%" stopOpacity={0} />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__i" x1="37.339%" x2="62.501%" y1="49.881%" y2="50.118%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__j" x1="105.982%" x2="-5.938%" y1="49.972%" y2="49.972%">
        <stop offset="0%" stopOpacity={0} />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__k" x1="105.707%" x2="-5.769%" y1="49.983%" y2="49.983%">
        <stop offset="0%" stopOpacity={0} />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient
        id="icon-boat-on_svg__l"
        x1="127.011%"
        x2="-27.014%"
        y1="50.076%"
        y2="50.076%"
      >
        <stop offset="0%" stopOpacity={0} />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__m" x1="90.955%" x2="8.745%" y1="8.957%" y2="91.071%">
        <stop offset="0%" stopOpacity={0} />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__n" x1="39.695%" x2="64.766%" y1="43.278%" y2="59.437%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__o" x1="37.967%" x2="61.119%" y1="22.681%" y2="76.955%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__p" x1="11.285%" x2="56.846%" y1="-67.548%" y2="70.671%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__q" x1="41.903%" x2="54.268%" y1="8.051%" y2="86.763%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__r" x1="19.983%" x2="82.758%" y1="-9.75%" y2="111.056%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient
        id="icon-boat-on_svg__s"
        x1="-10.005%"
        x2="110.161%"
        y1="49.952%"
        y2="49.952%"
      >
        <stop offset="0%" stopOpacity={0} />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__t" x1="41.163%" x2="56.249%" y1="13.036%" y2="100.433%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__u" x1="38.08%" x2="62.087%" y1="48.888%" y2="51.129%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__v" x1="34.173%" x2="64.878%" y1="33.9%" y2="65.197%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__w" x1="36.212%" x2="63.692%" y1="2.614%" y2="97.411%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__x" x1="36.704%" x2="63.204%" y1="2.365%" y2="98.152%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__y" x1="37.188%" x2="63.017%" y1="1.82%" y2="98.55%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__z" x1="37.383%" x2="62.531%" y1="1.285%" y2="98.991%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__A" x1="37.894%" x2="62.36%" y1=".986%" y2="99.251%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__B" x1="2.9%" x2="97.95%" y1=".613%" y2="99.894%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__C" x1="4.425%" x2="96.517%" y1=".053%" y2="101.328%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__D" x1="5.85%" x2="94.667%" y1="-.291%" y2="101.143%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__E" x1="8.592%" x2="93.092%" y1="-1.5%" y2="103.042%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__F" x1="42.973%" x2="56.274%" y1="27.056%" y2="70.679%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__G" x1="38.141%" x2="67.604%" y1="8.442%" y2="86.74%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-boat-on_svg__H" x1="39.269%" x2="64.837%" y1="12.505%" y2="81.005%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="url(#icon-boat-on_svg__a)"
        fillRule="nonzero"
        d="M160.959 28.74c-1.154-.224-16.461-2.184-21.269-3.235l-.414.612s-1.456-.184-3.208-1.603c0 0-10.785-.428-12.879-1.112-2.093-.674-10.714-6.606-13.882-8.453C106.14 13.1 89.01 9.15 82.475 8.22 75.94 7.292 41.8 8.691 37 11.917c0 0 1.449 2.664 1.266 3.103-.183.45-.63.694-.39 1.235.238.541 1.56.5 3.478.45 1.918-.051 8.58.05 18.149 4.46 0 0-6.75 4.37-9.242 6.545l.454.347s-2.34 2.297-3.272 3.083c0 0 19.231 3.685 47.377 3.798 19.184.071 42.283-3.043 67.174-4.115.008.02.119-1.858-1.035-2.082zm-96.49-17.099c-2.26-.704-7.275-1.46-7.275-1.46s2.117-.755 9.815-.878c7.697-.112 10.236 1.756 10.236 1.756-6.63 0-12.776.582-12.776.582z"
      />
      <path
        fill="url(#icon-boat-on_svg__b)"
        fillRule="nonzero"
        d="M116 26.091c-3.87-3.384-11.505-8.818-21.554-9.858-14.795-1.529-30.202 3.395-30.202 3.395S61.098 22.146 55 25.867c0 0 19.727-2.294 33.332-1.968 13.605.327 19.228 4.15 21.908 4.037 2.687-.112 5.76-1.845 5.76-1.845z"
      />
      <path
        fill="url(#icon-boat-on_svg__c)"
        fillRule="nonzero"
        d="M135.809 24.361s-10.758-.425-12.846-1.102c-2.088-.668-10.686-6.544-13.845-8.375-3.16-1.83-20.245-5.745-26.763-6.666-6.518-.92-40.568.466-45.355 3.662 0 0 1.445 2.64 1.262 3.075-.182.445-.627.687-.389 1.223.238.537 1.556.496 3.47.445 1.445-.04 5.589.01 11.646 1.922 0 0 10.575-3.965 26.778-5.229 16.204-1.264 25.921 3.125 33.082 7.727 7.161 4.603 10.654 4.613 15.433 4.532 4.772-.081 10.718.364 10.718.364s-1.445-.172-3.191-1.578zM64.397 11.597c-2.254-.698-7.256-1.447-7.256-1.447s2.112-.748 9.789-.87c7.677-.11 10.21 1.74 10.21 1.74-6.614 0-12.743.577-12.743.577z"
      />
      <path
        fill="url(#icon-boat-on_svg__d)"
        fillRule="nonzero"
        d="M78.782 31.934s2.512.12 7.528-.826c5.015-.947 9.69-5.783 9.69-5.783a116.578 116.578 0 0 0-12.345-.352c-15.466.362-32.398 3.203-32.398 3.203S48.927 30.443 48 31.22c8.169-.192 30.782.715 30.782.715z"
      />
      <path
        fill="url(#icon-boat-on_svg__e)"
        fillRule="nonzero"
        d="M23.067 74.939s82.302-.04 98.495-.576c16.193-.526 20.52-3.173 28.613-8.317 7.926-5.043 20.148-24.122 26.754-34.46.15-.233.047-.647-.159-.647-7.23.182-79.612 2.021-85.49 2.021-6.186 0-32.75-2.223-56.308-.91l-1.883 1.405-7.87-.05-2.263 3.072s-4.85 3.678-8.686 10.116c0 0-.451 1.728-.696 4.537l-12.586.404s-.815 2.122-.973 2.527c-.158.404.91 1.091 4.533 1.202 0 0 1.78 4.275 17.522 5.488l.316 5.174.475 4.052.206 4.962z"
      />
      <path
        fill="url(#icon-boat-on_svg__f)"
        fillRule="nonzero"
        d="M121.35 59.737H22.395c-5.29 0-13.727-1.923-16.396-2.798 2.177 1.333 6.633 3.012 15.601 3.704l.318 5.21.477 4.08.214 5.006s82.645-.04 98.906-.58c16.26-.53 20.606-3.195 28.732-8.374 2.026-1.293 4.33-3.5 6.752-6.248h-35.65z"
      />
      <path
        stroke="url(#icon-boat-on_svg__g)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M7.517 50.939a29.938 29.938 0 0 0-1.474 3.281c-.07.188-.054.453.046.597.187.265.551.608 1.21.608 1.056 0 14.275.862 18.224.353C29.472 55.27 31 50.95 31 50.95H7.517v-.011z"
        opacity={0.5}
      />
      <path
        stroke="url(#icon-boat-on_svg__h)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M21.5 54.939v4"
        opacity={0.5}
      />
      <path
        fill="url(#icon-boat-on_svg__i)"
        fillRule="nonzero"
        d="M106.249 56.956c-2.15-.017-7.035-.017-13.413-.017-2.332.459-4.861.247-6.49.006-25.308.035-64.346.164-64.346.164v.647s62.023-.064 82.154-.064c20.132 0 53.81.247 53.81.247l1.036-.712c0 .006-42.365-.188-52.751-.27z"
      />
      <path
        stroke="url(#icon-boat-on_svg__j)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M28 32.939s-5.886 7.513-9.5 14.01c0 0-.333 1.746-.5 3.99"
        opacity={0.5}
      />
      <path
        stroke="url(#icon-boat-on_svg__k)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M34 35.939h-1.1s-5.366 6.204-8.808 10.662c-.294.384-.513.878-.639 1.443A23.284 23.284 0 0 0 23 50.939"
        opacity={0.5}
      />
      <path
        stroke="url(#icon-boat-on_svg__l)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M33 36.939l-2 2"
        opacity={0.5}
      />
      <path
        stroke="url(#icon-boat-on_svg__m)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M33 33.939h3v2h-3z"
        opacity={0.5}
      />
      <path
        fill="url(#icon-boat-on_svg__n)"
        fillRule="nonzero"
        d="M23 69.732s-8.876.95-10.846 1.173C10.466 71.093 10 70.45 10 70.01c0-.636 1.377-1.048 2.117-1.298 1-.34 10.542-1.773 10.542-1.773L23 69.732z"
      />
      <path
        fill="url(#icon-boat-on_svg__o)"
        fillRule="nonzero"
        d="M17.923 66.653L22 60.225l-.009-.158s-.885-.059-1.617-.128l-4.374 7 1.923-.286z"
      />
      <path
        fill="url(#icon-boat-on_svg__p)"
        fillRule="nonzero"
        d="M17 70.939s-2.044 4.244-2.415 5H13V74.69l.594-3.327L17 70.94z"
      />
      <path
        fill="url(#icon-boat-on_svg__q)"
        fillRule="nonzero"
        d="M9.279 63.947c-.195-.063-.343.252-.25.504.565 1.458.983 3.221 1.187 5.141.27 2.53.12 4.932-.334 6.895-.065.283.13.545.325.42C11.859 75.846 13 73.432 13 70.641c0-3.295-1.596-6.044-3.721-6.695z"
      />
      <path fill="url(#icon-boat-on_svg__r)" fillRule="nonzero" d="M83 31.939v-1l-1 .028v.926z" />
      <path
        stroke="url(#icon-boat-on_svg__s)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M59 20.939s3.434-.333 6-1"
        opacity={0.5}
      />
      <path
        fill="url(#icon-boat-on_svg__t)"
        fillRule="nonzero"
        d="M48 9.344V4.78c0-2.117-.895-3.84-2-3.84s-2 1.713-2 3.84v5.16l4-.595z"
      />
      <path
        fill="url(#icon-boat-on_svg__u)"
        fillRule="nonzero"
        d="M180 27.542c0-.343-.166-.613-.364-.603-7.162.218-118.308 3.555-121.636 4.678 0 0 7.645.156 11.645.322 0 0 98.6-3.306 110.007-3.784.198-.02.348-.29.348-.613z"
      />
      <path
        fill="url(#icon-boat-on_svg__v)"
        fillRule="nonzero"
        d="M174 27.939l-1.63 1.976-1.37.024 1.504-1.969z"
      />
      <path
        fill="url(#icon-boat-on_svg__w)"
        fillRule="nonzero"
        d="M164 30.914v-1.975l-1 .025v1.975z"
      />
      <path
        fill="url(#icon-boat-on_svg__x)"
        fillRule="nonzero"
        d="M152 30.921V28.94l-1 .026v1.974z"
      />
      <path
        fill="url(#icon-boat-on_svg__y)"
        fillRule="nonzero"
        d="M144 30.92v-1.98l-1 .027v1.973z"
      />
      <path
        fill="url(#icon-boat-on_svg__z)"
        fillRule="nonzero"
        d="M135 31.92v-1.98l-1 .028v1.972z"
      />
      <path
        fill="url(#icon-boat-on_svg__A)"
        fillRule="nonzero"
        d="M127 31.92v-1.981l-1 .029v1.97z"
      />
      <path
        fill="url(#icon-boat-on_svg__B)"
        fillRule="nonzero"
        d="M118 31.929v-.99l-1 .015v.985z"
      />
      <path fill="url(#icon-boat-on_svg__C)" fillRule="nonzero" d="M109 31.939v-1l-1 .016v.984z" />
      <path
        fill="url(#icon-boat-on_svg__D)"
        fillRule="nonzero"
        d="M100 31.928v-.99l-1 .017v.984z"
      />
      <path fill="url(#icon-boat-on_svg__E)" fillRule="nonzero" d="M92 32.939v-1l-1 .018v.982z" />
      <path
        fill="url(#icon-boat-on_svg__F)"
        fillRule="nonzero"
        d="M27 32.96l-2.078-.021-2.122 3.084s-4.548 3.694-8.147 10.157c0 0-.423 1.938-.653 4.759h1.38c.111-2.872.623-4.272.623-4.272 2.768-5.57 8.415-10.106 8.415-10.106C25.523 34.846 27 32.96 27 32.96z"
      />
      <path
        fill="url(#icon-boat-on_svg__G)"
        fillRule="nonzero"
        d="M3 50.939l-1.702.048S.23 53.494.022 53.972c-.124.286.26.704 1.608.967l1.37-4z"
      />
      <path
        fill="url(#icon-boat-on_svg__H)"
        fillRule="nonzero"
        d="M8 50.939H6.669a24.652 24.652 0 0 0-1.622 2.94c-.077.169-.06.406.051.535.18.208.512.465 1.067.525l1.835-4z"
      />
      <path d="M0-17h180v94H0z" />
    </g>
  </svg>
);

export default SvgIconBoatOn;
