import React from 'react';

const SvgIconPause = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.5 2A2.5 2.5 0 0 1 9 4.5v15a2.5 2.5 0 1 1-5 0v-15A2.5 2.5 0 0 1 6.5 2zm11 0A2.5 2.5 0 0 1 20 4.5v15a2.5 2.5 0 1 1-5 0v-15A2.5 2.5 0 0 1 17.5 2z"
    />
  </svg>
);

export default SvgIconPause;
