import React from 'react';

const SvgIconLock = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="#54575F"
      d="M6.635 10.355h1.18V7.34a3.8 3.8 0 1 1 7.6 0v3.015h1.17V7.379a4.97 4.97 0 0 0-4.97-4.979 4.98 4.98 0 0 0-4.98 4.98v2.975zm2.58 0h4.8V7.34a2.4 2.4 0 1 0-4.8 0v3.015zm8.77.002a2.335 2.335 0 0 1 2.245 2.333v8.37a2.335 2.335 0 0 1-2.335 2.335H5.335A2.335 2.335 0 0 1 3 21.06v-8.37c0-1.256.992-2.28 2.235-2.333V7.38A6.38 6.38 0 0 1 11.615 1a6.37 6.37 0 0 1 6.37 6.38v2.977zm-12.65 1.448a.885.885 0 0 0-.885.885v8.37c0 .489.396.885.885.885h12.56a.885.885 0 0 0 .885-.885v-8.37a.885.885 0 0 0-.885-.885H5.335z"
    />
  </svg>
);

export default SvgIconLock;
