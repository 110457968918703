import React from 'react';

const SvgIconCircleInstagram = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-circle-instagram_svg__a"
        d="M15.574 19H8.426A3.434 3.434 0 0 1 5 15.572V8.421A3.434 3.434 0 0 1 8.426 5h7.148c1.89 0 3.422 1.53 3.426 3.42v7.152A3.427 3.427 0 0 1 15.574 19zM8.426 6.01a2.417 2.417 0 0 0-2.41 2.41v7.152a2.417 2.417 0 0 0 2.41 2.418h7.148a2.417 2.417 0 0 0 2.416-2.418V8.421a2.417 2.417 0 0 0-2.416-2.41H8.426zM12 15.465a3.466 3.466 0 0 1-2.488-5.91 3.465 3.465 0 0 1 5.927 2.442A3.467 3.467 0 0 1 12 15.465zm0-5.92a2.457 2.457 0 1 0 0 4.914 2.457 2.457 0 0 0 0-4.914zm3.991-.579a.976.976 0 1 1 0-1.952.976.976 0 0 1 0 1.952zM12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12zm0-1c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11z"
      />
    </defs>
    <use fill="currentColor" xlinkHref="#icon-circle-instagram_svg__a" />
  </svg>
);

export default SvgIconCircleInstagram;
