import React from 'react';

const SvgIconMoto = props => (
  <svg width={100} height={101} viewBox="0 0 100 101" {...props}>
    <defs>
      <path id="icon-moto_svg__a" d="M0 .12h2.742v8.253H0z" />
      <path id="icon-moto_svg__c" d="M0 .071h2.742v8.373H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#F9DCDF"
        d="M34.923 37.333c0 5.7-4.64 10.321-10.365 10.321-5.725 0-10.366-4.62-10.366-10.32s4.641-10.322 10.366-10.322c5.724 0 10.365 4.621 10.365 10.321"
      />
      <path
        fill="#FF1D46"
        d="M23.615 43.901c-5.204 0-9.423-4.2-9.423-9.382 0-5.183 4.219-9.383 9.423-9.383 5.205 0 9.423 4.2 9.423 9.383 0 5.182-4.218 9.382-9.423 9.382m0-22.518c-7.286 0-13.192 5.88-13.192 13.136 0 7.255 5.906 13.135 13.192 13.135 7.287 0 13.193-5.88 13.193-13.135 0-7.256-5.906-13.136-13.193-13.136"
      />
      <g transform="translate(22.673 12)">
        <mask id="icon-moto_svg__b" fill="#fff">
          <use xlinkHref="#icon-moto_svg__a" />
        </mask>
        <path
          fill="#FF1D46"
          d="M1.37 8.373c.758 0 1.372-.863 1.372-1.93V2.05C2.742.984 2.128.12 1.37.12S0 .984 0 2.05v4.393c0 1.067.613 1.93 1.37 1.93"
          mask="url(#icon-moto_svg__b)"
        />
      </g>
      <g transform="translate(22.673 48.593)">
        <mask id="icon-moto_svg__d" fill="#fff">
          <use xlinkHref="#icon-moto_svg__c" />
        </mask>
        <path
          fill="#FF1D46"
          d="M1.37.071C.615.071 0 .948 0 2.03v4.457c0 1.082.614 1.958 1.37 1.958.758 0 1.372-.876 1.372-1.958V2.03C2.742.948 2.128.071 1.37.071"
          mask="url(#icon-moto_svg__d)"
        />
      </g>
      <path
        fill="#FF1D46"
        d="M44.247 32.642h-4.514c-1.095 0-1.983.63-1.983 1.407 0 .778.888 1.408 1.983 1.408h4.514c1.096 0 1.984-.63 1.984-1.408 0-.777-.888-1.407-1.984-1.407M9.48 34.05c0-.778-.883-1.408-1.973-1.408H2.974c-1.09 0-1.974.63-1.974 1.407 0 .778.884 1.408 1.974 1.408h4.533c1.09 0 1.974-.63 1.974-1.408m27.024-9.505l4.42-4.4c.793-.79.793-2.07 0-2.86a2.038 2.038 0 0 0-2.873 0l-4.419 4.4a2.016 2.016 0 0 0 0 2.86c.793.79 2.08.79 2.872 0m-25.78 19.949l-4.418 4.4a2.016 2.016 0 0 0 0 2.86c.792.79 2.078.79 2.871 0l4.42-4.4c.793-.79.793-2.07 0-2.86a2.037 2.037 0 0 0-2.873 0m25.78 0a2.037 2.037 0 0 0-2.872 0 2.016 2.016 0 0 0 0 2.86l4.42 4.4c.792.79 2.078.79 2.871 0 .794-.79.794-2.07 0-2.86l-4.419-4.4zm-25.78-19.949c.793.79 2.08.79 2.872 0 .794-.79.794-2.07 0-2.86l-4.419-4.4a2.037 2.037 0 0 0-2.871 0 2.016 2.016 0 0 0 0 2.86l4.418 4.4z"
      />
      <path
        fill="#F78092"
        d="M39.88 26.197l-1.942.683c-.943.331-1.383 1.232-.982 2.012.4.78 1.49 1.144 2.433.812l1.943-.683c.943-.331 1.382-1.232.981-2.012-.4-.78-1.49-1.144-2.433-.812M7.35 42.84l1.943-.683c.943-.331 1.383-1.232.982-2.013-.401-.78-1.49-1.143-2.433-.811l-1.942.683c-.944.33-1.383 1.232-.982 2.012.4.78 1.49 1.144 2.433.812m33.952-2.858l-1.952-.66c-.947-.32-2.026.064-2.408.856-.383.792.075 1.693 1.023 2.013l1.952.659c.947.32 2.026-.063 2.409-.855.382-.792-.076-1.693-1.024-2.013M5.927 29.055l1.952.66c.948.32 2.026-.064 2.409-.856s-.075-1.693-1.023-2.013l-1.952-.659c-.948-.32-2.026.063-2.409.855-.382.792.076 1.693 1.023 2.013m25.36 19.725c-.333-.94-1.238-1.377-2.021-.978-.784.4-1.149 1.484-.816 2.422l.686 1.935c.333.939 1.238 1.376 2.021.977.784-.399 1.149-1.484.816-2.422l-.686-1.935zM15.944 20.258c.333.939 1.238 1.376 2.02.977.784-.4 1.15-1.484.817-2.422l-.686-1.935c-.333-.939-1.238-1.376-2.022-.977-.783.399-1.148 1.483-.815 2.422l.686 1.935zm1.988 27.531c-.796-.381-1.701.075-2.022 1.018l-.662 1.944c-.321.944.063 2.017.859 2.398.795.381 1.7-.075 2.022-1.018l.661-1.944c.322-.943-.063-2.018-.858-2.398m11.367-26.541c.796.381 1.7-.075 2.022-1.019l.662-1.943c.32-.944-.064-2.018-.86-2.399-.795-.38-1.7.076-2.02 1.02l-.663 1.943c-.32.943.064 2.017.859 2.398"
      />
      <g transform="matrix(-1 0 0 1 92 52)">
        <path
          fill="#F78092"
          d="M35.865 30.345H18.492L8.167 17.185h-4.03v-5.001h4.195a7.766 7.766 0 0 1 6.13 3.045l4.962 6.495H30.39l5.777-9.54h6.643V4.138h.768c3.637 0 6.612 2.917 6.76 6.56h-3.144v3.44h3.15v2.937c-7.476.135-13.63 5.898-14.48 13.27z"
        />
        <path fill="#F78092" d="M30.852 15.576l-1.81 2.97h-7.551l-2.283-2.97z" />
        <circle cx={52.069} cy={32.759} r={6.552} fill="#FCC3C6" />
        <circle cx={11.379} cy={32.759} r={6.552} fill="#FCC3C6" />
        <path
          fill="#FF1D46"
          fillRule="nonzero"
          d="M35.806 3.493h3.708V8.17h-5.258l-1.965 3.224H15.68A11.44 11.44 0 0 0 7.76 8.17H2.069A2.069 2.069 0 0 0 0 10.239v7.925a2.07 2.07 0 0 0 2.069 2.07h3.845l2.293 2.904a8.626 8.626 0 0 0-6.085 8.245c0 4.751 3.846 8.617 8.574 8.617 3.944 0 7.315-2.707 8.29-6.405H37.52c.963 0 1.744-.78 1.746-1.743a11.738 11.738 0 0 1 2.887-7.7l1.76 2.02a8.6 8.6 0 0 0-1.752 5.211c0 4.751 3.847 8.617 8.574 8.617 4.728 0 8.574-3.866 8.574-8.617 0-4.751-3.846-8.617-8.574-8.617-1.526 0-2.96.405-4.202 1.11L44.8 21.888a11.578 11.578 0 0 1 7.439-1.7 1.737 1.737 0 0 0 1.924-1.726v-8.02C54.164 4.683 49.504 0 43.775 0h-7.97a1.747 1.747 0 1 0 0 3.493zm-5.644 11.394l-1.81 2.969h-7.55l-2.283-2.97h11.643zM5.598 31.383c0-2.817 2.274-5.11 5.074-5.123l4.985 6.313c-.534 2.263-2.57 3.934-4.96 3.934-2.812 0-5.1-2.298-5.1-5.124zm30.296-1.281H18.142L7.592 16.74H3.475v-5.078h4.286a7.953 7.953 0 0 1 6.264 3.091l5.07 6.595h11.204l5.904-9.686h6.787v-8.17h.785c3.717 0 6.756 2.962 6.907 6.66H47.47v3.494h3.22v2.981c-7.64.137-13.929 5.99-14.796 13.474zm14.843 6.405c-2.812 0-5.099-2.298-5.099-5.124 0-.904.235-1.754.646-2.492l3.373 3.871 2.614-2.3-3.363-3.86a5.049 5.049 0 0 1 1.828-.343c2.812 0 5.099 2.299 5.099 5.124 0 2.826-2.287 5.124-5.098 5.124z"
        />
      </g>
    </g>
  </svg>
);

export default SvgIconMoto;
