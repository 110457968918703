import React from 'react';

const SvgIconMessenger = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-messenger_svg__a"
        d="M10 0C4.478 0 0 4.145 0 9.259c0 2.913 1.454 5.512 3.726 7.21V20l3.405-1.869c.909.252 1.871.388 2.869.388 5.523 0 10-4.145 10-9.259C20 4.146 15.523 0 10 0zm.994 12.469L8.448 9.753l-4.97 2.716 5.467-5.803 2.609 2.716 4.906-2.716-5.466 5.803z"
      />
    </defs>
    <use fill="currentColor" transform="translate(2 2)" xlinkHref="#icon-messenger_svg__a" />
  </svg>
);

export default SvgIconMessenger;
