import React from 'react';

const SvgIconPickup = props => (
  <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
    <defs>
      <path
        id="icon-pickup_svg__a"
        d="M21.759 5.951l-.134-.128V3.75c0-.393-.335-.714-.746-.714h-5.612V.716c0-.391-.332-.712-.74-.714-3.115-.023-4.942.17-6.306.662-1.331.48-2.193 1.229-3.277 2.43-1.192.118-2.248.425-3.063.889-.86.49-1.376 1.115-1.5 1.812l-.162.156A.703.703 0 0 0 0 6.457v.676c0 .188.08.373.218.505l.135.129v.38c0 .394.335.715.746.715h1.768c.19 1.19 1.267 2.106 2.564 2.106 1.296 0 2.373-.915 2.563-2.106h6.401c.19 1.19 1.267 2.106 2.563 2.106 1.297 0 2.374-.915 2.564-2.106h1.356c.197 0 .39-.077.528-.21l.353-.338a.695.695 0 0 0 .219-.505V6.457a.703.703 0 0 0-.219-.506zM5.431 10.216c-.997 0-1.808-.777-1.808-1.73 0-.955.811-1.73 1.808-1.73.996 0 1.807.776 1.807 1.73 0 .953-.81 1.73-1.807 1.73zm11.528 0c-.997 0-1.808-.777-1.808-1.73 0-.955.811-1.73 1.808-1.73.996 0 1.807.776 1.807 1.73 0 .953-.81 1.73-1.807 1.73zm4.233-2.423l-.33.316h-1.34c-.19-1.19-1.267-2.106-2.563-2.106-1.297 0-2.374.915-2.564 2.106H7.994c-.19-1.19-1.267-2.106-2.563-2.106-1.297 0-2.374.915-2.564 2.106H1.14v-.654l-.353-.338v-.645l.345-.33.013-.135c.108-1.074 1.802-1.99 4.03-2.179l.16-.013.104-.117C7.45 1.443 8.6.715 14.48.753V3.79h6.358v2.345l.353.338v1.321z"
      />
    </defs>
    <use fill="currentColor" transform="translate(0 6)" xlinkHref="#icon-pickup_svg__a" />
  </svg>
);

export default SvgIconPickup;
