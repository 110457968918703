import React from 'react';

const SvgIconFavoriteWhitebgOff = props => (
  <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
    <defs>
      <path
        id={`icon-favorite-whitebg-off_svg__a${props.customId}`}
        d="M9.143 16.496a.635.635 0 0 1-.298-.075C2.739 12.865.555 8.008.467 7.81v-.075A5.528 5.528 0 0 1 0 5.493C-.001 3.243 1.324 1.22 3.345.389 5.366-.442 7.673.085 9.167 1.72c1.794-1.946 4.687-2.265 6.838-.754 2.15 1.512 2.928 4.41 1.838 6.852h-.048c-.5 1.383-3.223 5.647-8.33 8.63a.633.633 0 0 1-.322.05zM1.595 7.334c1.77 3.273 4.382 5.975 7.548 7.805 3.194-1.826 5.82-4.553 7.572-7.864.26-.561.395-1.176.395-1.799.005-1.855-1.157-3.5-2.868-4.057-1.712-.557-3.575.101-4.6 1.625a.598.598 0 0 1-.499.266.599.599 0 0 1-.491-.266C7.628 1.52 5.764.862 4.052 1.419c-1.711.558-2.873 2.202-2.868 4.057 0 .62.133 1.23.387 1.791l.024.067z"
      />
    </defs>
    <g fill="none" transform="translate(1.833 2.75)">
      <path
        fill="#FFF"
        d="M1.595 7.334c1.77 3.273 4.382 5.975 7.548 7.805 3.194-1.826 5.82-4.553 7.572-7.864.26-.561.395-1.176.395-1.799.005-1.855-1.157-3.5-2.868-4.057-1.712-.557-3.575.101-4.6 1.625a.598.598 0 0 1-.499.266.599.599 0 0 1-.491-.266C7.628 1.52 5.764.862 4.052 1.419c-1.711.558-2.873 2.202-2.868 4.057 0 .62.133 1.23.387 1.791l.024.067z"
      />
      <use fill="#54575F" xlinkHref={`#icon-favorite-whitebg-off_svg__a${props.customId}`} />
    </g>
  </svg>
);

export default SvgIconFavoriteWhitebgOff;
