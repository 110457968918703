import React from 'react';

const SvgIconBusSmall = props => (
  <svg width={32} height={23} viewBox="0 0 32 23" {...props}>
    <defs>
      <path
        id="icon-bus-small_svg__a"
        d="M12.578 0c.988 0 1.797.83 1.797 1.843v13.27c0 .607-.287 1.142-.719 1.474v1.29c0 .718-.557 1.29-1.258 1.29h-.359c-.7 0-1.258-.572-1.258-1.29v-.922H3.594v.922c0 .718-.557 1.29-1.258 1.29h-.36c-.7 0-1.257-.572-1.257-1.29v-1.29c0-.037.018-.074.018-.093-.432-.331-.737-.81-.737-1.382V1.842C0 .83.809 0 1.797 0h10.781zM2.875 17.877v-.922h-.898c-.18 0-.36-.037-.54-.092v1.014c0 .313.234.552.54.552h.359c.305 0 .539-.24.539-.552zm10.063 0v-.959a2.159 2.159 0 0 1-.36.037H11.5v.922c0 .313.234.552.54.552h.358c.306 0 .54-.24.54-.552zm.718-2.765v-3.317H.72v3.317c0 .608.683 1.106 1.258 1.106h10.601c.593 0 1.078-.498 1.078-1.106zm0-4.054V3.686H.72v7.372h12.937zm0-8.11V1.844c0-.608-.485-1.106-1.078-1.106H1.797c-.593 0-1.078.498-1.078 1.106V2.95h12.937zM6.47 1.475c.197 0 .36.166.36.369a.365.365 0 0 1-.36.369H4.133a.365.365 0 0 1-.36-.37c0-.202.162-.368.36-.368h2.336zm2.156 0c.198 0 .36.166.36.369a.365.365 0 0 1-.36.369h-.54a.365.365 0 0 1-.358-.37c0-.202.161-.368.359-.368h.539zm-5.93 13.454c-.593 0-1.078-.498-1.078-1.106 0-.608.485-1.106 1.078-1.106.593 0 1.078.498 1.078 1.106 0 .608-.485 1.106-1.078 1.106zm0-1.474a.365.365 0 0 0-.36.368c0 .203.163.369.36.369.198 0 .36-.166.36-.369a.365.365 0 0 0-.36-.368zm8.985 1.474c-.593 0-1.078-.498-1.078-1.106 0-.608.485-1.106 1.078-1.106.593 0 1.078.498 1.078 1.106 0 .608-.485 1.106-1.078 1.106zm0-1.474a.365.365 0 0 0-.36.368c0 .203.162.369.36.369.197 0 .36-.166.36-.369a.365.365 0 0 0-.36-.368z"
      />
    </defs>
    <use fill="currentColor" transform="translate(9 4)" xlinkHref="#icon-bus-small_svg__a" />
  </svg>
);

export default SvgIconBusSmall;
