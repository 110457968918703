import React from 'react';

const SvgIconCarOff = props => (
  <svg width={221} height={72} viewBox="0 0 221 72" {...props}>
    <defs>
      <linearGradient
        id="icon-car-off_svg__a"
        x1="49.993%"
        x2="49.993%"
        y1="-130.871%"
        y2="88.623%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-car-off_svg__b" x1="33.061%" x2="58.825%" y1="41.154%" y2="58.091%">
        <stop offset="0%" stopColor="#DCE6E6" />
        <stop offset="19.25%" stopColor="#CED8D8" />
        <stop offset="55.37%" stopColor="#AAB4B4" />
        <stop offset="100%" stopColor="#768080" />
      </linearGradient>
      <linearGradient id="icon-car-off_svg__c" x1="37.045%" x2="67.787%" y1="45.199%" y2="60.689%">
        <stop offset="0%" stopColor="#DCE6E6" />
        <stop offset="19.25%" stopColor="#CED8D8" />
        <stop offset="55.37%" stopColor="#AAB4B4" />
        <stop offset="100%" stopColor="#768080" />
      </linearGradient>
      <linearGradient id="icon-car-off_svg__d" x1="18.039%" x2="78.634%" y1="-5.36%" y2="99.593%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-car-off_svg__e" x1="24.995%" x2="75.023%" y1="6.688%" y2="93.338%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient id="icon-car-off_svg__f" x1="18.811%" x2="77.568%" y1="-4.026%" y2="97.744%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-car-off_svg__g" x1="24.998%" x2="75.025%" y1="6.688%" y2="93.338%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient id="icon-car-off_svg__h" x1="32.932%" x2="73.434%" y1="39.555%" y2="57.606%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-car-off_svg__i" x1="36.929%" x2="64.381%" y1="46.55%" y2="56.142%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient id="icon-car-off_svg__j" x1="27.661%" x2="55.577%" y1="44.011%" y2="53.342%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-car-off_svg__k" x1="50.067%" x2="50.067%" y1="-1.225%" y2="101.257%">
        <stop offset="0%" stopOpacity={0} />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient id="icon-car-off_svg__l" x1="-.784%" x2="64.699%" y1="30.884%" y2="57.842%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-car-off_svg__m" x1="27.067%" x2="69.747%" y1="31.825%" y2="71.364%">
        <stop offset="0%" stopColor="#DCE6E6" />
        <stop offset="19.25%" stopColor="#CED8D8" />
        <stop offset="55.37%" stopColor="#AAB4B4" />
        <stop offset="100%" stopColor="#768080" />
      </linearGradient>
      <linearGradient
        id="icon-car-off_svg__n"
        x1="37.418%"
        x2="63.963%"
        y1="-17.537%"
        y2="101.858%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-car-off_svg__o" x1="48.66%" x2="50.18%" y1="49.043%" y2="51.4%">
        <stop offset="0%" stopColor="#8E071E" />
        <stop offset="25.88%" stopColor="#80061A" />
        <stop offset="74.25%" stopColor="#5D020F" />
        <stop offset="100%" stopColor="#470008" />
      </linearGradient>
      <linearGradient id="icon-car-off_svg__p" x1="11.444%" x2="61.672%" y1="40.046%" y2="52.483%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-car-off_svg__q" x1="36.78%" x2="62.6%" y1="30.616%" y2="69.92%">
        <stop offset="0%" stopColor="#E4A8C0" />
        <stop offset="100%" stopColor="#A7496E" />
      </linearGradient>
      <linearGradient id="icon-car-off_svg__r" x1="22.413%" x2="65.247%" y1="37.729%" y2="65.03%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient id="icon-car-off_svg__s" x1="27.913%" x2="71.997%" y1="31.572%" y2="68.035%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient
        id="icon-car-off_svg__t"
        x1="-71.546%"
        x2="75.152%"
        y1="-123.621%"
        y2="83.353%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-car-off_svg__u" x1="12.534%" x2="62.322%" y1="8.618%" y2="112.857%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-car-off_svg__v" x1="36.806%" x2="56.894%" y1="9.279%" y2="98.328%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
      <linearGradient id="icon-car-off_svg__w" x1="-5.57%" x2="69.245%" y1="-47.954%" y2="83.706%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-car-off_svg__x" x1="32.802%" x2="98.625%" y1="45.557%" y2="64.797%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient
        id="icon-car-off_svg__y"
        x1="-10.878%"
        x2="70.494%"
        y1="-57.299%"
        y2="85.903%"
      >
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-car-off_svg__z" x1="32.853%" x2="93.866%" y1="45.548%" y2="63.386%">
        <stop offset="0%" stopColor="#C8D2D2" />
        <stop offset="19.25%" stopColor="#BAC4C4" />
        <stop offset="55.37%" stopColor="#96A0A0" />
        <stop offset="100%" stopColor="#626C6C" />
      </linearGradient>
      <linearGradient id="icon-car-off_svg__A" x1="32.791%" x2="73.291%" y1="35.047%" y2="64.429%">
        <stop offset="0%" stopColor="#E8F2F2" />
        <stop offset="100%" stopColor="#C2CCCC" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M0-22h221v94H0z" />
      <g opacity={0.6}>
        <path
          fill="url(#icon-car-off_svg__a)"
          fillRule="nonzero"
          d="M171.736 70s-7.353-.639-9.753-6.43c0 0-.573.18-1.296.26-.723.08-3.615.19-4.369.3-.753.11.231-1.098.573-1.288.341-.19-93.95-.939-93.95-.939s-2.11 5.012-7.714 5.95c-5.605.939-25.2-9.584-25.2-9.584s-1.547 1.668-3.807 1.718c-2.26.05-5.634-22.553 13.53-25.818 19.173-3.264 145.093-2.626 151.19.46 6.096 3.094 10.345 10.901 10.495 15.144.15 4.233-.854 10.523-3.646 12.31 0 0-.542.4-1.778.59-1.235.189-3.103.309-4.067.498-.985.19-20.208 6.829-20.208 6.829z"
        />
        <path
          fill="url(#icon-car-off_svg__b)"
          fillRule="nonzero"
          d="M71.45 1.242S67.684-.184 64.474.02c-1.036.064-1.864.986-1.977 2.165A16.2 16.2 0 0 0 62.45 3l9-1.758z"
        />
        <path
          fill="url(#icon-car-off_svg__c)"
          fillRule="nonzero"
          d="M217.558 57.875s.754-2.494.724-4.988c0 0 1.217 0 1.458-.432 0 0 2.173-11.565-1.086-15.97-3.228-4.364-21.631-10.941-50.614-13.194a5.19 5.19 0 0 0-1.58.12c-1.075.252-3.257.674-6.003.795-.463.02-.915-.12-1.297-.382-3.349-2.363-20.737-14.43-33.7-18.725-.1-.03-.09-.171.01-.191.845-.14 2.846-.443 4.375-.493 0 0-15.547-5.068-36.998-4.344C74.836.674 66.83 1.73 56.402 4.838 45.973 7.945 31.412 14.894 28.676 16.07c-2.735 1.186-5.4 2.886-11.012 3.55-5.38.633-9.493 1.055-12.178 1.619a.481.481 0 0 0-.382.432c-.121 1.619-.574 8.538-.03 14.893a1.37 1.37 0 0 1-.343 1.026c-.814.895-2.705 3.027-3.901 4.888a1.76 1.76 0 0 0-.282.995c.01.704.09 2.152.473 4.747 0 0-.262.312-.483.734a.782.782 0 0 0-.07.523c.271 1.187 1.639 6.044 6.275 8.689.392.22.834.352 1.277.382 2.574.18 12.188.875 17.981 1.79.13.02.272.04.412.06v-3.68c0-13.607 8.67-18.987 18.002-18.987h2.222c11.454 0 17.448 8.468 17.448 20.415v5.611c1.61.08 2.907.13 3.761.13 6.256 0 27.304-.311 60.128 0 19.872.192 27.777.112 31.98-.05v-7.26c0-12.36 7.683-19.168 17.157-19.168h3.379c12.963 0 17.71 10.82 17.71 20.606V62.5c.27 0 .925-.01 1.699-.06 1.016-.06 1.347-.845 3.992-1.066 2.645-.222 10.51-1.217 14.33-1.69 3.139-.392.805-1.639-.663-1.81z"
        />
        <circle cx={179.45} cy={56} r={16} fill="url(#icon-car-off_svg__d)" fillRule="nonzero" />
        <circle cx={178.45} cy={56} r={12} fill="url(#icon-car-off_svg__e)" fillRule="nonzero" />
        <circle cx={45.45} cy={56} r={16} fill="url(#icon-car-off_svg__f)" fillRule="nonzero" />
        <circle cx={45.45} cy={56} r={12} fill="url(#icon-car-off_svg__g)" fillRule="nonzero" />
        <path
          fill="url(#icon-car-off_svg__h)"
          fillRule="nonzero"
          d="M6.422 52.283L.452 52c-.01.233.011.442.065.633.26.434.572.892.95 1.367h4.22c.983-.383.735-1.717.735-1.717z"
        />
        <path
          fill="url(#icon-car-off_svg__i)"
          fillRule="nonzero"
          d="M140.292 16.383c-7.209-4.275-17.539-9.272-28.412-10.937-15.515-2.377-34.503-1.747-44.229 1.33-8.145 2.57-18.747 6.814-24.737 10.684-.977.63-1.43 1.056-1.46 2.153-.01.325-.01.69.09 1.066.232.863.574 1.422 1.51 1.432 2.81.01 9.555.04 18.456.04 11.84 0 59.059.844 70.446 1.433 3.523.182 6.755.314 9.494.416-.212-2.844-.776-5.82-1.158-7.617z"
        />
        <path
          fill="url(#icon-car-off_svg__j)"
          fillRule="nonzero"
          d="M153.13 22.631c-2.556-2.156-17.94-15.645-38.383-18.855-18.189-2.855-35.396-2.018-44.846.739-8.709 2.54-20.664 7.07-27.008 11.303-1.032.69-1.583 1.88-1.413 3.092.05.354.12.738.23 1.142a2.157 2.157 0 0 0 2.085 1.605c2.977.01 10.132.039 19.552.039 12.547 0 62.584.916 74.64 1.566 10.492.56 14.49.708 15.302.738.09 0 .16-.069.16-.148v-.531a.92.92 0 0 0-.32-.69zM94.212 3.146zm2.746.06c-.882-.03-1.754-.04-2.626-.05.872 0 1.744.02 2.626.05zM42.613 19.302c-.1-.354-.1-.708-.09-1.033.03-1.064.48-1.477 1.453-2.088 4.77-2.993 12.466-6.222 19.501-8.674-.651.226-1.302.453-1.954.69-3.758 4.853-7.706 11.381-9.21 12.504h.712c-4.129-.01-7.225-.02-8.91-.03-.931.01-1.272-.532-1.502-1.369zm19.872 1.418c-3.066 0-5.872 0-8.358-.01h1.744c4.179-7.857 9.62-13.892 9.62-13.892-.63.206-1.272.433-1.923.66a151.105 151.105 0 0 1 5.04-1.655c5.462-1.683 13.86-2.609 22.91-2.678.69 4.421 2.565 17.989 2.565 17.989h.03c-13.389-.237-26.216-.414-31.628-.414zm-9.14-.01h.472-.471zm79.25 1.399c-5.07-.256-17.317-.562-30.345-.818h.05c-1.513-6.626-5.18-18.057-5.19-18.087 5.25.158 10.562.59 15.522 1.34 10.824 1.614 21.106 6.458 28.28 10.603.382 1.743.943 4.628 1.133 7.365a655.288 655.288 0 0 1-9.45-.403z"
        />
        <path
          stroke="url(#icon-car-off_svg__k)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M93.45 2s.32.51.394 1.293L98.218 23.8s.32.346.245 1.14c0 0 .426 1.498.948 2.21 0 0 3.108 12.423 1.65 36.85"
          opacity={0.5}
        />
        <path
          fill="url(#icon-car-off_svg__l)"
          fillRule="nonzero"
          d="M146.255 22.015s1.187.117 2.006.132c.809.014 1.474 1.218 1.064 1.51-.42.3-2.64.343-3.387.343-.747 0-2.292-.073-2.415-.3-.123-.225-.184-1.218.604-1.495.788-.285 2.128-.19 2.128-.19z"
        />
        <path
          fill="url(#icon-car-off_svg__m)"
          fillRule="nonzero"
          d="M146.442 21.866c.02-.647.01-1.519-.11-2.214a1.01 1.01 0 0 0-.44-.676c-.82-.569-3.037-1.901-5.905-1.97-3.636-.088-4.066.774-4.276 1.176-.17.343-.46 2.136-.06 3.625.13.47.53.833 1.02.92 1.088.187 3.576.422 8.742.148.569-.03 1.009-.47 1.029-1.01z"
        />
        <path
          fill="url(#icon-car-off_svg__n)"
          fillRule="nonzero"
          d="M136.5 18.59c.099-.843.95-1.59.95-1.59-1.258.288-1.53.758-1.678 1.025-.21.374-.567 2.327-.074 3.95.16.513.654.908 1.258 1.004.037.01.074.01.111.021-1.048-.865-.666-3.566-.567-4.41z"
        />
        <g fillRule="nonzero">
          <path
            fill="url(#icon-car-off_svg__o)"
            d="M.76.52c0 .01 0 .02.01.03C.76.54.76.53.76.52z"
            transform="translate(134.45 22)"
          />
          <path
            fill="url(#icon-car-off_svg__p)"
            d="M1.408.12c-.01 0-.021.01-.021.01.074.57.244 1.08.563 1.37 1.2.19 3.846.41 9.201.14.585-.03 1.052-.48 1.073-1.03C8.283.09 1.408.12 1.408.12z"
            transform="translate(134.45 22)"
          />
        </g>
        <g fillRule="nonzero">
          <path
            fill="url(#icon-car-off_svg__q)"
            d="M1.17 2.69c0 .01 0 .01 0 0a3.82 3.82 0 0 1-.1-.506c.02.188.05.357.1.507z"
            transform="translate(135.45 19)"
          />
          <path
            fill="url(#icon-car-off_svg__r)"
            d="M5.78.637C4.12.122 1.08.141 1.08.141 1.03.703.98 1.5 1.07 2.194c.01 0 .02-.01.02-.01s2.57-.009 5.4.103c-.3-.974-.71-1.65-.71-1.65z"
            transform="translate(135.45 19)"
          />
        </g>
        <path
          fill="url(#icon-car-off_svg__s)"
          fillRule="nonzero"
          d="M125.531 5.498c-.101.02-.111.163-.01.193 13.06 4.35 30.58 16.533 33.954 18.92.385.275.841.407 1.307.387a32.927 32.927 0 0 0 6.05-.803 4.17 4.17 0 0 1 .618-.101S148.694 11.554 129.939 5l-3.577.366c-.335.05-.618.091-.831.132z"
        />
        <path
          fill="url(#icon-car-off_svg__t)"
          fillRule="nonzero"
          d="M217.77 58.853s.707-2.857.679-5.714c0 0-2.244-.104-3.715-.139a3.597 3.597 0 0 0-2.074.634c-1.047.714-2.546 2.05-3.169 4.136-.16.553.16 1.14.642 1.152 1.63.07 5.204.161 7.637-.07z"
        />
        <path
          fill="url(#icon-car-off_svg__u)"
          fillRule="nonzero"
          d="M16.45 35.872C12.988 25.418 11.973 25.3 11.973 25.3s-.396-.275-1.36-.737c-.711-.333-1.208-1.521-1.422-2.14a1.692 1.692 0 0 0-.609-.814A4.159 4.159 0 0 0 7.293 21a44.54 44.54 0 0 0-2.162.363.475.475 0 0 0-.386.422c-.122 1.561-.569 8.1-.05 14.215 3.664-.363 11.755-.128 11.755-.128z"
        />
        <path
          fill="url(#icon-car-off_svg__v)"
          fillRule="nonzero"
          d="M6.382 29.642a2.205 2.205 0 0 0-1.265-1.443A3.213 3.213 0 0 0 4.464 28c-.042 2.438 0 5.264.228 8 .767-.08 1.721-.13 2.758-.16-.467-3.631-.84-5.382-1.068-6.198z"
        />
        <path
          fill="url(#icon-car-off_svg__w)"
          fillRule="nonzero"
          d="M112.505 31h-2.11c-1.074 0-1.945-1.063-1.945-2.371v-.258c0-1.308.871-2.371 1.945-2.371h2.11c1.073 0 1.945 1.063 1.945 2.371v.258c0 1.308-.872 2.371-1.945 2.371z"
        />
        <path
          fill="url(#icon-car-off_svg__x)"
          fillRule="nonzero"
          d="M116.443 29.37c-.03-.198.068-.622-1.098-.757-1.165-.144-6.312-.613-7.302-.613h-1.952c-.35 0-.641.261-.641.595v.766c0 .325.281.595.64.595h2.001c1.068 0 6.914.1 7.565 0 .641-.1.835-.27.787-.586z"
        />
        <path
          fill="url(#icon-car-off_svg__y)"
          fillRule="nonzero"
          d="M64.505 30h-2.11c-1.074 0-1.945-1.063-1.945-2.371v-.258c0-1.308.871-2.371 1.945-2.371h2.11c1.073 0 1.945 1.063 1.945 2.371v.258c0 1.308-.872 2.371-1.945 2.371z"
        />
        <path
          fill="url(#icon-car-off_svg__z)"
          fillRule="nonzero"
          d="M68.44 28.37c-.029-.198.068-.622-1.097-.757-1.165-.144-6.31-.613-7.301-.613h-1.951c-.35 0-.641.261-.641.595v.766c0 .325.281.595.64.595h2c1.069 0 6.913.1 7.564 0 .64-.108.845-.27.786-.586z"
        />
        <path
          fill="url(#icon-car-off_svg__A)"
          fillRule="nonzero"
          d="M215.428 43s.589-6.846-4.335-7.845c0 0-13.298-.191-14.06-.149-.761.043-1.736.234-1.563.936.172.701 2.883 4.539 4.71 5.166C204.525 42.596 215.428 43 215.428 43z"
        />
      </g>
    </g>
  </svg>
);

export default SvgIconCarOff;
