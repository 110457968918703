import React from 'react';

const SvgIconAlinhamento = props => (
  <svg width={19} height={10.45} viewBox="0 0 40 22" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="#54575F" fillRule="nonzero">
        <path d="M6.633.327H3.52C1.58.327 0 1.907 0 3.847v14.3c0 1.94 1.58 3.52 3.52 3.52h3.107c1.94 0 3.52-1.58 3.526-3.52v-14.3c0-1.94-1.58-3.52-3.52-3.52zM3.227 17.28c.2-.1.286-.34.193-.547a.406.406 0 00-.547-.193l-2.053.967v-3.454l2.407-1.126a.378.378 0 00.206-.234.425.425 0 00-.013-.313.418.418 0 00-.547-.193l-2.053.966V9.707L3.227 8.58a.378.378 0 00.206-.233.425.425 0 00-.013-.314.378.378 0 00-.233-.206.424.424 0 00-.314.013L.82 8.807v-3.46L3.227 4.22c.2-.1.286-.34.193-.547a.406.406 0 00-.547-.193L.82 4.447v-.594c0-1.486 1.213-2.7 2.7-2.7h3.113c1.487 0 2.7 1.214 2.7 2.7v.607l-2.06-.967a.407.407 0 00-.54.187.377.377 0 00-.02.307.4.4 0 00.214.24L9.34 5.36v3.453l-2.06-.966a.401.401 0 00-.54.186.406.406 0 00.187.554L9.34 9.72v3.447L7.28 12.2a.418.418 0 00-.573.5.432.432 0 00.213.247l2.413 1.12v3.446l-2.06-.966a.4.4 0 00-.54.186.377.377 0 00-.02.307.4.4 0 00.214.24l2.386 1.12c-.133 1.367-1.273 2.447-2.673 2.447H3.52a2.69 2.69 0 01-2.673-2.454l2.38-1.113z" />
        <path d="M4.3 20.427a.41.41 0 00.413-.407V1.987a.415.415 0 00-.413-.414.415.415 0 00-.413.414v18.026c0 .227.186.414.413.414zm1.553 0a.41.41 0 00.414-.407V1.987a.415.415 0 00-.414-.414.415.415 0 00-.413.414v18.026c0 .227.187.414.413.414zm28.294 0a.41.41 0 00.413-.407V1.987a.415.415 0 00-.413-.414.415.415 0 00-.414.414v18.026a.42.42 0 00.414.414zm1.553 0a.41.41 0 00.413-.407V1.987a.415.415 0 00-.413-.414.415.415 0 00-.413.414v18.026c0 .227.186.414.413.414zM21.24 8.1h-2.487c-.573 0-1.033.467-1.033 1.033v3.727c0 .573.467 1.033 1.033 1.033h2.487c.573 0 1.033-.466 1.033-1.033V9.133c0-.566-.46-1.033-1.033-1.033zM20 11.567a.415.415 0 00.413-.414h-.1V8.927h.934a.21.21 0 01.213.213v3.727a.21.21 0 01-.213.213H18.76a.21.21 0 01-.213-.213V9.133a.21.21 0 01.213-.213h.827v2.227c0 .233.186.42.413.42zm-3.113.26h-5.914a.415.415 0 00-.413.413c0 .227.187.413.413.413h5.914a.415.415 0 00.413-.413.415.415 0 00-.413-.413zm0-2.487h-5.914a.415.415 0 00-.413.413c0 .227.187.414.413.414h5.914a.415.415 0 00.413-.414.415.415 0 00-.413-.413zm12.126 0H23.1a.415.415 0 00-.413.413c0 .227.186.414.413.414h5.913a.415.415 0 00.414-.414.415.415 0 00-.414-.413zm0 2.487H23.1a.415.415 0 00-.413.413c0 .227.186.413.413.413h5.913a.415.415 0 00.414-.413.415.415 0 00-.414-.413z" />
        <path d="M40 3.847c0-1.94-1.58-3.52-3.52-3.52h-3.113c-1.94 0-3.52 1.58-3.52 3.52v14.3c0 1.94 1.58 3.52 3.52 3.52h3.113A3.524 3.524 0 0040 18.193V3.847zM33.08 17.28c.2-.1.287-.34.193-.547a.404.404 0 00-.54-.193l-2.053.967v-3.454l2.407-1.126a.378.378 0 00.206-.234.425.425 0 00-.013-.313.408.408 0 00-.547-.193l-2.053.966V9.707l2.407-1.127a.378.378 0 00.206-.233.395.395 0 00-.013-.314.408.408 0 00-.54-.193l-2.053.967v-3.46l2.406-1.127c.2-.1.287-.34.194-.547a.4.4 0 00-.54-.193l-2.054.967v-.594c0-1.486 1.214-2.7 2.7-2.7h3.114c1.486 0 2.7 1.214 2.7 2.7v.607l-2.06-.967a.407.407 0 00-.54.187.377.377 0 00-.02.307.4.4 0 00.213.24l2.413 1.133v3.453l-2.06-.966a.401.401 0 00-.54.186.377.377 0 00-.02.307.432.432 0 00.214.247L39.22 9.72v3.447l-2.06-.967a.418.418 0 00-.547.193.377.377 0 00-.02.307.432.432 0 00.214.247l2.413 1.133v3.447l-2.06-.967a.407.407 0 00-.54.187.377.377 0 00-.02.306.4.4 0 00.213.24l2.387 1.12c-.133 1.367-1.273 2.447-2.673 2.447h-3.114a2.69 2.69 0 01-2.673-2.453l2.34-1.127z" />
      </g>
      <path d="M0-9h40v40H0z" />
    </g>
  </svg>
);

export default SvgIconAlinhamento;
