import React from 'react';

const IconOrders = ({ customColor, ...props }) => (
  <svg width="32" height="32" stroke={customColor} viewBox="0 0 32 32" {...props}>
    <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <path d="M16 14.602v16.032l14.634-6.52V8.055zm0 0v16.032l-14.634-6.52V8.055zM1.366 8.054L16 14.602l14.634-6.548L16 1.366z" />
      <path d="M7.285 5.346l14.459 6.688v5.546l3.724-1.55v-5.664L11.112 3.598zM3.349 21.532l2.092.929m-2.092-3.022l4.214 1.888" />
    </g>
  </svg>
);

export default IconOrders;
