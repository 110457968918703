import React from 'react';

const SvgIconHourglass = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-hourglass_svg__a"
        d="M.833 0h15a.833.833 0 0 1 .105 1.66l-.105.007h-1.666v3.216c0 1.268-.577 2.46-1.555 3.246l-.017.013-.017.013-.083.064-.033.025-.033.024-.042.029-.038.027-.038.026L9.836 10l2.475 1.65a4.164 4.164 0 0 1 1.85 3.236l.006.232v3.215h1.666a.833.833 0 0 1 .105 1.66l-.105.007h-15a.833.833 0 0 1-.104-1.66l.104-.007H2.5v-3.216c0-1.316.621-2.55 1.666-3.333l.19-.133L6.83 10 4.355 8.35l-.068-.048-.051-.036a4.044 4.044 0 0 1-.149-.112l-.033-.026A4.165 4.165 0 0 1 2.507 5.12L2.5 4.882V1.667H.833A.833.833 0 0 1 .73.007L.833 0h15zm10.554 13.037l-2.448-1.632a.83.83 0 0 1-1.13.075l-.081-.075-2.449 1.633a2.497 2.497 0 0 0-1.106 1.901l-.006.178v3.216H12.5v-3.215a2.5 2.5 0 0 0-.968-1.977l-.145-.104-2.448-1.632zm-3.045 2.796a.833.833 0 1 1 0 1.667.837.837 0 0 1-.838-.833.83.83 0 0 1 .83-.834zM8.333 12.5c.426 0 .776.317.827.729l.007.104v.834a.833.833 0 0 1-1.66.104l-.007-.104v-.834c0-.46.372-.833.833-.833zm3.417-5.833H4.916c.055.053.111.104.17.153l.008.006.069.053.023.018.04.03.027.017.027.019 3.053 2.035 3.054-2.036.058-.04.01-.006a2.614 2.614 0 0 0 .117-.09l.007-.005c.06-.049.117-.1.171-.154zm.75-5H4.167V4.94L4.17 5h8.327l.002-.059.001-.058V1.667z"
      />
    </defs>
    <use fill="currentColor" transform="translate(4 2)" xlinkHref="#icon-hourglass_svg__a" />
  </svg>
);

export default SvgIconHourglass;
