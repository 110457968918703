import React from 'react';

const SvgIconShowMe = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-show-me_svg__a"
        d="M9.133 6.894c-1.23.072-2.246-.872-2.166-2.017.063-.948.888-1.713 1.904-1.775 1.23-.072 2.246.872 2.165 2.018-.066.95-.89 1.716-1.903 1.774m.114 1.627c-2.283.134-4.169-1.62-4.025-3.751.118-1.757 1.646-3.181 3.531-3.291 2.283-.134 4.168 1.62 4.025 3.751-.122 1.754-1.65 3.178-3.531 3.291M9 0C5.56 0 2.442 1.753.14 4.602a.628.628 0 0 0 0 .793C2.443 8.246 5.56 10 9 10c3.44 0 6.558-1.754 8.86-4.602a.628.628 0 0 0 0-.793C15.557 1.753 12.44 0 9 0"
      />
    </defs>
    <use
      fill="currentColor"
      fillRule="evenodd"
      transform="translate(3 7)"
      xlinkHref="#icon-show-me_svg__a"
    />
  </svg>
);

export default SvgIconShowMe;
