import React from 'react';

const SvgIconProfilePhoto = props => (
  <svg width={57} height={42} viewBox="0 0 57 42" {...props}>
    <defs>
      <path id="icon-profile-photo_svg__a" d="M0 41.571h61.83V0H0z" />
      <path id="icon-profile-photo_svg__c" d="M.096 0h46.277v31.861H.096z" />
      <path id="icon-profile-photo_svg__e" d="M0 0h22v20H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#F9DCDF"
        d="M14.723 1.268H53.43c1.068 0 1.935.79 1.935 1.765v26.474c0 .974-.867 1.764-1.935 1.764H14.723c-1.069 0-1.935-.79-1.935-1.764V3.033c0-.974.866-1.765 1.935-1.765"
      />
      <path
        fill="#FFF"
        d="M12.906 30.922h2.842c-1.045-.002-1.891-.79-1.894-1.764V2.684c.003-.973.85-1.762 1.894-1.765h-2.842c-1.045.003-1.891.792-1.894 1.765v26.474c.003.973.85 1.762 1.894 1.764"
      />
      <mask id="icon-profile-photo_svg__b" fill="#fff">
        <use xlinkHref="#icon-profile-photo_svg__a" />
      </mask>
      <path
        fill="#FFF"
        d="M10.42 29.12h1.894V2.649H10.42z"
        mask="url(#icon-profile-photo_svg__b)"
      />
      <path
        fill="#FF1D46"
        d="M11.367 24.709c-.523 0-.947.395-.947.882v3.53h1.894v-3.53c0-.487-.424-.882-.947-.882m.947-22.061H10.42V18.53c0 .488.424.883.947.883.523 0 .947-.395.947-.883V2.648z"
        mask="url(#icon-profile-photo_svg__b)"
      />
      <path
        fill="#FF1D46"
        d="M10.401 22.126a.9.9 0 0 0 .274.628l.142.106a.74.74 0 0 0 .17.08.636.636 0 0 0 .17.053c.062.01.125.016.188.018.25-.004.49-.095.671-.257a.902.902 0 0 0 .198-.292.861.861 0 0 0-.198-.965.983.983 0 0 0-.311-.185.996.996 0 0 0-.36-.071.968.968 0 0 0-.67.256.848.848 0 0 0-.274.629"
      />
      <g transform="translate(10.305)">
        <mask id="icon-profile-photo_svg__d" fill="#fff">
          <use xlinkHref="#icon-profile-photo_svg__c" />
        </mask>
        <path
          fill="#FF1D46"
          d="M43.54 31.861H2.929c-1.565 0-2.833-1.188-2.833-2.655 0-.489.423-.885.944-.885.522 0 .945.396.945.885s.423.885.944.885h40.61c.523 0 .946-.396.946-.885V2.656c0-.49-.423-.886-.945-.886H2.929c-.521 0-.944.396-.944.885s-.423.885-.945.885c-.521 0-.944-.396-.944-.885C.096 1.19 1.364 0 2.929 0h40.61c1.566 0 2.835 1.189 2.835 2.655v26.551c0 1.467-1.269 2.655-2.834 2.655"
          mask="url(#icon-profile-photo_svg__d)"
        />
      </g>
      <g fill="#FF1D46">
        <path d="M16.064 26.508h34.963V5.378H16.064v21.13zm35.435 1.761H15.592c-.783 0-1.418-.591-1.418-1.32V4.938c0-.73.635-1.321 1.418-1.321h35.907c.783 0 1.418.59 1.418 1.32v22.01c0 .73-.635 1.321-1.418 1.321zM.945 17.704c-.522 0-.945-.394-.945-.88v-2.641c0-1.46 1.27-2.642 2.835-2.642H5.67c.522 0 .945.395.945.88 0 .487-.423.881-.945.881H2.835c-.522 0-.945.394-.945.88v2.642c0 .486-.423.88-.945.88M5.67 41.475H2.835C1.269 41.475 0 40.293 0 38.834v-2.641c0-.487.423-.88.945-.88s.945.393.945.88v2.64c0 .487.423.881.945.881H5.67c.522 0 .945.394.945.88 0 .487-.423.881-.945.881m34.962 0h-2.835c-.521 0-.944-.394-.944-.88 0-.487.423-.88.944-.88h2.835c.522 0 .945-.395.945-.881v-2.641c0-.487.424-.88.945-.88.522 0 .945.393.945.88v2.64c0 1.46-1.27 2.642-2.835 2.642m-29.293 0h-1.89c-.521 0-.944-.394-.944-.88 0-.487.423-.88.944-.88h1.89c.522 0 .945.393.945.88 0 .486-.423.88-.945.88m5.671 0h-1.89c-.523 0-.946-.394-.946-.88 0-.487.423-.88.945-.88h1.89c.522 0 .945.393.945.88 0 .486-.423.88-.945.88m5.67 0h-1.89c-.522 0-.945-.394-.945-.88 0-.487.423-.88.945-.88h1.89c.521 0 .944.393.944.88 0 .486-.423.88-.944.88m5.669 0h-1.89c-.522 0-.945-.394-.945-.88 0-.487.423-.88.945-.88h1.89c.522 0 .945.393.945.88 0 .486-.423.88-.945.88m5.67 0h-1.89c-.522 0-.945-.394-.945-.88 0-.487.423-.88.945-.88h1.89c.522 0 .945.393.945.88 0 .486-.423.88-.945.88M.945 33.551c-.522 0-.945-.394-.945-.88v-1.76c0-.487.423-.881.945-.881s.945.394.945.88v1.761c0 .486-.423.88-.945.88m0-5.282c-.522 0-.945-.394-.945-.88v-1.761c0-.486.423-.88.945-.88s.945.394.945.88v1.76c0 .487-.423.881-.945.881m0-5.282c-.522 0-.945-.394-.945-.88v-1.762c0-.486.423-.88.945-.88s.945.394.945.88v1.761c0 .487-.423.88-.945.88m41.577 10.565c-.521 0-.945-.394-.945-.88v-1.76c0-.487.424-.881.945-.881.522 0 .945.394.945.88v1.761c0 .486-.423.88-.945.88M11.339 13.302h-1.89c-.521 0-.944-.394-.944-.88s.423-.88.944-.88h1.89c.522 0 .945.394.945.88s-.423.88-.945.88" />
      </g>
      <g transform="translate(22 7)">
        <mask id="icon-profile-photo_svg__f" fill="#fff">
          <use xlinkHref="#icon-profile-photo_svg__e" />
        </mask>
        <path
          fill="#FF1D46"
          d="M6.62 10.7a1.04 1.04 0 0 0-1.086-.836.468.468 0 0 1-.125-.008c-.213-.043-.374-.264-.374-.514v-.379c0-.244.166-.464.378-.501a.47.47 0 0 1 .46.173.75.75 0 0 0 .379.26c.22.066.462.027.648-.102.335-.233.63-.517.879-.845a.521.521 0 0 0-.11-.737.542.542 0 0 0-.75.108 2.817 2.817 0 0 1-.418.444 1.542 1.542 0 0 0-.581-.305 11.93 11.93 0 0 1-.383-2.971c0-.807.253-1.374.752-1.686.39-.243.802-.252.801-.252.138 0 .27-.053.37-.146.013-.012 1.331-1.214 3.588-1.334 2.066-.11 3.661.403 4.613 1.483.958 1.088 1.278 2.76.931 4.851-.143-.005-.3.006-.457.031l-.504-1.189a.54.54 0 0 0-.63-.307c-.05.013-5 1.247-7.077-1.294a.542.542 0 0 0-.754-.081.521.521 0 0 0-.082.74c2.12 2.595 6.326 2.031 7.727 1.756l.39.92a.885.885 0 0 0 1.017.513c.174-.04.34-.041.402-.03.216.043.38.256.38.495v.385c0 .25-.161.471-.374.514a.462.462 0 0 1-.125.008 1.041 1.041 0 0 0-1.086.837c-.39 2.087-2.24 3.602-4.4 3.602s-4.01-1.515-4.4-3.602zm7.374 4.816l-2.975 2.559-2.974-2.56v-1.008c.865.54 1.888.85 2.974.85 1.087 0 2.11-.31 2.975-.85v1.009zM2.452 20l-.85-.835c.115-.127.243-.242.383-.344a.536.536 0 0 0 .12-.06c.156-.103.325-.19.5-.257l2.71-1.027L7.026 20H8.32a.495.495 0 0 1-.041-.053L6.192 16.87l.84-.825 3.203 2.756L9.015 20h1.527v-.01l.477-.47.477.47V20h1.528l-1.22-1.2 3.204-2.755.839.825-2.087 3.077a.486.486 0 0 1-.041.053h1.294l1.71-2.523 2.71 1.027c.38.145.707.374.964.661l-.849.835H22a3.503 3.503 0 0 0-2.181-2.479l-2.92-1.107-1.488-1.463a.538.538 0 0 0-.344-.153v-1.164a5.403 5.403 0 0 0 1.402-2.716 1.56 1.56 0 0 0 .376-.03c.713-.143 1.231-.794 1.231-1.546v-.385c0-.435-.18-.835-.472-1.12.473-2.532.083-4.595-1.133-5.975C15.295.526 13.4-.112 10.991.016 8.765.134 7.328 1.141 6.873 1.51c-.25.03-.674.116-1.103.369-.596.351-1.305 1.089-1.305 2.61 0 1.325.215 2.433.371 3.06a1.56 1.56 0 0 0-.873 1.415v.379c0 .752.518 1.403 1.231 1.547.125.025.251.035.376.029.202 1.05.7 1.98 1.402 2.716v1.164a.538.538 0 0 0-.344.153l-1.489 1.463-.749.284-.007-.012-2.202.835A3.503 3.503 0 0 0 0 20h2.452z"
          mask="url(#icon-profile-photo_svg__f)"
        />
      </g>
    </g>
  </svg>
);

export default SvgIconProfilePhoto;
