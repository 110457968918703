import React from 'react';

const SvgIconPintura = props => (
  <svg width={19} height={19} viewBox="0 0 40 40" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="#54575F" fillRule="nonzero">
        <path d="M34.62 13.154H13.473c-1.08 0-1.966.866-1.966 1.935v7.96c0 1.062.88 1.935 1.966 1.935h9.107a.412.412 0 00.413-.407.412.412 0 00-.413-.407h-9.107a1.137 1.137 0 01-1.146-1.127v-7.954c0-.623.513-1.128 1.146-1.128h21.14c.634 0 1.147.505 1.147 1.128v7.947c0 .623-.513 1.128-1.147 1.128h-.326a.412.412 0 00-.414.406c0 .223.187.407.414.407h.326a1.96 1.96 0 001.967-1.934v-7.954c.007-1.07-.88-1.935-1.96-1.935zm-23.947 4.472a.412.412 0 00.414-.406.412.412 0 00-.414-.407h-2.8c-.573 0-1.033.46-1.033 1.017v2.445c0 .564.467 1.017 1.033 1.017h2.8a.412.412 0 00.414-.407.412.412 0 00-.414-.406h-1.46v-2.866h1.46v.013zm-2.28 0v2.866h-.52a.209.209 0 01-.213-.21v-2.446c0-.118.093-.21.213-.21h.52zm29.647-2.642h-.627a.412.412 0 00-.413.406c0 .223.187.407.413.407h.627c.12 0 .213.092.213.21v1.226c0 .118-.093.21-.213.21h-.627a.412.412 0 00-.413.406c0 .223.187.407.413.407h.627c.56 0 1.027-.46 1.033-1.017V16c0-.564-.466-1.016-1.033-1.016z" />
        <path d="M31.593 18.938a.412.412 0 00-.52-.256.398.398 0 00-.26.505l4.714 14.485a.223.223 0 01-.027.19.222.222 0 01-.173.086h-6.76a1.16 1.16 0 01-1.08-.741l-3.494-9.6a1.97 1.97 0 00-1.853-1.28h-8.98a.412.412 0 00-.413.407c0 .223.186.407.413.407h8.973c.48 0 .914.302 1.08.741l3.487 9.6c.18.498.567.892 1.04 1.102l.78 1.154a1.97 1.97 0 001.633.859h1.554v.813c0 .623-.514 1.128-1.147 1.128H11.913a.412.412 0 00-.413.406c0 .223.187.407.413.407h18.66c1.08 0 1.967-.872 1.967-1.941v-.82h1.267c.853 0 1.6-.531 1.866-1.318l.234-.702a1.07 1.07 0 00.253-.236c.193-.268.247-.603.147-.904l-4.714-14.492zm.534 16.839c-.014 0-.02.007-.034.007h-1.94c-.38 0-.74-.184-.953-.499l-.353-.53h6.126l-.086.255c-.16.459-.594.767-1.087.767h-1.64c-.013.007-.02 0-.033 0zM6.013 18.656H1.347a.412.412 0 00-.414.406c0 .223.187.407.414.407h4.666a.412.412 0 00.414-.407.412.412 0 00-.414-.406zm-.093 1.862a.43.43 0 00-.313 0l-4.314 1.757a.395.395 0 00-.22.525c.06.15.214.25.38.25a.5.5 0 00.16-.027l4.314-1.764c.1-.04.18-.118.22-.216a.41.41 0 000-.309.427.427 0 00-.227-.216zm-4.633-4.675l4.306 1.75c.047.027.1.04.16.04.167 0 .32-.099.38-.25a.393.393 0 00-.22-.524L1.6 15.102a.43.43 0 00-.313 0 .4.4 0 000 .741zm16.226-4.636h.214v1.12c0 .224.186.407.413.407a.412.412 0 00.413-.406v-1.121h1.98v1.12c0 .224.187.407.414.407a.412.412 0 00.413-.406v-1.121h.207c.24 0 .473-.027.706-.073.04-.006.08-.026.127-.039a3.67 3.67 0 00.54-.164c.027-.013.047-.026.073-.04.18-.078.36-.17.527-.281a.289.289 0 01.047-.04c.166-.118.333-.242.48-.386.093-.092.16-.19.24-.289.06-.079.133-.144.186-.23.074-.11.134-.235.194-.354.04-.078.093-.157.133-.242.033-.079.047-.158.073-.236.12-.348.2-.715.2-1.108V4.072c0-.013-.006-.02-.006-.033V1.934A1.979 1.979 0 0023.107 0h-7.154c-1.08 0-1.966.866-1.966 1.934v5.81c.006 1.908 1.586 3.463 3.526 3.463zm6.74-7.541h-9.44V1.94c0-.623.514-1.128 1.147-1.128l.1-.006h7.047c.633 0 1.146.504 1.146 1.127v1.732zm0 4.078c0 1.463-1.213 2.656-2.7 2.656h-4.04c-1.486 0-2.7-1.193-2.7-2.656V4.48h9.44v3.265z" />
        <path d="M22.04 27.128c.007-.033.02-.066.02-.112V25.79a.412.412 0 00-.413-.406.412.412 0 00-.414.406v1.167l-.673 2.21a1.01 1.01 0 00.16.899c.2.262.5.413.827.413h2.8a.412.412 0 00.413-.407.412.412 0 00-.413-.406h-2.8a.222.222 0 01-.174-.086.222.222 0 01-.033-.183l.7-2.27zM21.247 9.37c1.08 0 1.966-.865 1.973-1.934V5.915a.412.412 0 00-.413-.407.412.412 0 00-.414.407v1.521c0 .623-.513 1.128-1.146 1.128a.412.412 0 00-.414.406c0 .223.187.4.414.4z" />
      </g>
      <path d="M0 0h40v39.344H0z" />
    </g>
  </svg>
);

export default SvgIconPintura;
