import React from 'react';

const SvgIconHeartfull = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-heartfull_svg__a"
        d="M10.972 18.996a.788.788 0 0 1-.358-.086C3.287 14.814.667 9.222.561 8.992v-.087A6.147 6.147 0 0 1 0 6.325 6.322 6.322 0 0 1 4.014.448a6.405 6.405 0 0 1 6.987 1.532 6.417 6.417 0 0 1 8.205-.868 6.302 6.302 0 0 1 2.206 7.89h-.058c-.6 1.591-3.867 6.502-9.996 9.936a.789.789 0 0 1-.386.058z"
      />
    </defs>
    <use fill="#f70505" transform="translate(1 3)" xlinkHref="#icon-heartfull_svg__a" />
  </svg>
);

export default SvgIconHeartfull;
