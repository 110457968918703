import React from 'react';

const SvgIconSuccess = props => (
  <svg width={140} height={158} viewBox="0 0 140 158" {...props}>
    <g fill="none">
      <path
        fill="#DCF7DC"
        d="M131.345 97.968a13.428 13.428 0 0 0 3.44-8.988c0-7.434-6.047-13.484-13.48-13.484H95.149c2.592-7.866 3.695-18.298 3.695-22.773v-4.49c0-9.91-8.064-17.976-17.97-17.976h-4.495a4.493 4.493 0 0 0-4.359 3.405l-2.446 9.795c-3.43 13.715-14.317 28.848-25.585 31.6-1.972-4.998-6.846-8.546-12.537-8.546H4.494A4.496 4.496 0 0 0 0 71.006v80.895a4.496 4.496 0 0 0 4.494 4.496h26.957c5.358 0 9.995-3.142 12.165-7.684l15.439 5.148a49.277 49.277 0 0 0 15.629 2.538h37.639c7.432 0 13.48-6.05 13.48-13.483 0-1.752-.336-3.43-.948-4.967 5.715-1.561 9.933-6.801 9.933-13.01 0-3.448-1.304-6.6-3.44-8.987a13.428 13.428 0 0 0 3.44-8.988 13.45 13.45 0 0 0-3.443-8.996z"
      />
      <g fill="#82F485" transform="translate(4.944 4.425)">
        <path d="M121.555 72.67h-26.16c2.592-7.867 3.695-18.3 3.695-22.774v-4.49c0-9.91-8.064-17.976-17.97-17.976h-4.495a4.493 4.493 0 0 0-4.358 3.405L69.82 40.63c-3.43 13.715-14.317 28.848-25.585 31.6-1.972-4.998-6.846-8.546-12.537-8.546H4.74A4.496 4.496 0 0 0 .247 68.18v80.895a4.496 4.496 0 0 0 4.494 4.496h26.957c5.358 0 9.995-3.142 12.165-7.684l15.44 5.148a49.277 49.277 0 0 0 15.628 2.538h37.639c7.432 0 13.48-6.049 13.48-13.483 0-1.752-.336-3.43-.948-4.966 5.715-1.562 9.933-6.802 9.933-13.01 0-3.449-1.304-6.601-3.44-8.988a13.428 13.428 0 0 0 3.44-8.988c0-3.45-1.304-6.601-3.44-8.988a13.428 13.428 0 0 0 3.44-8.988c-.003-7.442-6.048-13.491-13.48-13.491zM36.19 140.08a4.5 4.5 0 0 1-4.494 4.496H9.23V72.67h22.465a4.5 4.5 0 0 1 4.494 4.495v62.916zm76.38-40.447h8.986a4.5 4.5 0 0 1 4.494 4.495 4.5 4.5 0 0 1-4.494 4.495h-8.985a4.496 4.496 0 0 0 0 8.991h8.985a4.5 4.5 0 0 1 4.494 4.495 4.5 4.5 0 0 1-4.494 4.496h-8.985a4.496 4.496 0 0 0 0 8.99 4.5 4.5 0 0 1 4.494 4.496 4.5 4.5 0 0 1-4.494 4.495H74.93a40.34 40.34 0 0 1-12.79-2.077l-16.964-5.656v-55.62c7.047-1.339 13.946-5.805 20.173-13.112 6.081-7.135 11.01-16.596 13.188-25.314l1.595-6.39h.986c4.955 0 8.986 4.032 8.986 8.988v4.49c0 6.034-1.887 17.61-4.302 22.774h-9.178a4.496 4.496 0 0 0 0 8.99h44.928a4.5 4.5 0 0 1 4.494 4.496 4.5 4.5 0 0 1-4.494 4.495h-8.986a4.496 4.496 0 0 0-4.494 4.495 4.496 4.496 0 0 0 4.497 4.488z" />
        <ellipse cx={22.712} cy={131.093} rx={4.494} ry={4.495} />
        <path d="M85.61 18.442a4.494 4.494 0 0 0 4.495-4.495V4.659a4.494 4.494 0 1 0-8.988 0v9.288a4.497 4.497 0 0 0 4.494 4.495zM60.195 26.34a4.494 4.494 0 0 0 6.354-6.357l-6.354-6.354a4.494 4.494 0 0 0-6.355 6.354l6.355 6.356zm50.832 0l6.354-6.357a4.494 4.494 0 0 0-6.354-6.354l-6.355 6.354a4.494 4.494 0 0 0 6.355 6.356z" />
      </g>
      <g fill="#1BDB10">
        <path d="M132.144 95.172a13.428 13.428 0 0 0 3.44-8.988c0-7.434-6.048-13.483-13.48-13.483H95.947c2.592-7.866 3.695-18.299 3.695-22.774v-4.49c0-9.91-8.064-17.976-17.971-17.976h-4.494a4.493 4.493 0 0 0-4.359 3.405l-2.446 9.795c-3.43 13.715-14.317 28.849-25.585 31.6-1.973-4.998-6.846-8.546-12.538-8.546H5.29a4.496 4.496 0 0 0-4.494 4.496v80.895A4.496 4.496 0 0 0 5.29 153.6h26.957c5.358 0 9.995-3.142 12.165-7.684l15.44 5.149a49.277 49.277 0 0 0 15.628 2.538h37.639c7.432 0 13.48-6.05 13.48-13.484 0-1.752-.336-3.43-.948-4.966 5.715-1.562 9.933-6.802 9.933-13.01 0-3.449-1.304-6.601-3.44-8.988a13.428 13.428 0 0 0 3.44-8.988c0-3.457-1.301-6.609-3.44-8.996zm-95.403 44.94a4.5 4.5 0 0 1-4.494 4.496H9.784V72.7H32.25a4.5 4.5 0 0 1 4.494 4.495v62.916h-.002zm76.378-40.447h8.985a4.5 4.5 0 0 1 4.495 4.495 4.5 4.5 0 0 1-4.495 4.496h-8.985a4.496 4.496 0 0 0 0 8.99h8.985a4.5 4.5 0 0 1 4.495 4.496 4.5 4.5 0 0 1-4.495 4.495h-8.985a4.496 4.496 0 0 0 0 8.99 4.5 4.5 0 0 1 4.494 4.496 4.5 4.5 0 0 1-4.494 4.495H75.48c-4.353 0-8.657-.697-12.79-2.077l-16.964-5.656V81.262c7.047-1.338 13.946-5.805 20.173-13.111 6.081-7.135 11.01-16.597 13.188-25.314l1.595-6.39h.986c4.955 0 8.986 4.032 8.986 8.988v4.49c0 6.034-1.887 17.609-4.302 22.773h-9.178a4.496 4.496 0 0 0 0 8.99h44.928a4.5 4.5 0 0 1 4.494 4.496 4.5 4.5 0 0 1-4.494 4.496h-8.983a4.496 4.496 0 0 0-4.494 4.495 4.491 4.491 0 0 0 4.494 4.49z" />
        <path d="M27.755 131.124a4.494 4.494 0 1 1-8.988 0 4.494 4.494 0 1 1 8.988 0zM86.162.195a4.494 4.494 0 0 0-4.494 4.496v9.287a4.494 4.494 0 1 0 8.989 0V4.69A4.494 4.494 0 0 0 86.162.195zM67.101 20.014l-6.355-6.354a4.494 4.494 0 0 0-6.354 6.354l6.354 6.357a4.494 4.494 0 0 0 6.355-6.357zm50.832-6.354a4.494 4.494 0 0 0-6.355 0l-6.354 6.354a4.494 4.494 0 0 0 6.355 6.356l6.354-6.356a4.494 4.494 0 0 0 0-6.354z" />
      </g>
    </g>
  </svg>
);

export default SvgIconSuccess;
