import React from 'react';

const SvgIconBoatSmall = props => (
  <svg width={32} height={25} viewBox="0 0 32 25" {...props}>
    <defs>
      <path
        id="icon-boat-small_svg__a"
        d="M19.811 12.987c-.068.034-1.247.979-3.054.979H4.37c-1.343 0-2.553-.808-3.187-2.07l-.246-1.034v-3.62h.605c1.94 0 3.935.497 5.748 1.497a.434.434 0 0 0 .209.054H12c.259 0 .469-.232.469-.517V4.138h2.486c2.59 0 5.047 1.367 6.598 3.62h-.834c-.26 0-.469.232-.469.518 0 .285.21.517.469.517h1.45c.334.653.591 1.346.77 2.069l-1.492 1.034c-.561.347-1.103.722-1.636 1.09M2.687 4.714a.87.87 0 0 1 .778-.214l4.219.931c.436.097.753.523.753 1.014V7.76H7.61a12.888 12.888 0 0 0-5.266-1.525v-.72c0-.312.125-.603.343-.8m21.304 6.565v-.001c-.868-4.792-4.679-8.175-9.036-8.175h-1.26L12.42.286c-.116-.255-.397-.359-.629-.231L8.978 1.606c-.232.128-.325.439-.21.695.116.255.397.359.63.23l2.392-1.32.858 1.892H12c-.259 0-.469.232-.469.518v4.138H9.375V6.444c0-.983-.634-1.836-1.508-2.028L3.65 3.485a1.74 1.74 0 0 0-1.556.428 2.15 2.15 0 0 0-.687 1.6v.694H.47c-.26 0-.469.232-.469.517v4.655l.001.028.001.02a.556.556 0 0 0 .022.116C.649 13.61 2.395 15 4.371 15h12.386c2.046 0 3.33-1.027 3.553-1.137.935-.647 1.901-1.316 2.92-1.784l.482-.223a.532.532 0 0 0 .28-.577"
      />
    </defs>
    <use
      fill="currentColor"
      fillRule="evenodd"
      transform="translate(4 10)"
      xlinkHref="#icon-boat-small_svg__a"
    />
  </svg>
);

export default SvgIconBoatSmall;
