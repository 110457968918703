import SvgIconAccount from './components/IconAccount';
import SvgIconAdd from './components/IconAdd';
import SvgIconAddBold from './components/IconAddBold';
import SvgIconAlinhamento from './components/IconAlinhamento';
import SvgIconArrowDown from './components/IconArrowDown';
import SvgIconArrowDownBold from './components/IconArrowDownBold';
import SvgIconArrowleft from './components/IconArrowleft';
import SvgIconArrowLeftBold from './components/IconArrowLeftBold';
import SvgIconArrowRight from './components/IconArrowRight';
import SvgIconArrowRightBold from './components/IconArrowRightBold';
import SvgIconArrowUp from './components/IconArrowUp';
import SvgIconArrowUpBold from './components/IconArrowUpBold';
import SvgIconBack from './components/IconBack';
import SvgIconBoating from './components/IconBoating';
import SvgIconBoatOff from './components/IconBoatOff';
import SvgIconBoatOn from './components/IconBoatOn';
import SvgIconBoatSmall from './components/IconBoatSmall';
import SvgIconBus from './components/IconBus';
import SvgIconBusOff from './components/IconBusOff';
import SvgIconBusOn from './components/IconBusOn';
import SvgIconBusPhotos from './components/IconBusPhotos';
import SvgIconBusSmall from './components/IconBusSmall';
import SvgIconButtonFloaterFilters from './components/IconButtonFloaterFilters';
import SvgIconBuy from './components/IconBuy';
import SvgIconBuy2 from './components/IconBuy2';
import SvgIconCambagemSuspensao from './components/IconCambagemSuspensao';
import SvgIconCar from './components/IconCar';
import SvgIconCarOff from './components/IconCarOff';
import SvgIconCarOn from './components/IconCarOn';
import SvgIconCarTest from './components/IconCarTest';
import SvgIconCellphone from './components/IconCellphone';
import SvgIconCheck from './components/IconCheck';
import SvgIconCircleFacebook from './components/IconCircleFacebook';
import SvgIconCircleInstagram from './components/IconCircleInstagram';
import SvgIconTikTok from './components/IconCircleTiktok';
import SvgIconCircleTwitter from './components/IconCircleTwitter';
import SvgIconYoutube from './components/IconCircleYoutube';
import SvgIconClose from './components/IconClose';
import SvgIconCloseChip from './components/IconCloseChip';
import SvgIconConfiguration from './components/IconConfiguration';
import SvgIconCopy from './components/IconCopy';
import SvgIconCoverPhoto from './components/IconCoverPhoto';
import SvgIconCreditCard from './components/IconCreditCard';
import SvgIconCurrency from './components/IconCurrency';
import SvgIconDelete from './components/IconDelete';
import SvgIconDollar from './components/IconDollar';
import SvgIconDrop from './components/IconDrop';
import SvgIconEdit from './components/IconEdit';
import SvgIconEletrica from './components/IconEletrica';
import SvgIconEmptyProposal from './components/IconEmptyProposal';
import SvgIconError from './components/IconError.js';
import SvgIconFacebook from './components/IconFacebook';
import SvgIconFarm from './components/IconFarm';
import SvgIconFavoriteWhitebgOff from './components/IconFavoriteWhitebgOff';
import SvgIconFilter from './components/IconFilter';
import SvgIconFunilaria from './components/IconFunilaria';
import SvgIconGarage from './components/IconGarage';
import SvgIconGear from './components/IconGear';
import SvgIconGraphic from './components/IconGraphic.js';
import SvgIconGraphicPizza from './components/IconGraphicPizza.js';
import SvgIconHeart from './components/IconHeart';
import SvgIconHeartfull from './components/IconHeartfull';
import SvgIconHideMe from './components/IconHideMe';
import SvgIconHome from './components/IconHome.js';
import SvgIconHourglass from './components/IconHourglass';
import SvgIconInstagram from './components/IconInstagram';
import SvgIconKm from './components/IconKm';
import SvgIconLavaJato from './components/IconLavaJato';
import SvgIconList from './components/IconList';
import SvgIconLocation from './components/IconLocation';
import SvgIconLock from './components/IconLock';
import SvgIconLogar from './components/IconLogar';
import SvgIconLogo from './components/IconLogo';
import SvgIconLogoAutoscarInline from './components/IconLogoAutoscarInline';
import SvgIconLogoAutoscarInlineWhite from './components/IconLogoAutoscarInlineWhite';
import SvgIconLogout from './components/IconLogout';
import SvgIconLogoWhite from './components/IconLogoWhite';
import SvgIconLookmaps from './components/IconLookmaps';
import SvgIconMail from './components/IconMail';
import SvgIconMecanica from './components/IconMecanica';
import SvgIconMenu from './components/IconMenu';
import SvgIconMessenger from './components/IconMessenger';
import SvgIconMoney from './components/IconMoney.js';
import SvgIconMoto from './components/IconMoto';
import SvgIconMotorcycle from './components/IconMotorcycle';
import SvgIconMotorcycleOff from './components/IconMotorcycleOff';
import SvgIconMotorcycleOn from './components/IconMotorcycleOn';
import SvgIconMySales from './components/IconMySales';
import SvgIconName from './components/IconName';
import SvgIconOne from './components/IconOne';
import SvgIconOrders from './components/IconOrders';
import SvgIconPagseguro from './components/IconPagseguro.js';
import SvgIconPart from './components/IconPart';
import SvgIconParts from './components/IconParts';
import SvgIconPartsRegister from './components/IconPartsRegister';
import SvgIconPartsSettings from './components/IconPartsSettings';
import SvgIconPasswordshow from './components/IconPasswordshow';
import SvgIconPause from './components/IconPause';
import SvgIconPerformance from './components/IconPerformance';
import SvgIconPhone from './components/IconPhone';
import SvgIconPickup from './components/IconPickup';
import SvgIconPintura from './components/IconPintura';
import SvgIconPlay from './components/IconPlay';
import SvgIconPlus from './components/IconPlus';
import SvgIconPrint from './components/IconPrint';
import SvgIconPrivateCar from './components/IconPrivateCar';
import SvgIconProfilePhoto from './components/IconProfilePhoto';
import SvgIconPurpleError from './components/IconPurpleError';
import SvgIconSafezone from './components/IconSafezone.js';
import SvgIconSales from './components/IconSales';
import SvgIconSearch from './components/IconSearch';
import SvgIconSearchFilter from './components/IconSearchFilter';
import SvgIconSecurity from './components/IconSecurity';
import SvgIconSell from './components/IconSell';
import SvgIconServices from './components/IconServices';
import SvgIconShare from './components/IconShare';
import SvgIconShowCase from './components/IconShowCase';
import SvgIconShowMe from './components/IconShowMe';
import SvgIconShowPhone from './components/IconShowPhone';
import SvgIconShowWhatsapp from './components/IconShowWhatsapp';
import SvgIconStar from './components/IconStar.js';
import SvgIconStep from './components/IconStep';
import SvgIconStepcheck from './components/IconStepcheck';
import SvgIconStepedit from './components/IconStepedit';
import SvgIconStepError from './components/IconStepError';
import SvgIconStore from './components/IconStore';
import SvgIconSuccess from './components/IconSuccess.js';
import SvgIconSupport from './components/IconSupport';
import SvgIconThree from './components/IconThree';
import SvgIconTractorOff from './components/IconTractorOff';
import SvgIconTractorOn from './components/IconTractorOn';
import SvgIconTractorSmall from './components/IconTractorSmall';
import SvgIconTrocaDeOleo from './components/IconTrocaDeOleo';
import SvgIconTruck from './components/IconTruck';
import SvgIconTruckOff from './components/IconTruckOff';
import SvgIconTruckOn from './components/IconTruckOn';
import SvgIconTruckSmall from './components/IconTruckSmall';
import SvgIconTwitter from './components/IconTwitter';
import SvgIconTwo from './components/IconTwo';
import SvgIconUploadMoto from './components/IconUploadMoto';
import SvgIconUploadNautical from './components/IconUploadNautical';
import SvgIconUploadTractor from './components/IconUploadTractor';
import SvgIconUploadTruck from './components/IconUploadTruck';
import SvgIconUtilityOff from './components/IconUtilityOff';
import SvgIconUtilityOn from './components/IconUtilityOn';
import SvgIconVanOff from './components/IconVanOff';
import SvgIconVanOn from './components/IconVanOn';
import SvgIconView from './components/IconView';
import SvgIconWarning from './components/IconWarning';
import SvgIconWhatsapp from './components/IconWhatsapp';
import SvgIconWhatsappLine from './components/IconWhatsappLine';

interface IIcons {
  [key: string]: any;
}

export const Icons: IIcons = {
  SvgIconAdd,
  SvgIconArrowUp,
  SvgIconArrowUpBold,
  SvgIconArrowDown,
  SvgIconArrowDownBold,
  SvgIconArrowRight,
  SvgIconArrowleft,
  SvgIconCarTest,
  SvgIconClose,
  SvgIconCloseChip,
  SvgIconCopy,
  SvgIconGraphic,
  SvgIconGraphicPizza,
  SvgIconLogo,
  SvgIconStepcheck,
  SvgIconStepError,
  SvgIconStepedit,
  SvgIconStep,
  SvgIconDrop,
  SvgIconHeart,
  SvgIconHeartfull,
  SvgIconHideMe,
  SvgIconLocation,
  SvgIconLock,
  SvgIconShowMe,
  SvgIconMail,
  SvgIconMoney,
  SvgIconName,
  SvgIconPhone,
  SvgIconPause,
  SvgIconPlay,
  SvgIconGear,
  SvgIconStar,
  SvgIconOne,
  SvgIconTwo,
  SvgIconThree,
  SvgIconCheck,
  SvgIconAddBold,
  SvgIconSuccess,
  SvgIconError,
  SvgIconPlus,
  SvgIconTractorOn,
  SvgIconTractorOff,
  SvgIconTruckOn,
  SvgIconTruckOff,
  SvgIconCarOn,
  SvgIconCarOff,
  SvgIconMotorcycleOn,
  SvgIconMotorcycleOff,
  SvgIconBoatOn,
  SvgIconBoatOff,
  SvgIconBusOn,
  SvgIconBusOff,
  SvgIconUtilityOn,
  SvgIconUtilityOff,
  SvgIconVanOn,
  SvgIconVanOff,
  SvgIconGarage,
  SvgIconLogar,
  SvgIconCar,
  SvgIconMotorcycle,
  SvgIconPickup,
  SvgIconBoating,
  SvgIconStore,
  SvgIconServices,
  SvgIconFavoriteWhitebgOff,
  SvgIconHome,
  SvgIconLogoAutoscarInline,
  SvgIconFacebook,
  SvgIconInstagram,
  SvgIconTwitter,
  SvgIconLogoWhite,
  SvgIconCircleInstagram,
  SvgIconTikTok,
  SvgIconYoutube,
  SvgIconCircleFacebook,
  SvgIconCircleTwitter,
  SvgIconSearch,
  SvgIconBuy,
  SvgIconSell,
  SvgIconShowCase,
  SvgIconLogoAutoscarInlineWhite,
  SvgIconTruck,
  SvgIconBus,
  SvgIconShowPhone,
  SvgIconDollar,
  SvgIconShare,
  SvgIconBack,
  SvgIconWarning,
  SvgIconMenu,
  SvgIconEdit,
  SvgIconLogout,
  SvgIconPagseguro,
  SvgIconSafezone,
  SvgIconDelete,
  SvgIconLookmaps,
  SvgIconPurpleError,
  SvgIconWhatsapp,
  SvgIconWhatsappLine,
  SvgIconShowWhatsapp,
  SvgIconMessenger,
  SvgIconFarm,
  SvgIconParts,
  SvgIconBoatSmall,
  SvgIconBusSmall,
  SvgIconTruckSmall,
  SvgIconTractorSmall,
  SvgIconPart,
  SvgIconArrowRightBold,
  SvgIconArrowLeftBold,
  SvgIconHourglass,
  SvgIconPrint,
  SvgIconPasswordshow,
  SvgIconView,
  SvgIconConfiguration,
  SvgIconList,
  SvgIconFilter,
  SvgIconCellphone,
  SvgIconSearchFilter,
  SvgIconButtonFloaterFilters,
  SvgIconProfilePhoto,
  SvgIconCoverPhoto,
  SvgIconAccount,
  SvgIconBuy2,
  SvgIconSales,
  SvgIconSecurity,
  SvgIconSupport,
  SvgIconEmptyProposal,
  SvgIconMoto,
  SvgIconUploadMoto,
  SvgIconUploadTruck,
  SvgIconKm,
  SvgIconCurrency,
  SvgIconBusPhotos,
  SvgIconUploadNautical,
  SvgIconUploadTractor,
  SvgIconAlinhamento,
  SvgIconCambagemSuspensao,
  SvgIconEletrica,
  SvgIconFunilaria,
  SvgIconLavaJato,
  SvgIconMecanica,
  SvgIconPerformance,
  SvgIconPintura,
  SvgIconTrocaDeOleo,
  SvgIconPartsSettings,
  SvgIconPartsRegister,
  SvgIconPrivateCar,
  SvgIconCreditCard,
  SvgIconMySales,
  SvgIconOrders,
};
