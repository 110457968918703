import React from 'react';

const SvgIconAdd = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-add_svg__a"
        d="M11.916 11.916v9.174a.91.91 0 0 1-.91.91.898.898 0 0 1-.91-.91v-9.174H.91a.91.91 0 1 1 0-1.82h9.186V.91a.91.91 0 0 1 1.82 0v9.186h9.174a.91.91 0 1 1 0 1.82h-9.174z"
      />
    </defs>
    <use
      fill="#54575F"
      fillRule="evenodd"
      transform="translate(1 1)"
      xlinkHref="#icon-add_svg__a"
    />
  </svg>
);

export default SvgIconAdd;
