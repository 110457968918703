import React from 'react';

const SvgIconTruckSmall = props => (
  <svg width={32} height={25} viewBox="0 0 32 25" {...props}>
    <defs>
      <path
        id="icon-truck-small_svg__a"
        d="M24.938 6.658l-1.711-3.035C22.64 2.598 22.086 2 20.977 1.9a.43.43 0 0 0-.114-.016h-3.424v-.95c0-.515-.4-.934-.893-.934H.893C.4 0 0 .42 0 .934v11.698c0 .515.4.934.893.934h.931c.214 1.325 1.317 2.337 2.642 2.337 1.326 0 2.429-1.012 2.642-2.337h10.808c.214 1.325 1.317 2.337 2.642 2.337 1.326 0 2.429-1.012 2.642-2.337h1.354a.457.457 0 0 0 .446-.467V6.895a.484.484 0 0 0-.062-.237zm-1.099-.11h-4.1a.43.43 0 0 0-.092-.01.467.467 0 0 1-.455-.476V4.19h3.317l1.33 2.358zM.893.934h15.653v11.698H7.108c-.213-1.325-1.316-2.337-2.642-2.337-1.325 0-2.429 1.012-2.642 2.337H.893V.934zM4.466 14.97c-.985 0-1.787-.839-1.787-1.87 0-1.031.802-1.87 1.787-1.87.985 0 1.787.839 1.787 1.87 0 1.031-.802 1.87-1.787 1.87zm16.092 0c-.985 0-1.787-.839-1.787-1.87 0-1.031.802-1.87 1.787-1.87.986 0 1.787.839 1.787 1.87 0 1.031-.801 1.87-1.787 1.87zm0-4.674c-1.325 0-2.428 1.012-2.642 2.337h-.477V2.818h3.185c.57 0 .925.125 1.239.437h-3.118a.457.457 0 0 0-.446.468v2.339c0 .739.546 1.347 1.238 1.406a.4.4 0 0 0 .11.014h4.46v5.15H23.2c-.213-1.325-1.316-2.337-2.642-2.337z"
      />
    </defs>
    <use fill="currentColor" transform="translate(4 9)" xlinkHref="#icon-truck-small_svg__a" />
  </svg>
);

export default SvgIconTruckSmall;
