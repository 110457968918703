import React from 'react';

const SvgIconPagseguro = props => (
  <svg width={115} height={24} viewBox="0 0 115 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#1C1B19"
        d="M34.426 12.891c1.322-1.11 1.308-3.124.4-3.703-.907-.578-2.959-.117-3.627 1.539-.668 1.655-.722 2.664-1.088 3.565.765-.057 2.993-.291 4.315-1.4zm-8.65 6.376c1.298-3.949 2.534-7.957 3.467-12.09.727 0 1.558.017 2.285.017l-.275 1.56c1.848-1.2 3.064-1.511 4.275-1.412 4.38.359 2.025 6.124-.29 7.51-1.357.813-3.197 1.176-5.738.814l-.899 3.601h-2.824zm16.476-9.123c-1.15 1.11-1.137 3.124-.348 3.703.79.578 2.574.117 3.155-1.538.582-1.656.629-2.665.947-3.565-.665.056-2.604.29-3.754 1.4zm4.935 5.738c-.824 0-1.544.017-2.368.017l.153-1.788c-1.693 1.603-4.438 2.663-5.697 1.152-1.725-2.361-.091-5.432 1.853-6.694 2.158-1.4 5.023-1.327 7.878-1.388-1.022 2.846-1.709 5.764-1.82 8.701zm5.279-5.738c-1.15 1.11-1.137 3.124-.347 3.703.79.578 2.574.117 3.155-1.538.581-1.656.629-2.665.947-3.565-.666.056-2.604.29-3.755 1.4zm-4.34 9.19l.414-2.207c1.84.597 3.438.885 4.638.281.934-.47 1.628-1.478 2.01-3.297-1.694 1.603-4.44 2.663-5.698 1.152-1.726-2.361-.092-5.432 1.853-6.694 2.158-1.4 5.023-1.327 7.878-1.388-.885 2.461-.9 6.325-2.075 9.073-.62 1.452-1.66 2.383-2.907 2.917-1.823.78-4.084.708-6.113.163z"
      />
      <path
        fill="#9DC73D"
        d="M80.75 10.144c-1.15 1.11-1.137 3.124-.348 3.703.79.578 2.575.117 3.156-1.538.581-1.656.629-2.665.947-3.565-.666.056-2.604.29-3.755 1.4zm-21.092 3.57c-.275.594-.583 1.224-.857 1.82 3.348.73 5.912.289 6.924-.79.988-1.221.822-2.603-.891-3.607-.366-.254-1.398-.767-1.372-1.374.049-1.38 2.143-1.052 3.429-.652l.41-1.89c-.898-.209-2.039-.27-2.956-.18a5.378 5.378 0 0 0-3.247 1.52c-.723.752-.872 1.766-.607 2.383.318.74 1.349 1.05 1.875 1.36.88.41 1.404.72 1.255 1.345-.125.525-.763.954-1.904.75-.71-.127-1.537-.38-2.059-.686zm11.02-2.886c.414-.917 1.114-1.845 2.537-2.026 1.12-.11 1.24 1.074.582 1.529-1.196.516-1.33-.191-3.119.497zm4.644 3.057c-.068.572-.274 1.144-.342 1.717-2.662.366-5.518.984-6.993-1.099-1.526-2.948.675-5.649 2.605-6.663 3.388-1.523 5.742-.747 6.239.756 1.004 2.636-2.102 4.375-6.582 3.846-.467 2.292 1.87 2.258 5.073 1.443zm14.055-6.732l2.467.069c-.434 1.831-1.143 3.594-1.576 5.426-.644 2.229 2.009 1.997 3.153.618 1.019-1.795 1.475-3.948 2.126-6.113h2.467c-.931 2.862-1.743 5.724-2.125 8.586-.731-.045-1.6-.091-2.33-.137l.068-1.374c-2.473 2.276-6.543 2.877-6.033-.962.292-2.038 1.117-4.075 1.783-6.113zm8.02 8.449c1.103-2.806 1.845-6.386 2.4-8.38h1.988l-.07 1.786c.87-1.202 2.446-1.866 4.046-1.786l-.754 2.267c-1.989-.262-3.39 1.332-4.166 3.967a16.16 16.16 0 0 0-.47 2.203l-2.974-.057zm10.147-7.316c-2.514 1.688-3.522 4.534-2.251 6.354 1.271 1.821 4.34 1.929 6.854.24 2.515-1.687 3.523-4.532 2.252-6.353-1.272-1.82-4.34-1.928-6.855-.24zm.724 2.59c-.812 1.454-.71 2.996.227 3.444.938.448 2.356-.368 3.169-1.822.812-1.455.71-2.997-.228-3.445-.938-.447-2.356.369-3.168 1.823zM76.41 19.334l.414-2.207c1.84.597 3.438.885 4.638.281.934-.47 1.628-1.478 2.009-3.297-1.693 1.603-4.438 2.663-5.697 1.152-1.726-2.361-.091-5.432 1.852-6.694 2.158-1.4 5.024-1.327 7.879-1.388-.885 2.461-.901 6.325-2.075 9.073-.62 1.452-1.66 2.383-2.907 2.917-1.823.78-4.085.708-6.113.163z"
      />
      <path
        fill="#1C1B19"
        d="M2.783 4.123C1.926 5.133 1.28 6.28.807 7.537a11.337 11.337 0 0 0-.762 4.08c0 1.622.346 3.125.94 4.545.591 1.409 1.4 2.62 2.464 3.685A11.586 11.586 0 0 0 7.1 22.318a11.226 11.226 0 0 0 9.043 0 11.756 11.756 0 0 0 3.65-2.47c1.044-1.048 1.848-2.284 2.426-3.686.608-1.435.951-2.923.951-4.545 0-1.431-.267-2.774-.762-4.08a12.173 12.173 0 0 0-1.976-3.414 11.19 11.19 0 0 0-2.955-2.497C15.723.61 13.913.004 11.815.004h-.3c-2.071 0-3.968.594-5.751 1.622a11.146 11.146 0 0 0-2.98 2.497m104.192 15.723c.294 0 .527.095.7.304.177.199.271.456.271.796 0 .314-.094.585-.27.773-.174.196-.407.29-.7.29-.29 0-.513-.09-.686-.29-.188-.188-.27-.459-.27-.773 0-.34.082-.597.27-.796.173-.21.397-.304.685-.304zm0-1.916c-.429 0-.825.068-1.201.22-.372.153-.7.362-.978.644-.28.282-.501.597-.643.959a3.085 3.085 0 0 0-.219 1.193c0 .408.068.806.22 1.171.14.347.361.677.642.959.293.267.617.488.993.64a3.304 3.304 0 0 0 2.393-.001c.36-.151.696-.372.977-.64.292-.28.502-.61.654-.973.151-.361.223-.747.223-1.156 0-.43-.072-.828-.223-1.193a2.636 2.636 0 0 0-.643-.959 2.925 2.925 0 0 0-.988-.644 3.159 3.159 0 0 0-1.207-.22zm-3.986 4.245a6.15 6.15 0 0 0 .067-1.02v-3.099h-2.053v3.125c0 .35-.036.596-.13.749-.093.14-.23.209-.444.209-.209 0-.349-.069-.44-.21-.083-.13-.13-.386-.13-.748v-3.125h-2.042v3.1c-.011.433.025.763.072 1.02.043.267.138.476.242.654.235.36.548.63.945.817.387.199.841.292 1.353.292.516 0 .96-.093 1.354-.292.397-.188.715-.457.934-.817a2.1 2.1 0 0 0 .271-.655m9.965-4.119h-2.055v5.732h4.05V21.93h-1.995v-3.874"
      />
      <path
        fill="#E8A33F"
        d="M19.333 4.219C17.9 2.475 16.047 1.352 13.76.919 11.514.494 9.387.833 7.373 1.927a9.2 9.2 0 0 0-2.615 2.167 8.917 8.917 0 0 0-1.637 3.017 8.788 8.788 0 0 0-.338 3.563c.301-.763.762-1.462 1.309-2.13.79-.944 1.768-1.65 2.86-2.169a9.071 9.071 0 0 1 3.527-.791c1.25 0 2.465.244 3.591.735a8.37 8.37 0 0 1 3.106 2.197c.33.33.604.725.875 1.121.395 0 .79.028 1.13.124.884.244 1.581.696 2.126 1.375l.027-.434a9.181 9.181 0 0 0-.355-3.412 9.255 9.255 0 0 0-1.646-3.072M2.537 15.19c.15.915.424 1.791.883 2.592a7.858 7.858 0 0 0 1.733 2.16l.367.302c1.252.858 2.586 1.47 4.047 1.772.941.178 1.89.122 2.803-.094a7.67 7.67 0 0 0 2.738-1.188 3.762 3.762 0 0 1-2.043-1.245l-.215-.245a11.274 11.274 0 0 1-3.463-.15c-1.47-.34-2.738-.953-3.924-1.8a10.277 10.277 0 0 1-2.776-3.234l-.178-.369a8.883 8.883 0 0 0 .028 1.5"
      />
      <path
        fill="#C74439"
        d="M13.366 7.508c-1.063-.462-2.222-.67-3.405-.67-1.158.028-2.25.274-3.256.763a7.434 7.434 0 0 0-2.613 1.982c-.52.64-.943 1.338-1.215 2.073a9.317 9.317 0 0 0 1.035 2.62 9.438 9.438 0 0 0 2.737 2.988c1.092.792 2.344 1.311 3.678 1.611.817.152 1.675.219 2.523.18a4.612 4.612 0 0 1-.64-1.79c-.114-.678-.084-1.348.036-2.075.15-.886.48-1.706.94-2.47a7.74 7.74 0 0 1 1.882-2.017 7.553 7.553 0 0 1 1.526-.849l-.274-.31c-.855-.876-1.825-1.584-2.954-2.036"
      />
      <path
        fill="#E18239"
        d="M21.494 12.72l-.27-.461c-.397 1.376-1.132 2.593-2.071 3.696a8.26 8.26 0 0 1-1.063 2.498 8.434 8.434 0 0 1-1.863 2.007 3.878 3.878 0 0 0 1.553-.245c.487-.18.94-.452 1.4-.821a12.642 12.642 0 0 0 1.676-1.95l.084-.122c.216-.454.434-.914.582-1.34a5.53 5.53 0 0 0 .275-1.337c.028-.463.028-.86-.056-1.255-.067-.244-.16-.462-.247-.67"
      />
      <path
        fill="#D36438"
        d="M1.473 7.356c-.395 1.096-.61 2.253-.666 3.508-.028 1.61.271 3.167.885 4.63.298.695.668 1.374 1.117 2.016.436.603.914 1.188 1.459 1.696-1.184-1.31-1.883-2.828-2.096-4.533a11.219 11.219 0 0 1-.03-1.707c-.394-1.105-.61-2.196-.61-3.328 0-1.064.186-2.101.517-3.168a12.3 12.3 0 0 1 1.035-2.071c-.7.914-1.213 1.893-1.611 2.957m20.776.642a12.162 12.162 0 0 0-1.093-2.536 9.858 9.858 0 0 0-1.673-2.1 9.241 9.241 0 0 0-2.098-1.651c.666.46 1.277 1.007 1.824 1.62.856.972 1.496 2.009 1.89 3.169a9.381 9.381 0 0 1 .481 3.686c-.027.396-.086.763-.15 1.159.15.244.31.546.433.857.3.887.356 1.829.206 2.772.461-1.309.695-2.688.668-4.11-.057-.98-.207-1.95-.488-2.866"
      />
      <path
        fill="#D36438"
        d="M18.662 10.892c.302.64.49 1.34.574 2.036.097.707.123 1.376.067 2.046l-.123.67a8.894 8.894 0 0 0 1.883-3.631c-.207-.206-.424-.424-.67-.602-.489-.34-1.06-.519-1.731-.519"
      />
      <path
        fill="#C74439"
        d="M16.839 18.933c.367-.52.667-1.092.884-1.669-1.225.877-2.559 1.49-4.017 1.79.207.34.487.615.789.831.302.207.64.359 1.034.481a8.23 8.23 0 0 0 1.31-1.433m-6.118 3.261l1.31.066c1.129-.027 2.19-.244 3.227-.584a10.98 10.98 0 0 0 2.739-1.367c-.52.246-1.065.425-1.647.452-.18.037-.397.037-.603.037-1.494.973-3.173 1.435-5.026 1.396"
      />
      <path
        fill="#FAE542"
        d="M17.967 13.362a8.373 8.373 0 0 0-.855-2.225c-.698.208-1.309.576-1.883 1.065a6.602 6.602 0 0 0-1.523 1.887c-.34.733-.546 1.431-.612 2.166-.084.697 0 1.34.188 1.952.084.206.15.395.273.575.762-.084 1.457-.302 2.154-.603a8.15 8.15 0 0 0 2.041-1.159l.057-.039c.123-.453.217-.914.283-1.368a8.34 8.34 0 0 0-.123-2.251"
      />
    </g>
  </svg>
);

export default SvgIconPagseguro;
