import React from 'react';

const SvgIconParts = props => (
  <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
    <defs>
      <path
        id="icon-parts_svg__a"
        d="M15.134 11.635a.43.43 0 0 0-.428.431v2.07H8.479l-2.697-2.273a.426.426 0 0 0-.275-.1H3.105V4.076h1.7a.43.43 0 0 0 .429-.432v-.798h7.03v2.072a.43.43 0 0 0 .43.432h2.012v1.11a.43.43 0 0 0 .428.432h1.844a.43.43 0 0 0 .429-.432V5.162h.938l.844 1.977-.02 4.346-.824 1.88h-.938v-1.299a.43.43 0 0 0-.429-.431h-1.844zm1.844 2.593h1.646c.17 0 .323-.1.392-.257l.993-2.266a.435.435 0 0 0 .036-.175V6.997a.433.433 0 0 0-.036-.174l-.993-2.267a.427.427 0 0 0-.392-.257h-1.646a.43.43 0 0 0-.428.432v1.298h-.988V4.92a.43.43 0 0 0-.428-.432H13.12V2.414a.43.43 0 0 0-.428-.432H9.177V.863h3.516a.43.43 0 0 0 .428-.432.43.43 0 0 0-.428-.431H4.805a.43.43 0 1 0 0 .863H8.32v1.12H4.805a.43.43 0 0 0-.428.431v.798h-1.7a.43.43 0 0 0-.428.432v3.844H.857V3.644a.43.43 0 0 0-.429-.432.43.43 0 0 0-.428.432v8.551a.43.43 0 0 0 .428.431.43.43 0 0 0 .429-.431V8.351h1.392v3.844a.43.43 0 0 0 .428.431h2.675L8.05 14.9a.424.424 0 0 0 .275.101h6.81a.43.43 0 0 0 .428-.432v-2.07h.988v1.298a.43.43 0 0 0 .428.432z"
      />
    </defs>
    <use
      fill="currentColor"
      fillRule="evenodd"
      transform="translate(1 3)"
      xlinkHref="#icon-parts_svg__a"
    />
  </svg>
);

export default SvgIconParts;
