import React from 'react';

const SvgIconLogar = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-logar_svg__a"
        d="M11.667 16.25V3.333c0-.712.453-1.348 1.128-1.582l1.502-.501H8.958c-.574 0-1.041.468-1.041 1.042v1.25a.625.625 0 0 1-1.25 0v-1.25A2.293 2.293 0 0 1 8.958 0h9.375a.63.63 0 0 1 .092.007C19.29.062 20 .777 20 1.667v15c0 .711-.453 1.346-1.127 1.581l-5.009 1.67a1.744 1.744 0 0 1-.53.082c-.92 0-1.667-.748-1.667-1.667V17.5H8.958a2.293 2.293 0 0 1-2.291-2.292v-1.25a.625.625 0 0 1 1.25 0v1.25c0 .574.467 1.042 1.041 1.042h2.709zM7.033 9.375H.625a.625.625 0 0 1 0-1.25h6.409L4.975 6.067a.625.625 0 0 1 .884-.885l3.125 3.125a.625.625 0 0 1 0 .885L5.86 12.317a.624.624 0 1 1-.884-.885l2.058-2.057zm11.3-8.125a.49.49 0 0 0-.149.022l-4.986 1.662a.43.43 0 0 0-.281.4v15c0 .284.308.475.566.394l4.985-1.662a.431.431 0 0 0 .282-.4v-15a.417.417 0 0 0-.417-.416z"
      />
    </defs>
    <use
      fill="currentColor"
      fillRule="nonzero"
      xlinkHref="#icon-logar_svg__a"
      transform="translate(2 2)"
    />
  </svg>
);

export default SvgIconLogar;
