import React from 'react';

const SvgIconServices = props => (
  <svg width={32} height={23} viewBox="0 0 32 23" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.804 21.484a1.69 1.69 0 0 1-1.259.477 1.684 1.684 0 0 1-1.215-.576l-.004-.004-5.048-5.582a1.717 1.717 0 0 0-.322-.425l-.497-.48L13 12.177a.356.356 0 0 0-.402-.089 2.95 2.95 0 0 1-3.184-.6 2.773 2.773 0 0 1-.812-2.439l1.29 1.253a.36.36 0 0 0 .338.088l1.503-.387a.347.347 0 0 0 .25-.242l.398-1.46a.335.335 0 0 0-.091-.33L11 6.718a2.961 2.961 0 0 1 2.512.789c.84.816 1.083 2.03.618 3.093a.335.335 0 0 0 .091.39l2.089 1.783.003.003 1.327 1.132h.002l6.056 5.17c.002 0 .003.002.005.003a1.602 1.602 0 0 1 .1 2.403zm-8.342-4.172l-4.817 4.679c-.366.356-.92.424-1.335.19a1.174 1.174 0 0 0-.35-.994 1.253 1.253 0 0 0-1.053-.337c-.311-.409-.264-1.004.133-1.39l3.821-3.709a.116.116 0 0 0 .018-.02l.975-.946c.411-.4 1.08-.4 1.493-.002l.31.299-5.226 5.075a.335.335 0 0 0 0 .483.356.356 0 0 0 .497 0l5.213-5.063.544.602a1.005 1.005 0 0 1-.223 1.133zm5.013-8.127l.75.728-3.368 3.27-.798-.68 3.416-3.318zm.83-1.567l1.693-.837.674.654-.837 1.672-1.029.405-.918-.892.418-1.002zm3.693 12.615a2.298 2.298 0 0 0-.838-1.67l-5.774-4.928 3.534-3.432 1.303-.512a.349.349 0 0 0 .185-.167l1.006-2.009a.334.334 0 0 0-.068-.39l-1.03-1.002a.36.36 0 0 0-.409-.063L20.87 7.067a.346.346 0 0 0-.167.176l-.527 1.266-3.646 3.542-1.66-1.417a3.411 3.411 0 0 0-.86-3.61 3.687 3.687 0 0 0-3.812-.802.344.344 0 0 0-.22.247.335.335 0 0 0 .095.314l1.577 1.531-.294 1.079-1.11.285-1.578-1.531a.358.358 0 0 0-.578.122 3.435 3.435 0 0 0 .827 3.702 3.66 3.66 0 0 0 3.717.834l.903.999a1.77 1.77 0 0 0-1.18.498l-1.353 1.314a.34.34 0 0 0-.018.02l-3.443 3.342c-.342.332-.535.77-.543 1.234a1.66 1.66 0 0 0 .51 1.232l.026.026a.359.359 0 0 0 .362.081.553.553 0 0 1 .564.119c.144.14.191.349.123.544a.335.335 0 0 0 .1.367c.329.28.741.419 1.156.419.47 0 .942-.177 1.301-.527l4.817-4.678c.23-.224.39-.5.465-.8l4.372 4.835a2.419 2.419 0 0 0 1.817.815c.63 0 1.24-.244 1.688-.678.47-.457.724-1.089.697-1.734z"
    />
  </svg>
);

export default SvgIconServices;
