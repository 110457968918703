import React from 'react';

const SvgIconFilter = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-filter_svg__a"
        d="M19.925.464A.808.808 0 0 0 19.192 0H.808c-.315 0-.6.18-.733.464a.795.795 0 0 0 .116.854l7.163 8.394v5.665a.8.8 0 0 0 .446.716l3.677 1.822a.813.813 0 0 0 .786-.035.8.8 0 0 0 .383-.68V9.711l7.163-8.394a.795.795 0 0 0 .116-.854zm-8.703 8.438a.797.797 0 0 0-.191.517v6.485l-2.062-1.022V9.42a.797.797 0 0 0-.19-.517l-6.23-7.3h14.902l-6.23 7.3z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(2 3)">
      <mask id="icon-filter_svg__b" fill="#fff">
        <use xlinkHref="#icon-filter_svg__a" />
      </mask>
      <use fill="currentColor" fillRule="nonzero" xlinkHref="#icon-filter_svg__a" />
      <g fill="currentColor" mask="url(#icon-filter_svg__b)">
        <path d="M-2-3h24v24H-2z" />
      </g>
    </g>
  </svg>
);

export default SvgIconFilter;
