import React from 'react';

const SvgIconArrowUpBold = props => (
  <svg width={11} height={6} viewBox="0 0 11 6" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      stroke="currentColor"
      d="M4.756.722L.636 4.73a.445.445 0 0 0 0 .637.471.471 0 0 0 .653 0l3.794-3.691 3.794 3.69a.472.472 0 0 0 .654 0 .443.443 0 0 0 0-.637L5.411.721a.476.476 0 0 0-.655 0z"
    />
  </svg>
);

export default SvgIconArrowUpBold;
