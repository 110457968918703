import React from 'react';

const SvgIconCellphone = props => (
  <svg width={24} height={25} viewBox="0 0 24 25" {...props}>
    <defs>
      <path
        id="icon-cellphone_svg__a"
        d="M10.92 22H2.07C.927 22 0 21.04 0 19.855V2.145A2.175 2.175 0 0 1 .604.625 2.025 2.025 0 0 1 2.07 0h8.85a2.03 2.03 0 0 1 1.47.625c.39.402.61.95.61 1.52v17.71C12.995 21.041 12.065 22 10.92 22zM2.07 1.45a.684.684 0 0 0-.671.695v17.71c0 .384.3.696.671.696h8.85c.373 0 .676-.31.681-.696V2.145a.693.693 0 0 0-.68-.696H2.07zM6.5 19.071c-.608 0-1.1-.51-1.1-1.14 0-.63.492-1.14 1.1-1.14.608 0 1.1.51 1.1 1.14 0 .63-.492 1.14-1.1 1.14z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(6 1)">
      <mask id="icon-cellphone_svg__b" fill="#fff">
        <use xlinkHref="#icon-cellphone_svg__a" />
      </mask>
      <use fill="currentColor" fillRule="nonzero" xlinkHref="#icon-cellphone_svg__a" />
      <g fill="currentColor" mask="url(#icon-cellphone_svg__b)">
        <path d="M-6-1h24v24H-6z" />
      </g>
    </g>
  </svg>
);

export default SvgIconCellphone;
