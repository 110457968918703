import React from 'react';

const SvgIconShowCase = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-show-case_svg__a"
        d="M11.458 11.175v2.575h5.576v-2.575h-5.576zm-1.375 0H4.944v2.575h5.14v-2.575zm6.951 3.95H4.944V22H0V7.363L11.03 0 22 7.363V22h-4.966v-6.875zM1.375 8.099v12.526h2.194V9.8h14.84v10.825h2.216V8.097l-9.598-6.442-9.652 6.444z"
      />
    </defs>
    <use fill="currentColor" transform="translate(1 1)" xlinkHref="#icon-show-case_svg__a" />
  </svg>
);

export default SvgIconShowCase;
