import React from 'react';

const SvgIconSecurity = props => (
  <svg width={114} height={111} viewBox="0 0 114 111" {...props}>
    <defs>
      <path id="icon-security_svg__a" d="M0 .038h112.485v21.836H0z" />
      <linearGradient id="icon-security_svg__c" x1="48.839%" x2="51.407%" y1="10.48%" y2="85.131%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-security_svg__d" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-security_svg__e" x1="19.324%" x2="64.585%" y1="36.261%" y2="65.131%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-security_svg__f" x1="19.324%" x2="64.585%" y1="43.336%" y2="57.339%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-security_svg__g" x1="41.212%" x2="60.646%" y1="10.48%" y2="85.131%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-security_svg__h" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-security_svg__i" x1="27.234%" x2="60.824%" y1="9.241%" y2="94.889%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-security_svg__j" x1="49.621%" x2="50.459%" y1="10.48%" y2="85.131%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-security_svg__k" x1="19.324%" x2="64.585%" y1="48.12%" y2="52.071%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-security_svg__l" x1="48.569%" x2="51.734%" y1="10.48%" y2="85.131%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-security_svg__m" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-security_svg__n" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-security_svg__o" x1="19.324%" x2="64.585%" y1="38.358%" y2="62.822%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-security_svg__p" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-security_svg__q" x1="34.978%" x2="57.142%" y1="9.241%" y2="94.889%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-security_svg__r" x1="34.743%" x2="68.482%" y1="10.48%" y2="85.131%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-security_svg__s" x1="32.024%" x2="58.547%" y1="9.241%" y2="94.889%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-security_svg__t" x1="42.958%" x2="58.53%" y1="10.48%" y2="85.131%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-security_svg__u" x1="47.717%" x2="52.766%" y1="10.48%" y2="85.131%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-security_svg__v" x1="47.424%" x2="53.121%" y1="10.48%" y2="85.131%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-security_svg__w" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-security_svg__x" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-security_svg__y" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-security_svg__z" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-security_svg__A" x1="39.956%" x2="62.167%" y1="10.48%" y2="85.131%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-security_svg__B" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-security_svg__C" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-security_svg__D" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-security_svg__E" x1="19.324%" x2="64.585%" y1="10.777%" y2="93.198%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-security_svg__F" x1="19.324%" x2="64.585%" y1="28.594%" y2="73.575%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-security_svg__G" x1="19.324%" x2="64.585%" y1="28.726%" y2="73.43%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-security_svg__H" x1="19.324%" x2="64.585%" y1="20.936%" y2="82.009%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-security_svg__I" x1="26.626%" x2="78.316%" y1="39.8%" y2="59.067%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-security_svg__J" x1=".012%" x2="99.811%" y1="49.999%" y2="49.999%">
        <stop offset="0%" stopColor="#004381" />
        <stop offset="28.97%" stopColor="#00417D" />
        <stop offset="57.64%" stopColor="#003971" />
        <stop offset="86.1%" stopColor="#002D5D" />
        <stop offset="100%" stopColor="#002551" />
      </linearGradient>
      <linearGradient id="icon-security_svg__K" x1="26.626%" x2="78.316%" y1="19.183%" y2="77.394%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-security_svg__L" x1="36.205%" x2="66.711%" y1="10.48%" y2="85.131%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-security_svg__M" x1="45.64%" x2="52.927%" y1="-7.028%" y2="158.269%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-security_svg__N" x1="47.751%" x2="51.51%" y1="-7.028%" y2="158.269%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-security_svg__O" x1="26.626%" x2="78.316%" y1="17.022%" y2="79.315%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient id="icon-security_svg__P" x1="29.5%" x2="63.762%" y1="25.995%" y2="95.574%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient
        id="icon-security_svg__Q"
        x1="49.414%"
        x2="50.393%"
        y1="-7.028%"
        y2="158.269%"
      >
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient
        id="icon-security_svg__R"
        x1="49.023%"
        x2="50.656%"
        y1="-7.028%"
        y2="158.269%"
      >
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient
        id="icon-security_svg__S"
        x1="49.415%"
        x2="50.393%"
        y1="-7.028%"
        y2="158.269%"
      >
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient
        id="icon-security_svg__T"
        x1="49.661%"
        x2="50.228%"
        y1="-7.028%"
        y2="158.269%"
      >
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-security_svg__U" x1="67.225%" x2="41.495%" y1="84.586%" y2="51.9%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#E35563" />
      </linearGradient>
      <linearGradient id="icon-security_svg__V" x1="34.163%" x2="69.186%" y1="10.48%" y2="85.131%">
        <stop offset="0%" stopColor="#FF96B2" />
        <stop offset="100%" stopColor="#FF828D" />
      </linearGradient>
      <linearGradient
        id="icon-security_svg__W"
        x1="39.047%"
        x2="57.353%"
        y1="-7.028%"
        y2="158.269%"
      >
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-security_svg__X" x1="29.5%" x2="63.762%" y1="-2.111%" y2="148.934%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient id="icon-security_svg__Y" x1="29.5%" x2="63.762%" y1="23.517%" y2="100.279%">
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient
        id="icon-security_svg__Z"
        x1="38.511%"
        x2="57.713%"
        y1="-7.028%"
        y2="158.269%"
      >
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
      <linearGradient
        id="icon-security_svg__aa"
        x1="49.058%"
        x2="50.633%"
        y1="-7.028%"
        y2="158.269%"
      >
        <stop offset="0%" stopColor="#FE074E" />
        <stop offset="23.72%" stopColor="#F00641" />
        <stop offset="68.02%" stopColor="#CD031D" />
        <stop offset="100%" stopColor="#AF0000" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(.67 87.236)">
        <mask id="icon-security_svg__b" fill="#fff">
          <use xlinkHref="#icon-security_svg__a" />
        </mask>
        <path
          fill="#E9F6FF"
          d="M86.947 2.113c-7.921-.114-32.773 4.409-47.184.635C16.408-3.37-10.597 1.986 4.25 7.8 8.398 9.426-3.5 19.251 25.747 21.4c25.674 1.885 51.818-2.331 64.472-4.144 12.655-1.814 18.484-4.995 21.19-6.43 2.38-1.261 3.229-8.315-24.462-8.712"
          mask="url(#icon-security_svg__b)"
        />
      </g>
      <path
        fill="#D7D7D7"
        fillOpacity={0.5}
        d="M47.746 3.122h-9.805V34.99l22.06 17.156L82.062 34.99V3.122h-9.804v4.903h-7.354V.67h-9.806v7.354h-7.352z"
      />
      <path
        fill="#D7D7D7"
        d="M72.258 8.025h-7.354V.67h-5.14v51.29l.236.184L82.062 34.99V3.122h-9.804z"
      />
      <path
        fill="url(#icon-security_svg__c)"
        d="M24.3 52.883L23.28 65.96s-1.286-3.522-1.877-7.542a25.429 25.429 0 0 1-.292-3.648c-.002-5.254 3.057-2.38 3.188-1.888"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__d)"
        d="M23.274 45.46s-2.01 1.96-2.398 5.158c-.389 3.198-.229 2.139-.229 2.139s.571 1.258 2.741.755c2.17-.504-.114-8.052-.114-8.052"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__e)"
        d="M22.901 95.837c.121-.146.033-.53.033-.53s-2.107-.235-2.784-.075c-.177.041-.474.192-.753.354-.047.027-.131.189-.178.216-.084.05-.129-.035-.2.01a2.64 2.64 0 0 0-.323.223c-.185.966.061 1.356.061 1.356s1.734.54 2.13.18c1.005-.908 1.75-1.415 2.014-1.734"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__f)"
        d="M29.565 96.634l.301 1.57h1.6s.09-.222.224-.222c.115 0 .262.222.417.222h4.071s.072-.33-.017-.444c-.043-.059-.313-.16-.49-.21-.827-.233-3.193-.958-3.604-1.748-.501-.96-2.502.832-2.502.832"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__g)"
        d="M30.838 43.733l.383-1.902s1.35.104 1.613-.623c.53-1.459.087-3.79-.233-4.443-.911-1.86-1.296-5.117-5.555-2.527-1.62.985.881 5.761 1.067 6.795.185 1.033-.367 2.224-.367 2.224l.997.748 2.095-.272z"
        transform="translate(.67 .67)"
      />
      <path
        fill="#4674A5"
        d="M32.783 60.52c-.086.034-1.005.482-2.214 4.477 0 0-2.732 11.312-5.456 19.446a326.764 326.764 0 0 1-2.838 12.256c-.205.808-.435.662-.435.662l-1.75.195-.833-.723 2.5-14.225.104-.173 2.557-21.51 8.365-.406z"
      />
      <path
        fill="#4674A5"
        d="M34.876 59.91l.127 5.907c.1 4.616-.267 10.569-.613 13.472.064.406.08.845.041 1.305-.254 2.99-1.36 16.342-1.36 16.342s-3.034.889-3.06.597c-.444-4.771-.437-11.64-.157-14.044.183-1.57.383-2.977.542-4.016-.976-6.348-2.747-18.907-2.747-18.907l7.227-.656z"
      />
      <path
        fill="url(#icon-security_svg__h)"
        d="M23.136 45.601l3.577-1.623.987-.934 3.803-.09.665 1.095 2.948 1.278s.727 1.144.095 5.389c-.7 4.703-.85 11.813-.85 11.813l-11.145.537s-2.062-12.825-.08-17.465"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__i)"
        d="M31.4 37.311s-.482-.296-.556.074c-.075.37.185 1.184.408 1.26.221.074.294 1.073.294 1.073l-.591 1.11h-2.999s-.926-.406-1-1.073c-.075-.667-.815-5.37-.815-5.37l2.11-1.11s1.112-.556 1.89-.778c1.193-.342 1.443.296 1.405.63-.036.332.334.37.815.37.483 0 1.075.37.816.592-.108.092.37.385.182.738-.273.505-1.182.67-1.256 1.484-.033.37-.186 1.592-.703 1"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__j)"
        d="M41.64 58.658c-.204 1.966-.725 4.448-1.12 6.157.058 1.195.585 1.82.739 2.382.177.648.473 1.305.467 1.302-.593-.4-.571-.915-.753-1.186-.181-.27-.085-.197-.167.366-.046.322.428.924.683 1.353.221.37.224.593.224.593s-.491-.447-.872-1.004c-.459-.67-.826-1.484-.878-1.674-.095-.348-.047-1.416.102-2.17l.005-.024.107-5.702s.187-2.971 1.188-3.818c0 0 .623.085.276 3.425z"
        transform="translate(.67 .67)"
      />
      <path fill="#E9D6B8" d="M42.318 95.975l-.194 4.376-2.754.218-1.222-.15 2.429-.765.27-3.64z" />
      <path
        fill="url(#icon-security_svg__k)"
        d="M41.241 100.242l-.6.078-.103-.169-2.278.032-1.103-.289s-.045-.195.058-.253c.31-.177 1.49-.27 1.49-.27s-.156.218-.031.315c.123.097 1.64.043 2.016-.018.647-.109.853-.3.873-.248.05.14-.322.822-.322.822"
        transform="translate(.67 .67)"
      />
      <path
        fill="#4674A5"
        d="M41.48 64.226l-.372 16.556-1.127 16.248 2.482.195s2.319-16.37 2.594-17.786c.275-1.417 3.214-12.775 1.844-15.674-1.37-2.899-5.421.461-5.421.461"
      />
      <path
        fill="url(#icon-security_svg__l)"
        d="M50.525 54.345c1.135 1.37 1.81 6.234 2.017 7.95l.008.01c.014.047.024.163.031.322l.03.273s-.005.028-.016.069l.003.097c.02.62.048 1.43.165 1.71.185.45.242 1.664.242 1.664l-.087 1.136-.314.628-.579.637.137-.5s.195-.23.297-.526c.103-.297.028-1.41.028-1.41s-.245-.094-.229.118c.017.213-.015 1.02-.015 1.02s-.193-.4-.266-.91c-.041-.298.14-1.442.005-2.198-.114-.639-.487-2.07-.6-2.496-.432-.964-1.01-2.584-1.776-5.242-.766-2.653.577-2.764.92-2.352z"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__m)"
        d="M43.13 50.495l-.891 9.33v.543l-2.672-.156s.774-7.2.89-7.549c.117-.349.388-3.369.35-3.91-.04-.543.076-3.253 1.858-2.982 1.78.272.464 4.724.464 4.724"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__n)"
        d="M48.902 50.495l.89 9.33v.543l2.672-.156c-1.204-5.458-1.475-6.543-1.51-7.549-.012-.367-.078-3.369-.039-3.91.038-.543.074-2.014-1.549-2.982-1.547-.923-.464 4.724-.464 4.724"
        transform="translate(.67 .67)"
      />
      <path
        fill="#E9D6B8"
        d="M46.694 95.472l.039 5.615 2.323-.814s.982-.712.135-.62c-1.24.135-.987-.387-1.026-1.394-.038-1.006.154-2.942.154-2.942l-1.625.155z"
      />
      <path
        fill="url(#icon-security_svg__o)"
        d="M48.695 98.983s-.264.247-.543.425c-.27.174-1.051.56-1.354.62-.388.077-.755.04-.755.04s-.175.579.212.541c.387-.039 2.324-.89 2.595-1.084.27-.193.31-.503-.155-.542"
        transform="translate(.67 .67)"
      />
      <path
        fill="#4674A5"
        d="M47.21 64.536l-.295 15.184-.273 16.1 1.961-.103s1.98-16.206 2.146-17.638c.167-1.433 2.54-10.964.953-13.75-1.588-2.785-4.492.207-4.492.207"
      />
      <path
        fill="url(#icon-security_svg__p)"
        d="M46.153 45.048s-3.047.206-3.77.516c-.722.31-.567 3.873-.104 6.505.466 2.632.259 5.576-.412 7.228-.672 1.651-1.187 4.8-1.187 4.8l10.687-.154s-2.427-6.556-2.427-9.086c0-2.53.67-8.312.619-8.88-.05-.567-2.735-.929-3.406-.929"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__q)"
        d="M41.573 44.023a.134.134 0 0 1-.01-.015c.665-.8 1.046-1.698 1.046-3.015 0-.558-.047-.76-.07-1.59l.004-.003c-.007-.078-.018-.155-.018-.235V38.16a2.676 2.676 0 0 1 2.676-2.676h.67a2.676 2.676 0 0 1 2.676 2.676v1.977a.053.053 0 0 0 .01.002l-.01.187v.108c.022 1.578.81 2.97 2.015 3.847A2.53 2.53 0 0 1 52 46.562a2.53 2.53 0 0 1-2.158 2.5 3.71 3.71 0 0 1-3.51 2.453 3.727 3.727 0 0 1-3.262-1.895c-1.343-.547-2.287-1.845-2.287-3.358 0-.845.295-1.621.789-2.24"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__r)"
        d="M60.752 76.307s-.487-1.49-.522-1.715c-.034-.224-1.515-.759-1.035-1.722.26-.524 1.754-3.068 2.957-2.958 1.049.097 2.757.64 2.625 2.01-.133 1.37-1.468 2.05-1.95 2.469-.341.298-.43 2.163.17 2.535.246.152-2.245-.62-2.245-.62"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__s)"
        d="M61.52 68.08c.049.353.408.84.576.452.168-.387.853.665.73-.07-.123-.734 1.634-.293 1.915.168.219.359.647 1.182.801.955.136-.2-.157-.657.49.23.497.684.19 1.663.141 2.112.012.067.022.141.029.22l.026.018c.18.053.086.172-.018.461-.036.618-.27 1.298-1.021 1.532-.602.187-.312.199-.738.426-.426.227-1.37.736-1.877.652-.442-.074-.76-.338-1.267-1.183-.208-.346.009-1.254.15-1.818-.136.008-.26.063-.32.212l-.168.423-.169-.507s.406-.562.725-1.098a1.282 1.282 0 0 1-.002-.073 2.451 2.451 0 0 1-.358-.596c-.668-1.56.304-2.869.354-2.516z"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__t)"
        d="M66.803 82.42s.653 1.76.88 2.47c.227.709.482 1.078.738 1.333.255.255-.057.426-.114.681-.057.256-.17.568-.17.568s-.114-.397-.256-.568c-.141-.17-.539-.227-.596-.567a4.83 4.83 0 0 0-.596-1.703c-.426-.738-1.249-1.59-.965-2.1.284-.512 1.079-.114 1.079-.114"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__u)"
        d="M59.36 93.12s-.107 1.64 0 2.342c.106.702.383 3.087.468 3.576.085.49 1.426.447 1.256-.17-.17-.618.085-1.66.191-2.512.107-.852.234-2.597-.021-3.364-.255-.766-1.895.128-1.895.128"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__v)"
        d="M62.625 92.865l1.907-.043s.023.49-.136 1.32c-.16.83-.704 2.767-.727 3.491-.022.724.568 1.448 0 1.235-.567-.213-1.044.277-.976.021.068-.255.045-2.214-.091-3.108-.136-.894-.159-2.533.023-2.916"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__w)"
        d="M58.487 87.87l.085 4.54-.142.994 3.236.085.312-3.15.198-1.249 1.363-1.022z"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__x)"
        d="M58.43 92.893l3.264.142-.029.454-3.207.029z"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__y)"
        d="M64.873 88.068l.284 4.769H62.12l-.256-2.896s.738-1.844.852-1.844c.113 0 2.157-.029 2.157-.029"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__z)"
        d="M62.063 92.581l3.15.057-.028.369-3.094.028z"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__A)"
        d="M52.441 66.696s.766.766.851 1.078c.086.313 1.25 2.924 1.278 3.094.028.17.653.965.88 1.39.227.426 1.078 1.221 1.873 1.76.795.54.142 1.42.142 1.42s-2.753-1.817-3.32-2.611c-.569-.795-1.222-3.577-1.278-3.86-.057-.285-.483 0-.596-.37a24.246 24.246 0 0 1-.284-1.078s.284-.738.454-.823"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__B)"
        d="M59.99 75.608s-2.809-1.959-2.922-2.186c-.114-.227-.937 1.22-1.08 1.789-.141.567-.311 1.333.086 1.532.398.199 2.725 1.448 3.122 2.186.398.738.795-3.321.795-3.321"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__C)"
        d="M63.88 76.46c.041-.168 1.05.141 1.76 1.22.709 1.079 1.362 4.882 1.362 4.882s-.341.454-.682.51c-.34.057-1.476-.226-1.845-.085-.369.142-.624-6.414-.596-6.527"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__D)"
        d="M62.517 76.005s-2.44-.567-2.753-.652c-.312-.086-1.107 2.327-1.079 4.314.029 1.987.313 3.037-.198 4.74-.511 1.703-.227 3.718-.227 3.718s2.866.71 5.023.426c2.157-.284 2.13-.142 2.3-.142.17 0-1.164-4.23-.91-7.408.257-3.18-.226-4.456-.765-4.655-.54-.199-1.39-.34-1.39-.34"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__E)"
        d="M60.02 98.697s.936-.617 1.234-.191c.298.426.383.98.383.98s-.468 1.106-.638 1.234c-.17.127-1.512.32-1.512.32s-.745-.938-.553-1.172a.635.635 0 0 1 .51-.255c.107 0 .448-.809.575-.916"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__F)"
        d="M60.466 99.528c-.127.255-.851 1.298-.979 1.277-.097-.016-.51-.66-.508-.986-.015.016-.03.03-.045.05-.192.233.553 1.17.553 1.17s1.341-.192 1.512-.32c.17-.127.638-1.234.638-1.234-.277-.851-1.043-.213-1.17.043"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__G)"
        d="M62.723 99.08s.362-.531.66-.489c.333.048.532.107.617.447.085.34 0 .703 0 .703l-1.362.021s-.32-.639-.235-.894c.086-.256.298-.34.32-.277.021.064 0 .49 0 .49"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__H)"
        d="M63.383 98.591c-.198-.028-.596.362-.554.681.025.187.153.34.34.34h.853a1.592 1.592 0 0 0-.022-.574c-.085-.34-.284-.4-.617-.447"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__I)"
        d="M36.894 51.118s.972 2.592 1.687 4.555c1.336.365 6.486 2.137 6.533 2.02.051-.133 1.13-1.329 1.392-1.479.263-.15 1.355-.48 1.355-.48s.881-.564.883-.31c.001.253-.619.668-.941.845-.324.176.062.227.307.12.244-.106.865-.266.908-.503.042-.237.365-.413.365-.413l-.116 1.322s-.766.915-1.263 1.276c-.42.306-.773.348-.872.354l-.028.001-9.61.47a.098.098 0 0 1-.04-.03.24.24 0 0 1-.098-.044c-.428-.324-4.387-5.521-4.41-7.36-.024-1.838 3.948-.344 3.948-.344z"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__J)"
        d="M33.375 44.573s2.994 1.149 3.449 4.316l.455 3.168s-3.553 1.13-4.053.983c-.5-.147-2.927-7.89.15-8.467"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__K)"
        d="M94.95 62.634l1.434-1.103.157-.222-.27-.316-.337.094-.09-1.029-1.681-.047-.899-.27 1.186 2.65z"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__L)"
        d="M97.916 51.887l-4.639 7.726s.353.974.728.374c.046-.072-.258-.234.058-.473 1.28-.966 3.528-2.66 4.565-4.007 1.361-1.772 1.423-4.142 1.423-4.142l-2.135.522z"
        transform="translate(.67 .67)"
      />
      <path fill="#E7F5FE" d="M96.562 59.531l-1.688-1.161 1.281-1.861 1.688 1.162z" />
      <path
        fill="url(#icon-security_svg__M)"
        d="M92.8 42.967l4.764 6.594-3.03 7.397s2 1.978 2.44 1.478c3.543-4.024 4.768-9.132 4.768-9.132l-6.099-10.53s-.693-1.667-2.089-1.388c-2.786.557-1.089 4.87-.755 5.581"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__N)"
        d="M83.774 54.584l5.44.135-.122 1.448 4.09.288.66 17.915-.007.013L95.556 92.5l-3.321.87s-2.056-11.91-2.423-16.837a5.268 5.268 0 0 1-.08-.286c-.603-2.412-1.192-5.924-1.637-8.887-.308 3.252-.595 5.999-.732 6.545a57.36 57.36 0 0 0-.09 2.763c-.022 3.013-.685 10.934-1.247 15.844-.034.3-2.395.015-2.395.015s-.739-16.375-.914-19.467a4.46 4.46 0 0 1 .107-1.225c-.078-2.555-.008-6.681.094-10.851.073-2.93.856-6.4.856-6.4z"
        transform="translate(.67 .67)"
      />
      <path
        fill="#4674A5"
        d="M82.694 94.515c-.118.14-.044.52-.044.52s1.983.279 2.627.139c.167-.037.452-.178.72-.329.046-.026.129-.182.173-.209.08-.047.12.037.19-.007.18-.11.307-.198.309-.21.198-.943-.025-1.33-.025-1.33s-1.679-.626-2.006-.227c-.77.941-1.688 1.346-1.944 1.653m10.374-1.17l.193 1.554 1.509.088s.096-.212.224-.205c.107.007.233.232.38.24.317.02.67.04.81.048l3.028.178s.088-.32.01-.435c-.038-.06-.285-.174-.45-.234-.766-.273-2.956-1.114-3.298-1.91-.416-.97-2.406.676-2.406.676"
      />
      <path
        fill="#E9D6B8"
        d="M74.208 44.743s-2.205-1.823-5.368-5.599c-1.069-1.275-.544.108-.364 1.709.18 1.6 3.15 4.77 6.411 5.656 3.26.886-.68-1.766-.68-1.766"
      />
      <path
        fill="url(#icon-security_svg__O)"
        d="M68.424 39.47s.506-1.715.244-2.143c-.262-.428-1.318-1.925-1.318-1.925l-.302-.356s-.55.142.171 1.139c.721.999.123.718.123.718l-.973-.636-1.248-.682-.518-.378s-.279-.237-.39-.234c-.321.013-.193.273-.193.273l1.897 1.653-2.135-1.013s-.293-.081-.386.058c-.087.133 0 .18 0 .18l2.166 1.758-1.156-.39s-.29-.07-.386.06c-.105.144-.137.18-.137.18l4.261 2.161.28-.423z"
        transform="translate(.67 .67)"
      />
      <path fill="#E7F5FE" d="M69.023 42.416l-1.055-1.423 1.767-1.31 1.055 1.423z" />
      <path
        fill="url(#icon-security_svg__P)"
        d="M86.739 39.583s.28 1.345-4.61 6.779c-2.732 3.036-4.244 4.504-5.603 3.825-2.764-1.382-9.306-7.858-9.306-7.858l2.566-3.055s6.197 5.205 7.148 5.75c.4.228 2.866-3.47 5.386-7.204.457-.678 4.089 1.395 4.419 1.763"
        transform="translate(.67 .67)"
      />
      <path
        fill="#D1DBEA"
        d="M83.341 39.033l3.614-2.015 2.712-.636 1.52.848s3.175 1.904 3.493 1.904c.318 0-.106 17.777-.106 17.777l-8.145.282-2.965.247-.123-18.407z"
      />
      <path
        fill="url(#icon-security_svg__Q)"
        d="M85.09 61.897l-.07-21.836 1.16-3.603-4.067 1.347-.233 22z"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__R)"
        d="M89.671 36.347l1.235 2.363-.235 14.815.976 8.512 3.07-1.256.562-20.099-.952-3.324z"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__S)"
        d="M84.593 49.362l-1.248-8.604.837-.628-.907-.767.788-2.486 2.221-.74-.985 3.924-.489 13.883z"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__T)"
        d="M86.434 38.018s-1.246 5.654-.151 16.053c.07.673.918 1.042.918 1.042l.831-1.067s-.882-8.94-.981-15.43c-.013-.827-.648.175-.617-.598"
        transform="translate(.67 .67)"
      />
      <path
        fill="#B0BFDE"
        d="M87.547 38.05l.934 1.286 1.937-2.212v-.371zm-.545-1.405l-.696 2.786.928-1.16z"
      />
      <path
        fill="url(#icon-security_svg__U)"
        d="M90.062 34.292c-.097.46-.265 1.17-.45 1.587-.29.647-2.798 1.48-3.183 1.573-.387.093.097-1.945.097-2.407 0-.355.227-.243-.047-.753h3.583z"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__V)"
        d="M87.28 27.858l.099.009 3.234.517a.729.729 0 0 1 .613.736l-.01.099-.333 2.075.25.04c.11.018.19.108.203.214l-.001.066-.206 1.277a.243.243 0 0 1-.213.202l-.065-.002-.25-.04-.153.959a.73.73 0 0 1-.735.613l-.099-.01-3.235-.519a.728.728 0 0 1-.612-.736l.01-.098.155-.964a.241.241 0 0 1-.149-.196l.002-.068.204-1.276a.244.244 0 0 1 .278-.203l-.057-.001.335-2.082a.728.728 0 0 1 .639-.608l.096-.004z"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__W)"
        d="M86.382 29.456s-.36-.532-.394-.83c-.017-.151.21-.673.603-.826.393-.15.63.286.63.286s-.625.227-.674.342c-.05.116-.165 1.028-.165 1.028"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__X)"
        d="M91.2 31.427s.784-1.763.781-2.316c-.004-.555-.004-.938-.652-1.104-.971-.246-1.018-.04-1.448-.416-.432-.375-1.178-1.236-1.462-1.355-.476-.2-1.274-.159-1.453.765-.133.682-.593.78-.584.808.025.09.192.197.48.404.828.593 1.352.77 2.069.7.718-.069 2.062-.253 2.13.28.069.534-.24 2.081-.201 2.21.042.13.206.096.34.024"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__Y)"
        d="M90.823 31.798l-.403 2.514c-.092.569-.519 1.78-3.73 1.264-1.901-.304-1.608-1.69-1.534-2.15.056-.349.62-1.332.852-1.5.008-.006-.124.778-.124.778s.517-.694 1.42-.55c1.458.234 1.653 1.14 2.298 1.229.497.067.997-.68 1.22-1.585"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__Z)"
        d="M86.43 37.452l.446-.073.306 1.077-.599.343-.464-.155z"
        transform="translate(.67 .67)"
      />
      <path
        fill="url(#icon-security_svg__aa)"
        d="M89.612 35.879c-.106.317 1.187 2.973 1.187 2.973l.67 12.732 1.434-10.686-.698-.907.768-.768-1.396-2.72-1.965-.624z"
        transform="translate(.67 .67)"
      />
    </g>
  </svg>
);

export default SvgIconSecurity;
