import React from 'react';

const SvgIconLookmaps = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-lookmaps_svg__a"
        d="M2.04 10.8H.8a.8.8 0 1 1 0-1.6h1.24A8.003 8.003 0 0 1 9.2 2.04V.8a.8.8 0 1 1 1.6 0v1.24a8.003 8.003 0 0 1 7.16 7.16h1.24a.8.8 0 1 1 0 1.6h-1.24a8.003 8.003 0 0 1-7.16 7.16v1.24a.8.8 0 1 1-1.6 0v-1.24a8.003 8.003 0 0 1-7.16-7.16zm1.34 0a6.67 6.67 0 0 0 5.82 5.82V14.8a.81.81 0 0 1 .003-.074 4.796 4.796 0 0 1-3.929-3.93.81.81 0 0 1-.074.004H3.38zm0-1.6H5.2a.81.81 0 0 1 .074.003 4.796 4.796 0 0 1 3.93-3.929A.81.81 0 0 1 9.2 5.2V3.38A6.67 6.67 0 0 0 3.38 9.2zm13.24 1.6H14.8a.81.81 0 0 1-.074-.003 4.796 4.796 0 0 1-3.93 3.929.81.81 0 0 1 .004.074v1.82a6.67 6.67 0 0 0 5.82-5.82zm0-1.6a6.67 6.67 0 0 0-5.82-5.82V5.2a.81.81 0 0 1-.003.074 4.796 4.796 0 0 1 3.929 3.93.81.81 0 0 1 .074-.004h1.82zM10 13.542a3.542 3.542 0 1 0 0-7.084 3.542 3.542 0 0 0 0 7.084zm0-1.459a2.083 2.083 0 1 1 0-4.166 2.083 2.083 0 0 1 0 4.166zm0-.833a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5z"
      />
    </defs>
    <use fill="currentColor" transform="translate(2 2)" xlinkHref="#icon-lookmaps_svg__a" />
  </svg>
);

export default SvgIconLookmaps;
