import React from 'react';

const SvgIconPasswordshow = props => (
  <svg width={16} height={17} viewBox="0 0 16 17" {...props}>
    <defs>
      <path
        id="icon-passwordshow_svg__a"
        d="M6.088 5.263c-.82.048-1.497-.581-1.443-1.345.042-.632.592-1.142 1.269-1.183.82-.048 1.497.581 1.443 1.345-.044.634-.594 1.144-1.269 1.183m.076 1.084c-1.521.09-2.778-1.08-2.683-2.5.079-1.172 1.098-2.121 2.354-2.194 1.522-.09 2.78 1.08 2.684 2.5-.081 1.17-1.1 2.119-2.355 2.194M6 .667C3.707.667 1.628 1.836.094 3.735a.419.419 0 0 0 0 .528C1.628 6.164 3.707 7.333 6 7.333c2.293 0 4.372-1.169 5.906-3.068a.419.419 0 0 0 0-.528C10.372 1.836 8.293.667 6 .667"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(2 4)">
      <mask id="icon-passwordshow_svg__b" fill="#fff">
        <use xlinkHref="#icon-passwordshow_svg__a" />
      </mask>
      <use fill="#919191" xlinkHref="#icon-passwordshow_svg__a" />
      <g fill="#54575F" mask="url(#icon-passwordshow_svg__b)">
        <path d="M-2-4h15.92v15.92H-2z" />
      </g>
    </g>
  </svg>
);

export default SvgIconPasswordshow;
