import React from 'react';

const SvgIconButtonFloaterFilters = props => (
  <svg width={54} height={54} viewBox="0 0 54 54" {...props}>
    <defs>
      <linearGradient
        id="icon-button-floater-filters_svg__c"
        x1="87.981%"
        x2="16.828%"
        y1="100%"
        y2="100%"
      >
        <stop offset="0%" stopColor="#EB0000" />
        <stop offset="100%" stopColor="#FE074E" />
      </linearGradient>
      <circle id="icon-button-floater-filters_svg__b" cx={22} cy={22} r={22} />
      <filter
        id="icon-button-floater-filters_svg__a"
        width="138.6%"
        height="138.6%"
        x="-19.3%"
        y="-14.8%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={2.5} />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.350360577 0"
        />
      </filter>
      <path id="icon-button-floater-filters_svg__d" d="M0 0h20v4.8H0z" />
      <path id="icon-button-floater-filters_svg__f" d="M0 0h20v4.8H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(5 3)">
        <use
          fill="#000"
          filter="url(#icon-button-floater-filters_svg__a)"
          xlinkHref="#icon-button-floater-filters_svg__b"
        />
        <use
          fill="url(#icon-button-floater-filters_svg__c)"
          xlinkHref="#icon-button-floater-filters_svg__b"
        />
      </g>
      <path
        fill="#FFF"
        d="M23.094 23c-1.019 0-1.887.669-2.21 1.6H17v1.6h3.884c.323.931 1.191 1.6 2.21 1.6 1.018 0 1.887-.669 2.21-1.6H37v-1.6H25.303c-.322-.931-1.19-1.6-2.21-1.6"
      />
      <g transform="translate(17 30.2)">
        <mask id="icon-button-floater-filters_svg__e" fill="#fff">
          <use xlinkHref="#icon-button-floater-filters_svg__d" />
        </mask>
        <path
          fill="#FFF"
          d="M12.89 0c-1.018 0-1.886.669-2.209 1.6H0v1.6h10.681c.323.931 1.191 1.6 2.21 1.6 1.018 0 1.887-.669 2.209-1.6H20V1.6h-4.9C14.778.669 13.91 0 12.89 0"
          mask="url(#icon-button-floater-filters_svg__e)"
        />
      </g>
      <g transform="translate(17 15)">
        <mask id="icon-button-floater-filters_svg__g" fill="#fff">
          <use xlinkHref="#icon-button-floater-filters_svg__f" />
        </mask>
        <path
          fill="#FFF"
          d="M16.584 1.6C16.262.669 15.394 0 14.375 0c-1.019 0-1.887.669-2.21 1.6H0v1.6h12.166c.322.931 1.19 1.6 2.209 1.6 1.019 0 1.887-.669 2.21-1.6H20V1.6h-3.416z"
          mask="url(#icon-button-floater-filters_svg__g)"
        />
      </g>
    </g>
  </svg>
);

export default SvgIconButtonFloaterFilters;
