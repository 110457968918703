import React from 'react';

const SvgIconError = props => (
  <svg
    id="icon-error_svg__Camada_1"
    width={157}
    height={143}
    x={0}
    y={0}
    viewBox="0 0 384 357"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.icon-error_svg__st1{fill:#f78092}.icon-error_svg__st2{fill:#ff1d46}'}</style>
    <path
      d="M375.12 259.5L257.67 56.1c-11.62-20.47-32.62-32.62-56.18-32.62-23.55 0-44.55 12.23-56.17 32.55L27.72 259.65c-11.7 20.48-11.62 44.85.23 65.18C39.65 344.92 60.57 357 83.97 357h234.6c23.47 0 44.55-12.23 56.4-32.7 11.77-20.33 11.85-44.55.15-64.8z"
      fill="#f9dcdf"
    />
    <path
      className="icon-error_svg__st1"
      d="M244.3 41.28c-11.12-20.47-31.21-32.62-53.74-32.62-22.52 0-42.61 12.23-53.73 32.55L24.34 244.84c-11.19 20.48-11.12 44.85.22 65.18 11.19 20.1 31.21 32.18 53.59 32.18h224.41c22.45 0 42.61-12.23 53.95-32.7 11.26-20.33 11.34-44.55.14-64.8L244.3 41.28zm87.82 253.5c-6.24 11.25-17.29 17.93-29.63 17.93H78.08c-12.2 0-23.17-6.52-29.27-17.55-6.17-11.17-6.24-24.52-.07-35.77L161.23 55.84c6.1-11.18 17-17.77 29.34-17.77 12.27 0 23.25 6.68 29.34 17.85l112.42 203.55c6.03 10.94 5.95 24.14-.21 35.31z"
    />
    <path
      className="icon-error_svg__st2"
      d="M355.97 301.49c11.77-20.33 11.85-44.55.15-64.8L238.67 33.28C227.05 12.81 206.05.66 182.49.66c-23.55 0-44.55 12.23-56.17 32.55L8.72 236.84c-11.7 20.48-11.62 44.85.23 65.18 11.7 20.1 32.62 32.18 56.02 32.18h234.6c23.47-.01 44.55-12.24 56.4-32.71zm-25.5-14.7c-6.52 11.25-18.07 17.93-30.98 17.93H64.89c-12.75 0-24.23-6.52-30.6-17.55-6.45-11.17-6.52-24.52-.07-35.77l117.6-203.55c6.38-11.18 17.77-17.77 30.67-17.77 12.83 0 24.3 6.68 30.68 17.85L330.7 251.48c6.29 10.93 6.22 24.13-.23 35.31zm0 0"
    />
    <path
      className="icon-error_svg__st1"
      d="M192.42 238.03c-10.2 0-18.75 8.55-18.75 18.75s8.55 18.75 18.75 18.75c9.82 0 18.75-8.55 18.3-18.3.45-10.72-8.02-19.2-18.3-19.2zM187.77 106.41c-8.93 2.55-14.48 10.65-14.48 20.48.45 5.92.82 11.92 1.28 17.85 1.27 22.57 2.55 44.7 3.82 67.27.45 7.65 6.38 13.2 14.02 13.2 7.65 0 13.65-5.93 14.03-13.65 0-4.65 0-8.93.45-13.65.82-14.47 1.72-28.95 2.55-43.42.45-9.38 1.27-18.75 1.72-28.12 0-3.38-.45-6.38-1.72-9.38-3.82-8.4-12.74-12.68-21.67-10.58z"
    />
    <path
      className="icon-error_svg__st2"
      d="M187.43 235.04c-10.2 0-18.75 8.55-18.75 18.75s8.55 18.75 18.75 18.75c9.82 0 18.75-8.55 18.3-18.3.45-10.73-8.02-19.2-18.3-19.2zm0 0M182.76 101.41c-8.93 2.55-14.48 10.65-14.48 20.48.45 5.92.82 11.92 1.28 17.85 1.27 22.57 2.55 44.7 3.82 67.27.45 7.65 6.38 13.2 14.02 13.2 7.65 0 13.65-5.93 14.03-13.65 0-4.65 0-8.93.45-13.65.82-14.47 1.72-28.95 2.55-43.42.45-9.38 1.27-18.75 1.72-28.13 0-3.38-.45-6.38-1.72-9.38-3.83-8.39-12.75-12.67-21.67-10.57zm0 0"
    />
  </svg>
);

export default SvgIconError;
