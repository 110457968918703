import React from 'react';

const SvgIconPartsSettings = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g fill="#54575F">
      <path d="M5.49 7.97a2.48 2.48 0 110-4.96 2.48 2.48 0 010 4.96zm0-4a1.52 1.52 0 10-.001 3.039A1.52 1.52 0 005.49 3.97zm13.02 17.02a2.48 2.48 0 110-4.96c1.37 0 2.49 1.11 2.49 2.48 0 1.37-1.12 2.48-2.49 2.48zm0-4a1.52 1.52 0 10-.001 3.039 1.52 1.52 0 00.001-3.039z" />
      <path d="M18.51 24c-3.03 0-5.49-2.46-5.49-5.49 0-.57.09-1.14.28-1.7L7.19 10.7c-.56.19-1.13.28-1.7.28C2.46 10.98 0 8.51 0 5.49S2.46 0 5.49 0c3.03 0 5.49 2.46 5.49 5.49 0 .57-.09 1.14-.28 1.7l6.12 6.12c.56-.19 1.13-.28 1.7-.28 3.03 0 5.49 2.46 5.49 5.49 0 3.03-2.47 5.48-5.5 5.48zM7.31 9.66c.12 0 .25.05.34.14l6.55 6.55c.13.14.18.34.11.51-.21.54-.32 1.1-.32 1.65 0 2.5 2.03 4.53 4.53 4.53 2.5 0 4.53-2.03 4.53-4.53 0-2.5-2.03-4.53-4.53-4.53-.55 0-1.1.11-1.65.32-.17.07-.38.03-.51-.11L9.8 7.65a.497.497 0 01-.1-.52c.21-.54.32-1.1.32-1.65 0-2.5-2.03-4.53-4.53-4.53C2.99.95.96 2.99.96 5.49s2.03 4.53 4.53 4.53c.55 0 1.1-.11 1.65-.32.05-.03.11-.04.17-.04z" />
      <path d="M14.83 12.48c-.12 0-.25-.05-.34-.14l-2.83-2.83a.476.476 0 01-.14-.34c0-.13.05-.25.14-.34l1.64-1.64c-.19-.56-.28-1.13-.28-1.7 0-3.03 2.46-5.49 5.49-5.49.9 0 1.77.23 2.61.68.13.07.22.2.25.35.02.15-.03.3-.14.41l-2.04 2.04 1.32 1.32 2.04-2.04c.11-.11.26-.16.41-.14.15.02.28.11.35.25.46.85.69 1.72.69 2.62 0 3.03-2.46 5.49-5.49 5.49-.57 0-1.14-.09-1.7-.28l-1.64 1.64c-.09.09-.21.14-.34.14zm-2.15-3.31l2.15 2.15 1.52-1.52c.14-.14.34-.18.51-.11.54.21 1.09.32 1.65.32 2.5 0 4.53-2.03 4.53-4.53 0-.53-.1-1.05-.29-1.55l-1.89 1.89c-.19.19-.49.19-.68 0l-2-2a.476.476 0 01-.14-.34c0-.13.05-.25.14-.34l1.89-1.89a4.43 4.43 0 00-1.55-.29c-2.5 0-4.53 2.03-4.53 4.53 0 .55.11 1.1.32 1.65.07.18.03.38-.11.52l-1.52 1.51zM5.49 24c-.89 0-1.77-.23-2.61-.68a.479.479 0 01-.25-.35c-.02-.15.03-.3.14-.41l2.04-2.04-1.32-1.32-2.04 2.04c-.11.11-.26.16-.41.14a.455.455 0 01-.35-.25C.23 20.28 0 19.41 0 18.51c0-3.03 2.46-5.49 5.49-5.49.57 0 1.14.09 1.7.28l1.64-1.64c.09-.09.21-.14.34-.14.13 0 .25.05.34.14l2.83 2.83c.19.19.19.49 0 .68l-1.64 1.64c.19.56.28 1.13.28 1.7 0 3.03-2.46 5.49-5.49 5.49zm-1.56-1.25c.51.19 1.03.29 1.55.29 2.5 0 4.53-2.03 4.53-4.53 0-.55-.11-1.1-.32-1.65a.46.46 0 01.11-.51l1.52-1.52-2.15-2.15-1.52 1.52c-.14.14-.34.18-.51.11-.54-.21-1.1-.32-1.65-.32-2.5 0-4.53 2.03-4.53 4.53 0 .53.1 1.05.29 1.55l1.89-1.89c.19-.19.49-.19.68 0l2 2c.19.19.19.49 0 .68l-1.89 1.89zm9.73-8.64c-.12 0-.25-.05-.34-.14l-2.64-2.64a.477.477 0 010-.68c.19-.19.49-.19.68 0L14 13.29c.19.19.19.49 0 .68-.09.09-.21.14-.34.14zm1.06 1.04c-.12 0-.23-.04-.32-.12a.466.466 0 01-.03-.67l.01-.01c.18-.2.48-.21.68-.03.19.18.21.48.03.68-.11.1-.24.15-.37.15z" />
    </g>
  </svg>
);

export default SvgIconPartsSettings;
