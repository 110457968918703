import React from 'react';

const SvgIconShowWhatsapp = props => (
  <svg width={30} height={30} viewBox="0 0 30 30" {...props}>
    <defs>
      <path id={`icon-show-whatsapp_svg__a${props.customId}`} d="M0 .06h29.37v29.328H0z" />
      <path id={`icon-show-whatsapp_svg__c${props.customId}`} d="M0 .06h29.37v29.328H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(.612 .612)">
        <mask id={`icon-show-whatsapp_svg__b${props.customId}`} fill="#fff">
          <use xlinkHref={`#icon-show-whatsapp_svg__a${props.customId}`} />
        </mask>
        <path
          fill="#82F485"
          d="M13.967.078C6.147.446-.023 6.897 0 14.65a14.37 14.37 0 0 0 1.598 6.56L.04 28.707a.569.569 0 0 0 .692.665l7.416-1.74a14.73 14.73 0 0 0 6.31 1.517c7.992.121 14.657-6.165 14.906-14.078C29.63 6.588 22.562-.328 13.967.078m8.849 22.58a11.48 11.48 0 0 1-8.13 3.337c-1.8 0-3.52-.4-5.118-1.188l-1.033-.51-4.547 1.067.957-4.601-.509-.987a11.175 11.175 0 0 1-1.25-5.17 11.26 11.26 0 0 1 3.368-8.053 11.576 11.576 0 0 1 8.131-3.336 11.48 11.48 0 0 1 8.131 3.335 11.26 11.26 0 0 1 3.368 8.053c0 3.015-1.216 5.922-3.368 8.054"
          mask={`url(#icon-show-me_svg__a${props.customId || ''})`}
        />
      </g>
      <path
        fill="#82F485"
        d="M22.497 18.732l-2.847-.809a1.069 1.069 0 0 0-1.05.274l-.696.701a1.047 1.047 0 0 1-1.128.236c-1.346-.54-4.179-3.03-4.902-4.277a1.017 1.017 0 0 1 .082-1.137l.607-.778a1.04 1.04 0 0 0 .131-1.066l-1.197-2.68a1.067 1.067 0 0 0-1.658-.375c-.795.665-1.737 1.675-1.852 2.794-.202 1.973.653 4.46 3.888 7.446 3.737 3.45 6.73 3.906 8.678 3.44 1.105-.266 1.988-1.327 2.546-2.196.38-.593.08-1.38-.602-1.573"
      />
      <mask id={`icon-show-whatsapp_svg__d${props.customId}`} fill="#fff">
        <use xlinkHref={`#icon-show-whatsapp_svg__c${props.customId}`} />
      </mask>
      <path
        fill="#FFF"
        d="M22.816 22.659a11.48 11.48 0 0 1-8.13 3.336c-1.8 0-3.52-.4-5.118-1.188l-1.033-.51-4.547 1.067.957-4.601-.509-.987a11.175 11.175 0 0 1-1.25-5.17 11.26 11.26 0 0 1 3.368-8.053 11.576 11.576 0 0 1 8.131-3.336 11.48 11.48 0 0 1 8.131 3.335 11.26 11.26 0 0 1 3.368 8.053c0 3.015-1.216 5.922-3.368 8.054M13.967.078C6.147.446-.023 6.897 0 14.65a14.37 14.37 0 0 0 1.598 6.56L.04 28.707a.57.57 0 0 0 .692.665l7.416-1.74a14.73 14.73 0 0 0 6.31 1.517c7.993.121 14.657-6.165 14.906-14.078C29.63 6.588 22.562-.328 13.967.078"
        mask={`url(#icon-show-whatsapp_svg__d${props.customId})`}
      />
      <path
        fill="#FFF"
        d="M21.885 17.507l-2.847-.809a1.069 1.069 0 0 0-1.05.274l-.696.702c-.294.295-.74.39-1.128.235-1.346-.539-4.18-3.03-4.903-4.277a1.017 1.017 0 0 1 .082-1.137l.608-.777a1.04 1.04 0 0 0 .131-1.067l-1.198-2.679a1.067 1.067 0 0 0-1.657-.376c-.795.665-1.738 1.675-1.852 2.794-.202 1.973.653 4.46 3.888 7.447 3.737 3.45 6.73 3.905 8.678 3.439 1.105-.265 1.988-1.327 2.546-2.196.38-.592.08-1.38-.602-1.573"
      />
      <path d="M0 0h30v30H0z" />
    </g>
  </svg>
);

export default SvgIconShowWhatsapp;
