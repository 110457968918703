import React from 'react';

const SvgIconArrowDownBold = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="icon-arrow-down-bold_svg__a"
        d="M6.566 18.74a1.902 1.902 0 0 0 0 2.705c.749.74 1.957.74 2.705 0l8.168-8.092a1.911 1.911 0 0 0-.001-2.707L9.27 2.556a1.926 1.926 0 0 0-2.704-.002 1.901 1.901 0 0 0-.001 2.707L13.368 12l-6.802 6.74z"
      />
    </defs>
    <use fill="currentColor" xlinkHref="#icon-arrow-down-bold_svg__a" />
  </svg>
);

export default SvgIconArrowDownBold;
